<template>
  <BaseModal
    name="Modal-Templates"
    id="Modal-Templates"
    idModal="Modal-Templates"
    size="xl"
    title="Selecione um template"
  >
    <div>
      <p class="desc">
        Escolha um dos templates exclusivos da {{ nameSystem }} para personaliza-lo da
        maneira que preferir!
      </p>
    </div>
    <div>
      <div class="selectCategory">
        <BaseSelect
          :selectModel="selectedCategory"
          placeholder="Selecionar Categoria"
          track-by="id"
          :loading="loadingCategories"
          :array="options_categories"
          trackname="name"
          :searchable="true"
          :multiple="false"
          noResult="Oops! Nenhuma categoria encontrada."
          noOptions="Pesquise pela categoria"
          selectLabel=""
          deselectLabel=""
          selectedLabel=""
          @select="handleCategoryChange($event)"
        >
        </BaseSelect>
      </div>
      <div
        v-if="templatesCopy && templatesCopy.length > 0"
        class="container-templates"
      >
        <div>
          <div class="box">
            <img
              class="img-conteudo mb-2 w-100"
              src="@/assets/img/icons/img_null.svg"
              alt="imagem-vazia"
            />
            <div
              style="
                display: flex;
                justify-content: space-between;
                align-items: center;
              "
              class="template-title"
            >
              <label> Página em branco </label>
              <div class="menu">
                <svg
                  @click="setNewPage()"
                  v-b-tooltip.hover
                  title="Editar template"
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="#9c9c9c"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-edit"
                >
                  <path
                    d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"
                  />
                  <path
                    d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
        <div
          v-for="(template, index) in templatesCopy"
          :key="index"
          class="box"
        >
          <img
            class="img-conteudo mb-2 w-100"
            :src="getMeta(template.metas, 'image')"
            alt="imagem-vazia"
            v-if="template && template.metas && template.metas.length"
          />
          <img
            v-else
            class="img-conteudo mb-2 w-100"
            src="@/assets/img/icons/img_null.svg"
            alt="imagem-vazia"
          />
          <div
            style="
              display: flex;
              justify-content: space-between;
              align-items: center;
            "
            class="template-title"
          >
            <label>
              {{
                template.title.length > 23
                  ? template.title.substr(0, 23) + "..."
                  : template.title
              }}
            </label>
            <div class="menu">
              <svg
                @click="viewTemplate(template)"
                v-b-tooltip.hover
                title="Visualizar template"
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#9c9c9c"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-eye"
              >
                <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z" />
                <circle cx="12" cy="12" r="3" />
              </svg>

              <svg
                @click="setaTemplate(template)"
                v-b-tooltip.hover
                title="Editar template"
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#9c9c9c"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-edit"
              >
                <path
                  d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"
                />
                <path
                  d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="loading">
        <p class="nao-encontrado">
          Nenhum template foi encontrado
          {{ selectedCategory ? "nessa categoria" : "" }}.
        </p>
      </div>

      <div class="view-and-edit"></div>
    </div>
  </BaseModal>
</template>

<script>
import PageListService from "@/services/resources/PageListService";
import Vue from "vue";
const PaginaListService = PageListService.build();
export default {
  name: "ModalTemplates",
  props: ["templates", "categories"],
  components: {},
  data() {
    return {
      selectedCategory: null,
      templatesCopy: [],
      options_categories: [],
      loadingCategories: false,
    };
  },
  computed:{
    nameSystem() {
      return this.$store.getters["getNameSystem"];
    },
  },  methods: {
    getCategories() {
      if (!this.categories || this.categories == [] || this.categories == "") {
        this.loadingCategories = true;
      } else {
        this.loadingCategories = false;
        this.options_categories = this.categories.filter(
          (category) => category.status === "active"
        );
        this.options_categories.unshift({
          id: null,
          name: "Todas as categorias",
          status: "all",
        });
      }
    },

    handleCategoryChange(newCategory) {
      this.selectedCategory = newCategory;

      if (
        newCategory.status === "all" ||
        newCategory === null ||
        newCategory === ""
      ) {
        this.loadingCategories = true;
        if (this.templates && this.templates.length > 0) {
          this.templatesCopy = this.templates;
          this.loadingCategories = false;
          return;
        }
      } else {
        this.templatesCopy = (newCategory.templates || []).filter(
          (template) => template.status === "publish"
        );
      }
    },
    closeModal(data) {
      this.$bvModal.hide(data);
    },
    setNewPage() {
      this.$emit("newPage");
      this.$bvModal.hide("Modal-Templates");
    },
    viewTemplate(template) {
      this.$emit("viewTemplate", template, true);
      this.$bvModal.hide("Modal-Templates");
    },
    setaTemplate(template) {
      this.$emit("enviarTemplate", template);
      this.$bvModal.hide("Modal-Templates");
    },
    fetchPageListService(search = null) {
      let data = {
        page: this.current_page,
        status: this.status ? "save" : "trash",
      };

      if (this.search) {
        data.search = this.search;
      }

      // this.loading = true;

      PaginaListService.create(data)
        .then((response) => {
          if (response.data.length < response.per_page) {
            this.current_page = "stop";
          }
          this.paginas = this.paginas.concat(response.data);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getMeta(metas, chave) {
      var result =
        metas.find((x) => x.meta_key == chave) &&
        metas.find((x) => x.meta_key == chave).meta_value
          ? metas.find((x) => x.meta_key == chave).meta_value
          : "";
      if (!result) {
        if (chave === "image") {
          result = "/img/img_null.f2037356.svg";
        } else {
          result = null;
        }
      }
      return result;
    },
    getNextPage() {
      const listElm = document.querySelector("div#page-list");
      if (listElm) {
        listElm.addEventListener("scroll", () => {
          if (
            listElm.scrollTop + listElm.clientHeight >= listElm.scrollHeight &&
            this.current_page !== "stop"
          ) {
            this.current_page += 1;
            this.fetchPageListService();
          }
        });
      }
    },
  },
  mounted() {
    setTimeout(() => {
      this.getCategories();
      this.getNextPage();
      this.selectedCategory = this.options_categories.filter(
        (x) => x.status === "all"
      );
    }, 500);
  },
  watch: {
    templates() {
      if (this.templatesCopy.length) {
        this.templatesCopy.unshift({
          id: null,
          name: "Selecione todos os templates",
        });
      }
      this.templatesCopy = [...this.templates];
    },
    categories: {
      handler: "getCategories",
      immediate: true,
    },
  },
};
</script>

<style scoped lang="scss">
.container-templates {
  padding: 30px 0px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
}

.box {
  border: 1px solid #eee;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  transition: all 0.3s;

  .menu {
    transform: translateY(35px);
    transition-delay: 0.5s;

    transition: all 0.3s;
  }

  &:hover {
    transform: scale(0.99);

    .menu {
      display: flex;
      transform: translateY(0);
      transition-delay: 0.5s;
      transition: all 0.3s;
    }
  }
}

.template-title {
  margin: 13px 20px;
  label {
    margin: 0 !important;
  }
  div {
    display: flex;
    padding-left: 10px;
    gap: 20px;
    transition: all 0.3s;
    svg {
      cursor: pointer;
    }
  }
}
.img-conteudo {
  height: 175px;
  object-fit: cover;
  border-radius: 10px 10px 0 0;
}
.header {
  padding: 30px;
  display: flex;
  justify-content: space-between;
}

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}

.loading {
  display: grid;
  place-content: center;
  height: 60%;
}
.nao-encontrado {
  margin: 0px auto;
  width: 480px;
  font-size: 17px;
}

.selectCategory {
  width: 100%;
  margin-top: 30px;
  width: 290px !important;
}

.desc {
  color: #81858e;
  font-size: 14px;
  position: fixed;
  z-index: 99999;
  height: 40px;
  padding-top: 10px;
  background-color: #fff;
  width: 100%;
  margin-top: -25px !important;
}
</style>
