<template>
  <div id="SideRightTabs">
    <b-card no-body>
      <b-tabs pills justified small style="width: 100%">
        <b-tab title="Conteúdo" active>
          <!-- Controle de imagens -->
          <div class="separador"></div>
          <div class="content">
            <div class="container-links">
              <div class="half">
                <label class="titulo">Imagens</label>
                <div class="contain-buttons" style="margin-top: -15px">
                  <button
                    class="btn button-add-produto"
                    @click="addImg"
                  ></button>
                </div>
              </div>
            </div>
            <div v-for="(slide, idx) in editaItem.propsData.slides" :key="idx">
              <div class="p-2" style="position: relative;">
                <div class="separador" style="position:absolute; top: 17px; left: -3px;"></div>
                <label class="subtitulo component-group-title">Imagem {{ idx + 1 }}</label>
              </div>
              <div style="display: flex">
                <div class="select-image" style="width: calc( 100% - 35px )">
                  <div @click="openModal('Upload-Editor', idx)">
                    <img :src="editaItem.propsData.slides[idx].imagem ? editaItem.propsData.slides[idx].imagem : 'https://gdigital.s3.amazonaws.com/gdigital/8/imagem-vazia%20%28200%C2%A0%C3%97%C2%A0200%C2%A0px%29.webp'" alt="Imagem preview">
                  </div>
                  <div>
                    <input type="text" v-model="editaItem.propsData.slides[idx].imagem">
                    <span @click="openModal('Upload-Editor', idx)">{{ editaItem.propsData.slides[idx].imagem ? 'Alterar imagem' : 'Selecionar imagem'}}</span>
                  </div>
                </div>
                <div class="image-control">
                  <div>
                    <img
                      :class="{ 'disabled-arrow' : idx == 0}"
                      class="first-arrow"
                      src="@/assets/editor/chevron-down.svg"
                      @click="idx == 0 ? '' : moveUp(idx)"
                      title="Mover para cima"
                    />
                    <button
                      class="button-remove-produto"
                      @click="removeImgIndex(idx)"
                    ></button>
                    <img
                      :class="{ 'disabled-arrow' : idx + 1 == editaItem.propsData.slides.length}"
                      class="last-arrow"
                      src="@/assets/editor/chevron-down.svg"
                      @click="idx + 1 == editaItem.propsData.slides.length ? '' : moveDown(idx)"
                      title="Mover para baixo"
                    />
                  </div>
                </div>
              </div>
              <div class="space"></div>
            </div>
          </div>
          <div class="separador"></div>
        </b-tab>

        <b-tab title="Estilo">
          <div class="separador"></div>

          <!-- Altura e largura -->
          <div class="content">
            <div class="label-icon">
              <label class="titulo">Dimensões das fotos</label>
              <img
                src="@/assets/editor/globe.svg"
                alt="monitor"
                style="filter: invert(50%) !important"
                @click="nextIcon('desktop'), (global = !global)"
                v-if="global"
              />
              <img
                src="@/assets/editor/monitor.svg"
                alt="monitor"
                style="filter: invert(50%) !important"
                @click="nextIcon('tablet')"
                v-if="typeView === 'desktop' && !global"
              />
              <img
                src="@/assets/editor/tablet.svg"
                alt="tablet"
                style="filter: invert(50%) !important"
                @click="nextIcon('mobile')"
                v-if="typeView === 'tablet' && !global"
              />
              <img
                src="@/assets/editor/phone.svg"
                alt="phone"
                style="filter: invert(50%) !important"
                @click="nextIcon('desktop'), (global = !global)"
                v-if="typeView === 'mobile' && !global"
              />
            </div>
            <div class="half">
              <div>
                <label class="subtitulo">Altura</label>
                <section id="Altura mínima" class="und_form">
                  <section class="und_form">
                    <input
                      v-if="editaItem.propsData.size[typeView].und_height == 'px'"
                      type="number"
                      max="1000"
                      min="50"
                      step="10"
                      v-model="editaItem.propsData.size[typeView].height"
                      @focus="openDropdownSlider('editaItem.propsData.size[typeView].height')"                      
                    />
                    <input
                      v-else
                      type="number"
                      max="1000"
                      min="50"
                      step="10"
                      v-model="editaItem.propsData.size[typeView].smallerHeight"
                      @focus="openDropdownSlider('editaItem.propsData.size[typeView].smallerHeight')" 
                    />
                    <span
                      @click="next_und('und_height')"
                      class="und text validClick"
                      v-b-tooltip.hover
                      title="Largura em pixel"
                    >
                      {{ editaItem.propsData.size[typeView].und_height }}</span
                    >
                  </section>
                </section>
              </div>

              <div>
                <label class="subtitulo">Largura</label>
                <section id="Altura mínima" class="und_form">
                  <section class="und_form">
                    <input
                      v-if="editaItem.propsData.size[typeView].und_width == 'px'"
                      type="number"
                      max="1000"
                      min="50"
                      step="10"
                      v-model="editaItem.propsData.size[typeView].width"
                      @focus="openDropdownSlider('editaItem.propsData.size[typeView].width')"                   
                    />
                    <input
                      v-else
                      type="number"
                      max="1000"
                      min="50"
                      step="10"
                      v-model="editaItem.propsData.size[typeView].smallerWidth"
                      @focus="openDropdownSlider('editaItem.propsData.size[typeView].smallerWidth')"  
                    />
                    <span
                      @click="next_und('und_width')"
                      class="und text validClick"
                      v-b-tooltip.hover
                      title="Largura em pixel"
                    >
                      {{ editaItem.propsData.size[typeView].und_width }}</span
                    >
                  </section>
                </section>
              </div>
            </div>
          </div>
          <div class="separador"></div>

          <!-- Foco de cor -->
          <div class="content">
            <!-- Terá ou não o foco de cor -->
            <div style="display: flex; width: 100%; transition: all 0.3s">
              <label class="titulo">Foco de cor</label>
              <b-form-checkbox
                style="margin-top: -5px; margin-left: auto; margin-right: 0"
                v-model="editaItem.propsData.styles.colorFocus"
                size="lg"
                switch
              >
              </b-form-checkbox>
            </div>

            <!-- Posição do slide colorido -->
            <div class="label-icon">
              <label
                class="subtitulo"
                :class="
                  !editaItem.propsData.styles.colorFocus ? 'labelOff' : ''
                "
                >Posição do foco</label
              >
              <img
                src="@/assets/editor/globe.svg"
                alt="monitor"
                style="filter: invert(50%) !important; margin-top: 7px"
                @click="nextIcon('desktop'), (global = !global)"
                v-if="global"
              />
              <img
                src="@/assets/editor/monitor.svg"
                alt="monitor"
                style="filter: invert(50%) !important; margin-top: 7px"
                @click="nextIcon('tablet')"
                v-if="typeView === 'desktop' && !global"
              />
              <img
                src="@/assets/editor/tablet.svg"
                alt="tablet"
                style="filter: invert(50%) !important; margin-top: 7px"
                @click="nextIcon('mobile')"
                v-if="typeView === 'tablet' && !global"
              />
              <img
                src="@/assets/editor/phone.svg"
                alt="phone"
                style="filter: invert(50%) !important; margin-top: 7px"
                @click="nextIcon('desktop'), (global = !global)"
                v-if="typeView === 'mobile' && !global"
              />
            </div>
            <div class="focus-control">
              <button
                :disabled="!editaItem.propsData.styles.colorFocus"
                style="
                  border-radius: 10px 0 0 10px;
                  border-right: none !important;
                "
                @click="
                  editaItem.propsData.size[typeView].colorIndex--,
                    globalMeasure(editaItem.propsData.size[typeView].colorIndex)
                "
              >
                -
              </button>
              <button
                :disabled="!editaItem.propsData.styles.colorFocus"
                @click="
                  (editaItem.propsData.size[typeView].colorIndex = 0),
                    globalMeasure(editaItem.propsData.size[typeView].colorIndex)
                "
              >
                o
              </button>
              <button
                :disabled="!editaItem.propsData.styles.colorFocus"
                style="
                  border-radius: 0 10px 10px 0;
                  border-left: none !important;
                "
                @click="
                  editaItem.propsData.size[typeView].colorIndex++,
                    globalMeasure(editaItem.propsData.size[typeView].colorIndex)
                "
              >
                +
              </button>
            </div>
          </div>
          <div class="separador"></div>

          <!-- Tempo -->
          <div class="content">
            <label class="titulo">Temporarizador</label>
            <label class="subtitulo">Selecione o tempo em segundos</label>
            <div class="space"></div>
            <div class="focus-control">
              <button
                :disabled="editaItem.propsData.styles.timer <= 1"
                style="
                  border-radius: 10px 0 0 10px;
                  border-right: none !important;
                "
                @click="
                  editaItem.propsData.styles.timer--
                "               
              >
                -
              </button>
              <input
                type="number"
                style="
                  height: 40px !important;
                  border-radius: 0px !important;
                  text-align: center;
                "
                min="1"
                v-model="editaItem.propsData.styles.timer"
                @focus="openDropdownSlider('editaItem.propsData.styles.timer')"
              />
              <button
                style="
                  border-radius: 0 10px 10px 0;
                  border-left: none !important;
                "
                @click="
                  editaItem.propsData.styles.timer++
                "
              >
                +
              </button>
            </div>
          </div>
          <div class="separador"></div>

          <!-- Botões -->
          <!-- <div class="separador"></div>
          <div class="content">
            <label class="titulo">Botões</label>
            <label class="sub">Cor</label>
            <div class="space"></div>
            <LvColorpicker
              id="LvColorpicker"
              v-model="editaItem.propsData.styles.color_btns"
              label="Cor dos Botões"
              :colors="palette"
            ></LvColorpicker>
          </div> -->
        </b-tab>

        <b-tab title="Avançado">
          <advancedBasic
            :spacing="editaItem.propsData.spacing" 
            :visibility="editaItem.propsData.visibility" 
            :animation="editaItem.propsColumn" 
            :attributes="editaItem.propsData.custom" 
            :typeView="typeView"
            :global="global"
            @toggleGlobal="global = !global"
            @nextIcon="nextIcon"
            @update:spacing="editaItem.propsData.spacing = $event"
            @update:visibility="editaItem.propsData.visibility = $event"
            @update:animation="editaItem.propsColumn = $event"
            @update:attributes="editaItem.propsData.custom = $event"
          ></advancedBasic>
        </b-tab>
      </b-tabs>
    </b-card>
    <Upload @setImg="setImg" />
    <dropdownSlider
      ref="dropdownSlider"   
      @updateValue="updateValue"
    /> 
  </div>
</template>

<script>
import dropdownSliderMixin from "@/mixins/dropdownSliderMixin.js";
import advancedBasic from "../EditComponents/advancedBasic.vue";
import Upload from "../../Upload.vue";
import LvColorpicker from "lightvue/color-picker";
import Multiselect from "vue-multiselect";

export default {
  mixins: [dropdownSliderMixin],
  props: ["editaItem", "typeView", "palette"],
  components: {
    advancedBasic,
    Upload,
    LvColorpicker: LvColorpicker,
    Multiselect,
  },
  data() {
    return {
      global: false,
      img_carousel_index: null,
      proportion: "Personalizado",
    };
  },
  methods: {
    moveUp(idx) {
      var moving = this.editaItem.propsData.slides[idx]
      this.editaItem.propsData.slides.splice(idx, 1);
      this.editaItem.propsData.slides.splice(idx - 1, 0, moving);
    },
    moveDown(idx) {
      var moving = this.editaItem.propsData.slides[idx]
      this.editaItem.propsData.slides.splice(idx, 1);
      this.editaItem.propsData.slides.splice(idx + 1, 0, moving);
    },
    validTimer(){
      if (this.editaItem.propsData.styles.timer < 1) {
        this.editaItem.propsData.styles.timer = 1
      }
    },
    next_und(place) {
      var props = this.editaItem.propsData;
      if (props.size[this.typeView][place] == "px") {
        props.size[this.typeView][place] = "vh";
        if (this.global && this.typeView == 'desktop') {
          props.size.tablet[place] = "vh";
          props.size.mobile[place] = "vh";
        }
        return;
      }
      if (props.size[this.typeView][place] == "vh") {
        props.size[this.typeView][place] = "vw";
        if (this.global && this.typeView == 'desktop') {
          props.size.tablet[place] = "vw";
          props.size.mobile[place] = "vw";
        }
        return;
      }
      if (props.size[this.typeView][place] == "vw") {
        props.size[this.typeView][place] = "px";
        if (this.global && this.typeView == 'desktop') {
          props.size.tablet[place] = "px";
          props.size.mobile[place] = "px";
        }
        return;
      }
    },
    nextIcon(type) {
      this.$emit("attIconView", type);
    },
    globalMeasure(pixel) {
      var px = pixel.target ? pixel.target.value : pixel;
      if (this.global && this.typeView == 'desktop') {
        var props = this.editaItem.propsData.size;
        props.tablet.colorIndex = px;
        props.mobile.colorIndex = px;
      }
    },    
    addImg() {
      this.editaItem.propsData.slides.push({
        imagem:
          "https://gdigital.s3.amazonaws.com/gdigital/1/imagem-vazia2.webp",
      });
      this.openModal('Upload-Editor', this.editaItem.propsData.slides.length - 1)
    },    
    removeImgIndex(index) {
      this.editaItem.propsData.slides.splice(index, 1);
    },
    openModal(data, index) {
      this.img_carousel_index = index;
      this.$bvModal.show(data);
    },
    setImg(img) {
      this.editaItem.propsData.slides[this.img_carousel_index].imagem = img;
    },    
  },
  created() {
    if (this.typeView == "desktop") {
      this.global = true;
    }
  },
};
</script>

<style lang="scss">
.focus-control {
  display: flex;
  button {
    height: 40px;
    width: 40px;
    background-color: #00000000;
    border: 1px solid #eee !important;
    font-size: 20px;
    transition: all 0.3s;
  }
  input {
    width: 40px !important;
    background-color: #00000000 !important;
    border: 1px solid #eee !important;
    font-size: 20px !important;
    padding: 15px 0px !important;
  }
}
</style>
