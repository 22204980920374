let propsForm = {
    alignment: {
        desktop: { justify_vertical: "center" },
        tablet: { justify_vertical: "center" },
        mobile: { justify_vertical: "center" },
    },
    styles: {
        form_border_size: 1,
        form_border_color: "#E2E2E2",
        border_radius: 25,
        input_back: "#ffffff",
        back_form_content: "#ffffff",

        //BUTTON
        back_content: "#4ea934",
        color_button: "#FFFFFF",
        font_family: "Montserrat",
    },
    spacing: {
        desktop: {
            margin_top: 0,
            margin_right: 0,
            margin_bottom: 0,
            margin_left: 0,
            padding_top: 50,
            padding_right: 100,
            padding_bottom: 50,
            padding_left: 100,
        },
        tablet: {
            margin_top: 0,
            margin_right: 0,
            margin_bottom: 0,
            margin_left: 0,
            padding_top: 50,
            padding_right: 60,
            padding_bottom: 50,
            padding_left: 60,
        },
        mobile: {
            margin_top: 0,
            margin_right: 0,
            margin_bottom: 0,
            margin_left: 0,
            padding_top: 30,
            padding_right: 30,
            padding_bottom: 30,
            padding_left: 30,
        }
    },
    size: {
        desktop: {
            height_form: 600,
            font_size: 18,
        },
        tablet: {
            height_form: 400,
            font_size: 18,
        },
        mobile: {
            height_form: 400,
            font_size: 18,
        },
    },
    visibility: {
        monitor: true,
        tablet: true,
        phone: true,
    },
    form: {},
    footer: "",
    opcao_agradecimento: { value: "thank_you_page", text: "Página" },
    thank_you_page: {},
    success_mensage: "Resposta enviada",
    phone: "",
    whatsapp_mensage: "Me cadastrei no formulário x",
    footer_link: "#",
    fields: [
        {
            name_field: "text",
            form_field: `<li class="prev-holder" style=""><div class="fb-text form-group field-nome-preview"><label class="fb-text-label">Nome</label><span class="required-asterisk" style="display: none;"> </span><input type="text" style="teste: 2px;" placeholder="Seu nome" class="form-control" name="nome-preview" mascara="form-control" id="nome-preview"></div></li>`,
        },
        {
            name_field: "text",
            form_field: `<li class="prev-holder" style=""><div class="fb-text form-group field-nome-preview"><label class="fb-text-label">E-mail</label><span class="required-asterisk" style="display: none;"> </span><input type="text" placeholder="Seu e-mail" class="form-control" name="email-preview" mascara="form-control" id="email-preview"></div></li>`,
        },
        {
            name_field: "text",
            form_field: `<li class="prev-holder" style=""><div class="fb-text form-group field-nome-preview"><label class="fb-text-label">Telefone</label><span class="required-asterisk" style="display: none;"> </span><input type="text" placeholder="Seu Telefone" class="form-control" name="nome-preview" mascara="form-control" id="nome-preview"></div></li>`,
        },
    ],
};
export default propsForm;
