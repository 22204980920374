let propsGaleria = {
    alignment: {
      justify: "center",
    },
    custom: {
      c_class: '',
      c_id: '',
    },  
    gallery: [
      {
        imagem:
          "https://gdigital.s3.amazonaws.com/gdigital/1/imagem-vazia3%20%281%29.webp",
        ratio: 1620 / 911,
      },
      {
        imagem:
          "https://gdigital.s3.amazonaws.com/gdigital/1/imagem-vazia3%20%281%29.webp",
        ratio: 1620 / 911,
      },
      {
        imagem:
          "https://gdigital.s3.amazonaws.com/gdigital/1/imagem-vazia3%20%281%29.webp",
        ratio: 1620 / 911,
      },
      {
        imagem:
          "https://gdigital.s3.amazonaws.com/gdigital/1/imagem-vazia3%20%281%29.webp",
        ratio: 1620 / 911,
      },
      {
        imagem:
          "https://gdigital.s3.amazonaws.com/gdigital/1/imagem-vazia3%20%281%29.webp",
        ratio: 1620 / 911,
      },
      {
        imagem:
          "https://gdigital.s3.amazonaws.com/gdigital/1/imagem-vazia3%20%281%29.webp",
        ratio: 1620 / 911,
      },
    ],


    styles: {
      size: {
        desktop: 3,
        tablet: 2,
        mobile: 2,
      },
      color_btns: "#FFFFFF",
      gap: {
        desktop: 5,
        tablet: 5,
        mobile: 5,
      }, 
      align: {
        desktop: 'center',
        tablet: 'center',
        mobile: 'center',
      }, 
    },
    spacing: {
      desktop: {
        margin_top: 0,
        margin_right: 0,
        margin_bottom: 0,
        margin_left: 0,
        padding_top: 0,
        padding_right: 0,
        padding_bottom: 0,
        padding_left: 0,
      },
      tablet: {
        margin_top: 0,
        margin_right: 0,
        margin_bottom: 0,
        margin_left: 0,
        padding_top: 0,
        padding_right: 0,
        padding_bottom: 0,
        padding_left: 0,
      },
      mobile: {
        margin_top: 0,
        margin_right: 0,
        margin_bottom: 0,
        margin_left: 0,
        padding_top: 0,
        padding_right: 0,
        padding_bottom: 0,
        padding_left: 0,
      },
    },

    border: {
      radius: 0,
      border: false,
      borderStyle: 'Sólido',
      borderColor: "#000000",
      borderSize: 3,
    },

    shadow: {
      hasShadow: false,
      shadowColor: "#000000",
      shadowX: 5,
      shadowY: 5,
      shadowBlur: 5,
    },

    visibility: {
      tablet: true,
      phone: true,
      monitor: true,
    },
  };
  export default propsGaleria;
  