<template>
  <div id="SideRightTabs">
    <b-tabs pills justified small style="width: 100%">
      <!-- Geral -->
      <b-tab title="Geral" active style="max-height: 80vh">
        <div class="separador"></div>

        <!-- Formulário -->
        <div class="content">
          <label class="titulo">Formulário</label>
          <label class="subtitulo">Formulário referente</label>
          <div class="max-multi">
            <BaseSelect
              id="ajax"
              :selectModel="editaItem.propsData.form"
              :show-labels="false"
              placeholder="Selecione um formulário"
              :hide-selected="false"
              track-by="id"
              :allow-empty="false"
              :array="forms"
              trackname="title"
              :searchable="true"
              noResult="Nenhum formulário encontrado."
              noOptions="Pesquise pelo formulário"
              selectLabel=""
              deselectLabel=""
              selectedLabel=""
              @change="(editaItem.propsData.form = $event), setaForm($event)"
              @callback="getForms($event)"
            >
            </BaseSelect>
          </div>
          <div class="space"></div>

          <!-- Cabeçalho -->
          <div>
            <label class="subtitulo">Cabeçalho</label>
            <input
              v-focus
              :disabled="
                !editaItem.propsData.form && !editaItem.propsData.form.id
              "
              @input="debounce('adicionais')"
              type="text"
              placeholder="Cadastre-se aqui!"
              name="msg"
              id="msg"
              v-model="editaItem.propsData.header"
            />
            <div class="space"></div>
          </div>

          <!-- Rodapé -->
          <div>
            <label class="subtitulo">Rodapé</label>
            <input
              :disabled="
                !editaItem.propsData.form && !editaItem.propsData.form.id
              "
              placeholder="Política de privacidade"
              @input="debounce('adicionais')"
              type="text"
              name="msg"
              id="msg"
              v-model="editaItem.propsData.footer"
            />
            <div class="space"></div>
          </div>

          <!-- Link do rodapé -->
          <div v-if="editaItem.propsData.footer">
            <label class="subtitulo">Link do rodapé</label>
            <input
              placeholder="https://seulink"
              :disabled="
                !editaItem.propsData.form && !editaItem.propsData.form.id
              "
              @input="debounce('adicionais')"
              type="text"
              name="msg"
              id="msg"
              v-model="editaItem.propsData.footer_link"
            />
          </div>
        </div>
        <div class="separador"></div>

        <!-- Agradecimento -->
        <div class="content">
          <label class="titulo">Agradecimento</label>
          <label class="subtitulo">Tipos</label>
          <multiselect
            v-model="editaItem.propsData.opcao_agradecimento"
            :options="editaItem.propsData.opcoes_agradecimento"
            :searchable="false"
            label="text"
            track-by="value"
            :show-labels="false"
            placeholder="Selecione um tipo de agradecimento"
            :hide-selected="true"
            @select="saveOpt('page')"
          ></multiselect>
          <div class="space"></div>

          <!-- Página de agradecimento -->
          <div
            v-if="
              editaItem.propsData.opcao_agradecimento.value === 'thank_you_page'
            "
          >
            <label class="subtitulo">Página de agradecimento</label>
            <div class="max-multi">
              <BaseSelect
                id="ajax"
                :selectModel="editaItem.propsData.thank_you_page"
                :show-labels="false"
                placeholder="Página de agradecimentos"
                :hide-selected="true"
                track-by="id"
                :allow-empty="false"
                :array="pages"
                trackname="title"
                :searchable="false"
                noResult="Nenhum página encontrada."
                selectLabel=""
                deselectLabel=""
                selectedLabel=""
                @change="
                  (editaItem.propsData.thank_you_page = $event), saveOpt('page')
                "
                @callback="getPages($event)"
              >
              </BaseSelect>
            </div>
            <div v-if="editaItem.propsData.thank_you_page && editaItem.propsData.thank_you_page.title && !editaItem.propsData.thank_you_page.id">
              <div class="space"></div>
              <label class="subtitulo">Link externo</label>
              <input @input="saveOpt('page')" type="text" v-model="editaItem.propsData.thank_you_page.path_name">
            </div>
          </div>

          <!-- Pop up -->
          <div v-if="editaItem.propsData.opcao_agradecimento.value === 'popup'">
            <label class="subtitulo">Mensagem caso lead seja cadastrado</label>
            <textarea
              @input="debounce('popup')"
              :disabled="
                !editaItem.propsData.form && !editaItem.propsData.form.id
              "
              placeholder="Muito obrigada por se cadastrar!..."
              type="text"
              name="msg"
              id="msg"
              v-model="editaItem.propsData.success_mensage"
            />
            <div class="space"></div>
            <label class="subtitulo"
              >Mensagem caso lead não seja cadastrado</label
            >
            <textarea
              placeholder="Confira os dados digitados"
              :disabled="
                !editaItem.propsData.form && !editaItem.propsData.form.id
              "
              @input="debounce('popup')"
              type="text"
              name="msg"
              id="msg"
              v-model="editaItem.propsData.error_mensage"
            />
          </div>

          <!-- Mensagem whatsapp -->
          <div
            v-if="editaItem.propsData.opcao_agradecimento.value === 'whatsapp'"
          >
            <label class="subtitulo">Número de telefone de origem</label>
            <input
              @input="debounce('whatsapp')"
              :disabled="
                !editaItem.propsData.form && !editaItem.propsData.form.id
              "
              type="text"
              v-mask="['+##(##) ####-####', '+##(##) #####-####']"
              name="msg"
              id="msg"
              placeholder="Apenas números"
              v-model="editaItem.propsData.phone"
            />
            <div class="space"></div>
            <label class="subtitulo">Mensagem para ser enviada</label>
            <textarea
              placeholder="Olá! Recebi o seu cadastro na página..."
              @input="debounce('whatsapp')"
              :disabled="
                !editaItem.propsData.form && !editaItem.propsData.form.id
              "
              type="text"
              name="msg"
              id="msg"
              v-model="editaItem.propsData.whatsapp_mensage"
            />
          </div>
        </div>
        <div class="separador"></div>

        <!-- Botão -->
        <div class="content">
          <label class="titulo">Botão</label>

          <label class="subtitulo">Texto do botão</label>
          <input
            type="text"
            name="btn-content"
            id="btn-content"
            v-model="editaItem.propsData.content_button"
          />
          <div class="space"></div>

          <label class="subtitulo">Ícone</label>
          <div
            class="select-image"
            v-if="editaItem.propsData.icon_selected.hasOwnProperty('name')"
          >
            <div @click="openModal('Modal-Icons-svg', idx)">
              <span
                class="material-icons icon-preview"
                v-html="editaItem.propsData.icon_selected.code"
              ></span>
            </div>
            <div>
              <input
                type="text"
                v-model="editaItem.propsData.icon_selected.name"
              />
              <span @click="openModal('Modal-Icons-svg', idx)">{{
                editaItem.propsData.icon_selected
                  ? "Alterar ícone"
                  : "Selecionar ícone"
              }}</span>
            </div>
          </div>
          <div class="select-image" v-else>
            <div @click="openModal('Modal-Icons-svg', idx)">
              <span class="material-icons icon-preview">{{
                editaItem.propsData.icon_selected
              }}</span>
            </div>
            <div>
              <input type="text" v-model="editaItem.propsData.icon_selected" />
              <span @click="openModal('Modal-Icons-svg', idx)">{{
                editaItem.propsData.icon_selected
                  ? "Alterar ícone"
                  : "Selecionar ícone"
              }}</span>
            </div>
          </div>
        </div>
        <div class="separador"></div>
      </b-tab>

      <!-- Estilo -->
      <b-tab title="Estilo">
        <div class="separador"></div>

        <!-- Formulário -->
        <div class="content">
          <!-- Largura -->
          <div class="und_form">
            <div class="label-icon">
              <label class="titulo">Formulário</label>
              <img
                src="@/assets/editor/globe.svg"
                alt="monitor"
                style="filter: invert(50%) !important"
                @click="nextIcon('desktop'), (global = !global)"
                v-if="global"
              />
              <img
                src="@/assets/editor/monitor.svg"
                alt="monitor"
                style="filter: invert(50%) !important"
                @click="nextIcon('tablet')"
                v-if="typeView === 'desktop' && !global"
              />
              <img
                src="@/assets/editor/tablet.svg"
                alt="tablet"
                style="filter: invert(50%) !important"
                @click="nextIcon('mobile')"
                v-if="typeView === 'tablet' && !global"
              />
              <img
                src="@/assets/editor/phone.svg"
                alt="phone"
                style="filter: invert(50%) !important"
                @click="nextIcon('desktop'), (global = !global)"
                v-if="typeView === 'mobile' && !global"
              />
            </div>

            <div class="label-range">
              <label class="subtitulo">Largura máxima (px)</label>
              <input
                type="number"
                v-model="editaItem.propsData.size[typeView].width_form"
              />
            </div>
            <b-form-input
              v-model="editaItem.propsData.size[typeView].width_form"
              type="range"
              min="200"
              max="1000"
              step="10"
            ></b-form-input>
          </div>
          <!-- Fundo -->
          <label class="subtitulo">Fundo</label>
          <div>
            <LvColorpicker
              id="LvColorpicker"
              v-model="editaItem.propsData.styles.back_form_content"
              :colors="palette"
            ></LvColorpicker>
          </div>

          <!-- Posição -->
          <div>
            <div class="space"></div>
            <label class="subtitulo">Posicionamento</label>
            <div style="display: flex; width: 100%; gap: 10px">
              <div style="display: flex; width: 50%; transition: all 0.3s">
                <div class="select-align">
                  <button
                    :class="{
                      'selected-align':
                        editaItem.propsData.alignment[typeView].justify ==
                        'flex-start',
                    }"
                    @click="globalJustify('flex-start', 'justify')"
                  >
                    <img src="@/assets/editor/align-left-new.svg" />
                  </button>
                  <button
                    :class="{
                      'selected-align':
                        editaItem.propsData.alignment[typeView].justify ==
                        'center',
                    }"
                    @click="globalJustify('center', 'justify')"
                  >
                    <img src="@/assets/editor/align-center-horizontal.svg" />
                  </button>
                  <button
                    :class="{
                      'selected-align':
                        editaItem.propsData.alignment[typeView].justify ==
                        'flex-end',
                    }"
                    @click="globalJustify('flex-end', 'justify')"
                  >
                    <img src="@/assets/editor/align-right-new.svg" />
                  </button>
                </div>
              </div>

              <div style="display: flex; width: 50%; transition: all 0.3s">
                <div class="select-align">
                  <button
                    :class="{
                      'selected-align':
                        editaItem.propsData.alignment[typeView]
                          .justify_vertical == 'start',
                    }"
                    @click="globalJustify('start', 'justify_vertical')"
                  >
                    <img src="@/assets/editor/align-top.svg" />
                  </button>
                  <button
                    :class="{
                      'selected-align':
                        editaItem.propsData.alignment[typeView]
                          .justify_vertical == 'center',
                    }"
                    @click="globalJustify('center', 'justify_vertical')"
                  >
                    <img src="@/assets/editor/align-center-vertical.svg" />
                  </button>
                  <button
                    :class="{
                      'selected-align':
                        editaItem.propsData.alignment[typeView]
                          .justify_vertical == 'end',
                    }"
                    @click="globalJustify('end', 'justify_vertical')"
                  >
                    <img src="@/assets/editor/align-bottom.svg" />
                  </button>
                </div>
              </div>
            </div>
            <div class="space"></div>
          </div>
        </div>
        <div class="separador"></div>

        <!-- Bordas -->
        <div class="content">
          <label class="titulo">Bordas</label>
          <div class="label-range">
            <label class="subtitulo">Arredondar bordas </label>
            <img
              :class="{ borderLinkActive: borderLink === true }"
              style="
                height: 18px;
                transition: all 0.3s;
                filter: invert(50%);
              "
              src="@/assets/editor/link-2.svg"
              alt="linkar"
              @click="borderLink = !borderLink"
            />
          </div>
          <div class="inputs-group">
            <input
              type="number"
              v-model="editaItem.propsData.styles.border_top_left_radius"
              @focus="openDropdownSlider('editaItem.propsData.styles.border_top_left_radius', 'borderLink')"
            />
            <input
              type="number"
              v-model="editaItem.propsData.styles.border_top_right_radius"
              @focus="openDropdownSlider('editaItem.propsData.styles.border_top_right_radius', 'borderLink')"
            />
            <input
              type="number"
              v-model="editaItem.propsData.styles.border_bottom_left_radius"
               @focus="openDropdownSlider('editaItem.propsData.styles.border_bottom_left_radius', 'borderLink')"
            />
            <input
              type="number"
              v-model="editaItem.propsData.styles.border_bottom_right_radius"
              @focus="openDropdownSlider('editaItem.propsData.styles.border_bottom_left_radius', 'borderLink')"
            />
          </div>
          <div>
            <div class="space"></div>
            <label class="sub">Cor da borda</label>
            <LvColorpicker
              id="LvColorpicker"
              v-model="editaItem.propsData.styles.form_border_color"
              :colors="palette"
            ></LvColorpicker>
            <div class="space"></div>
            <label class="subtitulo">Traço</label>
            <multiselect
              v-model="borderStyle"
              :options="[
                '● ● ● ● ● ● ● ● ● ● ● ● ● ●',
                '━━━━━━━━━━━━━━━━━━',
                '━ ━ ━ ━ ━ ━ ━ ━ ━ ━ ━ ━ ━ ━',
                '══════════════════',
              ]"
              :searchable="false"
              :show-labels="false"
              placeholder="Traço"
            ></multiselect>
            <div class="space"></div>

            <div class="label-range">
              <label class="subtitulo">Espessura da borda</label>
              <input
                type="number"
                v-model="editaItem.propsData.styles.form_border_size"
              />
            </div>
            <b-form-input
              v-model="editaItem.propsData.styles.form_border_size"
              type="range"
              min="0"
              max="10"
              step="1"
            ></b-form-input>
          </div>
        </div>

        <div class="separador"></div>
        <div class="content">
          <div>
            <label class="titulo">Campos</label>

            <div class="space"/>
            <!-- <label class="subtitulo">Cor do placeholder</label>
            <LvColorpicker
              id="LvColorpicker"
              v-model="editaItem.propsData.styles.placeholder_color"
              :colors="palette"
            ></LvColorpicker>
            <div class="space"></div> -->
            <label class="subtitulo">Fundo</label>
            <LvColorpicker
              @input="setaInputStyles"
              v-model="editaItem.propsData.styles.input_back"
              id="LvColorpicker"
              :colors="palette"
            ></LvColorpicker>
            <!-- <div class="space"></div> -->
            <!-- <label class="subtitulo">Cor da borda</label>
            <LvColorpicker
              @input="setaInputStyles"
              v-model="editaItem.propsData.styles.input_border_color"
              id="LvColorpicker"
              :colors="palette"
            ></LvColorpicker>
            <div class="space"></div> -->
          </div>
          <div>
            <!-- <label class="subtitulo">Arredondar bordas dos campos</label>
            <div class="und_form">
              <b-form-input
                v-model="editaItem.propsData.styles.input_radius"
                type="number"
                min="0"
                max="20"
                @input="setaInputStyles"
              ></b-form-input>
            </div> -->
          </div>
          <div class="space"></div>
        </div>
        <div class="separador"></div>

        <div class="content">
          <label class="titulo">Botão</label>

          <label class="subtitulo">Tipografia</label>
          <div
            style="
              display: flex;
              gap: 10px;
              width: 100%;
              justify-content: space-between;
              margin-bottom: 1rem;
            "
          >
            <b-button-group>
              <b-button
                :disabled="
                  editaItem.propsData.size.desktop.font_size_button == 0
                "
                size="sm"
                style="
                  height: min-content !important;
                  padding: 10.5px 7px !important;
                "
                @click="editaItem.propsData.size.desktop.font_size_button--"
                ><img src="@/assets/editor/minus3.svg" alt="align"
              /></b-button>
              <b-form-input
                class="text-right"
                @focus="openDropdownSlider('editaItem.propsData.size.desktop.font_size_button')"
                v-model="editaItem.propsData.size.desktop.font_size_button"
                style="
                  width: 44px;
                  text-align: center !important;
                  border: none !important;
                  padding: 0 !important;
                "
              ></b-form-input>
              <b-button
                @click="editaItem.propsData.size.desktop.font_size_button++"
                size="sm"
                style="
                  height: min-content !important;
                  padding: 10.5px 7px !important;
                "
                ><img src="@/assets/editor/plus.svg" alt="align"
              /></b-button>
            </b-button-group>

            <div style="width: 30%">
              <multiselect
                v-model="editaItem.propsData.styles.textFontMeasure"
                :options="['px', 'rem', '%', 'em']"
                :searchable="false"
                :show-labels="false"
                :allow-empty="false"
                placeholder=" "
                :hide-selected="true"
              ></multiselect>
            </div>

            <div>
              <b-button
                size="sm"
                @click="trocarType"
                style="
                  height: min-content !important;
                  padding: 10.5px 10px !important;
                "
                ><img src="@/assets/editor/type.svg" alt="align"
              /></b-button>
            </div>
          </div>
          <multiselect
            v-model="editaItem.propsData.styles.font_family"
            :options="fontOptions"
            :allow-empty="false"
            :hide-selected="true"
            :show-labels="false"
            @input="$emit('callCharge',$event)"
            placeholder="Fonte"
          ></multiselect>

          <div class="space" />
          <label class="subtitulo">Cor do conteúdo</label>
          <LvColorpicker
            id="LvColorpicker"
            v-model="editaItem.propsData.styles.color_button"
            :colors="palette"
          ></LvColorpicker>
          <div class="space"></div>
          <div class="label-range">
            <label class="subtitulo">Espessura da fonte</label>
            <input
              type="number"
              v-model="editaItem.propsData.styles.font_weight"
            />
          </div>
          <b-form-input
            v-model="editaItem.propsData.styles.font_weight"
            type="range"
            min="100"
            max="1200"
            step="50"
          ></b-form-input>          
          <div class="label-range">
            <label class="subtitulo">Espaço entre letras</label>
            <input
              type="number"
              v-model="editaItem.propsData.styles.letter_space"
            />
          </div>
          <b-form-input
            v-model="editaItem.propsData.styles.letter_space"
            type="range"
            min="-5"
            max="15"
            step="0.25"
          ></b-form-input>
          <label>Tipo do fundo</label>
          <multiselect
            v-model="editaItem.propsData.styles.back_selected"
            :options="['Cor sólida', 'Gradiente']"
            :searchable="false"
            :show-labels="false"
            :allow-empty="false"
            placeholder="Tipo do fundo"
            :hide-selected="true"
            @select="backgroundCreate()"
          ></multiselect>
          <div
            v-show="!editaItem.propsData.styles.back_selected || editaItem.propsData.styles.back_selected == 'Cor sólida'"
            style="height: 1rem"
          ></div>
          <!-- Cor sólida -->
          <div v-show="editaItem.propsData.styles.back_selected == 'Cor sólida'">
            <LvColorpicker
              id="LvColorpicker"
              @select="backgroundCreate()"
              v-model="editaItem.propsData.styles.back_content"
              :colors="palette"
            ></LvColorpicker>
          </div>
          <!-- Gradiente -->  
          <div v-show="editaItem.propsData.styles.back_selected == 'Gradiente'">
            <!-- Pickers -->
            <div class="pickers pb-2">
              <div class="picker">
                <LvColorpicker
                  @select="backgroundCreate()"
                  id="LvColorpicker"
                  v-model="editaItem.propsData.styles.grad_color1"
                  :colors="palette"
                ></LvColorpicker>
              </div>
              <div class="picker">
                <LvColorpicker
                  @select="backgroundCreate()"
                  id="LvColorpicker"
                  v-model="editaItem.propsData.styles.grad_color2"
                  :colors="palette"
                ></LvColorpicker>
              </div>
            </div>
            <div class="space"></div>

            <!-- Direção -->
            <div class="label-range">
              <label class="subtitulo">Direção do gradiente</label>
              <input type="number" v-model="editaItem.propsData.styles.grad_angle" />
            </div>
            <b-form-input
              v-model="editaItem.propsData.styles.grad_angle"
              type="range"
              min="0"
              max="360"
              step="1"
            ></b-form-input>
          </div>
        </div>

        <div class="separador"></div>
        <div class="content">
          <label class="titulo">Ícone</label>
          <label>Cor do ícone</label>
          <LvColorpicker
            id="LvColorpicker"
            v-model="editaItem.propsData.styles.icon_color"
            :colors="palette"
          ></LvColorpicker>
          <div class="space"></div>

          <div class="label-range">
            <label class="subtitulo">Tamanho</label>
            <input
              type="number"
              v-model="editaItem.propsData.size.desktop.icon_size"
            />
          </div>
          <b-form-input
            v-model="editaItem.propsData.size.desktop.icon_size"
            type="range"
            min="0"
            max="50"
            step="1"
          ></b-form-input>
          <div class="space"></div>
          <div class="label-range">
            <label class="subtitulo">Margem lateral</label>
            <input
              type="number"
              v-model="editaItem.propsData.spacing.desktop.icon_margin"
            />
          </div>
          <b-form-input
            v-model="editaItem.propsData.spacing.desktop.icon_margin"
            type="range"
            min="0"
            max="25"
            step="1"
          ></b-form-input>
        </div>
      </b-tab>

      <!-- Avançado -->
      <b-tab title="Avançado">
        <!-- Espaçamento formulário -->
        <div class="separador"></div>      
        <!-- Espaçamento botão -->
        <div class="separador"></div>
        <div class="content">
          <div class="label-icon">
            <label class="titulo">Botão</label>
            <img
              src="@/assets/editor/globe.svg"
              alt="monitor"
              style="filter: invert(50%) !important"
              v-if="typeView === 'desktop'"
            />
          </div>
          <!-- Componente de Espaçamento -->
          <div class="container-spacing" style="padding-bottom: 15px">
            <div class="border-dash">
              <span class="txt-mg">MARGEM EXTERNA</span>
              <img
                src="@/assets/editor/link-click.svg"
                alt="Link"
                v-if="linkMarginButton"
                class="links-advanced"
                @click="linkMarginButton = !linkMarginButton"
              />
              <img
                src="@/assets/editor/link.svg"
                v-else
                alt="Link"
                class="links-advanced"
                @click="linkMarginButton = !linkMarginButton"
              />
              <div class="alinhamento">
                <!-- MARGIN LEFT -->
                <div class="item-1">
                  <input
                    type="number"
                    v-model="editaItem.propsData.spacing[typeView].margin_button_left"
                    @focus="openDropdownSlider('editaItem.propsData.spacing[typeView].margin_button_left', 'linkMarginButton')"
                    min="0"
                    max="100"
                  />
                </div>
                <div class="item-2">
                  <div class="alinhamento-2">
                    <!-- MARGIN TOP -->
                    <div>
                      <input
                        type="number"                    
                        v-model="editaItem.propsData.spacing[typeView].margin_button_top"
                        @focus="openDropdownSlider('editaItem.propsData.spacing[typeView].margin_button_top', 'linkMarginButton')"
                        min="0"
                        max="100"
                      />
                    </div>
                    <div>
                      <div class="contain-borders">
                        <span class="txt-mg">MARGEM INTERNA</span>
                        <img
                          src="@/assets/editor/link-click.svg"
                          alt="Link"
                          v-if="linkPaddingButton"
                          class="links-advanced"
                          @click="linkPaddingButton = !linkPaddingButton"
                        />
                        <img
                          src="@/assets/editor/link.svg"
                          v-else
                          alt="Link"
                          class="links-advanced"
                          @click="linkPaddingButton = !linkPaddingButton"
                        />
                        <div class="alinhamento">
                          <!-- PADDING LEFT -->
                          <div class="item-1">
                            <input
                              type="number"                             
                              v-model="editaItem.propsData.spacing[typeView].padding_button_left"
                              @focus="openDropdownSlider('editaItem.propsData.spacing[typeView].padding_button_left', 'linkPaddingButton')"
                              min="0"
                              max="100"
                            />
                          </div>
                          <div class="item-2">
                            <div class="alinhamento-2">
                              <!-- PADDING TOP -->
                              <div>
                                <input
                                  type="number"
                                  v-model="editaItem.propsData.spacing[typeView].padding_button_top"
                                  @focus="openDropdownSlider('editaItem.propsData.spacing[typeView].padding_button_top', 'linkPaddingButton')"
                                  min="0"
                                  max="100"
                                />
                              </div>
                              <div>
                                <div class="border-final"></div>
                              </div>
                              <!-- PADDING BOTTOM -->
                              <input
                                type="number"
                                v-model="editaItem.propsData.spacing[typeView].padding_button_bottom"
                                @focus="openDropdownSlider('editaItem.propsData.spacing[typeView].padding_button_bottom', 'linkPaddingButton')"
                                min="0"
                                max="100"
                              />
                              <div></div>
                            </div>
                          </div>
                          <!-- PADDING RIGHT -->
                          <div class="item-3">
                            <input
                              type="number"
                              v-model="editaItem.propsData.spacing[typeView].padding_button_right"
                              @focus="openDropdownSlider('editaItem.propsData.spacing[typeView].padding_button_right', 'linkPaddingButton')"
                              min="0"
                              max="100"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- MARGIN BOTTOM -->
                    <div>
                      <input
                        type="number"
                        v-model="editaItem.propsData.spacing[typeView].margin_button_bottom"
                        @focus="openDropdownSlider('editaItem.propsData.spacing[typeView].margin_button_bottom', 'linkMarginButton')"                        
                        min="0"
                        max="100"
                      />
                    </div>
                  </div>
                </div>
                <!-- MARGIN RIGHT -->
                <div class="item-3">
                  <input
                    type="number"
                    v-model="editaItem.propsData.spacing[typeView].margin_button_right"
                    @focus="openDropdownSlider('editaItem.propsData.spacing[typeView].margin_button_right', 'linkMarginButton')"  
                    min="0"
                    max="100"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <advancedBasic
          :spacing="editaItem.propsData.spacing" 
          :visibility="editaItem.propsData.visibility" 
          :animation="editaItem.propsColumn"     
          :typeView="typeView"
          :global="global"
          :hideAttributes="true"
          @toggleGlobal="global = !global"
          @nextIcon="nextIcon"
          @update:spacing="editaItem.propsData.spacing = $event"
          @update:visibility="editaItem.propsData.visibility = $event"
          @update:animation="editaItem.propsColumn = $event"        
        ></advancedBasic>
      </b-tab>
    </b-tabs>
    <ModalIcons @iconSelect="iconSelect"></ModalIcons>
    <dropdownSlider
      ref="dropdownSlider"   
      @updateValue="updateValue"
    /> 
  </div>
</template>

<script>
import dropdownSliderMixin from "@/mixins/dropdownSliderMixin.js";
import advancedBasic from "../EditComponents/advancedBasic.vue";
import spacingBasic from "../EditComponents/spacingBasic.vue";
import LvColorpicker from "lightvue/color-picker";
import Multiselect from "vue-multiselect";
import FormService from "@/services/resources/FormService";
const serviceForm = FormService.build();
import PageListService from "@/services/resources/PageListService";
const PaginaListService = PageListService.build();
import ModalIcons from "@/components/Editor/ModalSvg";

export default {
  mixins: [dropdownSliderMixin],
  props: ["editaItem", "typeView", "fontOptions", "palette"],
  components: {
    spacingBasic,
    advancedBasic,
    LvColorpicker: LvColorpicker,
    Multiselect,
    ModalIcons,
  },
  data() {
    return {
      stop: false,
      stop2: false,
      forms: [],
      pages: [],   
      linkPaddingButton: true,
      linkMarginButton: true,
      type: "none",
      borderStyle: "━━━━━━━━━━━━━━━━━━",
      global: false,
      borderLink: true, 
      varLinks: {
        borderLink: [
          'editaItem.propsData.styles.border_top_left_radius',
          'editaItem.propsData.styles.border_top_right_radius',
          'editaItem.propsData.styles.border_bottom_left_radius',
          'editaItem.propsData.styles.border_bottom_right_radius',
        ],
        linkPaddingButton: [
          'editaItem.propsData.spacing[typeView].padding_button_top',
          'editaItem.propsData.spacing[typeView].padding_button_right',
          'editaItem.propsData.spacing[typeView].padding_button_bottom',
          'editaItem.propsData.spacing[typeView].padding_button_left',
        ],
        linkMarginButton: [
          'editaItem.propsData.spacing[typeView].margin_button_top',
          'editaItem.propsData.spacing[typeView].margin_button_right',
          'editaItem.propsData.spacing[typeView].margin_button_bottom',
          'editaItem.propsData.spacing[typeView].margin_button_left',
        ]
      }
    };
  },
  watch: {
  },
  computed: {
    inputStyle() {
      return {
        color: this.cor,
        backgroundColor: this.fundo,
        "::placeholder": {
          color: this.cor,
        },
        ":hover": {
          color: this.hoverCor,
          backgroundColor: this.hoverFundo,
        },
      };
    },
  },
  methods: {
    nextIcon(type) {
      this.$emit("attIconView", type);
    },  
    getForms(page = 1) {
      if (this.stop) {
        return;
      }
      var data = {
        campaign_id: this.$route.params.campaign_id,
        page: page,
        status: "active",
      };
      serviceForm.search(data).then((resp) => {
        if (resp.data.length < resp.per_page) {
          this.stop = true;
        }
        this.forms = this.forms.concat(resp.data);
      });
    },
    // getPages() {
    //   servicePage
    //     .read({ id: `all?campaign_id=${this.$route.params.campaign_id}` })
    //     .then((response) => {
    //       var pages = response;
    //       pages.map((page) => {
    //         page.path_name = `https://${this.$route.params.dynamicRoute}.gdigital.com.br/${page.path_name}`;
    //       });
    //       pages.push({ id: null, title: "Selecione a página" });
    //       this.pages = pages;
    //     });
    // },
    getPages(page = 1) {
      if (this.stop2) {
        return;
      }
      var data = {
        campaign_id: this.$route.params.campaign_id,
        page: page,
        status: "save",
      };

      PaginaListService.create(data).then((resp) => {
        if (resp.data.length < resp.per_page) {
          this.stop2 = true;
        }
        var pages = resp.data;
        pages.map((page) => {
          page.path_name = `https://${this.$route.params.dynamicRoute}.gdigital.com.br/${page.path_name}`;
        });
        if (page == 1) {
          pages.unshift({id: 0, title: 'Página externa', path_name: ''})
        }
        this.pages = this.pages.concat(pages);
      });
    },
    setaForm(e) {
      e.fields.push(
        {
          id: 0,
          name_field: "hidden",
          form_field: `<input type="hidden" id="tenant_id" value="${this.$store.getters.user.user.tenant_id}">`,
        },
        {
          id: 0,
          name_field: "hidden",
          form_field: `<input type="hidden" id="page_id" value="${this.$route.params.page_id}">`,
        }
      );
      this.editaItem.propsData.fields = e.fields;
      this.editaItem.propsData.tenant_id =
        this.$store.getters.user.user.tenant_id;
      this.editaItem.propsData.page_id = this.$route.params.page_id;
    },    
    globalJustify(value, place) {
      let props = this.editaItem.propsData.alignment;
      props[this.typeView][place] = value;
      if (this.global && this.typeView == "desktop") {
        props[this.typeView][place] = value;
        props[this.typeView][place] = value;
      }
    },
    setaInputStyles() {
      var style = `style="border-radius:${
        this.editaItem.propsData.styles.input_radius
      }px !important; width:${
        this.editaItem.propsData.styles.input_width > 100
          ? 100
          : this.editaItem.propsData.styles.input_width
      }% !important; margin-bottom: ${
        this.editaItem.propsData.spacing.desktop.input_gap > 30
          ? 30
          : this.editaItem.propsData.spacing.desktop.input_gap
      }px; background-color: ${this.editaItem.propsData.styles.input_back};"`;

      var fields = this.editaItem.propsData.fields;
      for (let i = 0; i < fields.length; i++) {
        this.editaItem.propsData.fields[i].form_field =
          this.editaItem.propsData.fields[i].form_field.replace(
            /style="([^"]*)"/gi,
            ""
          );
        this.editaItem.propsData.fields[i].form_field =
          this.editaItem.propsData.fields[i].form_field.replace(
            /input type="([^\s]+)"/,
            "$&" + style
          );
      }
    },
    trocarType() {
      if (this.type == "none") {
        this.type = "uppercase";
        this.editaItem.propsData.styles.text_type = this.type;
        return;
      }
      if (this.type == "uppercase") {
        this.type = "none";
        this.editaItem.propsData.styles.text_type = this.type;
        return;
      }
    },
    openModal(data, fluxo) {
      this.$bvModal.show(data);
    },
    iconSelect(item) {
      this.editaItem.propsData.icon_selected = item;
    },
    saveOpt(opt) {
      if (!this.editaItem.propsData.form && !this.editaItem.propsData.form.id) {
        this.$grToast.toast("Por favor, selecione um formulário", {
          title: "Editor",
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
        this.editaItem.propsData.thank_you_page = {
          id: null,
          title: "Selecione a página de agradecimento",
        };
        return;
      }

      let data = {
        id: `insert-options/${this.editaItem.propsData.form.id}`,
        page_id: 1,
      };

      switch (opt) {
        case "page":
          data.metas = {
            editor_thank_you_page: this.editaItem.propsData.thank_you_page.link,
            thank_you_option:
              this.editaItem.propsData.opcao_agradecimento.value,
          };
          break;
        case "popup":
          data.metas = {
            success_mensage: this.editaItem.propsData.success_mensage,
            error_mensage: this.editaItem.propsData.error_mensage,
            thank_you_option:
              this.editaItem.propsData.opcao_agradecimento.value,
          };
          break;
        case "whatsapp":
          data.metas = {
            phone: this.editaItem.propsData.phone,
            whatsapp_mensage: this.editaItem.propsData.whatsapp_mensage,
            thank_you_option:
              this.editaItem.propsData.opcao_agradecimento.value,
          };
          break;
        case "adicionais":
          data.metas = {
            header: this.editaItem.propsData.header,
            footer: this.editaItem.propsData.footer,
          };
          break;
      }
      serviceForm.createId(data);
    },
    nextIcon(type) {
      this.$emit("attIconView", type);
    },
    backgroundCreate() {
      if (this.editaItem.propsData.styles.back_selected == "Cor sólida") {
        this.editaItem.propsData.styles.back_type = "color";
      }
      if (this.editaItem.propsData.styles.back_selected == "Gradiente") {
        this.editaItem.propsData.styles.back_type = "image";
      }
    },   
    debounce: _.debounce(function (opt) {
      this.saveOpt(opt);
    }, 500),
  },
  created() {
    this.borderLink= this.editaItem.propsData.form_linked;
    if (this.typeView == "desktop") {
      this.global = true;
    }
  },
};
</script>

<style lang="scss" scoped>
.links-advanced {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 18px;
  height: 18px;
  cursor: pointer;
}
.links {
  cursor: pointer;
}
.link-disabled {
  filter: invert(50%);
}

.und_form {
  position: relative;
  .und {
    position: absolute;
    right: 8px;
    top: 43px;
    font-size: 20px;
    font-weight: 600;
    color: #ccc;
    cursor: pointer;
  }
  .und2 {
    top: 7px !important;
  }
}

.alinhamento-2 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.alinhamento {
  display: flex;
  align-items: center;
  justify-content: center;
}

.item-1,
.item-2,
.item-3 {
  input {
    font-size: 0.8rem !important;
    line-height: 1.25rem !important;
    outline: 2px solid transparent !important;
    outline-offset: 2px !important;
    text-align: center !important;
    width: 2rem !important;
    padding: 0 !important;
    margin: 0 !important;
    height: 1rem !important;
    border: none !important;
  }
}

.container-spacing {
  display: flex;
  justify-content: center;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.labelOff {
  color: rgb(185, 185, 185) !important;
}
</style>
