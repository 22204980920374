import Rest from "@/services/Rest";

/**
 * @typedef {LineService}
 */
export default class LineService extends Rest {
  /**
   * @type {String}
   */
  static resource = "page/lines/list";
}