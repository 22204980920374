<template>
  <b-modal
    name="Modal-mark"
    id="Modal-mark"
    idModal="Modal-mark"
    title="Mudar marca d'água"
    centered
    hide-footer
    scrollable
    @shown="showMark = true"
  >
    <div>
      <p class="pb-3 pt-1">A marca d'água "Feito com {{ nameSystem }}" mostra que o seu site utiliza as tecnologias desenvolvidas pela Greenn Company. Caso queira oculta-la, é necessário ter o Plano Pro ou o Plano Enterprise.</p>
      <b-form-checkbox
        v-if="canChangeMark == false"
        class="disabledMarca"
        name="is_main"
        disabled
        size="lg"
        v-model="marcaTruePermanent"
        switch
        variant="suclightcess"
        validated
      >
        <p
          class="d-flex align-items-center"
          style="
            color: #00000075;
            font-size: small;
            padding-top: 5px;
            font-weight: 400 !important;
          "
        >
          Ativado
        </p>
      </b-form-checkbox>

      <b-form-checkbox
        v-else
        v-model="showMark"
        name="is_main"
        size="lg"
        switch
        variant="suclightcess"
        validated
        @change="emitMark()"
      >
        <p
          class="d-flex align-items-center"
          style="
            color: #00000075;
            font-size: small;
            padding-top: 5px;
            font-weight: 400 !important;
          "
        >
          {{ showMark ? "Ativado" : "Desativado" }}
        </p>
      </b-form-checkbox>
    </div>
  </b-modal>
</template>

<script>

export default {
  name: "MyComponent",
  props: ["canChangeMark", "viewMarca"],
  data() {
    return {
      showMark: true,
      marcaTruePermanent: true,
    };
  },
  computed:{
    nameSystem() {
      return this.$store.getters["getNameSystem"];
    },
  },
  methods: {
    emitMark() {
      this.$emit('changeMark', this.showMark)
    }
  },
};
</script>
<style>
#Modal-rows .modal-content {
  height: auto !important;
}
</style>

<style scoped lang="scss">
.buttons {
  width: 100%;
  display: flex;
  gap: 10px;
  margin-top: 15px;
  button {
    width: 100%;
    height: 45px;
    border-radius: 10px;
    font-family: "Montserrat";
    font-weight: 600;
    font-size: 14px;
  }
  .save {
    background-color: var(--greenn);
    color: white;
  }
}
</style>
