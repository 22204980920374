<template>
  <div class="class_render">
    <div 
      class="previewPage"
      :class="{
        'preview-tablet': typeView == 'tablet',
        'preview-phone2': typeView == 'mobile',
      }"
    >
      <div style="height: 100vh; display: flex; justify-content: center;">
        <iframe
        ref="preview"
        style="height: 100%"
        id="previewiframe"
        sandbox="allow-same-origin allow-scripts allow-forms"
        :style="`${responsiveCustonPage}`"      
        :src="`${returnProcessEnvNuxtApp}/preview`"
        frameborder="0"> 
        </iframe>
      </div>
    </div>

    <div class="controles-preview">
      <button class="button-preview" @click="$emit('changePreview', true, typePage)">
        <img
          style="margin-left: -10px"
          src="@/assets/editor/preview.svg"
          alt="voltar ao editor"
        />
        Voltar
      </button>
      <button
        class="button-preview"
        @click="viewType('desktop')"
        style="width: 45px"
        :class="{ activeType: typeView == 'desktop' }"
      >
        <img src="@/assets/editor/monitor2.svg" alt="monitor" />
      </button>
      <button
        class="button-preview"
        @click="viewType('tablet')"
        style="width: 45px"
        :class="{ activeType: typeView == 'tablet' }"
      >
        <img src="@/assets/editor/tablet2.svg" alt="tablet" />
      </button>
      <button
        class="button-preview"
        @click="viewType('mobile')"
        style="width: 45px"
        :class="{ activeType: typeView == 'mobile' }"
      >
        <img src="@/assets/editor/phone2.svg" alt="phone" />
      </button>
    </div>

  </div>
</template>

<script>
// COMPONENTS && PROPS
export default {
  props: ["path_name", "typeView", "preview"],
  data() {
    return {
      typePage: "desktop",
      cssCode: "",
      jsCode: "",
      currentPopUp: "",
      scrollPosition: 0,
      requireFontPreview: true,
      fontsLoadedPreviw: [],
    };
  },
  methods: {
    viewType(type){
      const mensage = { type:"responsiveEditor", typeView: type, location:'index.vue'}
      this.typePage = type
      this.$emit('newTypeView',type)
      this.iframePostMessage(mensage)
    },
    async renderPreview(allMensages) {
      allMensages.forEach(element => {
        this.iframePostMessage(element)
      }); 
    },
    async chargeFontPreview(font){
      let fonts = []
      font.forEach(async(item)=>{
        this.fontsLoadedPreviw.includes(item) ? null : fonts.push(item) && this.fontsLoadedPreviw.push(item)
      })
      const mensage = {type:'chargeFontOnly', fontOnly:fonts}
      if(mensage.fontOnly.length){
        // console.log("quais as fontes que o previw carregou",fonts);
        this.iframePostMessage(mensage)
      }
    },
    async iframePostMessage(array){
      await this.$nextTick()
      const iframe = this.$el.querySelector('#previewiframe').contentWindow
      if(iframe){
        iframe.postMessage(array, this.returnProcessEnvNuxtApp);
      }
    },
  },
  computed: {
    responsiveCustonPage(){
      if(this.typeView=="desktop"){
        return`width: 100% !important; `
      }else if(this.typeView=="mobile"){
        return`width: 400px !important;`
      }else if(this.typeView=="tablet"){
        return`width: 700px !important;`
      }
    },
    returnProcessEnvNuxtApp(){
      return `${process.env.NUXT_IFRAME ?? `https://3d1t05.gdigital.com.br`}`
    },
  },
};
</script>

<style scoped lang="scss">
body {
  overflow: scroll !important;
}
.activeType {
  background-color: #33333375 !important;
}
.preview-tablet {
  width: 700px !important;
  max-width: 700px !important;
}
.preview-phone2 {
  width: 400px !important;
  max-width: 400px !important;
}

.class_render {
  margin: 0 auto;
}
.previewPage {
  // position: absolute;
  // top: 0;
  // left: 50%;
  // transform: translateX(-50%);
  max-width: 100vw;
  width: 100vw;
  overflow: hidden;
  transition: all 0.5s;
  position: relative;
  clip-path: inset(0);
  margin: 0 auto;
}

.controles-preview {
  position: fixed;
  top: 50px;
  left: 50px;
  display: flex;
  gap: 10px;
  user-select: none;
}

.button-preview {
  justify-content: center;
  align-items: center;
  display: flex;
  width: 116px;
  height: 45px;
  border-radius: 10px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.2px;
  color: #f7f7f7;
  background: #33333340;
  transition: all 0.3s;
}

* {
  scroll-behavior: smooth !important;
}
</style>
