<template>
  <div id="templates-editor">
    <div v-if="level === 'templater'">
      <b-tabs pills justified small style="width: 100%">
        <b-tab title="Templates">
          <div class="mt-2">
            <div @click="setNewPage()" class="pointer box">
              <img
                class="img-conteudo mb-2 w-100"
                src="@/assets/img/icons/img_null.svg"
                alt="imagem-vazia"
              />
              <label class="template-name pointer"
                >{{ level === "templater" ? "Template" : "Página" }} em
                branco</label
              >
            </div>
          </div>
          <div class="mt-2" v-if="templatesCopy.length">
            <div
              @click="setaTemplate(template)"
              v-for="(template, index) in templatesCopy"
              :key="index"
              class="pointer box"
            >
              <img
                class="img-conteudo mb-2 w-100"
                :src="getMeta(template.metas, 'image')"
                alt="imagem-vazia"
                v-if="template && template.metas && template.metas.length"
              />
              <img
                v-else
                class="img-conteudo mb-2 w-100"
                src="@/assets/img/icons/img_null.svg"
                alt="imagem-vazia"
              />
              <label class="template-name pointer">{{ template.title }}</label>
            </div>
          </div>

          <!-- <b-row v-else-if="!templatesth" class="justify-content-center">
            <p>Nenhuma template encontrado</p>
          </b-row> -->
        </b-tab>
        <b-tab v-if="level != 'templater'" title="Pessoais">
          <div id="page-list" v-show="paginas.length" class="mt-2">
            <div
              @click="setaTemplate(pagina)"
              v-for="(pagina, index) in paginas"
              :key="index"
              class="pointer box"
            >
              <img
                class="img-conteudo mb-2 w-100"
                :src="getMeta(pagina.metas, 'image')"
                alt="imagem-vazia"
                v-if="pagina && pagina.metas.length"
              />
              <img
                v-else
                class="img-conteudo mb-2 w-100"
                src="@/assets/img/icons/img_null.svg"
                alt="imagem-vazia"
              />
              <label class="template-name pointer">{{ pagina.title }}</label>
            </div>
          </div>

          <b-row v-show="!paginas.length" class="justify-content-center">
            <p>Nenhuma template encontrado</p>
          </b-row>
        </b-tab>
      </b-tabs>
    </div>

    <div v-else>
      <b-tabs pills justified small style="width: 100%">
        <b-tab title="Prontos">
          <div v-if="!$store.getters.recursos.ntemplates" class="__blocked" style="top: 55px; left: 100px; width: calc(100% - 100px); height: calc(100vh - 130px); z-index: 1; padding: 30px;">
              <svg fill="#4ea934" height="100px" width="100px" version="1.1" viewBox="0 0 330 330" ><g stroke-width="0"></g><g stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g id="XMLID_509_"> <path id="XMLID_510_" d="M65,330h200c8.284,0,15-6.716,15-15V145c0-8.284-6.716-15-15-15h-15V85c0-46.869-38.131-85-85-85 S80,38.131,80,85v45H65c-8.284,0-15,6.716-15,15v170C50,323.284,56.716,330,65,330z M180,234.986V255c0,8.284-6.716,15-15,15 s-15-6.716-15-15v-20.014c-6.068-4.565-10-11.824-10-19.986c0-13.785,11.215-25,25-25s25,11.215,25,25 C190,223.162,186.068,230.421,180,234.986z M110,85c0-30.327,24.673-55,55-55s55,24.673,55,55v45H110V85z"></path> </g> </g></svg>
              <span style="color: inherit; " class="title">Acesso bloqueado</span>
              <span style="font-size: 14px; color: inherit; opacity: 0.7;">Esse recurso ainda não está incluso em seu plano,<br> fale com nosso time comercial para mais informações.</span>
              <button @click="upgradePlan()">Fazer upgrade</button>
          </div>
          <div class="list-templates">
            <div  v-if="$store.getters.recursos.ntemplates || !disappear" @mousemove.once="$store.getters.recursos.ntemplates ? '' : disappear = true">
              <div class="mt-2">
                <BaseSelect
                  id="limit-height"
                  :selectModel="selectedCategory"
                  placeholder="Selecionar Categoria"
                  track-by="id"
                  :array="options_categories"
                  trackname="name"
                  :searchable="true"
                  :loading="loadingCategories"
                  :multiple="false"
                  noResult="Oops! Nenhuma categoria encontrada."
                  noOptions="Pesquise pela categoria"
                  selectLabel=""
                  deselectLabel=""
                  selectedLabel=""
                  @select="handleCategoryChange($event)"
                >
                </BaseSelect>
              </div>
              <div @click="setNewPage()" class="pointer box mt-2">
                <img
                  class="img-conteudo mb-2 w-100"
                  src="@/assets/img/icons/img_null.svg"
                  alt="imagem-vazia"
                />
                <label class="template-name pointer">Página em branco</label>
              </div>
            </div>
            <div v-if="templatesCopy.length" class="mt-2">
              <div
                v-for="(template, index) in templatesCopy"
                @click="$store.getters.recursos.ntemplates ? setaTemplate(template) : ''"
                :key="index"
                tabindex=""
                class="pointer box"
              >
                <img
                  class="img-conteudo mb-2 w-100"
                  :src="getMeta(template.metas, 'image')"
                  alt="imagem-vazia"
                  v-if="template && template.metas && template.metas.length"
                />
                <img
                  v-else
                  class="img-conteudo mb-2 w-100"
                  src="@/assets/img/icons/img_null.svg"
                  alt="imagem-vazia"
                />
                <label class="template-name pointer">{{ template.title }}</label>
              </div>
            </div>
          </div>

          <!-- <b-row v-else-if="!paginas.length" class="justify-content-center">
            <p>Nenhuma template encontrado</p>
          </b-row> -->
        </b-tab>

        <b-tab title="Pessoais">
          <div id="page-list" v-show="paginas.length && !loading" class="mt-2">
            <div
              @click="setaTemplate(page)"
              v-for="(page, index) in paginas"
              :key="index"
              class="pointer box"
            >
              <img
                class="img-conteudo mb-2 w-100"
                :src="getMeta(page.metas, 'image')"
                alt="imagem-pagina"
                v-if="page && page.metas.length"
              />
              <img
                v-else
                class="img-conteudo mb-2 w-100"
                src="@/assets/img/icons/img_null.svg"
                alt="imagem-vazia"
              />
              <label class="template-name pointer">{{ page.title }}</label>
            </div>
          </div>

          <div class="d-flex justify-content-center" v-if="loading">
            <b-spinner></b-spinner>
          </div>

          <b-row
            v-if="paginas.length === 0 && !loading"
            class="justify-content-center"
          >
            <p>Nenhuma template encontrado</p>
          </b-row>
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<script>
import PageService from "@/services/resources/PageService";
const servicePage = PageService.build();
import Vue from "vue";
export default {
  props: ["templates", "paginas", "categories", "hasTemplates"],
  data() {
    return {
      level: this.$store.getters.user.user.level,
      current_page: 1,
      loading: false,
      options_categories: [],
      loadingCategories: false,
      selectedCategory:null,
      templatesCopy:[],
      disappear: false
    };
  },
  methods: {
    getCategories() {
      if (!this.categories || this.categories == [] || this.categories == "") {
        this.loadingCategories = true;
      } else {
        this.loadingCategories = false;
        this.options_categories = this.categories.filter(
          (category) => category.status === "active"
        );
        this.options_categories.unshift({
          id: null,
          name: "Todos as categorias",
          status: "all",
        });
      }
    },

    handleCategoryChange(newCategory) {
      this.selectedCategory = newCategory;

      if (
        newCategory.status === "all" ||
        newCategory === null ||
        newCategory === ""
      ) {
        this.loadingCategories = true;
        if (this.templates && this.templates.length > 0) {
          this.templatesCopy = this.templates;
          this.loadingCategories = false;
          return;
        }
      } else {
        this.templatesCopy = (newCategory.templates || []).filter(
          (template) => template.status === "publish"
        );
      }
    },
    upgradePlan() {
        $crisp.push(["set", "session:segments", [["financeiro"]]]);
        $crisp.push(["do", "chat:open"]);
        $crisp.push([
            "do",
            "message:send",
            ["text", `Quero fazer um upgrade no meu plano e ter acesso ao CRM.`],
        ]);
    },
    setNewPage() {
      Vue.swal({
        title: "Editor",
        text: `Deseja substituir ${
          this.level !== "templater"
            ? " essa página por uma página"
            : "esse template por um template"
        }  em branco?`,
        type: "danger",
        showCancelButton: true,
        confirmButtonText: "Sim",
        cancelButtonText: "Cancelar",
        customClass: "sweet-container",
        confirmButtonClass: "button button-danger mt-3 mb-3",
        cancelButtonClass: "button button-link-info mr-4 mt-3 mb-3",
        buttonsStyling: false,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.$emit("newPage");
        }
      });
    },
    setaTemplate(template) {
      let customClasses = "loading-page sweet-container"
      if (template.json) {
        customClasses = "sweet-container"
      } else {
        servicePage
          .read(`get/${template.id}`)
          .then((resp) => {
            template.json = resp.json;
            let swal = document.querySelector('.loading-page')
            swal.classList.remove('loading-page');
          })
          .catch((error) => {
            Vue.swal.close();
            this.$grToast.toast("Erro ao encontrar página selecionada", {
              title: "Editor",
              variant: "danger",
              autoHideDelay: 5000,
              appendToast: true,
            });
            return
          });
      }
      Vue.swal({
        title: "Editor",
        text: `Deseja substituir ou adicionar à página já existente?`,
        showCancelButton: true,
        confirmButtonText: "Adicionar",
        cancelButtonText: "Substituir",
        customClass: customClasses,
        confirmButtonClass: "button mt-3 mb-3",
        cancelButtonClass: "button button-link-info mr-4 mt-3 mb-3",
        buttonsStyling: false,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.$emit("somarTemplate", template);
        } else if (result.dismiss === 'cancel') {
          this.$emit("enviarTemplate", template);
        }
      });
    },
    getMeta(metas, chave) {
      var result =
        metas.find((x) => x.meta_key == chave) &&
        metas.find((x) => x.meta_key == chave).meta_value
          ? metas.find((x) => x.meta_key == chave).meta_value
          : "";
      if (!result) {
        if (chave === "image") {
          result = "/img/img_null.f2037356.svg";
        } else {
          result = null;
        }
      }
      return result;
    },
    getNextPage() {
      const listElm = document.querySelector("div#page-list");
      if (listElm) {
        listElm.addEventListener("scroll", () => {
          if (
            listElm.scrollTop + listElm.clientHeight >= listElm.scrollHeight &&
            this.current_page !== "stop"
          ) {
            this.current_page += 1;
            this.fetchPageListService();
          }
        });
      }
    },
  },
  mounted() {
    setTimeout(() => {
      this.getCategories();
      this.getNextPage();
      this.selectedCategory = this.options_categories.filter(
        (x) => x.status === "all"
      );
    }, 500);
  },

  watch: {
    templates() {
      if (this.templatesCopy.length) {
        this.templatesCopy.unshift({
          id: null,
          name: "Selecione todos os templates",
        });
      }
      this.templatesCopy = [...this.templates];
    },

    categories: {
      handler: "getCategories",
      immediate: true,
    },
  },
};
</script>

<style scoped lang="scss">
.img-conteudo {
  width: 100%;
  height: 154px;
  border-radius: 10px 10px 0 0;
  object-fit: cover;
}
.paginas {
  color: black;
}

.template-name {
  padding: 5px 15px !important;
  font-size: 14px !important;
  font-weight: 500 !important;
}
.not-found {
  font-size: 14px;
  color: #81858e;
  font-weight: normal;
  border-radius: 10px;
  padding: 20px !important;
  margin: 20px auto;
}
.pointer {
  cursor: pointer;
}

.box {
  border-radius: 10px;
  border: 1px solid #eee;
  transition: all 0.3s;
  &:hover {
    transform: scale(1.01);
  }
  margin-bottom: 10px;
}
#page-list {
  overflow-y: scroll;
  height: 100vh;
}
.nav-pills {
  padding: 0px 20px 15px 20px;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: var(--gray01) !important;
  background-color: #fff !important;
  font-weight: 600 !important;
  position: relative;
  transition: all 0.3s;
  &::before {
    content: "";
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: var(--gray01);
    top: 13px;
    left: 5px;
    animation: appear 0.8s forwards;
  }
}
a {
  color: var(--gray01) !important;
  font-weight: 400 !important;
}
</style>

<style lang="scss">
#templates-editor {
  #limit-height .multiselect__tags {
    max-height: 45px !important;
  }
  .tab-content {
    max-height: calc(100vh - 130px);
    overflow: hidden auto;
  }
}
.loading-page::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  z-index: 2;
  background: linear-gradient(100deg, #ecececAA 8%, #f5f5f5 18%, #ecececAA 33%);
  background-size: 200% 100%;
  animation: shine 1s linear infinite;
  border-color: var(--white-medium) !important;
  border-radius: 10px;
  cursor: wait;
}
@keyframes shine {
  to {
    background-position-x: -200%;
  }
}

@-webkit-keyframes shine {
  to {
    background-position-x: -200%;
  }
}

@-moz-keyframes shine {
  to {
    background-position-x: -200%;
  }
}

@-o-keyframes shine {
  to {
    background-position-x: -200%;
  }
}

.list-templates {
  width: 100%;
  height: 100%;
  overflow: scroll !important;
}
</style>