let propsTextoVideo = {
  custom: {
    c_class: '',
    c_id: '',
  },
  video: {
    url: "https://www.youtube.com/watch?v=23QLGlUbeT8",
    url_converted: "https://www.youtube.com/embed/23QLGlUbeT8",
    thumb: "",
    type: 'YouTube',
    code: ``,
  },
  alignment: {
    desktop: { justify: "center", justify_vertical: "center" },
    tablet: { justify: "center", justify_vertical: "center" },
    mobile: { justify: "center", justify_vertical: "center" },
  },
  size: {
    desktop: { width: 444, height: 250, compleate: true, proportion: '16/9' },
    tablet: { width: 250, height: 250, compleate: true},
    mobile: { width: 250, height: 250, compleate: true},
  },
  spacing: {
    desktop: {
      margin_top: 0,
      margin_right: 0,
      margin_bottom: 0,
      margin_left: 0,
      padding_top: 0,
      padding_right: 0,
      padding_bottom: 0,
      padding_left: 0,
    },
    tablet: {
      margin_top: 0,
      margin_right: 0,
      margin_bottom: 0,
      margin_left: 0,
      padding_top: 0,
      padding_right: 0,
      padding_bottom: 0,
      padding_left: 0,
    },
    mobile: {
      margin_top: 0,
      margin_right: 0,
      margin_bottom: 0,
      margin_left: 0,
      padding_top: 0,
      padding_right: 0,
      padding_bottom: 0,
      padding_left: 0,
    },
  },
  visibility: {
    monitor: true,
    tablet: true,
    phone: true,
  },
};

export default propsTextoVideo;
