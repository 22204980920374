<template>
  <div id="SideRightTabs">
    <b-card no-body>
      <b-tabs pills justified small style="width: 100%">
        <b-tab title="Editor" @click="editMode = false">
          <div class="separador"></div>
          <div id="app" class="text-component">
            <div :style="dynamicPosition" style="display: flex; align-items: center;" class="ck-theme"
              :class="{ 'button-expand': expandCkButton }">
              <span class="ck-button-theme">
                {{ isBlack ? 'Tema escuro' : 'Tema claro' }}
              </span>
              <div @click="blockTheme = true">
                <b-form-checkbox v-model="isBlack" style="margin-top: -5px; margin-left: auto; margin-right: 0"
                name="is_main" size="lg" switch>
                </b-form-checkbox>
              </div>
            </div>
            <ckeditor @input="debouncecolorCalculer2" @namespaceloaded="onNamespaceLoaded" v-if="!editMode"
              :config="editorConfig" v-model="editaItem.propsData.text.content_text"></ckeditor>
          </div>
          <div class="separador"></div>
        </b-tab>

        <b-tab title="Estilo" v-if="editaItem.propsData.styles.hasOwnProperty('line_height')">
          <!-- Cores -->
          <div class="separador"></div>
          <div class="content">
            <label class="titulo"> Cores </label>
            <label class="subtitulo"> Cor do texto </label>
            <LvColorpicker id="LvColorpicker" v-model="editaItem.propsData.styles.color_text"
              @input="debouncecolorCalculer" label="Cor do texto" :colors="palette"></LvColorpicker>
            <div class="space"></div>
            <label class="subtitulo"> Fundo </label>
            <LvColorpicker id="LvColorpicker" v-model="editaItem.propsData.styles.background_color_text"
              label="Cor do texto" :colors="palette"></LvColorpicker>
          </div>

          <div class="separador"></div>
          <div class="content">
            <label class="titulo">Tipografia</label>
            <div class="label-icon">
              <label class="subtitulo" style="margin: 5px 0 0 0 !important;">Tamanho</label>
              <div>
                <img src="@/assets/editor/globe.svg" alt="monitor" style="filter: invert(50%) !important; margin: 0"
                  @click="nextIcon('desktop'), (global = !global)" v-if="global" />
                <img src="@/assets/editor/monitor.svg" alt="monitor" style="filter: invert(50%) !important; margin: 0"
                  @click="nextIcon('tablet')" v-if="typeView === 'desktop' && !global" />
                <img src="@/assets/editor/tablet.svg" alt="tablet" style="filter: invert(50%) !important; margin: 0"
                  @click="nextIcon('mobile')" v-if="typeView === 'tablet' && !global" />
                <img src="@/assets/editor/phone.svg" alt="phone" style="filter: invert(50%) !important; margin: 0"
                  @click="nextIcon('desktop'), (global = !global)" v-if="typeView === 'mobile' && !global" />
              </div>
            </div>
            <div class="mt-2" style="
                display: flex;
                gap: 10px;
                width: 100%;
                gap: 10px;
                margin-bottom: -1rem;
              ">
              <b-button-group>
                <b-button :disabled="editaItem.propsData.styles[typeView].size == 0
                  " size="sm" style="
                    height: min-content !important;
                    padding: 10.5px 7px !important;
                  "
                  @click="editaItem.propsData.styles[typeView].size--, globalInput(editaItem.propsData.styles[typeView].size, 'styles', 'size')"><img
                    src="@/assets/editor/minus3.svg" alt="align" /></b-button>
                <b-form-input 
                  @focus="openDropdownSlider('editaItem.propsData.styles[typeView].size')"
                  @input="globalInput($event, 'styles', 'size')" 
                  class="text-right"
                  v-model="editaItem.propsData.styles[typeView].size" style="
                    width: 44px;
                    text-align: center !important;
                    border: none !important;
                    padding: 0 !important;
                "></b-form-input>
                <b-button
                  @click="editaItem.propsData.styles[typeView].size++, globalInput(editaItem.propsData.styles[typeView].size, 'styles', 'size')"
                  size="sm" style="
                    height: min-content !important;
                    padding: 10.5px 7px !important;
                  "><img src="@/assets/editor/plus.svg" alt="align" /></b-button>
              </b-button-group>

              <div>
                <multiselect v-model="editaItem.propsData.styles[typeView].font_measure"
                  :options="['px', 'rem', '%', 'em']" :searchable="false" :show-labels="false" placeholder=" "
                  :hide-selected="true" :allow-empty="false"
                  @input="globalInput(editaItem.propsData.styles[typeView].font_measure, 'styles', 'font_measure')">
                </multiselect>
              </div>

              <!-- <div>
                <b-button
                  size="sm"
                  @click="trocarType"
                  style="
                    height: min-content !important;
                    padding: 10.5px 10px !important;
                  "
                  ><img src="@/assets/editor/type.svg" alt="align"
                /></b-button>
              </div> -->
            </div>
            <div class="space"></div>
            <div class="space"></div>

            <!-- Fonte e cor -->
            <label class="subtitulo">Fonte</label>

            <div class="max-multi">
              <multiselect 
                v-model="editaItem.propsData.styles.font_family" 
                :options="fontOptions" 
                :searchable="true"
                :show-labels="false" 
                placeholder="Fonte" 
                :hide-selected="true"
                @input="$emit('callCharge',$event)" 
                :allow-empty="false">
              </multiselect>
            </div>

            <div class="space"></div>

            <!-- Alinhamento -->
            <div>
              <div class="label-icon">
                <label class="subtitulo" style="margin: 5px 0 0px 0 !important;">Alinhamento</label>
                <div>
                  <img src="@/assets/editor/globe.svg" alt="monitor" style="filter: invert(50%) !important; margin: 0"
                    @click="nextIcon('desktop'), (global = !global)" v-if="global" />
                  <img src="@/assets/editor/monitor.svg" alt="monitor" style="filter: invert(50%) !important; margin: 0"
                    @click="nextIcon('tablet')" v-if="typeView === 'desktop' && !global" />
                  <img src="@/assets/editor/tablet.svg" alt="tablet" style="filter: invert(50%) !important; margin: 0"
                    @click="nextIcon('mobile')" v-if="typeView === 'tablet' && !global" />
                  <img src="@/assets/editor/phone.svg" alt="phone" style="filter: invert(50%) !important; margin: 0"
                    @click="nextIcon('desktop'), (global = !global)" v-if="typeView === 'mobile' && !global" />
                </div>
              </div>
              <b-button-group size="sm" style="margin-top: 5px">
                <b-button
                  @click="editaItem.propsData.styles[typeView].align = 'left', globalInput(editaItem.propsData.styles[typeView].align, 'styles', 'align')"><img
                    src="@/assets/editor/align-left.svg" /></b-button>
                <b-button
                  @click="editaItem.propsData.styles[typeView].align = 'center', globalInput(editaItem.propsData.styles[typeView].align, 'styles', 'align')"><img
                    src="@/assets/editor/align-center.svg" /></b-button>
                <b-button
                  @click="editaItem.propsData.styles[typeView].align = 'right', globalInput(editaItem.propsData.styles[typeView].align, 'styles', 'align')"><img
                    src="@/assets/editor/align-right.svg" /></b-button>
                <b-button
                  @click="editaItem.propsData.styles[typeView].align = 'justify', globalInput(editaItem.propsData.styles[typeView].align, 'styles', 'align')"><img
                    src="@/assets/editor/align-justify.svg" /></b-button>
              </b-button-group>
            </div>

            <!-- Espessura -->
            <div>
              <div class="space"></div>
              <div class="label-range">
                <label class="subtitulo">Espessura da fonte</label>
                <input type="number" v-model="editaItem.propsData.styles.font_weight" />
              </div>
              <b-form-input v-model="editaItem.propsData.styles.font_weight" type="range" min="100" max="900"
                step="100"></b-form-input>
            </div>

            <!-- Espaçamento entre letras -->
            <div>
              <div class="label-range">
                <label class="subtitulo">Espaço entre letras</label>
                <input type="number" v-model="editaItem.propsData.styles.letter_space" />
              </div>
              <b-form-input v-model="editaItem.propsData.styles.letter_space" type="range" min="-15" max="15"
                step="0.1"></b-form-input>
            </div>

            <!-- Altura da linha -->
            <div>
              <div class="label-range">
                <label class="subtitulo">Altura da linha</label>
                <input type="number" v-model="editaItem.propsData.styles.line_height" />
              </div>
              <b-form-input v-model="editaItem.propsData.styles.line_height" type="range" min="0" max="5"
                step="0.1"></b-form-input>
            </div>
          </div>
          <div class="separador"></div>
        </b-tab>

        <b-tab title="Avançado">
          <div class="separador"></div>

          <!-- Largura máxima -->
          <div class="content">
            <div class="label-icon">
              <label class="titulo">Dimensões</label>
              <img src="@/assets/editor/globe.svg" alt="monitor" style="filter: invert(50%) !important"
                @click="nextIcon('desktop'), (global = !global)" v-if="global" />
              <img src="@/assets/editor/monitor.svg" alt="monitor" style="filter: invert(50%) !important"
                @click="nextIcon('tablet')" v-if="typeView === 'desktop' && !global" />
              <img src="@/assets/editor/tablet.svg" alt="tablet" style="filter: invert(50%) !important"
                @click="nextIcon('mobile')" v-if="typeView === 'tablet' && !global" />
              <img src="@/assets/editor/phone.svg" alt="phone" style="filter: invert(50%) !important"
                @click="nextIcon('desktop'), (global = !global)" v-if="typeView === 'mobile' && !global" />
            </div>
            <div class="label-range">
              <label class="subtitulo">Largura máxima</label>
              <input type="number" v-model="editaItem.propsData.styles.max_width[typeView]"
                @input="globalMeasure($event)" />
            </div>
            <b-form-input id="range-1" v-model="editaItem.propsData.styles.max_width[typeView]" type="range" min="10"
              max="2000" @input="globalMeasure($event)"></b-form-input>

            <div>
              <label for="form-altura" class="sub">Altura mínima</label>
              <b-form-group id="form-altura" class="und_form">
                <div class="und_form">
                  <b-form-input 
                    id="form-altura" 
                    v-model="editaItem.propsColumn.height[typeView].height" 
                    type="number"
                    min="0"
                    @focus="openDropdownSlider('editaItem.propsColumn.height[typeView].height')" 
                    >
                  </b-form-input>
                  <span 
                    @click="next_und_height" 
                    class="und text" 
                    v-b-tooltip.hover 
                    title="Altura em pixel"
                  >{{editaItem.propsColumn.height[typeView].height_und }}
                  </span>
                </div>
              </b-form-group>
            </div>
            <div class="space"></div>
            <label class="subtitulo">Posição do bloco de texto</label>
            <div style="display: flex; width: 50%; transition: all 0.3s"
              v-if="editaItem.propsData.styles.hasOwnProperty('justify_vertical')">
              <div class="select-align">
                <button :class="{ 'selected-align': editaItem.propsData.styles.justify_vertical[typeView] == 'start' }"
                  @click="globalJustify('start', true)"><img src="@/assets/editor/align-top.svg" /></button>
                <button :class="{ 'selected-align': editaItem.propsData.styles.justify_vertical[typeView] == 'center' }"
                  @click="globalJustify('center', true)"><img
                    src="@/assets/editor/align-center-vertical.svg" /></button>
                <button
                  :class="{ 'selected-align': editaItem.propsData.styles.justify_vertical[typeView] == 'flex-end' }"
                  style="z-index: 2" @click="globalJustify('flex-end', true)"><img
                    src="@/assets/editor/align-bottom.svg" /></button>
              </div>
            </div>
          </div>
          
          <advancedBasic
            :spacing="editaItem.propsData.spacing" 
            :visibility="editaItem.propsData.visibility" 
            :animation="editaItem.propsColumn" 
            :attributes="editaItem.propsData.custom" 
            :typeView="typeView"
            :global="global"
            @toggleGlobal="global = !global"
            @nextIcon="nextIcon"
            @update:spacing="editaItem.propsData.spacing = $event"
            @update:visibility="editaItem.propsData.visibility = $event"
            @update:animation="editaItem.propsColumn = $event"
            @update:attributes="editaItem.propsData.custom = $event"
          ></advancedBasic>
        </b-tab>
      </b-tabs>
    </b-card>
    <dropdownSlider
      ref="dropdownSlider"   
      @updateValue="updateValue"
    /> 
  </div>
</template>
<script>
import dropdownSliderMixin from "@/mixins/dropdownSliderMixin.js";
import advancedBasic from "../EditComponents/advancedBasic.vue";
import LvColorpicker from "lightvue/color-picker";
import Multiselect from "vue-multiselect";
import CKEditor from "ckeditor4-vue";
import _ from "lodash";
import Vue from "vue";
Vue.use(CKEditor);

export default {
  mixins: [dropdownSliderMixin],
  props: ["editaItem", "openSideRight", "fontOptions", "typeView", "divWidth", "palette"],
  components: {
    advancedBasic,
    Multiselect,
    LvColorpicker: LvColorpicker,
  },
  data() {
    return {
      originalAlert: window.alert,
      originalConfirm: window.confirm,
      blockTheme: false,
      expandCkButton: false,
      isBlack: false,
      editMode: false,
      content: true,
      global: false,
      editorConfig: {
        uiColor: "#eeeeee",
        allowedContent: true,
        startupFocus: true,
        toolbar: [
          ["Maximize"],
          [
            "Bold",
            "Italic",
            "Strike",
          ],
          ["TextColor", "BGColor"],
          ["Link", "Unlink"],
          ["Source"],
          ["FontSize"],
          ["Undo", "Redo"],
        ],
        extraPlugins:
          "justify, colorbutton, colordialog, font, lineutils, richcombo, uicolor,divarea,link",
        removePlugins:
          "about, blockquote, image, a11yhelp, resize, list, pastetext, pastefromgdocs, pastefromlibreoffice, pastefromword, showborders, tableselection, tabletools, pastetools, tableselection",
        on: {
          instanceReady: (event) => {
            event.editor.on('paste', this.handlePasteEvent);
            event.editor.on('dialogShow', (dialogEvent) => {
              const dialogName = dialogEvent.data._.name;
              if (dialogName === 'link') {
                this.handleLinkDialogShow(dialogEvent);
              }
            });
            event.editor.on('dialogHide', (dialogEvent) => {
              const dialogName = dialogEvent.data._.name;
              if (dialogName === 'link') {
                this.handleLinkDialogHide(dialogEvent);
              }
            });
          },
        },
      },
    };
  },
  computed: {
    dynamicPosition() {
      if (this.divWidth > 536) {
        return `top: 22px`;
      } else if (this.divWidth > 330) {
        return `top: 55px`;
      } else if (this.divWidth > 245) {
        return `top: 90px`;
      } else if (this.divWidth > 210){
        return `top: 120px`;
      }else {
        return `top: 155px`;
      }
    },
  },
  watch: {
    
    isBlack: {
      handler: function (newValue) {
        this.themeCkEditor()
      }
    },

    editaItem: {
      handler: function (newValue) {
        this.debounceSetProps();
      },
      immediate: true,
      deep: true,
    },
    divWidth: {
      handler: function (newValue) {
        this.debounceDynamicHeight();
      },
      immediate: true,
    },
  },
  methods: {
    saveText(){
      let isSurceOpen = this.$el.querySelector('a.cke_button__source.cke_button_on') 
      if(isSurceOpen !== null ){
        isSurceOpen.click()
      }
    },
    handlePasteEvent(evt) {
        var textoColado = evt.data.dataValue;
        // Remover <b> com qualquer atributo
        textoColado = textoColado.replace(/<b[^>]*>/g, '');
        evt.data.dataValue = textoColado;
    },
    handleLinkDialogShow(event) {     
      const dialog = event.data;
      const linkDisplayText = dialog.getContentElement('info', 'linkDisplayText');
      const url = dialog.getContentElement('info', 'url');
      const linkType = dialog.getContentElement('info', 'linkType');
      const targetTypeElement = dialog.getContentElement('target', 'linkTargetType');
      const protocol = dialog.getContentElement('info', 'protocol'); 
      if (linkDisplayText) {
        const inputElement = linkDisplayText.getInputElement().$;
        inputElement.placeholder = "Exemplo: Greenn sales";
      }
        const container = linkDisplayText.getElement().$.parentNode;
        if (!container.querySelector('.info-text')) {
          const infoText = document.createElement('p');
          infoText.textContent = "Insira aqui o seu texto:";
          infoText.className = 'info-text'; 
          infoText.style.fontSize = '14px'; 
          infoText.style.color = '#141414'; 
          container.insertBefore(infoText, linkDisplayText.getElement().$);
        }
      if (url) {
        const inputElement = url.getInputElement().$;
        inputElement.placeholder = "greenn.com.br/sales";
        const container = url.getElement().$.parentNode;
        if (!container.querySelector('.info-text-url')) {
            const infoText = document.createElement('p');
          infoText.textContent = "insira aqui o seu link:";
          infoText.className = 'info-text-url';
          infoText.style.fontSize = '14px'; 
          infoText.style.color = '#141414';
          container.insertBefore(infoText, url.getElement().$);
        }
      }
      if (linkType) {
        const linkTypeElement = linkType.getElement().$;
        linkTypeElement.style.display = 'none'; 
      }
      if (protocol) {
        const container = protocol.getElement().$.parentNode;
        if (!container.querySelector('.info-text-protocol')) {
          const infoText = document.createElement('p');
          infoText.textContent = "Protocolo:";
          infoText.className = 'info-text-protocol'; 
          infoText.style.fontSize = '14px'; 
          infoText.style.color = '#141414';
          container.insertBefore(infoText, protocol.getElement().$);
        }
      }
      if (targetTypeElement) {
        const selectElement = targetTypeElement.getInputElement().$;
        selectElement.classList.add('custom-select-teste');
        const optionsToRemove = ["frame", "popup", "_top", "_parent"];
        for (let i = selectElement.options.length - 1; i >= 0; i--) {
          if (optionsToRemove.includes(selectElement.options[i].value)) {
            selectElement.remove(i);
          }
        }
        for (let i = 0; i < selectElement.options.length; i++) {
          if (selectElement.options[i].value === "notSet") {
            selectElement.options[i].textContent = "Não definido";
            break; 
          }
        }
        const container = targetTypeElement.getElement().$.parentNode;
        if (!container.querySelector('.info-text-target-type')) {
          const infoText = document.createElement('p');
          infoText.textContent = "Escolha o destino:";
          infoText.className = 'info-text-target-type'; 
          infoText.style.fontSize = '14px'; 
          infoText.style.color = '#141414';
          container.insertBefore(infoText, targetTypeElement.getElement().$);
        }
      }
      window.alert = function() { 
      }
      window.confirm = function(message) {
        return true; 
      };
    },
    handleLinkDialogHide() {
      window.alert = this.originalAlert;
      window.confirm = this.originalConfirm;

    },
    debouncecolorCalculer: _.debounce(function () {
      const element = this.editaItem.propsData.text.content_text;
      const tempElement = document.createElement('div');
      tempElement.innerHTML = element;
      const spanElement = tempElement.querySelector('span');

      if (spanElement) {
        const styleValue = spanElement.getAttribute('style');

        if (styleValue !== null) {
          const stylePairs = styleValue.split(';').map(pair => pair.trim());

          for (const pair of stylePairs) {
            const [key, value] = pair.split(':').map(item => item.trim());

            if (key === 'color') {
              this.luminanceCalculer(value);
              // console.log("valor3", value)
              return; 
            }
          }
        }
      }
      this.luminanceCalculer(this.editaItem.propsData.styles.color_text);
      this.setClickOnCkEditor()
    }, 300),
    debouncecolorCalculer2: _.debounce(function () {
      if (this.blockTheme) {
        return
      }
      this.debouncecolorCalculer()
    }, 300),

    luminanceCalculer(value) {
      const colorValue = value;
      //console.log("valor recebudo",value);
      const hex = colorValue.slice(1);
      const r = parseInt(hex.slice(0, 2), 16);
      const g = parseInt(hex.slice(2, 4), 16);
      const b = parseInt(hex.slice(4, 6), 16);
      const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
      if (luminance <= 0.5) {
        this.isBlack = false
      } else {
        this.isBlack = true
      }
    },

    next_und_height() {
      const props = this.editaItem.propsColumn.height;
      props[this.typeView].height_und = props[this.typeView].height_und == "px" ? "vh" : props[this.typeView].height_und == "vh" ? "vw" : "px";
      if (this.global && this.typeView == 'desktop') {
        props.tablet.height_und = props.desktop.height_und
        props.mobile.height_und = props.desktop.height_und
      }
    },
    globalInput(px, place1, place2) {
      if (this.global && this.typeView == 'desktop') {
        var pixel = px.target ? px.target.value : px;
        var props = this.editaItem.propsData;
        props[place1].tablet[place2] = pixel;
        props[place1].mobile[place2] = pixel;
      }
    },
    debounceDynamicHeight: _.debounce(function () {
      this.dynamicHeight();
    }, 200),
    dynamicHeight() {
      // console.log('oiiiii')
      const textarea = document.querySelector(".cke_reset");
      if(textarea){
        if (this.divWidth > 629) {
          textarea.style['min-height'] = 'calc(100vh - 224px)'
          textarea.style['max-height'] = 'calc(100vh - 224px)'
          // console.log('oiiiii1')
        } else if (this.divWidth > 335) {
          textarea.style['min-height'] = 'calc(100vh - 257px)'
          textarea.style['max-height'] = 'calc(100vh - 257px)'
          // console.log('oiiiii2')
        } else {
          textarea.style['min-height'] = 'calc(100vh - 290px)'
          textarea.style['max-height'] = 'calc(100vh - 290px)'
          // console.log('oiiiii3')
        }
      }
    },
    debounceSetProps: _.debounce(function () {
      this.setProps();
    }, 200),
    setProps() {
      var styles = this.editaItem.propsData.styles
      if (styles.hasOwnProperty('letter_space')) {
        let shadow
        if (styles.shadow.hasShadow) {
          shadow = `${shadow_x} ${shadow_y} ${shadow_blur} ${shadow_color}`
        } else {
          shadow = 'none'
        }
        let backColor
        if (this.isBlack) {
          backColor = '#000000'
        } else {
          backColor = styles.background_color_text
        }
        var textarea = document.querySelector(".cke_wysiwyg_div");
        if (textarea) {
          textarea.style['color'] = `${styles.color_text}`
          textarea.style['font-size'] = `${styles[this.typeView].size}px`
          textarea.style['font-family'] = `${styles.font_family}`
          textarea.style['font-weight'] = `${styles.font_weight}`
          textarea.style['letter-spacing'] = `${styles.letter_space}px`
          textarea.style['line-height'] = `${styles.line_height}`
          textarea.style['text-transform'] = `${styles.transform}`
          textarea.style['text-align'] = `${styles[this.typeView].align}`
          textarea.style['text-shadow'] = `${shadow}`
          textarea.style['background'] = `${backColor}`
        }
      }
    },
    themeCkEditor() {
      let textarea = document.querySelector(".cke_wysiwyg_div");
      if(textarea){
        textarea.style.background = this.isBlack ? "#000000" : this.editaItem.propsData.styles.background_color_text;
      }
    },
    setClickOnCkEditor(){
      let source = this.$el.querySelector('.cke_button__source')
      if (source) {
        source.addEventListener('click',(ev)=>{
          this.verifySource()
        })
      }
    },
    verifySource(){
      let sourceMode = this.$el.querySelector('.cke_source')
      
      if(!sourceMode){
        this.setProps()
      }
    },
    handleClick(event) {
      const expandCkEditor = document.querySelector(".cke_toolgroup");
      if (expandCkEditor.contains(event.target)) {
        this.expandCkButton = !this.expandCkButton;
      } else { return }
    },
    onNamespaceLoaded(CKEditor) {
      // console.log("teste");
      CKEditor.plugins.addExternal(
        "letterspacing",
        "./plugins/letterspacing/plugin",
        "plugin.js"
      );
      // console.log(CKEditor);
    },
    globalJustify(value, isVertical) {
      var props = this.editaItem.propsData.styles.justify;
      if (isVertical) {
        props = this.editaItem.propsData.styles.justify_vertical;
      }
      props[this.typeView] = value;
      if (this.global && this.typeView == 'desktop') {
        props.tablet = value;
        props.mobile = value;
      }
    },
    globalMeasure(pixel) {
      var px = pixel.target ? pixel.target.value : pixel;
      if (this.global && this.typeView == 'desktop') {
        var props = this.editaItem.propsData.styles.max_width;
        props.tablet = px;
        props.mobile = px;
      }
    },
    nextIcon(type) {
      this.$emit("attIconView", type);
    },
  },
  async mounted() {
    this.blockTheme = false;
    window.addEventListener("click", this.handleClick);
    this.setProps()
    this.debouncecolorCalculer()
  },
  beforeDestroy() {
    window.removeEventListener("click", this.handleClick);
  },
  created() {
    if (this.typeView == "desktop") {
      this.global = true;
    }
  },
};
</script>

<style lang="scss">
strong {
  font-weight: bolder !important;
}

.text-component .cke_toolbar_last {
  padding-right: 68px !important;
}

.button-expand {
  z-index: 9999999999;
  top: 7px !important;
  right: -775px !important;
}

a.cke_button,
a.cke_combo_button {
  border-radius: 10px !important;
  transition: all 0.3s !important;

  &:hover {
    background-color: #ededf0 !important;
  }
}

.ck-button-theme {
  border-radius: 10px;
  background-color: transparent;
  font-size: 12px;
  color: #484848;
  font-family: Arial, Helvetica, sans-serif;
  padding: 5px 10px 3px;
}

.cke_button_on {
  .cke_button_icon {
    filter: brightness(0.5);
  }
}

.cke_button_off {
  .cke_button_icon {
    filter: brightness(1.5);
  }
}

.cke_top {
  background-color: transparent !important;
  border-bottom: 1px solid #ededf0 !important;
  padding: 20px 20px 10px !important;
}

#SideRightTabs {
  position: relative;
}

.ck-theme {
  position: absolute;
  top: 140px;
  right: 20px;
}

.cke_wysiwyg_div {
  padding-top: 1em !important;
  padding: 10px 25px !important;
}

textarea.cke_source {
  min-height: 100% !important;
  border: 0px solid #000000 !important;
}

.cke_source:hover {
  border: 0px solid #000000 !important;
}

.cke_wysiwyg_div * {
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  letter-spacing: inherit;
  line-height: inherit;
  font-size: inherit;
  text-align: inherit;
  text-shadow: inherit;
  text-transform: inherit;
}

a.cke_button_off:hover,
a.cke_button_off:focus,
a.cke_button_off:active {
  background: #e5e5e5;
  padding: 4px 6px !important;
}

.cke_toolbar_start+.cke_combo_off a.cke_combo_button:hover,
.cke_toolbar_start+.cke_combo_off a.cke_combo_button:focus,
.cke_toolbar_start+.cke_combo_off a.cke_combo_button:active {
  padding: 1px !important;
  margin-left: 0px !important;
}

.text-component .cke_reset {
  min-height: calc(100vh - 290px);
  max-height: calc(100vh - 290px);
}

a.cke_button_expandable.cke_button_off:hover,
a.cke_button_expandable.cke_button_off:focus,
a.cke_button_expandable.cke_button_off:active {
  padding: 4px 5px !important;
}

.cke_combo_on a.cke_combo_button,
.cke_combo_off a.cke_combo_button:hover,
.cke_combo_off a.cke_combo_button:focus,
.cke_combo_off a.cke_combo_button:active {
  background: #e5e5e5;
  padding: 1px !important;
  margin-left: 0px !important;
}

.cke_resizer {
  display: none !important;
}

a.cke_dialog_ui_button {
  border-radius: 10px !important;
  display: inline-block;
  padding: 4px 1px;
  margin: 0;
  text-align: center;
  color: #484848;
  vertical-align: middle;
  cursor: pointer;
  border: 0px solid #bcbcbc !important;
  background: #f8f8f8;
  letter-spacing: 0.3px;
  line-height: 18px;
  box-sizing: border-box;
  transition: all 0.3s !important;
}

.cke_dialog_footer_buttons a.cke_dialog_ui_button span {
  color: inherit;
  font-size: 12px;
  font-weight: 500 !important;
  padding: 0 12px;
  font-family: montserrat;
}

a.cke_button_on {
  background: rgb(255, 255, 255);
  padding: 4px 6px !important;
}

.cke_dialog_body{
  .cke_dialog_title{
    font-size: 16px !important;
    color: var(--greenn) !important;
    font-weight: 600 !important;
  }
}
a.cke_dialog_tab{
  font-family: montserrat !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  padding: 5px 10px !important;
  color: var(--gray01) !important;
  background-color: var(--transparent) !important;
  border-radius: 10px !important;
  position: relative !important;
  -webkit-transition: all 0.3s !important;
  transition: all 0.3s !important;
  border: none !important
}

a.cke_dialog_tab:hover{
  background-color: var(--greenn2) !important;
}

div.cke_dialog_tabs{
  padding: 15px 20px !important;
  position: relative !important;
  margin: 0 !important;
  display: flex !important;
  gap: 20px
}

.cke_dialog_body .cke_dialog_contents_body{
  margin-top: 100px !important;
}

.cke_dialog_contents{
  margin-top: 0px !important;
  border-top-color: var(--white-medium) !important;
}

.cke_1 .cke_dialog_tab_selected, .cke_1 .cke_dialog_tab_selected:hover{
  background-color: var(--greenn-transparent) !important;
}

select.cke_dialog_ui_input_select{
  padding: 5px 10px 4px 10px !important;
  margin: 0px !important;
}
.cke_dialog_ui_input_select option {
  padding: 10px;
}
.cke_dialog_body input{
  font-family: montserrat !important;
  font-size: 14px !important;
  padding: 20px !important;
  border-radius: 10px !important;
  width: 99% !important;
  height: 45px !important
}

a.cke_dialog_close_button{
  background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="%23009488" viewBox="0 0 256 256"%3E%3Cpath d="M205.66,194.34a8,8,0,0,1-11.32,11.32L128,139.31,61.66,205.66a8,8,0,0,1-11.32-11.32L116.69,128,50.34,61.66A8,8,0,0,1,61.66,50.34L128,116.69l66.34-66.35a8,8,0,0,1,11.32,11.32L139.31,128Z" stroke="%23009488" stroke-width="10"%3E%3C/path%3E%3C/svg%3E') !important;
}
.cke_dialog_ui_button_ok{
  .cke_dialog_ui_button{
    color: white !important
  }
}

</style>
