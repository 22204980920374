<template>
  <div id="SideRightTabs">
    <b-card no-body>
      <b-tabs pills fill small style="width: 100%">
        <b-tab title="Estilo" active >
          <div class="separador"></div>

          <!-- Traçado -->
          <div class="content">
            <label class="titulo">Traçado</label>
            <label class="subtitulo">Cor</label>
            <LvColorpicker
              class="mb-3"
              id="LvColorpicker"
              v-model="editaItem.propsData.styles.border_color"
              :colors="palette"
            ></LvColorpicker>
            <div class="label-range">
              <label class="subtitulo">Espessura</label>
              <input
                type="number"
                v-model="editaItem.propsData.styles.border_size"
                min="0"
                max="20"
              />
            </div>
            <b-form-input
              id="range-1"
              v-model="editaItem.propsData.styles.border_size"
              type="range"
              min="0"
              max="20"
              step="1"
            ></b-form-input>
            <label class="subtitulo">Traço</label>
            <multiselect
              class="mb-3"
              v-model="editaItem.propsData.styles.border_style"
              :options="styleOptions"
              :searchable="false"
              :show-labels="false"
              :allow-empty="false"
              label="text"
              :hide-selected="true"
            ></multiselect>
          </div>
          <div class="separador"></div>

          <!-- Dimensões -->
          <div class="content">
            <div class="label-icon">
              <label class="titulo">Dimensões</label>
              <img
                src="@/assets/editor/globe.svg"
                alt="monitor"
                style="filter: invert(50%) !important"
                @click="nextIcon('desktop'), (global = !global)"
                v-if="global"
              />
              <img
                src="@/assets/editor/monitor.svg"
                alt="monitor"
                style="filter: invert(50%) !important"
                @click="nextIcon('tablet')"
                v-if="typeView === 'desktop' && !global"
              />
              <img
                src="@/assets/editor/tablet.svg"
                alt="tablet"
                style="filter: invert(50%) !important"
                @click="nextIcon('mobile')"
                v-if="typeView === 'tablet' && !global"
              />
              <img
                src="@/assets/editor/phone.svg"
                alt="phone"
                style="filter: invert(50%) !important"
                @click="nextIcon('desktop'), (global = !global)"
                v-if="typeView === 'mobile' && !global"
              />
            </div>
            <div class="label-range">
              <label class="subtitulo">Largura</label>
              <div style="margin-top: 7px"></div>
              <input
                type="number"
                v-model="editaItem.propsData.size[typeView].width"
                @input="globalWidth($event)"
                max="100"
                min="0"
              />
            </div>
            <b-form-input
              id="range-1"
              v-model="editaItem.propsData.size[typeView].width"
              type="range"
              min="10"
              max="100"
              class="mb-3"
              @input="globalWidth($event)"
            ></b-form-input>
            <label class="subtitulo">Alinhamento</label>
            <div style="display: flex; width: 100%; gap: 10px">
              <div style="display: flex; width: 50%; transition: all 0.3s">
                <div class="select-align">
                  <button :class="{ 'selected-align': editaItem.propsData.alignment[typeView].justify == 'flex-start' }"
                  @click="globalJustify('flex-start', 'justify')"><img
                      src="@/assets/editor/align-left-new.svg" /></button>
                  <button :class="{ 'selected-align': editaItem.propsData.alignment[typeView].justify == 'center' }"
                  @click="globalJustify('center', 'justify')"><img
                      src="@/assets/editor/align-center-horizontal.svg" /></button>
                  <button :class="{ 'selected-align': editaItem.propsData.alignment[typeView].justify == 'flex-end' }"
                  @click="globalJustify('flex-end', 'justify')"><img
                      src="@/assets/editor/align-right-new.svg" /></button>
                </div>
              </div>

              <div style="display: flex; width: 50%; transition: all 0.3s">
                <div class="select-align">
                  <button :class="{ 'selected-align': editaItem.propsData.alignment[typeView].justify_vertical == 'start' }"
                    @click="globalJustify('start', 'justify_vertical')"><img
                      src="@/assets/editor/align-top.svg" /></button>
                  <button :class="{ 'selected-align': editaItem.propsData.alignment[typeView].justify_vertical == 'center' }"
                    @click="globalJustify('center', 'justify_vertical')"><img
                      src="@/assets/editor/align-center-vertical.svg" /></button>
                  <button :class="{ 'selected-align': editaItem.propsData.alignment[typeView].justify_vertical == 'end' }"
                    @click="globalJustify('end', 'justify_vertical')"><img
                      src="@/assets/editor/align-bottom.svg" /></button>
                </div>
              </div>
            </div>
          </div>
          <div class="separador"></div>
        </b-tab>

        <b-tab title="Avançado">  
          <advancedBasic
            :spacing="editaItem.propsData.spacing" 
            :visibility="editaItem.propsData.visibility" 
            :animation="editaItem.propsColumn" 
            :attributes="editaItem.propsData.custom" 
            :typeView="typeView"
            :global="global"
            @nextIcon="nextIcon"
            @update:spacing="editaItem.propsData.spacing = $event"
            @update:visibility="editaItem.propsData.visibility = $event"
            @update:animation="editaItem.propsColumn = $event"
            @update:attributes="editaItem.propsData.custom = $event"
          ></advancedBasic>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import advancedBasic from "../EditComponents/advancedBasic.vue";
import LvColorpicker from "lightvue/color-picker";
import Multiselect from "vue-multiselect";

export default {
  props: ["editaItem", "typeView", "palette"],
  components: {
    advancedBasic,
    Multiselect,
    LvColorpicker: LvColorpicker,
  },
  data() {
    return {
      content: true,
      global: false,
      styleOptions: [
        { value: "dotted", text: '● ● ● ● ● ● ● ● ● ● ● ● ● ●' },
        { value: "double", text: "══════════════════" },
        { value: "dashed", text: "━ ━ ━ ━ ━ ━ ━ ━ ━ ━ ━ ━ ━ ━" },
        { value: "solid", text: "━━━━━━━━━━━━━━━━━━━━━" },
      ],
    };
  },
  methods: {
    nextIcon(type) {
      this.$emit("attIconView", type);
    },
    globalJustify(value, place) {
      let props = this.editaItem.propsData.alignment
      props[this.typeView][place] = value
      if (this.global && this.typeView == 'desktop') {
        props[this.typeView][place] = value
        props[this.typeView][place] = value
      }
    },
  },
  created() {
    if (this.typeView == "desktop") {
      this.global = true;
    }
  },
};
</script>
