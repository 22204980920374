<template>
  <div id="SideRightTabs">
    <b-card no-body>
      <b-tabs pills justified small style="width: 100%">
        <b-tab title="Editor" @click="editMode = false">
          <div class="separador"></div>
          <div id="app" class="text-component">
            <!-- Controlador do texto -->
            <div class="content">
              <div class="content-links">
                <div class="half">
                  <label class="titulo">Controle do texto</label>
                  <div class="contain-buttons" style="margin-top: -15px">
                    <button class="btn button-add-produto" @click="addtext()"></button>
                  </div>
                </div>
              </div>
              <div class="space"></div>
              <div class="separador"></div>
              <div v-for="(item, idx) in editaItem.propsData.text" :key="idx">
                <div class="p-2" style="position: relative; ;">
                  <div class="separador" style="position:absolute; top: 17px; left: -3px;"></div>
                  <label class="subtitulo component-group-title">Texto {{ idx + 1 }}</label>
                  <div style="display: flex; justify-content: space-between;">
                    <div class="select" >
                      <input type="text"  v-model="editaItem.propsData.text[idx]">
                    </div>
                    <div class="image-control">
                    <div>
                      <img
                        :class="{ 'disabled-arrow' : idx == 0}"
                        class="first-arrow"
                        src="@/assets/editor/chevron-down.svg"
                        @click="idx == 0 ? '' : moveUp(idx)"
                        title="Mover para cima"
                        style="margin-top: 0px;"
                      />
                      <button
                        class="button-remove-produto"
                        @click="removetextIndex(idx)"
                      ></button>
                      <img
                        :class="{ 'disabled-arrow' : idx + 1 == editaItem.propsData.text.length}"
                        class="last-arrow"
                        src="@/assets/editor/chevron-down.svg"
                        @click="idx + 1 == editaItem.propsData.text.length ? '' : moveDown(idx)"
                        title="Mover para baixo"
                        style=""
                      />
                    </div>
                  </div>


                  </div>

                </div>
              </div>
            </div>

          </div>

        </b-tab>

        <b-tab title="Estilo" v-if="editaItem.propsData.styles.hasOwnProperty('line_height')">
          <!--Comportamento do texto-->
          <div class="separador"></div>
          <div class="content">
            <label class="titulo">Comportamento</label>
            <multiselect v-model="editaItem.propsData.textloop.loop"
                :options="['Ficar Rodando', 'Parar na última frase',]" :searchable="false" :show-labels="false" placeholder=""
                :hide-selected="true" :allow-empty="false">
                  
                </multiselect>
                <div class="space"></div>
                <label class="subtitulo"> Cor da barra </label>
            <LvColorpicker id="LvColorpicker" v-model="editaItem.propsData.styles.hover_bar" label="Cor da barra"
              :colors="palette"></LvColorpicker>
                <div>
              <div class="space"></div>
              <div class="label-range">
                <label class="subtitulo">Tempo entre as frases</label>
                <input type="number" @input="debouncetypeSpeed()" v-model="texttype">
              </div>
              <b-form-input v-model="editaItem.propsData.textloop.typingSpeed" type="range" min="100" max="500"
                step="50"></b-form-input>
            </div>
          </div>
          <div class="separador"></div>
          <div class="content">
            <!-- Cores -->
            <label class="titulo"> Cores </label>
            <label class="subtitulo"> Cor do texto </label>
            <LvColorpicker id="LvColorpicker" v-model="editaItem.propsData.styles.color_text" label="Cor do texto"
              :colors="palette"></LvColorpicker>
            <div class="space"></div>
            <label class="subtitulo"> Fundo </label>
            <LvColorpicker id="LvColorpicker" v-model="editaItem.propsData.styles.background_color_text"
              label="Cor do texto" :colors="palette"></LvColorpicker>
          </div>

          <div class="separador"></div>
          <div class="content">
            <label class="titulo">Tipografia</label>
            <div class="label-icon">
              <label class="subtitulo" style="margin: 5px 0 0 0 !important;">Tamanho</label>
              <div>
                <img src="@/assets/editor/globe.svg" alt="monitor" style="filter: invert(50%) !important; margin: 0"
                  @click="nextIcon('desktop'), (global = !global)" v-if="global" />
                <img src="@/assets/editor/monitor.svg" alt="monitor" style="filter: invert(50%) !important; margin: 0"
                  @click="nextIcon('tablet')" v-if="typeView === 'desktop' && !global" />
                <img src="@/assets/editor/tablet.svg" alt="tablet" style="filter: invert(50%) !important; margin: 0"
                  @click="nextIcon('mobile')" v-if="typeView === 'tablet' && !global" />
                <img src="@/assets/editor/phone.svg" alt="phone" style="filter: invert(50%) !important; margin: 0"
                  @click="nextIcon('desktop'), (global = !global)" v-if="typeView === 'mobile' && !global" />
              </div>
            </div>
            <div class="mt-2" style="
                display: flex;
                gap: 10px;
                width: 100%;
                gap: 10px;
                margin-bottom: -1rem;
              ">
              <b-button-group>
                <b-button :disabled="editaItem.propsData.styles[typeView].size == 0
                  " size="sm" style="
                    height: min-content !important;
                    padding: 10.5px 7px !important;
                  "
                  @click="editaItem.propsData.styles[typeView].size--, globalInput(editaItem.propsData.styles[typeView].size, 'styles', 'size')"><img
                    src="@/assets/editor/minus3.svg" alt="align" /></b-button>
                <b-form-input 
                  @focus="openDropdownSlider('editaItem.propsData.styles[typeView].size')"
                  class="text-right"
                  v-model="editaItem.propsData.styles[typeView].size" 
                  style="
                    width: 44px;
                    text-align: center !important;
                    border: none !important;
                    padding: 0 !important;
                  "></b-form-input>
                <b-button
                  @click="editaItem.propsData.styles[typeView].size++, globalInput(editaItem.propsData.styles[typeView].size, 'styles', 'size')"
                  size="sm" style="
                    height: min-content !important;
                    padding: 10.5px 7px !important;
                  "><img src="@/assets/editor/plus.svg" alt="align" /></b-button>
              </b-button-group>

              <div>
                <multiselect v-model="editaItem.propsData.styles[typeView].font_measure"
                  :options="['px', 'rem', '%', 'em']" :searchable="false" :show-labels="false" placeholder=" "
                  :hide-selected="true" :allow-empty="false"
                  @input="globalInput(editaItem.propsData.styles[typeView].font_measure, 'styles', 'font_measure')">
                </multiselect>
              </div>
            </div>
            <div class="space"></div>
            <div class="space"></div>

            <!-- Fonte e cor -->
            <label class="subtitulo">Fonte</label>

            <div class="max-multi">
              
              <multiselect 
                v-model="editaItem.propsData.styles.font_family" 
                :options="fontOptions" 
                :searchable="true"
                :show-labels="false" 
                placeholder="Fonte"
                @input="$emit('callCharge',$event)"  
                :hide-selected="true" 
                :allow-empty="false">
              </multiselect>
            </div>

            <div class="space"></div>

            <!-- Alinhamento -->
            <div>
              <div class="label-icon">
                <label class="subtitulo" style="margin: 5px 0 0px 0 !important;">Alinhamento</label>
                <div>
                  <img src="@/assets/editor/globe.svg" alt="monitor" style="filter: invert(50%) !important; margin: 0"
                    @click="nextIcon('desktop'), (global = !global)" v-if="global" />
                  <img src="@/assets/editor/monitor.svg" alt="monitor" style="filter: invert(50%) !important; margin: 0"
                    @click="nextIcon('tablet')" v-if="typeView === 'desktop' && !global" />
                  <img src="@/assets/editor/tablet.svg" alt="tablet" style="filter: invert(50%) !important; margin: 0"
                    @click="nextIcon('mobile')" v-if="typeView === 'tablet' && !global" />
                  <img src="@/assets/editor/phone.svg" alt="phone" style="filter: invert(50%) !important; margin: 0"
                    @click="nextIcon('desktop'), (global = !global)" v-if="typeView === 'mobile' && !global" />
                </div>
              </div>
              <b-button-group size="sm" style="margin-top: 5px">
                <b-button
                  @click="editaItem.propsData.styles[typeView].align = 'left', globalInput(editaItem.propsData.styles[typeView].align, 'styles', 'align')"><img
                    src="@/assets/editor/align-left.svg" /></b-button>
                <b-button
                  @click="editaItem.propsData.styles[typeView].align = 'center', globalInput(editaItem.propsData.styles[typeView].align, 'styles', 'align')"><img
                    src="@/assets/editor/align-center.svg" /></b-button>
                <b-button
                  @click="editaItem.propsData.styles[typeView].align = 'right', globalInput(editaItem.propsData.styles[typeView].align, 'styles', 'align')"><img
                    src="@/assets/editor/align-right.svg" /></b-button>
                <b-button
                  @click="editaItem.propsData.styles[typeView].align = 'justify', globalInput(editaItem.propsData.styles[typeView].align, 'styles', 'align')"><img
                    src="@/assets/editor/align-justify.svg" /></b-button>
              </b-button-group>
            </div>
            <!-- Posicionamento -->
            <div class="space"></div>
            <label class="subtitulo">Posição do bloco de texto</label>
            <div style="display: flex; width: 100%;">
              <div style="display: flex; width: 50%; transition: all 0.3s"
                v-if="editaItem.propsData.styles.hasOwnProperty('justify_vertical')">
                <div class="select-align">
                  <button :class="{ 'selected-align': editaItem.propsData.styles.justify_vertical[typeView] == 'start' }"
                    @click="globalJustify('start', true)"><img src="@/assets/editor/align-top.svg" /></button>
                  <button :class="{ 'selected-align': editaItem.propsData.styles.justify_vertical[typeView] == 'center' }"
                    @click="globalJustify('center', true)"><img
                      src="@/assets/editor/align-center-vertical.svg" /></button>
                  <button :class="{ 'selected-align': editaItem.propsData.styles.justify_vertical[typeView] == 'end' }"
                    style="z-index: 2" @click="globalJustify('end', true)"><img
                      src="@/assets/editor/align-bottom.svg" /></button>
                </div>
              </div>
            </div>
            <!-- Espessura -->
            <div>
              <div class="space"></div>
              <div class="label-range">
                <label class="subtitulo">Espessura da fonte</label>
                <input type="number" v-model="editaItem.propsData.styles.font_weight" />
              </div>
              <b-form-input v-model="editaItem.propsData.styles.font_weight" type="range" min="100" max="900"
                step="100"></b-form-input>
            </div>

            <!-- Espaçamento entre letras -->
            <div>
              <div class="label-range">
                <label class="subtitulo">Espaço entre letras</label>
                <input type="number" v-model="editaItem.propsData.styles.letter_space" />
              </div>
              <b-form-input v-model="editaItem.propsData.styles.letter_space" type="range" min="-15" max="15"
                step="0.1"></b-form-input>
            </div>

            <!-- Altura da linha -->
            <div>
              <div class="label-range">
                <label class="subtitulo">Altura da linha</label>
                <input type="number" v-model="editaItem.propsData.styles.line_height" />
              </div>
              <b-form-input v-model="editaItem.propsData.styles.line_height" type="range" min="0" max="5"
                step="0.1"></b-form-input>
            </div>
          </div>
          <div class="separador"></div>
        </b-tab>

        <b-tab title="Avançado">
          <div class="separador"></div>

          <!-- Largura máxima -->
          <div class="content">
            <div class="label-icon">
              <label class="titulo">Dimensões</label>
              <img src="@/assets/editor/globe.svg" alt="monitor" style="filter: invert(50%) !important"
                @click="nextIcon('desktop'), (global = !global)" v-if="global" />
              <img src="@/assets/editor/monitor.svg" alt="monitor" style="filter: invert(50%) !important"
                @click="nextIcon('tablet')" v-if="typeView === 'desktop' && !global" />
              <img src="@/assets/editor/tablet.svg" alt="tablet" style="filter: invert(50%) !important"
                @click="nextIcon('mobile')" v-if="typeView === 'tablet' && !global" />
              <img src="@/assets/editor/phone.svg" alt="phone" style="filter: invert(50%) !important"
                @click="nextIcon('desktop'), (global = !global)" v-if="typeView === 'mobile' && !global" />
            </div>
            <div class="label-range">
              <label class="subtitulo">Largura máxima</label>
              <input type="number" v-model="editaItem.propsData.styles.max_width[typeView]"
                @input="globalMeasure($event)" />
            </div>
            <b-form-input id="range-1" v-model="editaItem.propsData.styles.max_width[typeView]" type="range" min="10"
              max="2000" @input="globalMeasure($event)"></b-form-input>

            <div>
              <label for="form-altura" class="sub">Altura mínima</label>
              <b-form-group id="form-altura" class="und_form">
                <div class="und_form">
                  <b-form-input 
                    id="form-altura" 
                    v-model="editaItem.propsColumn.height[typeView].height"
                    @focus="openDropdownSlider('editaItem.propsColumn.height[typeView].height')" 
                    type="number"
                    min="0"
                  ></b-form-input>
                  <span 
                    @click="next_und_height" 
                    class="und text" v-b-tooltip.hover 
                    title="Altura em pixel">
                    {{editaItem.propsColumn.height[typeView].height_und }}
                  </span>
                </div>
              </b-form-group>
            </div>
          </div>

          <advancedBasic
            :spacing="editaItem.propsData.spacing" 
            :visibility="editaItem.propsData.visibility" 
            :animation="editaItem.propsColumn" 
            :attributes="editaItem.propsData.custom" 
            :typeView="typeView"
            :global="global"
            @toggleGlobal="global = !global"
            @nextIcon="nextIcon"
            @update:spacing="editaItem.propsData.spacing = $event"
            @update:visibility="editaItem.propsData.visibility = $event"
            @update:animation="editaItem.propsColumn = $event"
            @update:attributes="editaItem.propsData.custom = $event"
          ></advancedBasic>
        </b-tab>
      </b-tabs>
    </b-card>
    <dropdownSlider
      ref="dropdownSlider"   
      @updateValue="updateValue"
    /> 
  </div>
</template>
<script>
import dropdownSliderMixin from "@/mixins/dropdownSliderMixin.js";
import advancedBasic from "../EditComponents/advancedBasic.vue";
import LvColorpicker from "lightvue/color-picker";
import Multiselect from "vue-multiselect";
import _ from "lodash";

export default {
  mixins: [dropdownSliderMixin],
  props: ["editaItem", "openSideRight", "fontOptions", "typeView", "divWidth", "palette"],
  components: {
    advancedBasic,
    Multiselect,
    LvColorpicker: LvColorpicker,
  },
  data() {
    return {
      texttype:"",
      expandCkButton: false,
      isBlack: false,
      editMode: false,
      content: true,
      global: false,
      editorConfig: {
        uiColor: "#eeeeee",
        allowedContent: true,
        toolbar: [
          ["Maximize"],
          [
            "Bold",
            "Italic",
            "Strike",
          ],
          ["TextColor", "BGColor"],
          ["Source"],
          ["FontSize"],
          ["Undo", "Redo"],
        ],
        extraPlugins:
          "justify, colorbutton, colordialog, font, lineutils, richcombo, uicolor,divarea",
        removePlugins:
          "about, blockquote, image, a11yhelp, resize, list, pastetext, pastefromgdocs, pastefromlibreoffice, pastefromword, showborders, tableselection, tabletools, pastetools, tableselection",
      },
    };
  },
  mounted(){
    this.texttype = this.editaItem.propsData.text
  },
  computed: {
    dynamicPosition() {
      if (this.divWidth > 265) {
        return `top: 75px`;
      } else if (this.divWidth > 290) {
        return `top: 108px`;
      } else {
        return `top: 140px`;
      }
    },
  },
  watch: {
    editaItem: {
      handler: function (newValue) {
        this.debounceSetProps();
      },
      immediate: true,
      deep: true,
    },
  },

  methods: {
    limiteText() {
      this.$grToast.toast("Limite de textos atingido", {
        title: "Editor",
        variant: "danger",
        autoHideDelay: 5000,
        appendToast: true,
      });
    },

    addtext() {
      if (this.editaItem.propsData.text.length < 8) {
        this.editaItem.propsData.text.push("Quero Aproveitar Agora")
      }
      if(this.editaItem.propsData.text.length == 8){
        this.limiteText()
      }
    },
    removetextIndex(index) {
      this.editaItem.propsData.text.splice(index, 1);
    },

    moveDown(idx) {
        var moving = this.editaItem.propsData.text[idx]
        this.editaItem.propsData.text.splice(idx, 1);
        this.editaItem.propsData.text.splice(idx + 1, 0, moving);
      },

      moveUp(idx) {
        var moving = this.editaItem.propsData.text[idx]
        this.editaItem.propsData.text.splice(idx, 1);
        this.editaItem.propsData.text.splice(idx - 1, 0, moving);
      },

    next_und_height() {
      const props = this.editaItem.propsColumn.height;
      props[this.typeView].height_und = props[this.typeView].height_und == "px" ? "vh" : props[this.typeView].height_und == "vh" ? "vw" : "px";
      if (this.global && this.typeView == 'desktop') {
        props.tablet.height_und = props.desktop.height_und
        props.mobile.height_und = props.desktop.height_und
      }
    },
    globalInput(px, place1, place2) {
      if (this.global && this.typeView == 'desktop') {
        var pixel = px.target ? px.target.value : px;
        var props = this.editaItem.propsData;
        props[place1].tablet[place2] = pixel;
        props[place1].mobile[place2] = pixel;
      }
    },

    debounceSetProps: _.debounce(function () {
      this.setProps();
    }, 200),
    setProps() {

    },

    globalJustify(value, isVertical) {
      var props = this.editaItem.propsData.styles.justify;
      if (isVertical) {
        props = this.editaItem.propsData.styles.justify_vertical;
      }
      props[this.typeView] = value;
      if (this.global && this.typeView == 'desktop') {
        props.tablet = value;
        props.mobile = value;
      }
    },
    globalMeasure(pixel) {
      var px = pixel.target ? pixel.target.value : pixel;
      if (this.global && this.typeView == 'desktop') {
        var props = this.editaItem.propsData.styles.max_width;
        props.tablet = px;
        props.mobile = px;
      }
    },
    nextIcon(type) {
      this.$emit("attIconView", type);
    },
  },
  mounted() {
    window.addEventListener("click", this.handleClick);
    this.setProps()
  },
  beforeDestroy() {
    window.removeEventListener("click", this.handleClick);
  },
  created() {
    if (this.typeView == "desktop") {
      this.global = true;
    }
  },
};
</script>
