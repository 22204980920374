let propsMenuHorizontal = {
  custom: {
    c_class: '',
    c_id: '',
  },
  styles: {
    background_header: "#ffffff",
    font: "Montserrat",
    color_itens: "#000000",
  },
  image: {
    has_logo: true,
    img_logo:
      "https://gdigital.s3.amazonaws.com/gdigital/1/imagem-vazia3.webp",
  },
  links: [
    { name: "Quem somos", link: "https://greenn.com.br/#conectado", type: {value: 'externo', text: 'Externo' }, blank: false },
    { name: "Produtos", link: "https://greenn.com.br/#conectado", type: {value: 'externo', text: 'Externo' }, blank: false  },
    { name: "Contato", link: "https://greenn.com.br/#conectado", type: {value: 'externo', text: 'Externo' }, blank: false  },
  ],
  size: {
    desktop: { width: 160, height: 100, gap_itens: 30, min_height: 50 },
    tablet: { width: 160, height: 100, gap_itens: 30, min_height: 50 },
    mobile: { width: 160, height: 100, gap_itens: 30, min_height: 50 },
  },
  spacing: {
    desktop: {
      margin_top: 0,
      margin_right: 0,
      margin_bottom: 0,
      margin_left: 0,
      padding_top: 0,
      padding_right: 50,
      padding_bottom: 0,
      padding_left: 50,
    },
    tablet: {
      margin_top: 0,
      margin_right: 0,
      margin_bottom: 0,
      margin_left: 0,
      padding_top: 0,
      padding_right: 0,
      padding_bottom: 0,
      padding_left: 0,
    },
    mobile: {
      margin_top: 0,
      margin_right: 0,
      margin_bottom: 0,
      margin_left: 0,
      padding_top: 0,
      padding_right: 0,
      padding_bottom: 0,
      padding_left: 0,
    },
  },
  visibility: {
    monitor: true,
    tablet: true,
    phone: true,
  },
};

export default propsMenuHorizontal;
