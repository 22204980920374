let propsButton = {
  alignment: {
    desktop: { justify: "center", justify_vertical: "center" },
    tablet: { justify: "center", justify_vertical: "center" },
    mobile: { justify: "center", justify_vertical: "center" },
  },
  button_size: {
    desktop: { font_size: 14 },
    tablet: { font_size: 14 },
    mobile: { font_size: 14 },
    textFontMeasure: "px",
  },
  button: {
    content_button: "Clique aqui",
    href_button: "",
    type: { value: "externo", text: "Externo" },
    blank: false,
    share_text: "",
  },
  custom: {
    c_class: '',
    c_id: '',
  },
  shadow: {
    shadow: "none",
    hasShadow: false,
    shadow_x: 0,
    shadow_y: 5,
    shadow_blur: 6,
    shadow_color: "#0000005E",
  },
  border: {
    border: "none",
    hasBorder: false,
    border_radius_top: 10,
    border_radius_right: 10,
    border_radius_bottom: 10,
    border_radius_left: 10,
    border_style: "━━━━━━━━━━━━━━━━━━━━━",
    border_thickness: "2",
    border_color: "#ffffff",
    borderLinked: true,
  },
  styles: {
    font_family: "Montserrat",
    color_button: "#ffffff",
    font_weight: 600,
    letter_space: "0.2",
    text_type: "none",
    back_type: "color",
    back_content: "#4ea934",
    hover_color: '#05855F',
    hover_font: '#ffffff',
    hover_border: '#ffffff',
    back_selected: "Cor sólida",
    grad_angle: "180",
    grad_color1: "#4ea934",
    grad_color2: "#05855F",
  },
  icon: {
    size: {
      desktop: { font_size: 25, margin: 5 },
      tablet: { font_size: 25, margin: 5 },
      mobile: { font_size: 25, margin: 5 },
    },
    color: "#ffffff",
    svg: "",
    isRight: false,
  },
  size: {
    desktop: { width: 125, height: 45, und_width: 'px', und_height: 'px', isAuto: true},
    tablet: { width: 125, height: 45, und_width: 'px', und_height: 'px', isAuto: true },
    mobile: { width: 125, height: 45, und_width: 'px', und_height: 'px', isAuto: true },
  },
  spacing: {
    desktop: {
      margin_top: 0,
      margin_right: 0,
      margin_bottom: 0,
      margin_left: 0,
      padding_top: 15,
      padding_right: 20,
      padding_bottom: 15,
      padding_left: 20,
    },
    tablet: {
      margin_top: 0,
      margin_right: 0,
      margin_bottom: 0,
      margin_left: 0,
      padding_top: 15,
      padding_right: 20,
      padding_bottom: 15,
      padding_left: 20,
    },
    mobile: {
      margin_top: 0,
      margin_right: 0,
      margin_bottom: 0,
      margin_left: 0,
      padding_top: 15,
      padding_right: 20,
      padding_bottom: 15,
      padding_left: 20,
    },
  },
  visibility: {
    monitor: true,
    tablet: true,
    phone: true,
  },
};
export default propsButton;
