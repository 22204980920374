let propsAccordion = {
  custom: {
    c_class: "",
    c_id: "",
  },
  alignment: {
    desktop: { justify_vertical: "center" },
    tablet: { justify_vertical: "center" },
    mobile: { justify_vertical: "center" },
  },
  rows: [
    {
      question: "Pergunta 1",
      resp: "Resposta 1",
      show_image: false,
      image: "https://gdigital.s3.amazonaws.com/gdigital/1/resposta.webp",
      image_ratio: 0.25,
      image_width: {
        desktop:100,
        tablet:100,
        mobile:100
      }
    },
  ],
  size: {
    desktop: {
      font_size_question: 20,
      font_size_resp: 18,
    },
    tablet: {
      font_size_question: 20,
      font_size_resp: 18,
    },
    mobile: {
      font_size_question: 20,
      font_size_resp: 18,
    },
  },
  styles: {
    background_question: "#F1F1f1",
    background_resp: "#F1F1f1",
    color_question: "#434343",
    color_resp: "#7D7D7D",
    font_family: 'Montserrat',
    image_radius: 0,

  },
  border: {
    has: true,
    radius: 10,
    color: "#DBD8D8",
    thickness: "1",
  },
  spacing: {
    desktop: {
      // DT MARGIN --->>
      dt_margin_top: 0,
      dt_margin_right: 0,
      dt_margin_bottom: 0,
      dt_margin_left: 0,
      // DT PADDING --->>
      dt_padding_top: 10,
      dt_padding_right: 10,
      dt_padding_bottom: 10,
      dt_padding_left: 10,
      // DD MARGIN --->>
      dd_margin_top: 0,
      dd_margin_right: 0,
      dd_margin_bottom: 0,
      dd_margin_left: 0,
      // DD PADDING --->>
      dd_padding_top: 10,
      dd_padding_right: 10,
      dd_padding_bottom: 10,
      dd_padding_left: 10,
      // DL MARGIN --->>
      dl_margin_top: 0,
      dl_margin_right: 0,
      dl_margin_bottom: 10,
      dl_margin_left: 0,
      // DL PADDING --->>
      dl_padding_top: 5,
      dl_padding_right: 5,
      dl_padding_bottom: 5,
      dl_padding_left: 5,
    },
    tablet: {
      // DT MARGIN --->>
      dt_margin_top: 0,
      dt_margin_right: 0,
      dt_margin_bottom: 0,
      dt_margin_left: 0,
      // DT PADDING --->>
      dt_padding_top: 10,
      dt_padding_right: 10,
      dt_padding_bottom: 10,
      dt_padding_left: 10,
      // DD MARGIN --->>
      dd_margin_top: 0,
      dd_margin_right: 0,
      dd_margin_bottom: 0,
      dd_margin_left: 0,
      // DD PADDING --->>
      dd_padding_top: 10,
      dd_padding_right: 10,
      dd_padding_bottom: 10,
      dd_padding_left: 10,
      // DL MARGIN --->>
      dl_margin_top: 0,
      dl_margin_right: 0,
      dl_margin_bottom: 10,
      dl_margin_left: 0,
      // DL PADDING --->>
      dl_padding_top: 5,
      dl_padding_right: 5,
      dl_padding_bottom: 5,
      dl_padding_left: 5,
    },
    mobile: {
      // DT MARGIN --->>
      dt_margin_top: 0,
      dt_margin_right: 0,
      dt_margin_bottom: 0,
      dt_margin_left: 0,
      // DT PADDING --->>
      dt_padding_top: 10,
      dt_padding_right: 10,
      dt_padding_bottom: 10,
      dt_padding_left: 10,
      // DD MARGIN --->>
      dd_margin_top: 0,
      dd_margin_right: 0,
      dd_margin_bottom: 0,
      dd_margin_left: 0,
      // DD PADDING --->>
      dd_padding_top: 10,
      dd_padding_right: 10,
      dd_padding_bottom: 10,
      dd_padding_left: 10,
      // DL MARGIN --->>
      dl_margin_top: 0,
      dl_margin_right: 0,
      dl_margin_bottom: 10,
      dl_margin_left: 0,
      // DL PADDING --->>
      dl_padding_top: 5,
      dl_padding_right: 5,
      dl_padding_bottom: 5,
      dl_padding_left: 5,
    },
  },
  visibility: {
    tablet: true,
    phone: true,
    monitor: true,
  },
};
export default propsAccordion;
