let propsMenuHorizontal = {
  custom: {
    c_class: '',
    c_id: '',
  },
  styles: {
    background_header: "#000",
    color_itens: "#FFFFFF",
    font: "Montserrat"
  },
  links: [
    { name: "Quem somos", link: "https://greenn.com.br/#conectado", type: {value: 'externo', text: 'Externo' }, blank: false },
    { name: "Produtos", link: "https://greenn.com.br/#conectado", type: {value: 'externo', text: 'Externo' }, blank: false  },
    { name: "Contato", link: "https://greenn.com.br/#conectado", type: {value: 'externo', text: 'Externo' }, blank: false  },
  ],
  visibility: {
    monitor: true,
    tablet: true,
    phone: true,
  },
};

export default propsMenuHorizontal;
