<template>
  <div class="chat-ia">
    <!-- texto superior -->
    <div class="top-dados mb-3" style="width: 100%" v-if="!loading">
      <p style="text-align: center">
        Solicite uma timeline ou texto referente ao seu nicho e veja a mágica
        acontecer.
      </p>
    </div>

    <!-- pergunta -->
    <div class="mt-4 w-100" v-show="!loading">
      <label class="w-100" style="text-align: center"
        >Escreva seu pedido aqui</label
      >
      <b-form-group v-if="texto_result === '' ? true : false" label-for="name">
        <b-form-textarea
          id="name"
          name="name"
          v-model="texto"
          placeholder="Ex: Preciso de uma timeline para minha loja de carros?"
        ></b-form-textarea>
      </b-form-group>
      <div v-else class="mb-3">
        <h6>Pergunta:</h6>
        <p>{{ texto }}</p>
      </div>
    </div>

    <!-- resultado -->
    <div v-if="texto_result === '' ? false : true" class="mb-3">
      <h6>Resultado:</h6>
      <p id="result">{{ texto_result }}</p>
    </div>

    <!-- botões -->
    <div class="mt-4 d-flex justify-content-center" v-show="!loading">
      <BaseButton
        v-if="texto_result !== '' ? true : false"
        variant="black"
        @click="restart"
        :disabled="loading"
        class="buttonGPT"
      >
        Reiniciar
      </BaseButton>
      <BaseButton
        v-if="texto_result !== '' ? false : true && !loading"
        variant="primary"
        @click="onSubmit"
        :disabled="loading"
        class="buttonGPT"
      >
        Buscar
      </BaseButton>
    </div>

    <!-- loading -->
    <div v-if="loading">
      <div style="width: fit-content; height: fit-content; margin: auto auto">
        <b-spinner label="Loading..."></b-spinner>
      </div>
    </div>

    <!-- código antigo -->
    <!-- <div> -->
    <!-- <b-row v-if="!loading">
      <b-col cols="12"> -->
    <!-- <div class="top-dados">
          <div>
            <p>
              Solicite uma timeline ou texto referente ao seu nicho e veja a
              mágica acontecer.
            </p>
          </div>
        </div> -->
    <!-- <div class="grid-edit">
          <div>
            <b-row class="mt-2">
              <b-col cols="12" md="12">
                <b-form-group
                  label="Escreva seu pedido aqui!"
                  v-if="texto_result === '' ? true : false"
                  label-for="name"
                >
                  <b-form-textarea
                    id="name"
                    name="name"
                    v-model="texto"
                    placeholder="Ex: Preciso de uma timeline para minha loja de carros?"
                  ></b-form-textarea>
                </b-form-group>
                <div v-else>
                  <h6>Pergunta:</h6>
                  <p>{{ texto }}</p>
                </div>
                <br />
                <div v-if="texto_result === '' ? false : true">
                  <h6>Resultado:</h6>
                  <p id="result">{{ texto_result }}</p>
                </div>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-col>
    </b-row> -->
    <!-- <BaseButton variant="link-info" class="mr-4" @click="cancel">
      Cancelar
    </BaseButton>
    <BaseButton
      v-if="texto_result !== '' ? true : false"
      variant="black"
      @click="restart"
      :disabled="loading"
    >
      Reiniciar
    </BaseButton>
    <BaseButton
      v-if="texto_result !== '' ? false : true"
      variant="primary"
      @click="onSubmit"
      :disabled="loading"
    >
      Buscar
    </BaseButton> -->
    <!-- </div> -->
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      texto: "",
      texto_result: "",
      loading: false,
    };
  },
  methods: {
    restart() {
      this.texto = "";
      this.texto_result = "";
    },
    cancel() {
      this.texto = "";
      this.texto_result = "";
    },
    onSubmit() {
      this.loading = true;
      this.$validator.validateAll().then((result) => {
        if (result) {
          if (this.texto === "") {
            this.$grToast.toast("Pesquisar apenas texto", {
              title: "Gerador de Texto",
              variant: "danger",
              autoHideDelay: 5000,
              appendToast: true,
            });
            this.loading = false;
            return;
          }
          if (this.texto.includes("imagem")) {
            this.$grToast.toast("Pesquisar apenas texto", {
              title: "Gerador de Texto",
              variant: "danger",
              autoHideDelay: 5000,
              appendToast: true,
            });
            this.loading = false;
            return;
          }

          const apiKey = process.env.OPENAI_API_KEY;

          const client = axios.create({
            headers: {
              Authorization: "Bearer " + apiKey,
            },
          });

          const params = {
            prompt: this.texto,
            model: "text-davinci-003",
            max_tokens: 2048,
            temperature: 0,
          };

          var that = this;

          client
            .post("https://api.openai.com/v1/completions", params)
            .then((result) => {
              that.texto_result = result.data.choices[0].text;
              that.loading = false;
            })
            .catch((err) => {
              // console.log(err);
            });
        }
      });
    },
  },
};
</script>

<style scoped>
.top-dados{
  user-select: none;
}
.buttonGPT {
  width: 120px;
  height: 40px;
  padding: 0px !important;
}
p {
  margin: 0;
  font-size: 14px;
  font-weight: normal;
  color: #81858e;
  line-height: 1.5;
}
p + p {
  margin-top: 5px;
}
.grid-edit {
  display: grid;
  grid-template-columns: 3fr 2fr;
  gap: 50px;
  margin-top: 30px;
}

.img-style {
  height: 400px;
  word-break: break-all;
  object-fit: contain;
  border-radius: 10px;
}

textarea {
  padding: 15px 20px !important;
  width: 100%;
  height: 155px !important;
  border: 0.5px solid #ededf0 !important;
  color: #000 !important;
  outline: none !important;
  appearance: none !important;
  font-size: 14px !important;
  transition: 0.3s !important;
  border-radius: 10px !important;
  margin-bottom: 12px !important;
}
</style>
