let propsEditWhatsapp = {
  redirection: {
    number: '',
    text: ''
  },
  custom: {
    c_class: '',
    c_id: '',
  },
  icon: {
    color: '#ffff',
    backColor: '#25D366',
    hoverColor: '#ffff',
    hoverBackColor: '#25D366',
    back_selected: "Cor sólida",
    grad_color1: '#4ea934',
    grad_color2: '#05855F',
    grad_angle: '180',
    back_type: "color"
  },
  visibility: {
    tablet: true,
    phone: true,
    monitor: true,
  },
}
export default propsEditWhatsapp;