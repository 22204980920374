<template>
  <div id="SideRightTabs">
    <b-card no-body>
      <b-tabs pills justified small style="width: 100%">
        <b-tab title="Card">
          <div class="separador"></div>
          <div class="content">
          <!-- Posição -->
          <div>
            <div style="display: flex; width: 100%; gap: 10px">
              <div style="display: flex; width: 50%; transition: all 0.3s;">
                <label class="titulo">Diagramação</label>
              </div>
              <div style="display: flex; width: 50%; transition: all 0.3s">
                <div class="select-align">
                  <button :class="{ 'selected-align': editaItem.propsData.alignment[typeView].justify_vertical == 'start' }"
                    @click="globalJustify('start', 'justify_vertical')"><img
                      src="@/assets/editor/align-top.svg" /></button>
                  <button :class="{ 'selected-align': editaItem.propsData.alignment[typeView].justify_vertical == 'center' }"
                    @click="globalJustify('center', 'justify_vertical')"><img
                      src="@/assets/editor/align-center-vertical.svg" /></button>
                  <button :class="{ 'selected-align': editaItem.propsData.alignment[typeView].justify_vertical == 'end' }"
                    @click="globalJustify('end', 'justify_vertical')"><img
                      src="@/assets/editor/align-bottom.svg" /></button>
                </div>
              </div>
            </div>
          </div>
            <div class="card-type" :class="{'card-selected' : editaItem.propsData.card.layout == 1}" @click="editaItem.propsData.card.layout = 1">
              <span
                v-if="editaItem.propsData.icon.iconSelected.hasOwnProperty('name')"
                v-html="editaItem.propsData.icon.iconSelected.code"
                ></span
              >
              <span
                v-else
                class="material-icons"
                >{{ editaItem.propsData.icon.iconSelected }}</span
              >
              <p class="card-title">
                Exemplo de título
              </p>
              <p class="card-content">
                Exemplo de texto conteúdo que você pode adicionar ao seu card para fornecer mais informações sobre determinado assunto.
              </p>
            </div>
            <div class="card-type card-2" :class="{'card-selected' : editaItem.propsData.card.layout == 2}" @click="editaItem.propsData.card.layout = 2">
              <span
                v-if="editaItem.propsData.icon.iconSelected.hasOwnProperty('name')"
                v-html="editaItem.propsData.icon.iconSelected.code"
                ></span
              >
              <span
                v-else
                class="material-icons"
                >{{ editaItem.propsData.icon.iconSelected }}</span
              >
              <div>
                <p class="card-title">
                  Exemplo de título
                </p>
                <p class="card-content">
                  Exemplo de texto conteúdo que você pode adicionar ao seu card para fornecer mais informações sobre determinado assunto.
                </p>
              </div>
            </div>
            <div class="card-type card-3" :class="{'card-selected' : editaItem.propsData.card.layout == 3}" @click="editaItem.propsData.card.layout = 3">
              <span
                v-if="editaItem.propsData.icon.iconSelected.hasOwnProperty('name')"
                v-html="editaItem.propsData.icon.iconSelected.code"
                ></span
              >
              <span
                v-else
                class="material-icons"
                >{{ editaItem.propsData.icon.iconSelected }}</span
              >
              <p class="card-title">
                Exemplo de título
              </p>
            </div>
          </div>

          <div class="separador"></div>
          <div class="content">
            <label class="titulo">Link (opcional)</label>
            <label class="subtitulo">Tipo de link</label>
            <multiselect
              :options="optionsType"
              v-model="editaItem.propsData.card.link_type"
              :searchable="false"
              :show-labels="false"
              :hide-selected="true"
              :allow-empty="false"
              placeholder="Tipo de link"
              label="text"
              track-by="value"
            ></multiselect>
            <div class="space"></div>

            <!-- externo -->
            <div v-if="editaItem.propsData.card.link_type.value == 'externo'">
              <label class="sub">Link para redirecionar</label>
              <input
                placeholder="https://seulink"
                class="w-100"
                type="text"
                name="text_1"
                id="text_1"
                v-model="editaItem.propsData.card.link"
              />
              <div class="space"></div>
              <div style="display: flex; width: 100%; transition: all 0.3s">
                <label class="subtitulo"
                  >Redirecionar para nova guia</label
                >
                <b-form-checkbox
                  style="margin-top: -5px; margin-left: auto; margin-right: 0; z-index: 0;"
                  v-model="editaItem.propsData.card.blank"
                  name="is_main"
                  size="lg"
                  switch
                >
                </b-form-checkbox>
              </div>
            </div>

            <!-- interno -->
            <div v-if="editaItem.propsData.card.link_type.value == 'interno'">
              <label class="sub"
                >ID do elemento de destino
                <img
                  width="17px"
                  height="17px"
                  src="@/assets/editor/help-circle.svg"
                  alt="icon"
                  v-b-tooltip.hover
                  title='Nessa opção, o usuário é levado até a posição da página onde se encontra o elemento de destino. O ID do elemento pode ser configurado nas aba "avançado" ao editar um componente ou linha.'
              /></label>
              <input
                placeholder="exemplo_de_id"
                type="text"
                name="text_1"
                id="text_1"
                v-model="editaItem.propsData.card.link"
              />
            </div>

            <!-- copartilhar -->
            <div v-if="editaItem.propsData.card.link_type.value == 'compartilhar'">
              <label class="sub"
                >Escreva um texto curto para ser compartilhado
                <img
                  width="17px"
                  height="17px"
                  src="@/assets/editor/help-circle.svg"
                  alt="icon"
                  v-b-tooltip.hover
                  title="Esse texto será automaticamente posto no campo de mensagens do whatsapp do usuário para que ele envie para outras pessoas"
              /></label>
              <textarea
                placeholder="Dê uma olhada nesse produto incrível!..."
                v-model="editaItem.propsData.card.share_text"
                rows="2"
                class="w-100 mb-1"
                style="height: min-content !important; min-height: 45px !important;"
              ></textarea>
            </div>

            <!-- chamar -->
            <div v-if="editaItem.propsData.card.link_type.value == 'chamar'">
              <label class="sub">Escreva um texto curto para ser chamado</label>
              <textarea
                placeholder="Olá, tudo bem? Gostei da oferta.."
                v-model="editaItem.propsData.card.share_text"
                rows="2"
                class="w-100 mb-1"
                style="height: min-content !important; min-height: 45px !important;"
              ></textarea>
              <label class="sub"
                >Insira o número de telefone
                <img
                  width="17px"
                  height="17px"
                  src="@/assets/editor/help-circle.svg"
                  alt="icon"
                  v-b-tooltip.hover
                  title="Digite apenas números e inclua o 55 e o código de área. Por exemplo: 5544912345678"
              /></label>
              <input
                placeholder="5544XXXXXXXXX"
                class="w-100"
                type="text"
                v-model="editaItem.propsData.card.link"
                v-mask="['+##(##) ####-####', '+##(##) #####-####']"
              />
            </div>

            <!-- telefone -->
            <div v-if="editaItem.propsData.card.link_type.value == 'telefone'">
              <label class="sub">Insira o número de telefone</label>
              <input
                placeholder="5544XXXXXXXXX"
                class="w-100"
                type="text"
                v-mask="['+##(##) ####-####', '+##(##) #####-####']"
                v-model="editaItem.propsData.card.link"
              />
            </div>
          </div>
          <div class="separador"></div>
        </b-tab>

        <b-tab title="Conteúdo">
          <div class="separador"></div>
          <div class="d-flex justify-content-between p-3" v-b-toggle.iconCard>
            <label class="titulo" style="margin: 0 !important">Ícone</label>
            <img
              class="chevron icon mt-0"
              src="@/assets/editor/chevron-right.svg"
            />
          </div>
          <b-collapse id="iconCard" accordion="card-accordion">
            <div class="content">

              <label class="subtitulo">Tipo</label>
              <multiselect
                v-model="editaItem.propsData.icon.type"
                :options="['Ícone', 'Imagem']"
                :searchable="false"
                :show-labels="false"
                placeholder="Fonte"
                :hide-selected="true"
                :allow-empty="false"
              ></multiselect>
              <div class="space"></div>
              <label class="subtitulo">{{ editaItem.propsData.icon.type == 'Imagem' ? 'Imagem selecionada' : 'Ícone selecionado' }} </label>
              <div v-if="editaItem.propsData.icon.type != 'Imagem'">
                <div class="select-image" v-if="editaItem.propsData.icon.iconSelected.hasOwnProperty('name')">
                  <div @click="openModal('Modal-Icons-svg')">
                    <span
                      class="material-icons icon-preview"
                      v-html="editaItem.propsData.icon.iconSelected.code"
                      ></span
                    >
                  </div>
                  <div>
                    <input type="text" v-model="editaItem.propsData.icon.iconSelected.name">
                    <span @click="openModal('Modal-Icons-svg')">{{ editaItem.propsData.icon.iconSelected ? 'Alterar ícone' : 'Selecionar ícone'}}</span>
                  </div>
                </div>
                <div class="select-image" v-else>
                  <div @click="openModal('Modal-Icons-svg')">
                    <span
                      class="material-icons icon-preview"
                      >{{ editaItem.propsData.icon.iconSelected }}</span
                    >
                  </div>
                  <div>
                    <input type="text" v-model="editaItem.propsData.icon.iconSelected">
                    <span @click="openModal('Modal-Icons-svg')">{{ editaItem.propsData.icon.iconSelected ? 'Alterar ícone' : 'Selecionar ícone'}}</span>
                  </div>
                </div>
              </div>
              <div class="select-image" v-else>
                <div @click="openModal('Upload-Editor')">
                  <img :src="editaItem.propsData.icon.imageUrl ? editaItem.propsData.icon.imageUrl : 'https://gdigital.s3.amazonaws.com/gdigital/8/imagem-vazia%20%28200%C2%A0%C3%97%C2%A0200%C2%A0px%29.webp'" alt="Imagem preview">
                </div>
                <div>
                  <input type="text" v-model="editaItem.propsData.icon.imageUrl">
                  <span @click="openModal('Upload-Editor')">{{ editaItem.propsData.icon.imageUrl ? 'Alterar imagem' : 'Selecionar imagem'}}</span>
                </div>
              </div>
              <div class="space" v-if="editaItem.propsData.icon.type != 'Imagem'"></div>
              <label class="sub" v-if="editaItem.propsData.icon.type != 'Imagem'">Cor do ícone</label>
              <LvColorpicker
                v-if="editaItem.propsData.icon.type != 'Imagem'"
                id="LvColorpicker"
                v-model="editaItem.propsData.icon.color"
                :colors="palette"
              ></LvColorpicker>
              <div class="space"></div>
              <div class="label-range">
                <label class="subtitulo">Tamanho</label>
                <input
                  type="number"
                  v-model="editaItem.propsData.icon[typeView].iconSize"
                  @input="globalInput($event, 'icon', 'iconSize')"                 
                />
              </div>
              <b-form-input
                v-model="editaItem.propsData.icon[typeView].iconSize"
                @input="globalInput($event, 'icon', 'iconSize')"
                type="range"
                min="0"
                max="100"
                step="1"
              ></b-form-input>

              <div class="label-range">
                <div class="label-icon">
                  <label class="subtitulo">Preenchimento</label>
                  <div>
                    <img src="@/assets/editor/globe.svg" alt="monitor" style="filter: invert(50%) !important"
                      @click="nextIcon('desktop'), (global = !global)" v-if="global" />
                    <img src="@/assets/editor/monitor.svg" alt="monitor" style="filter: invert(50%) !important"
                      @click="nextIcon('tablet')" v-if="typeView === 'desktop' && !global" />
                    <img src="@/assets/editor/tablet.svg" alt="tablet" style="filter: invert(50%) !important"
                      @click="nextIcon('mobile')" v-if="typeView === 'tablet' && !global" />
                    <img src="@/assets/editor/phone.svg" alt="phone" style="filter: invert(50%) !important"
                      @click="nextIcon('desktop'), (global = !global)" v-if="typeView === 'mobile' && !global" />
                  </div>
                </div>
                <input
                  type="number"
                  v-model="editaItem.propsData.spacingIcon[typeView].padding_top"
                  @input="equalPaddingIcon(editaItem.propsData.spacingIcon[typeView].padding_top)"
                />
              </div>
              <b-form-input
                v-model="editaItem.propsData.spacingIcon[typeView].padding_top"
                @input="equalPaddingIcon(editaItem.propsData.spacingIcon[typeView].padding_top)"
                type="range"
                min="0"
                max="25"
                step="1"
              ></b-form-input>

              <!-- Posição -->
              <div v-if="editaItem.propsData.card.layout == 1">
                <div class="label-icon">
                  <label class="subtitulo">Posição</label>
                  <div>
                    <img src="@/assets/editor/globe.svg" alt="monitor" style="filter: invert(50%) !important"
                      @click="nextIcon('desktop'), (global = !global)" v-if="global" />
                    <img src="@/assets/editor/monitor.svg" alt="monitor" style="filter: invert(50%) !important"
                      @click="nextIcon('tablet')" v-if="typeView === 'desktop' && !global" />
                    <img src="@/assets/editor/tablet.svg" alt="tablet" style="filter: invert(50%) !important"
                      @click="nextIcon('mobile')" v-if="typeView === 'tablet' && !global" />
                    <img src="@/assets/editor/phone.svg" alt="phone" style="filter: invert(50%) !important"
                      @click="nextIcon('desktop'), (global = !global)" v-if="typeView === 'mobile' && !global" />
                  </div>
                </div>
                <b-button-group size="sm">
                  <b-button
                    @click="editaItem.propsData.icon[typeView].align = 'flex-start', globalJustify()"
                    ><img src="@/assets/editor/align-left.svg"
                  /></b-button>
                  <b-button
                    @click="editaItem.propsData.icon[typeView].align = 'center', globalJustify()"
                    ><img src="@/assets/editor/align-center.svg"
                  /></b-button>
                  <b-button
                    @click="editaItem.propsData.icon[typeView].align = 'flex-end', globalJustify()"
                    ><img src="@/assets/editor/align-right.svg"
                  /></b-button>
                </b-button-group>
                <div class="space"></div>
              </div>
            </div>
          </b-collapse>
          <div class="separador"></div>

          <div class="d-flex justify-content-between p-3" v-b-toggle.titleCard @click="editing = 'title'">
            <label class="titulo" style="margin: 0 !important">Título</label>
            <img
              class="chevron icon mt-0"
              src="@/assets/editor/chevron-right.svg"
            />
          </div>
          <b-collapse id="titleCard" accordion="card-accordion">
            <ckeditor
              :editor-id="'editor1'"
              :config="editorConfig"
              v-model="editaItem.propsData.title.text.content_text"
            ></ckeditor>
            <div class="content">
              <!-- Cores -->
              <div class="half">
                <div>
                  <label class="subtitulo"> Cor do texto </label>
                  <LvColorpicker
                    id="LvColorpicker"
                    v-model="editaItem.propsData.title.styles.color_text"
                    label="Cor do texto"
                    :colors="palette"
                  ></LvColorpicker>
                </div>
                <div>
                  <label class="subtitulo"> Fundo </label>
                  <LvColorpicker
                    id="LvColorpicker"
                    v-model="editaItem.propsData.title.styles.background_color_text"
                    label="Cor do texto"
                    :colors="palette"
                  ></LvColorpicker>
                </div>
              </div>

              <!-- Tamanho -->
              <div>
                <div class="space"></div>
                <div class="label-icon">
                  <label class="subtitulo" style="margin: 5px 0 0 0 !important;">Tamanho</label>
                  <div>
                    <img
                      src="@/assets/editor/globe.svg"
                      alt="monitor"
                      style="filter: invert(50%) !important; margin: 0"
                      @click="nextIcon('desktop'), (global = !global)"
                      v-if="global"
                    />
                    <img
                      src="@/assets/editor/monitor.svg"
                      alt="monitor"
                      style="filter: invert(50%) !important; margin: 0"
                      @click="nextIcon('tablet')"
                      v-if="typeView === 'desktop' && !global"
                    />
                    <img
                      src="@/assets/editor/tablet.svg"
                      alt="tablet"
                      style="filter: invert(50%) !important; margin: 0"
                      @click="nextIcon('mobile')"
                      v-if="typeView === 'tablet' && !global"
                    />
                    <img
                      src="@/assets/editor/phone.svg"
                      alt="phone"
                      style="filter: invert(50%) !important; margin: 0"
                      @click="nextIcon('desktop'), (global = !global)"
                      v-if="typeView === 'mobile' && !global"
                    />
                  </div>
                </div>
                <div
                  class="mt-2"
                  style="
                    display: flex;
                    gap: 10px;
                    width: 100%;
                    gap: 10px;
                    margin-bottom: -1rem;
                  "
                >
                  <b-button-group>
                    <b-button
                      :disabled="
                        editaItem.propsData.title.styles[typeView].size == 0
                      "
                      size="sm"
                      style="
                        height: min-content !important;
                        padding: 10.5px 7px !important;
                      "
                      @click="editaItem.propsData.title.styles[typeView].size--, globalInput(editaItem.propsData.title.styles[typeView].size, 'title', 'size')"
                      ><img src="@/assets/editor/minus3.svg" alt="align"
                    /></b-button>
                    <b-form-input
                      @focus="openDropdownSlider('editaItem.propsData.title.styles[typeView].size')"                    
                      class="text-right"
                      v-model="editaItem.propsData.title.styles[typeView].size"
                      style="
                        width: 44px;
                        text-align: center !important;
                        border: none !important;
                        padding: 0 !important;
                      "
                    ></b-form-input>
                    <b-button
                      @click="editaItem.propsData.title.styles[typeView].size++, globalInput(editaItem.propsData.title.styles[typeView].size, 'title', 'size')"
                      size="sm"
                      style="
                        height: min-content !important;
                        padding: 10.5px 7px !important;
                      "
                      ><img src="@/assets/editor/plus.svg" alt="align"
                    /></b-button>
                  </b-button-group>
  
                  <div>
                    <multiselect
                      v-model="editaItem.propsData.title.styles[typeView].font_measure"
                      :options="['px', 'rem', '%', 'em']"
                      :searchable="false"
                      :show-labels="false"
                      placeholder=" "
                      :hide-selected="true"
                      :allow-empty="false"
                      @input="globalInput(editaItem.propsData.title.styles[typeView].font_measure, 'title', 'font_measure')"
                    ></multiselect>
                  </div>
                </div>
                <div class="space"></div>
                <div class="space"></div>
              </div>

              <!-- Fonte -->
              <div class="max-multi">
                <label class="subtitulo">Fonte</label>
                <multiselect
                  v-model="editaItem.propsData.title.styles.font_family"
                  :options="fontOptions"
                  @input="$emit('callCharge',$event)"
                  :searchable="true"
                  :show-labels="false"
                  placeholder="Fonte"
                  :hide-selected="true"
                  :allow-empty="false"
                ></multiselect>
                <div class="space"></div>
              </div>

              <!-- Alinhamento -->
              <div>
                <div class="label-icon">
                  <label class="subtitulo" style="margin: 5px 0 0px 0 !important;">Alinhamento</label>
                  <div>
                  <img
                    src="@/assets/editor/globe.svg"
                    alt="monitor"
                    style="filter: invert(50%) !important; margin: 0"
                    @click="nextIcon('desktop'), (global = !global)"
                    v-if="global"
                  />
                  <img
                    src="@/assets/editor/monitor.svg"
                    alt="monitor"
                    style="filter: invert(50%) !important; margin: 0"
                    @click="nextIcon('tablet')"
                    v-if="typeView === 'desktop' && !global"
                  />
                  <img
                    src="@/assets/editor/tablet.svg"
                    alt="tablet"
                    style="filter: invert(50%) !important; margin: 0"
                    @click="nextIcon('mobile')"
                    v-if="typeView === 'tablet' && !global"
                  />
                  <img
                    src="@/assets/editor/phone.svg"
                    alt="phone"
                    style="filter: invert(50%) !important; margin: 0"
                    @click="nextIcon('desktop'), (global = !global)"
                    v-if="typeView === 'mobile' && !global"
                  />
                </div>
              </div>
                <b-button-group size="sm" style="margin-top: 5px">
                  <b-button
                    @click="editaItem.propsData.title.styles[typeView].align = 'left', globalInput(editaItem.propsData.title.styles[typeView].align, 'title', 'align')"
                    ><img src="@/assets/editor/align-left.svg"
                  /></b-button>
                  <b-button
                    @click="editaItem.propsData.title.styles[typeView].align = 'center', globalInput(editaItem.propsData.title.styles[typeView].align, 'title', 'align')"
                    ><img src="@/assets/editor/align-center.svg"
                  /></b-button>
                  <b-button
                    @click="editaItem.propsData.title.styles[typeView].align = 'right', globalInput(editaItem.propsData.title.styles[typeView].align, 'title', 'align')"
                    ><img src="@/assets/editor/align-right.svg"
                  /></b-button>
                  <b-button
                    @click="editaItem.propsData.title.styles[typeView].align = 'justify', globalInput(editaItem.propsData.title.styles[typeView].align, 'title', 'align')"
                    ><img src="@/assets/editor/align-justify.svg"
                  /></b-button>
                </b-button-group>
              </div>

              <!-- Espessura -->
              <div>
                <div class="space"></div>
                <div class="label-range">
                  <label class="subtitulo">Espessura da fonte</label>
                  <input
                    type="number"
                    v-model="editaItem.propsData.title.styles.font_weight"
                  />
                </div>
                <b-form-input
                  v-model="editaItem.propsData.title.styles.font_weight"
                  type="range"
                  min="100"
                  max="900"
                  step="100"
                ></b-form-input>
              </div>

              <!-- Espaçamento entre letras -->
              <div>
                <div class="label-range">
                  <label class="subtitulo">Espaço entre letras</label>
                  <input
                    type="number"
                    v-model="editaItem.propsData.title.styles.letter_space"
                  />
                </div>
                <b-form-input
                  v-model="editaItem.propsData.title.styles.letter_space"
                  type="range"
                  min="-5"
                  max="15"
                  step="0.1"
                ></b-form-input>                
              </div>

              <!-- Altura da linha -->
              <div>
                <div class="label-range">
                  <label class="subtitulo">Altura da linha</label>
                  <input
                    type="number"
                    v-model="editaItem.propsData.title.styles.line_height"
                  />
                </div>
                <b-form-input
                  v-model="editaItem.propsData.title.styles.line_height"
                  type="range"
                  min="-5"
                  max="15"
                  step="0.1"
                ></b-form-input>                          
              </div>
            </div>
          </b-collapse>
          <div class="separador"></div>

          <div class="d-flex justify-content-between p-3" v-b-toggle.contentCard @click="editing = 'content'" v-if="editaItem.propsData.card.layout != 3">
            <label class="titulo" style="margin: 0 !important">Conteúdo</label>
            <img
              class="chevron icon mt-0"
              src="@/assets/editor/chevron-right.svg"
            />
          </div>
          <b-collapse v-if="editaItem.propsData.card.layout != 3" id="contentCard" accordion="card-accordion">
            <ckeditor
              :editor-id="'editor2'"
              :config="editorConfig2"
              v-model="editaItem.propsData.content.text.content_text"
            ></ckeditor>
            <div class="content">
              <!-- Cores -->
              <div class="half">
                <div>
                  <label class="subtitulo"> Cor do texto </label>
                  <LvColorpicker
                    id="LvColorpicker"
                    v-model="editaItem.propsData.content.styles.color_text"
                    label="Cor do texto"
                    :colors="palette"
                  ></LvColorpicker>
                </div>
                <div>
                  <label class="subtitulo"> Fundo </label>
                  <LvColorpicker
                    id="LvColorpicker"
                    v-model="editaItem.propsData.content.styles.background_color_text"
                    label="Cor do texto"
                    :colors="palette"
                  ></LvColorpicker>
                </div>
              </div>

              <!-- Tamanho -->
              <div>
                <div class="space"></div>
                <div class="label-icon">
                  <label class="subtitulo" style="margin: 5px 0 0 0 !important;">Tamanho</label>
                  <div>
                    <img
                      src="@/assets/editor/globe.svg"
                      alt="monitor"
                      style="filter: invert(50%) !important; margin: 0"
                      @click="nextIcon('desktop'), (global = !global)"
                      v-if="global"
                    />
                    <img
                      src="@/assets/editor/monitor.svg"
                      alt="monitor"
                      style="filter: invert(50%) !important; margin: 0"
                      @click="nextIcon('tablet')"
                      v-if="typeView === 'desktop' && !global"
                    />
                    <img
                      src="@/assets/editor/tablet.svg"
                      alt="tablet"
                      style="filter: invert(50%) !important; margin: 0"
                      @click="nextIcon('mobile')"
                      v-if="typeView === 'tablet' && !global"
                    />
                    <img
                      src="@/assets/editor/phone.svg"
                      alt="phone"
                      style="filter: invert(50%) !important; margin: 0"
                      @click="nextIcon('desktop'), (global = !global)"
                      v-if="typeView === 'mobile' && !global"
                    />
                  </div>
                </div>
                <div
                  class="mt-2"
                  style="
                    display: flex;
                    gap: 10px;
                    width: 100%;
                    gap: 10px;
                    margin-bottom: -1rem;
                  "
                >
                  <b-button-group>
                    <b-button
                      :disabled="
                        editaItem.propsData.content.styles[typeView].size == 0
                      "
                      size="sm"
                      style="
                        height: min-content !important;
                        padding: 10.5px 7px !important;
                      "
                      @click="editaItem.propsData.content.styles[typeView].size--, globalInput(editaItem.propsData.content.styles[typeView].size, 'content', 'size')"
                      ><img src="@/assets/editor/minus3.svg" alt="align"
                    /></b-button>
                    <b-form-input
                      @focus="openDropdownSlider('editaItem.propsData.content.styles[typeView].size')"   
                      class="text-right"
                      v-model="editaItem.propsData.content.styles[typeView].size"
                      style="
                        width: 44px;
                        text-align: center !important;
                        border: none !important;
                        padding: 0 !important;
                      "
                    ></b-form-input>
                    <b-button
                      @click="editaItem.propsData.content.styles[typeView].size++, globalInput(editaItem.propsData.content.styles[typeView].size, 'content', 'size')"
                      size="sm"
                      style="
                        height: min-content !important;
                        padding: 10.5px 7px !important;
                      "
                      ><img src="@/assets/editor/plus.svg" alt="align"
                    /></b-button>
                  </b-button-group>
  
                  <div>
                    <multiselect
                      v-model="editaItem.propsData.content.styles[typeView].font_measure"
                      :options="['px', 'rem', '%', 'em']"
                      :searchable="false"
                      :show-labels="false"
                      placeholder=" "
                      :hide-selected="true"
                      :allow-empty="false"
                      @input="globalInput(editaItem.propsData.content.styles[typeView].font_measure, 'content', 'font_measure')"
                    ></multiselect>
                  </div>
  
                  <!-- <div>
                    <b-button
                      size="sm"
                      @click="trocarType"
                      style="
                        height: min-content !important;
                        padding: 10.5px 10px !important;
                      "
                      ><img src="@/assets/editor/type.svg" alt="align"
                    /></b-button>
                  </div> -->
                </div>
                <div class="space"></div>
                <div class="space"></div>
              </div>

              <!-- Fonte -->
              <div class="max-multi">
                <label class="subtitulo">Fonte</label>
                <multiselect
                  v-model="editaItem.propsData.content.styles.font_family"
                  :options="fontOptions"
                  @input="$emit('callCharge',$event)"
                  :searchable="true"
                  :show-labels="false"
                  placeholder="Fonte"
                  :hide-selected="true"
                  :allow-empty="false"
                ></multiselect>
                <div class="space"></div>
              </div>

              <!-- Alinhamento -->
              <div>
                <div class="label-icon">
                  <label class="subtitulo" style="margin: 5px 0 0px 0 !important;">Alinhamento</label>
                  <div>
                  <img
                    src="@/assets/editor/globe.svg"
                    alt="monitor"
                    style="filter: invert(50%) !important; margin: 0"
                    @click="nextIcon('desktop'), (global = !global)"
                    v-if="global"
                  />
                  <img
                    src="@/assets/editor/monitor.svg"
                    alt="monitor"
                    style="filter: invert(50%) !important; margin: 0"
                    @click="nextIcon('tablet')"
                    v-if="typeView === 'desktop' && !global"
                  />
                  <img
                    src="@/assets/editor/tablet.svg"
                    alt="tablet"
                    style="filter: invert(50%) !important; margin: 0"
                    @click="nextIcon('mobile')"
                    v-if="typeView === 'tablet' && !global"
                  />
                  <img
                    src="@/assets/editor/phone.svg"
                    alt="phone"
                    style="filter: invert(50%) !important; margin: 0"
                    @click="nextIcon('desktop'), (global = !global)"
                    v-if="typeView === 'mobile' && !global"
                  />
                </div>
              </div>
                <b-button-group size="sm" style="margin-top: 5px">
                  <b-button
                    @click="editaItem.propsData.content.styles[typeView].align = 'left', globalInput(editaItem.propsData.content.styles[typeView].align, 'content', 'align')"
                    ><img src="@/assets/editor/align-left.svg"
                  /></b-button>
                  <b-button
                    @click="editaItem.propsData.content.styles[typeView].align = 'center', globalInput(editaItem.propsData.content.styles[typeView].align, 'content', 'align')"
                    ><img src="@/assets/editor/align-center.svg"
                  /></b-button>
                  <b-button
                    @click="editaItem.propsData.content.styles[typeView].align = 'right', globalInput(editaItem.propsData.content.styles[typeView].align, 'content', 'align')"
                    ><img src="@/assets/editor/align-right.svg"
                  /></b-button>
                  <b-button
                    @click="editaItem.propsData.content.styles[typeView].align = 'justify', globalInput(editaItem.propsData.content.styles[typeView].align, 'content', 'align')"
                    ><img src="@/assets/editor/align-justify.svg"
                  /></b-button>
                </b-button-group>
              </div>

              <!-- Espessura -->
              <div>
                <div class="space"></div>
                <div class="label-range">
                  <label class="subtitulo">Espessura da fonte</label>
                  <input
                    type="number"
                    v-model="editaItem.propsData.content.styles.font_weight"
                  />
                </div>
                <b-form-input
                  v-model="editaItem.propsData.content.styles.font_weight"
                  type="range"
                  min="100"
                  max="900"
                  step="100"
                ></b-form-input>
              </div>

              <!-- Espaçamento entre letras -->
              <div>
                <div class="label-range">
                  <label class="subtitulo">Espaço entre letras</label>
                  <input
                    type="number"
                    v-model="editaItem.propsData.content.styles.letter_space"
                  />
                </div>
                <b-form-input
                  v-model="editaItem.propsData.content.styles.letter_space"
                  type="range"
                  min="-5"
                  max="15"
                  step="0.1"
                ></b-form-input>                
              </div>

              <!-- Altura da linha -->
              <div>
                <div class="label-range">
                  <label class="subtitulo">Altura da linha</label>
                  <input
                    type="number"
                    v-model="editaItem.propsData.content.styles.line_height"
                  />
                </div>
                <b-form-input
                  v-model="editaItem.propsData.content.styles.line_height"
                  type="range"
                  min="-5"
                  max="15"
                  step="0.1"
                ></b-form-input>                          
              </div>
            </div>
          </b-collapse>
          <div class="separador" v-if="editaItem.propsData.card.layout != 3"></div>

          <div class="d-flex justify-content-between p-3" v-b-toggle.justCard>
            <label class="titulo" style="margin: 0 !important">Card</label>
            <img
              class="chevron icon mt-0"
              src="@/assets/editor/chevron-right.svg"
            />
          </div>
          <b-collapse id="justCard" accordion="card-accordion">
            <div class="content">
              <div class="max-multi">
                <!-- Tipo de fundo -->
                <label class="subtitulo">Fundo</label>
                <multiselect
                  v-model="editaItem.propsData.card.background.type"
                  :options="['Cor sólida', 'Gradiente']"
                  :searchable="false"
                  :show-labels="false"
                  placeholder="Fonte"
                  :hide-selected="true"
                  :allow-empty="false"
                ></multiselect>
                <div class="space"></div>

                <!-- Fundo Gradiente -->
                <div class="half" v-if="editaItem.propsData.card.background.type == 'Gradiente'">
                  <div>
                    <label class="subtitulo"> Cor 1 </label>
                    <LvColorpicker
                      id="LvColorpicker"
                      v-model="editaItem.propsData.card.background.grad1"
                      label="Cor do texto"
                      :colors="palette"
                    ></LvColorpicker>
                  </div>
                  <div>
                    <label class="subtitulo"> Cor 2 </label>
                    <LvColorpicker
                      id="LvColorpicker"
                      v-model="editaItem.propsData.card.background.grad2"
                      label="Cor do texto"
                      :colors="palette"
                    ></LvColorpicker>
                  </div>
                </div>
                <div v-if="editaItem.propsData.card.background.type == 'Gradiente'">
                  <div class="space"></div>
                  <div class="label-range">
                    <label class="subtitulo">Angulo do gradiente</label>
                    <input
                      type="number"
                      v-model="editaItem.propsData.card.background.gradAngle"
                    />
                  </div>
                  <b-form-input
                    v-model="editaItem.propsData.card.background.gradAngle"
                    type="range"
                    min="0"
                    max="360"
                    step="1"
                  ></b-form-input>                          
                </div>
                
                <!-- Fundo sólido -->
                <div v-else>
                  <label class="subtitulo"> Cor do fundo</label>
                  <LvColorpicker
                    id="LvColorpicker"
                    v-model="editaItem.propsData.card.background.color"
                    label="Cor do texto"
                    :colors="palette"
                  ></LvColorpicker>
                  <div class="space"></div>
                  <label class="subtitulo"> Cor do fundo ao passar o mouse</label>
                  <LvColorpicker
                    id="LvColorpicker"
                    v-model="editaItem.propsData.card.background.hover_color"
                    label="Cor do texto"
                    :colors="palette"
                  ></LvColorpicker>
                </div>
                <div class="space"></div>

                <!-- Arredondar bordas -->
                <div class="label-range">
                  <label class="subtitulo">Arredondar bordas </label>
                  <img
                    :class="{ borderLinkActive: borderLink === true }"
                    style="
                      height: 18px;
                      transition: all 0.3s;
                      filter: invert(50%);
                      margin-right: 10px;
                      cursor: pointer;
                    "
                    src="@/assets/editor/link-2.svg"
                    alt="linkar"
                    @click="borderLink = !borderLink"
                  />
                </div>
                <div class="inputs-group">
                  <input
                    type="number"
                    v-model="editaItem.propsData.card.border.radius.top"                  
                    @focus="openDropdownSlider('editaItem.propsData.card.border.radius.top', 'borderLink')"  
                  />
                  <input
                    type="number"
                    v-model="editaItem.propsData.card.border.radius.right"
                    @focus="openDropdownSlider('editaItem.propsData.card.border.radius.right', 'borderLink')"
                  />
                  <input
                    type="number"
                    v-model="editaItem.propsData.card.border.radius.bottom"
                    @focus="openDropdownSlider('editaItem.propsData.card.border.radius.bottom', 'borderLink')"
                  />
                  <input
                    type="number"
                    v-model="editaItem.propsData.card.border.radius.left"
                    @focus="openDropdownSlider('editaItem.propsData.card.border.radius.left', 'borderLink')"
                  />
                </div>

                <!-- Bordas -->
                <div>
                  <div class="space"></div>
                  <div style="display: flex; width: 100%; transition: all 0.3s">
                    <label :class="{ labelOff: editaItem.propsData.card.border.hasBorder == false }" class="subtitulo"
                      >Bordas visíveis</label
                    >
                    <b-form-checkbox
                      style="margin-top: -5px; margin-left: auto; margin-right: 0"
                      v-model="editaItem.propsData.card.border.hasBorder"
                      name="is_main"
                      size="lg"
                      switch
                    >
                    </b-form-checkbox>
                  </div>
                  <div class="space"></div>
                  <label class="subtitulo" :class="{ labelOff: editaItem.propsData.card.border.hasBorder == false }"> Cor da borda </label>
                  <LvColorpicker
                    :class="{ colorDisabled: editaItem.propsData.card.border.hasBorder == false }"
                    :disabled="editaItem.propsData.card.border.hasBorder == false"
                    id="LvColorpicker"
                    v-model="editaItem.propsData.card.border.color"
                    label="Cor do texto"
                    :colors="palette"
                  ></LvColorpicker>
                  <div class="space"></div>
                  <div class="label-range">
                    <label class="subtitulo" :class="{ labelOff: editaItem.propsData.card.border.hasBorder == false }">Espessura</label>
                    <input
                      :disabled="editaItem.propsData.card.border.hasBorder == false"
                      type="number"
                      v-model="editaItem.propsData.card.border.width"
                    />
                  </div>
                  <b-form-input
                    :disabled="editaItem.propsData.card.border.hasBorder == false"
                    v-model="editaItem.propsData.card.border.width"
                    type="range"
                    min="0"
                    max="5"
                    step="0.1"
                  ></b-form-input>   
                </div>

                <!-- Sombras -->
                <div>
                  <div class="space"></div>
                  <div style="display: flex; width: 100%; transition: all 0.3s">
                    <label :class="{ labelOff: editaItem.propsData.card.shadow.hasShadow == false }" class="subtitulo"
                      >Sombra</label
                    >
                    <b-form-checkbox
                      style="margin-top: -5px; margin-left: auto; margin-right: 0"
                      v-model="editaItem.propsData.card.shadow.hasShadow"
                      name="is_main"
                      size="lg"
                      switch
                    >
                    </b-form-checkbox>
                  </div>
                  <div class="space"></div>
                  <label class="subtitulo" :class="{ labelOff: editaItem.propsData.card.shadow.hasShadow == false }"> Cor da sombra </label>
                  <LvColorpicker
                    :class="{ colorDisabled: editaItem.propsData.card.shadow.hasShadow == false }"
                    :disabled="editaItem.propsData.card.shadow.hasShadow == false"
                    id="LvColorpicker"
                    v-model="editaItem.propsData.card.shadow.color"
                    label="Cor do texto"
                    :colors="palette"
                  ></LvColorpicker>
                  <div class="space"></div>
                  <div class="label-range">
                    <label class="subtitulo" :class="{ labelOff: editaItem.propsData.card.shadow.hasShadow == false }">Borrão</label>
                    <input
                      :disabled="editaItem.propsData.card.shadow.hasShadow == false"
                      type="number"
                      v-model="editaItem.propsData.card.shadow.blur"
                    />
                  </div>
                  <b-form-input
                    :disabled="editaItem.propsData.card.shadow.hasShadow == false"
                    v-model="editaItem.propsData.card.shadow.blur"
                    type="range"
                    min="0"
                    max="10"
                    step="0.5"
                  ></b-form-input>   
                  <div class="label-range">
                    <label class="subtitulo" :class="{ labelOff: editaItem.propsData.card.shadow.hasShadow == false }">Posição Horizontal</label>
                    <input
                      :disabled="editaItem.propsData.card.shadow.hasShadow == false"
                      type="number"
                      v-model="editaItem.propsData.card.shadow.x"
                    />
                  </div>
                  <b-form-input
                    :disabled="editaItem.propsData.card.shadow.hasShadow == false"
                    v-model="editaItem.propsData.card.shadow.x"
                    type="range"
                    min="-50"
                    max="50"
                    step="1"
                  ></b-form-input>  
                  <div class="label-range">
                    <label class="subtitulo" :class="{ labelOff: editaItem.propsData.card.shadow.hasShadow == false }">Posição Vertical</label>
                    <input
                      :disabled="editaItem.propsData.card.shadow.hasShadow == false"
                      type="number"
                      v-model="editaItem.propsData.card.shadow.y"
                    />
                  </div>
                  <b-form-input
                    :disabled="editaItem.propsData.card.shadow.hasShadow == false"
                    v-model="editaItem.propsData.card.shadow.y"
                    type="range"
                    min="-50"
                    max="50"
                    step="1"
                  ></b-form-input>  
                </div>
              </div>
            </div>
          </b-collapse>
          <div class="separador"></div>
        </b-tab>

        <b-tab title="Avançado">
          <div class="separador"></div>
          <!-- Espaçamento do card -->
          <div>
            <div class="d-flex justify-content-between p-3" v-b-toggle.spacingCard>
              <label class="titulo" style="margin: 0 !important">Card</label>
              <img
                class="chevron icon mt-0"
                src="@/assets/editor/chevron-right.svg"
              />
            </div>
            <b-collapse id="spacingCard" accordion="spacing-accordion">
              <!-- Componente de Espaçamento -->
              <div class="content">
                <!-- Ícones -->
                <div class="label-icon">
                  <label class="titulo">Espaçamento</label>
                  <img
                    src="@/assets/editor/globe.svg"
                    alt="monitor"
                    style="filter: invert(50%) !important"
                    @click="nextIcon('desktop'), (global = !global)"
                    v-if="global"
                  />
                  <img
                    src="@/assets/editor/monitor.svg"
                    alt="monitor"
                    style="filter: invert(50%) !important"
                    @click="nextIcon('tablet')"
                    v-if="typeView === 'desktop' && !global"
                  />
                  <img
                    src="@/assets/editor/tablet.svg"
                    alt="tablet"
                    style="filter: invert(50%) !important"
                    @click="nextIcon('mobile')"
                    v-if="typeView === 'tablet' && !global"
                  />
                  <img
                    src="@/assets/editor/phone.svg"
                    alt="phone"
                    style="filter: invert(50%) !important"
                    @click="nextIcon('desktop'), (global = !global)"
                    v-if="typeView === 'mobile' && !global"
                  />
                </div>
                <!-- Inputs -->
                <div class="container-spacing" style="padding-bottom: 15px">
                  <div class="border-dash">
                    <span class="txt-mg">MARGEM EXTERNA</span>
                    <img
                      src="@/assets/editor/link-click.svg"
                      alt="Link"
                      class="links-advanced"
                      :class="linkMargin ? '' : 'link-disabled'"
                      @click="linkMargin = !linkMargin"
                    />
                    <div class="alinhamento">
                      <!-- MARGIN LEFT -->
                      <div class="item-1">
                        <input
                          type="number"                      
                          v-model="editaItem.propsData.spacing[typeView].margin_left"
                          @focus="openDropdownSlider('editaItem.propsData.spacing[typeView].margin_left', 'linkMargin')" 
                          min="0"
                          max="100"
                        />
                      </div>
                      <div class="item-2">
                        <div class="alinhamento-2">
                          <!-- MARGIN TOP -->
                          <div>
                            <input
                              type="number"
                              v-model="editaItem.propsData.spacing[typeView].margin_top"
                              @focus="openDropdownSlider('editaItem.propsData.spacing[typeView].margin_top', 'linkMargin')" 
                              min="0"
                              max="100"
                            />
                          </div>
                          <div>
                            <div class="contain-borders">
                              <span class="txt-pd">MARGEM INTERNA</span>
                              <img
                                src="@/assets/editor/link-click.svg"
                                alt="Link"
                                class="links-advanced"
                                :class="linkPadding ? '' : 'link-disabled'"
                                @click="linkPadding = !linkPadding"
                              />
                              <div class="alinhamento">
                                <!-- PADDING LEFT -->
                                <div class="item-1">
                                  <input
                                    type="number"
                                    v-model="editaItem.propsData.spacing[typeView].padding_left"
                                    @focus="openDropdownSlider('editaItem.propsData.spacing[typeView].padding_left', 'linkPadding')" 
                                    min="0"
                                    max="100"
                                  />
                                </div>
                                <div class="item-2">
                                  <div class="alinhamento-2">
                                    <!-- PADDING TOP -->
                                    <div>
                                      <input
                                        type="number"                                  
                                        v-model="editaItem.propsData.spacing[typeView].padding_top"
                                        @focus="openDropdownSlider('editaItem.propsData.spacing[typeView].padding_top', 'linkPadding')" 
                                        min="0"
                                        max="100"
                                      />
                                    </div>
                                    <div>
                                      <div class="border-final"></div>
                                    </div>
                                    <!-- PADDING BOTTOM -->
                                    <input
                                      type="number"
                                      v-model="editaItem.propsData.spacing[typeView].padding_bottom"
                                      @focus="openDropdownSlider('editaItem.propsData.spacing[typeView].padding_bottom', 'linkPadding')" 
                                      min="0"
                                      max="100"
                                    />
                                    <div></div>
                                  </div>
                                </div>
                                <!-- PADDING RIGHT -->
                                <div class="item-3">
                                  <input
                                    type="number"                                 
                                    v-model="editaItem.propsData.spacing[typeView].padding_right"
                                    @focus="openDropdownSlider('editaItem.propsData.spacing[typeView].padding_right', 'linkPadding')" 
                                    min="0"
                                    max="100"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- MARGIN BOTTOM -->
                          <div>
                            <input
                              type="number"                         
                              v-model="editaItem.propsData.spacing[typeView].margin_bottom"
                              @focus="openDropdownSlider('editaItem.propsData.spacing[typeView].margin_bottom', 'linkMargin')" 
                              min="0"
                              max="100"
                            />
                          </div>
                        </div>
                      </div>
                      <!-- MARGIN RIGHT -->
                      <div class="item-3">
                        <input
                          type="number"                       
                          v-model="editaItem.propsData.spacing[typeView].margin_right"
                          @focus="openDropdownSlider('editaItem.propsData.spacing[typeView].margin_right', 'linkMargin')" 
                          min="0"
                          max="100"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </b-collapse>
          </div>
          <div class="separador"></div>

          <!-- Espaçamento do Ícone -->
          <div>
            <div class="d-flex justify-content-between p-3" v-b-toggle.spacingIcon>
              <label class="titulo" style="margin: 0 !important">Ícone</label>
              <img
                class="chevron icon mt-0"
                src="@/assets/editor/chevron-right.svg"
              />
            </div>
            <b-collapse id="spacingIcon" accordion="spacing-accordion">
              <!-- Componente de Espaçamento -->
              <div class="content">
                <!-- Ícones -->
                <div class="label-icon">
                  <label class="titulo">Espaçamento</label>
                  <img
                    src="@/assets/editor/globe.svg"
                    alt="monitor"
                    style="filter: invert(50%) !important"
                    @click="nextIcon('desktop'), (global = !global)"
                    v-if="global"
                  />
                  <img
                    src="@/assets/editor/monitor.svg"
                    alt="monitor"
                    style="filter: invert(50%) !important"
                    @click="nextIcon('tablet')"
                    v-if="typeView === 'desktop' && !global"
                  />
                  <img
                    src="@/assets/editor/tablet.svg"
                    alt="tablet"
                    style="filter: invert(50%) !important"
                    @click="nextIcon('mobile')"
                    v-if="typeView === 'tablet' && !global"
                  />
                  <img
                    src="@/assets/editor/phone.svg"
                    alt="phone"
                    style="filter: invert(50%) !important"
                    @click="nextIcon('desktop'), (global = !global)"
                    v-if="typeView === 'mobile' && !global"
                  />
                </div>
                <!-- Inputs -->
                <div class="container-spacing" style="padding-bottom: 15px">
                  <div class="border-dash">
                    <span class="txt-mg">MARGEM EXTERNA</span>
                    <img
                      src="@/assets/editor/link-click.svg"
                      alt="Link"
                      class="links-advanced"
                      :class="iconMargin ? '' : 'link-disabled'"
                      @click="iconMargin = !iconMargin"
                    />
                    <div class="alinhamento">
                      <!-- MARGIN LEFT -->
                      <div class="item-1">
                        <input
                          type="number"
                          v-model="editaItem.propsData.spacingIcon[typeView].margin_left"
                          @focus="openDropdownSlider('editaItem.propsData.spacingIcon[typeView].margin_left', 'iconMargin')"
                          min="0"
                          max="100"
                        />
                      </div>
                      <div class="item-2">
                        <div class="alinhamento-2">
                          <!-- MARGIN TOP -->
                          <div>
                            <input
                              type="number"
                              v-model="editaItem.propsData.spacingIcon[typeView].margin_top"
                              @focus="openDropdownSlider('editaItem.propsData.spacingIcon[typeView].margin_top', 'iconMargin')"
                              min="0"
                              max="100"
                            />
                          </div>
                          <div>
                            <div class="contain-borders">
                              <span class="txt-mg">MARGEM INTERNA</span>
                              <img
                                src="@/assets/editor/link-click.svg"
                                alt="Link"
                                class="links-advanced"
                                :class="iconPadding ? '' : 'link-disabled'"
                                @click="iconPadding = !iconPadding"
                              />
                              <div class="alinhamento">
                                <!-- PADDING LEFT -->
                                <div class="item-1">
                                  <input
                                    type="number"
                                    v-model="editaItem.propsData.spacingIcon[typeView].padding_left"
                                    @focus="openDropdownSlider('editaItem.propsData.spacingIcon[typeView].padding_left', 'iconPadding')"
                                    min="0"
                                    max="100"
                                  />
                                </div>
                                <div class="item-2">
                                  <div class="alinhamento-2">
                                    <!-- PADDING TOP -->
                                    <div>
                                      <input
                                        type="number"
                                        v-model="editaItem.propsData.spacingIcon[typeView].padding_top"
                                        @focus="openDropdownSlider('editaItem.propsData.spacingIcon[typeView].padding_top', 'iconPadding')"
                                        min="0"
                                        max="100"
                                      />
                                    </div>
                                    <div>
                                      <div class="border-final"></div>
                                    </div>
                                    <!-- PADDING BOTTOM -->
                                    <input
                                      type="number"
                                      v-model="editaItem.propsData.spacingIcon[typeView].padding_bottom"
                                      @focus="openDropdownSlider('editaItem.propsData.spacingIcon[typeView].padding_bottom', 'iconPadding')"
                                      min="0"
                                      max="100"
                                    />
                                    <div></div>
                                  </div>
                                </div>
                                <!-- PADDING RIGHT -->
                                <div class="item-3">
                                  <input
                                    type="number"
                                    v-model="editaItem.propsData.spacingIcon[typeView].padding_right"
                                    @focus="openDropdownSlider('editaItem.propsData.spacingIcon[typeView].padding_right', 'iconPadding')"
                                    min="0"
                                    max="100"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- MARGIN BOTTOM -->
                          <div>
                            <input
                              type="number"
                              v-model="editaItem.propsData.spacingIcon[typeView].margin_bottom"
                              @focus="openDropdownSlider('editaItem.propsData.spacingIcon[typeView].margin_bottom', 'iconMargin')"
                              min="0"
                              max="100"
                            />
                          </div>
                        </div>
                      </div>
                      <!-- MARGIN RIGHT -->
                      <div class="item-3">
                        <input
                          type="number"
                          v-model="editaItem.propsData.spacingIcon[typeView].margin_right"
                          @focus="openDropdownSlider('editaItem.propsData.spacingIcon[typeView].margin_right', 'iconMargin')"
                          min="0"
                          max="100"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </b-collapse>
          </div>
          <div class="separador"></div>

          <!-- Espaçamento do Título -->
          <div>
            <div class="d-flex justify-content-between p-3" v-b-toggle.spacingTitle>
              <label class="titulo" style="margin: 0 !important">Título</label>
              <img
                class="chevron icon mt-0"
                src="@/assets/editor/chevron-right.svg"
              />
            </div>
            <b-collapse id="spacingTitle" accordion="spacing-accordion">
              <!-- Componente de Espaçamento -->
              <div class="content">
                <!-- Ícones -->
                <div class="label-icon">
                  <label class="titulo">Espaçamento</label>
                  <img
                    src="@/assets/editor/globe.svg"
                    alt="monitor"
                    style="filter: invert(50%) !important"
                    @click="nextIcon('desktop'), (global = !global)"
                    v-if="global"
                  />
                  <img
                    src="@/assets/editor/monitor.svg"
                    alt="monitor"
                    style="filter: invert(50%) !important"
                    @click="nextIcon('tablet')"
                    v-if="typeView === 'desktop' && !global"
                  />
                  <img
                    src="@/assets/editor/tablet.svg"
                    alt="tablet"
                    style="filter: invert(50%) !important"
                    @click="nextIcon('mobile')"
                    v-if="typeView === 'tablet' && !global"
                  />
                  <img
                    src="@/assets/editor/phone.svg"
                    alt="phone"
                    style="filter: invert(50%) !important"
                    @click="nextIcon('desktop'), (global = !global)"
                    v-if="typeView === 'mobile' && !global"
                  />
                </div>
                <!-- Inputs -->
                <div class="container-spacing" style="padding-bottom: 15px">
                  <div class="border-dash">
                    <span class="txt-mg">MARGEM EXTERNA</span>
                    <img
                      src="@/assets/editor/link-click.svg"
                      alt="Link"
                      class="links-advanced"
                      :class="titleMargin ? '' : 'link-disabled'"
                      @click="titleMargin = !titleMargin"
                    />
                    <div class="alinhamento">
                      <!-- MARGIN LEFT -->
                      <div class="item-1">
                        <input
                          type="number"
                          v-model="editaItem.propsData.spacingTitle[typeView].margin_left"
                          @focus="openDropdownSlider('editaItem.propsData.spacingTitle[typeView].margin_left', 'titleMargin')"
                          min="0"
                          max="100"
                        />
                      </div>
                      <div class="item-2">
                        <div class="alinhamento-2">
                          <!-- MARGIN TOP -->
                          <div>
                            <input
                              type="number"
                              v-model="editaItem.propsData.spacingTitle[typeView].margin_top"
                              @focus="openDropdownSlider('editaItem.propsData.spacingTitle[typeView].margin_top', 'titleMargin')"
                              min="0"
                              max="100"
                            />
                          </div>
                          <div>
                            <div class="contain-borders">
                              <span class="txt-mg">MARGEM INTERNA</span>
                              <img
                                src="@/assets/editor/link-click.svg"
                                alt="Link"
                                class="links-advanced"
                                :class="titlePadding ? '' : 'link-disabled'"
                                @click="titlePadding = !titlePadding"
                              />
                              <div class="alinhamento">
                                <!-- PADDING LEFT -->
                                <div class="item-1">
                                  <input
                                    type="number"
                                    v-model="editaItem.propsData.spacingTitle[typeView].padding_left"
                                    @focus="openDropdownSlider('editaItem.propsData.spacingTitle[typeView].padding_left', 'titlePadding')"
                                    min="0"
                                    max="100"
                                  />
                                </div>
                                <div class="item-2">
                                  <div class="alinhamento-2">
                                    <!-- PADDING TOP -->
                                    <div>
                                      <input
                                        type="number"
                                        v-model="editaItem.propsData.spacingTitle[typeView].padding_top"
                                        @focus="openDropdownSlider('editaItem.propsData.spacingTitle[typeView].padding_top', 'titlePadding')"
                                        min="0"
                                        max="100"
                                      />
                                    </div>
                                    <div>
                                      <div class="border-final"></div>
                                    </div>
                                    <!-- PADDING BOTTOM -->
                                    <input
                                      type="number"
                                      v-model="editaItem.propsData.spacingTitle[typeView].padding_bottom"
                                      @focus="openDropdownSlider('editaItem.propsData.spacingTitle[typeView].padding_bottom', 'titlePadding')"
                                      min="0"
                                      max="100"
                                    />
                                    <div></div>
                                  </div>
                                </div>
                                <!-- PADDING RIGHT -->
                                <div class="item-3">
                                  <input
                                    type="number"
                                    v-model="editaItem.propsData.spacingTitle[typeView].padding_right"
                                    @focus="openDropdownSlider('editaItem.propsData.spacingTitle[typeView].padding_right', 'titlePadding')"
                                    min="0"
                                    max="100"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- MARGIN BOTTOM -->
                          <div>
                            <input
                              type="number"
                              v-model="editaItem.propsData.spacingTitle[typeView].margin_bottom"
                              @focus="openDropdownSlider('editaItem.propsData.spacingTitle[typeView].margin_bottom', 'titleMargin')"
                              min="0"
                              max="100"
                            />
                          </div>
                        </div>
                      </div>
                      <!-- MARGIN RIGHT -->
                      <div class="item-3">
                        <input
                          type="number"
                          v-model="editaItem.propsData.spacingTitle[typeView].margin_right"
                          @focus="openDropdownSlider('editaItem.propsData.spacingTitle[typeView].margin_right', 'titleMargin')"
                          min="0"
                          max="100"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </b-collapse>
          </div>
          <div class="separador"></div>

          <!-- Espaçamento do Conteúdo -->
          <div v-if="editaItem.propsData.card.layout != 3">
            <div class="d-flex justify-content-between p-3" v-b-toggle.spacingContent>
              <label class="titulo" style="margin: 0 !important">Conteúdo</label>
              <img
                class="chevron icon mt-0"
                src="@/assets/editor/chevron-right.svg"
              />
            </div>
            <b-collapse id="spacingContent" accordion="spacing-accordion">
              <!-- Componente de Espaçamento -->
              <div class="content">
                <!-- Ícones -->
                <div class="label-icon">
                  <label class="titulo">Espaçamento</label>
                  <img
                    src="@/assets/editor/globe.svg"
                    alt="monitor"
                    style="filter: invert(50%) !important"
                    @click="nextIcon('desktop'), (global = !global)"
                    v-if="global"
                  />
                  <img
                    src="@/assets/editor/monitor.svg"
                    alt="monitor"
                    style="filter: invert(50%) !important"
                    @click="nextIcon('tablet')"
                    v-if="typeView === 'desktop' && !global"
                  />
                  <img
                    src="@/assets/editor/tablet.svg"
                    alt="tablet"
                    style="filter: invert(50%) !important"
                    @click="nextIcon('mobile')"
                    v-if="typeView === 'tablet' && !global"
                  />
                  <img
                    src="@/assets/editor/phone.svg"
                    alt="phone"
                    style="filter: invert(50%) !important"
                    @click="nextIcon('desktop'), (global = !global)"
                    v-if="typeView === 'mobile' && !global"
                  />
                </div>
                <!-- Inputs -->
                <div class="container-spacing" style="padding-bottom: 15px">
                  <div class="border-dash">
                    <span class="txt-mg">MARGEM EXTERNA</span>
                    <img
                      src="@/assets/editor/link-click.svg"
                      alt="Link"
                      class="links-advanced"
                      :class="contentMargin ? '' : 'link-disabled'"
                      @click="contentMargin = !contentMargin"
                    />
                    <div class="alinhamento">
                      <!-- MARGIN LEFT -->
                      <div class="item-1">
                        <input
                          type="number"
                          v-model="editaItem.propsData.spacingContent[typeView].margin_left"                     
                          @focus="openDropdownSlider('editaItem.propsData.spacingContent[typeView].margin_left', 'contentMargin')"
                          min="0"
                          max="100"
                        />
                      </div>
                      <div class="item-2">
                        <div class="alinhamento-2">
                          <!-- MARGIN TOP -->
                          <div>
                            <input
                              type="number"
                              v-model="editaItem.propsData.spacingContent[typeView].margin_top"                     
                              @focus="openDropdownSlider('editaItem.propsData.spacingContent[typeView].margin_top', 'contentMargin')"
                              min="0"
                              max="100"
                            />
                          </div>
                          <div>
                            <div class="contain-borders">
                              <span class="txt-mg">MARGEM INTERNA</span>
                              <img
                                src="@/assets/editor/link-click.svg"
                                alt="Link"
                                class="links-advanced"
                                :class="contentPadding ? '' : 'link-disabled'"
                                @click="contentPadding = !contentPadding"
                              />
                              <div class="alinhamento">
                                <!-- PADDING LEFT -->
                                <div class="item-1">
                                  <input
                                    type="number"
                                    v-model="editaItem.propsData.spacingContent[typeView].padding_left"                     
                                    @focus="openDropdownSlider('editaItem.propsData.spacingContent[typeView].padding_left', 'contentPadding')"
                                    min="0"
                                    max="100"
                                  />
                                </div>
                                <div class="item-2">
                                  <div class="alinhamento-2">
                                    <!-- PADDING TOP -->
                                    <div>
                                      <input
                                        type="number"
                                        v-model="editaItem.propsData.spacingContent[typeView].padding_top"                     
                                        @focus="openDropdownSlider('editaItem.propsData.spacingContent[typeView].padding_top', 'contentPadding')"
                                        min="0"
                                        max="100"
                                      />
                                    </div>
                                    <div>
                                      <div class="border-final"></div>
                                    </div>
                                    <!-- PADDING BOTTOM -->
                                    <input
                                      type="number"
                                      v-model="editaItem.propsData.spacingContent[typeView].padding_bottom"                     
                                      @focus="openDropdownSlider('editaItem.propsData.spacingContent[typeView].padding_bottom', 'contentPadding')"
                                      min="0"
                                      max="100"
                                    />
                                    <div></div>
                                  </div>
                                </div>
                                <!-- PADDING RIGHT -->
                                <div class="item-3">
                                  <input
                                    type="number"
                                    v-model="editaItem.propsData.spacingContent[typeView].padding_right"                     
                                    @focus="openDropdownSlider('editaItem.propsData.spacingContent[typeView].padding_right', 'contentPadding')"
                                    min="0"
                                    max="100"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- MARGIN BOTTOM -->
                          <div>
                            <input
                              type="number"
                              v-model="editaItem.propsData.spacingContent[typeView].margin_bottom"                     
                              @focus="openDropdownSlider('editaItem.propsData.spacingContent[typeView].margin_bottom', 'contentMargin')"
                              min="0"
                              max="100"
                            />
                          </div>
                        </div>
                      </div>
                      <!-- MARGIN RIGHT -->
                      <div class="item-3">
                        <input
                          type="number"
                          v-model="editaItem.propsData.spacingContent[typeView].margin_right"                     
                          @focus="openDropdownSlider('editaItem.propsData.spacingContent[typeView].margin_right', 'contentMargin')"
                          min="0"
                          max="100"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </b-collapse>
          </div>
          <div class="separador" v-if="editaItem.propsData.card.layout != 3"></div>
          <advancedBasic           
            :visibility="editaItem.propsData.visibility" 
            :animation="editaItem.propsColumn" 
            :attributes="editaItem.propsData.custom" 
            :typeView="typeView"
            :global="global"
            :hideSpacing="true"
            @toggleGlobal="global = !global"
            @nextIcon="nextIcon"     
            @update:visibility="editaItem.propsData.visibility = $event"
            @update:animation="editaItem.propsColumn = $event"
            @update:attributes="editaItem.propsData.custom = $event"
          ></advancedBasic>
          <div class="separador"></div>
        </b-tab>
      </b-tabs>
    </b-card>
    <ModalIcons @iconSelect="iconSelect"></ModalIcons>
    <Upload @setImg="setImg" />
    <dropdownSlider
      ref="dropdownSlider"   
      @updateValue="updateValue"
    /> 
  </div>
</template>
<script>
import dropdownSliderMixin from "@/mixins/dropdownSliderMixin.js";
import advancedBasic from "../EditComponents/advancedBasic.vue";
import Upload from "../../Upload.vue";
import LvInput from "lightvue/input";
import LvColorpicker from "lightvue/color-picker";
import Multiselect from "vue-multiselect";
import CKEditor from "ckeditor4-vue";
import _ from "lodash";
import Vue from "vue";
import ModalIcons from "@/components/Editor/ModalSvg";

Vue.use(CKEditor);

export default {
  mixins: [dropdownSliderMixin],
  props: ["editaItem", "openSideRight", "fontOptions", "typeView", "divWidth", "palette"],
  components: {
    advancedBasic,
    Upload,
    Multiselect,
    LvInput,
    LvColorpicker: LvColorpicker,
    ModalIcons,
  },
  data() {
    return {
      iconPadding: false,
      iconMargin: false,
      titlePadding: false,
      titleMargin: false,
      contentPadding: false,
      contentMargin: false,
      varLinks: {
        borderLink: [
          'editaItem.propsData.card.border.radius.top',
          'editaItem.propsData.card.border.radius.right',
          'editaItem.propsData.card.border.radius.bottom',
          'editaItem.propsData.card.border.radius.left',
        ],
        linkMargin: [
          'editaItem.propsData.spacing[typeView].margin_top',
          'editaItem.propsData.spacing[typeView].margin_right',
          'editaItem.propsData.spacing[typeView].margin_bottom',
          'editaItem.propsData.spacing[typeView].margin_left',
        ],
        linkPadding: [
          'editaItem.propsData.spacing[typeView].padding_top',
          'editaItem.propsData.spacing[typeView].padding_right',
          'editaItem.propsData.spacing[typeView].padding_bottom',
          'editaItem.propsData.spacing[typeView].padding_left',
        ],
        iconMargin: [
          'editaItem.propsData.spacingIcon[typeView].margin_top',
          'editaItem.propsData.spacingIcon[typeView].margin_right',
          'editaItem.propsData.spacingIcon[typeView].margin_bottom',
          'editaItem.propsData.spacingIcon[typeView].margin_left'         
        ],
        iconPadding: [
          'editaItem.propsData.spacingIcon[typeView].padding_top',
          'editaItem.propsData.spacingIcon[typeView].padding_right',
          'editaItem.propsData.spacingIcon[typeView].padding_bottom',
          'editaItem.propsData.spacingIcon[typeView].padding_left' 
        ],  
        titleMargin: [
          'editaItem.propsData.spacingTitle[typeView].margin_top',
          'editaItem.propsData.spacingTitle[typeView].margin_right',
          'editaItem.propsData.spacingTitle[typeView].margin_bottom',
          'editaItem.propsData.spacingTitle[typeView].margin_left',       
        ],
        titlePadding: [
          'editaItem.propsData.spacingTitle[typeView].padding_top',
          'editaItem.propsData.spacingTitle[typeView].padding_right',
          'editaItem.propsData.spacingTitle[typeView].padding_bottom',
          'editaItem.propsData.spacingTitle[typeView].padding_left', 
        ],   
        contentMargin: [
          'editaItem.propsData.spacingContent[typeView].margin_top',
          'editaItem.propsData.spacingContent[typeView].margin_right',
          'editaItem.propsData.spacingContent[typeView].margin_bottom',
          'editaItem.propsData.spacingContent[typeView].margin_left',      
        ],
        contentPadding: [
          'editaItem.propsData.spacingContent[typeView].padding_top',
          'editaItem.propsData.spacingContent[typeView].padding_right',
          'editaItem.propsData.spacingContent[typeView].padding_bottom',
          'editaItem.propsData.spacingContent[typeView].padding_left',  
        ],      
      },  
      optionsType: [
        { value: "externo", text: "Externo" },
        { value: "interno", text: "Na própria página" },
        { value: "compartilhar", text: "Compartilhar no WhatsApp" },
        { value: "chamar", text: "Chamar no WhatsApp" },
        { value: "telefone", text: "Número de telefone" }
      ],
      borderLink: true,
      editing: 'content',
      expandCkButton: false,
      editMode: false,
      linkPadding: true,
      linkMargin: true,
      content: true,
      global: false,
      editorConfig: {
        uiColor: "#eeeeee",
        allowedContent: true,
        toolbar: [
          ["Maximize"],
          [
            "Bold",
            "Italic",
            "Strike",
          ],
          ["TextColor", "BGColor"],
          ["Source"],
        ],
        extraPlugins:
          "colorbutton, colordialog, font, lineutils, richcombo, uicolor,divarea",
        removePlugins:
          "about, blockquote, image, a11yhelp, resize, list, pastetext, pastefromgdocs, pastefromlibreoffice, pastefromword, showborders, tableselection, tabletools, pastetools, tableselection",
      },
      editorConfig2: {
        uiColor: "#eeeeee",
        allowedContent: true,
        toolbar: [
          ["Maximize"],
          [
            "Bold",
            "Italic",
            "Strike",
          ],
          ["TextColor", "BGColor"],
          ["Source"],
        ],
        extraPlugins:
          "colorbutton, colordialog, font, lineutils, richcombo, uicolor,divarea",
        removePlugins:
          "about, blockquote, image, a11yhelp, resize, list, pastetext, pastefromgdocs, pastefromlibreoffice, pastefromword, showborders, tableselection, tabletools, pastetools, tableselection",
      },
    };
  },
  computed: {
    selectedVar() {
      if (editaItem.propsData.icon.type == 'Imagem') {
        return 'imageUrl'
      }
      return 'iconSelected'
    },
  },
  watch: {
    editaItem: {
      handler: function(newValue) {
        this.debounceSetProps();
      },
      immediate: true,
      deep: true, 
    },
  },
  methods: {
    globalJustify(value, place) {
      let props = this.editaItem.propsData.alignment
      props[this.typeView][place] = value
      if (this.global && this.typeView == 'desktop') {
        props[this.typeView][place] = value
        props[this.typeView][place] = value
      }
    },
    setImg(img) {
      this.editaItem.propsData.icon.imageUrl = img;
    },
    equalPaddingIcon(value) {
      var space = this.editaItem.propsData.spacingIcon[this.typeView]
      space.padding_right = value
      space.padding_bottom = value
      space.padding_left = value
      if (this.global && this.typeView == 'desktop') {
        var spaceTablet = this.editaItem.propsData.spacingIcon.tablet
        var spaceMobile = this.editaItem.propsData.spacingIcon.mobile
        spaceTablet.padding_top = value
        spaceTablet.padding_right = value
        spaceTablet.padding_bottom = value
        spaceTablet.padding_left = value
        spaceMobile.padding_top = value
        spaceMobile.padding_right = value
        spaceMobile.padding_bottom = value
        spaceMobile.padding_left = value
      }
    },   
    iconSelect(item) {
      this.editaItem.propsData.icon.iconSelected = item;
    },
    openModal(data, fluxo) {
      this.$bvModal.show(data);
    },
    globalInput(px, place1, place2) {
      if (this.global && this.typeView == 'desktop') {
        var pixel = px.target ? px.target.value : px;
        var props = this.editaItem.propsData;
        if(props[place1].styles){
          props[place1].styles.tablet[place2] = pixel;
          props[place1].styles.mobile[place2] = pixel;
        }else if(props[place1]){
          props[place1].tablet[place2] = pixel;
          props[place1].mobile[place2] = pixel;
        }
      }
    },
    debounceDynamicHeight: _.debounce(function() {
      this.dynamicHeight();
    }, 200),
    debounceSetProps: _.debounce(function() {
      this.setProps();
    }, 500),
    async setProps() {
      var styles = this.editaItem.propsData.content.styles
      let shadow
      if (styles.shadow.hasShadow) {
        shadow = `${shadow_x} ${shadow_y} ${shadow_blur} ${shadow_color}`
      } else {
        shadow = 'none'
      }
      await this.$nextTick()
      var contentCard = document.getElementById("contentCard");
      if(!contentCard){
        return
      }
      var textarea = contentCard.querySelector(".cke_wysiwyg_div");
      textarea.style['color'] = `${styles.color_text}`
      textarea.style['font-size'] = `${styles[this.typeView].size}px`
      textarea.style['font-family'] = `${styles.font_family}`
      textarea.style['font-weight'] = `${styles.font_weight}`
      textarea.style['letter-spacing'] = `${styles.letter_space}px`
      textarea.style['line-height'] = `${styles.line_height}`
      textarea.style['text-transform'] = `${styles.transform}`
      textarea.style['text-align'] = `${styles[this.typeView].align}`
      textarea.style['text-shadow'] = `${shadow}`
      textarea.style['background'] = `${styles.background_color_text}`

      var styles2 = this.editaItem.propsData.title.styles
      let shadow2
      if (styles2.shadow.hasShadow) {
        shadow2 = `${shadow_x} ${shadow_y} ${shadow_blur} ${shadow_color}`
      } else {
        shadow2 = 'none'
      }
      await this.$nextTick()
      var titleCard = document.getElementById("titleCard");
      var textarea2 = titleCard.querySelector(".cke_wysiwyg_div");
      textarea2.style['color'] = `${styles2.color_text}`
      textarea2.style['font-size'] = `${styles2[this.typeView].size}px`
      textarea2.style['font-family'] = `${styles2.font_family}`
      textarea2.style['font-weight'] = `${styles2.font_weight}`
      textarea2.style['letter-spacing'] = `${styles2.letter_space}px`
      textarea2.style['line-height'] = `${styles2.line_height}`
      textarea2.style['text-transform'] = `${styles2.transform}`
      textarea2.style['text-align'] = `${styles2[this.typeView].align}`
      textarea2.style['text-shadow'] = `${shadow}`
      textarea2.style['background'] = `${styles2.background_color_text}`
    },
    handleClick(event) {
      const expandCkEditor = document.querySelector(".cke_toolgroup");
      if (expandCkEditor.contains(event.target)) {
        this.expandCkButton = !this.expandCkButton;
      } else {return}
    },
    nextIcon(type) {
      this.$emit("attIconView", type);
    }, 
  },
  mounted() {
    window.addEventListener("click", this.handleClick);
    this.debounceSetProps()
  },
  beforeDestroy() {
    window.removeEventListener("click", this.handleClick);
  },
  created() {
    if (this.typeView == "desktop") {
      this.global = true;
    }
  },
};
</script>

<style lang="scss">
  .card-type {
    border-radius: 7px;
    background-color: #f1f1f1;
    border: 1px dotted #c0c0c0;
    width: 100%;
    padding: 20px;
    margin-top: 1rem;
    transition: all 0.3s;
    cursor: pointer;
    &:hover {
      background-color: #e7e7e7;
    }
    .card-icon {
      transition: all 0.3s;
      color: #838383;
    }
    .card-title {
      transition: all 0.3s;
      color: #838383;
      font-size: 12px;
    }    
    .card-content {
      transition: all 0.3s;
      color: #838383;
      font-size: 8px;
    }
  }

  .card-2 {
    display: flex;
    gap: 15px;
    align-items: center;
  }

  .card-3 {
    display: flex;
    gap: 15px;
    align-items: center;
    .card-title {
      line-height: 1;
      margin: 0;
    } 
  }

  .card-selected {
    background-color: #d4d4d4 !important;
    * {
      color: #414040 !important;
    }
  }

  .link-disabled {
    filter: invert(50%);
  }

  .links-advanced {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 18px;
    height: 18px;
    cursor: pointer;
  }
</style>
