let propsTextoConteudo = {
  custom: {
    c_class: '',
    c_id: '',
  },
  alignment: {
    desktop: { justify_vertical: "center" },
    tablet: { justify_vertical: "center" },
    mobile: { justify_vertical: "center" },
  },
  card: {
    link_type: { "value": "externo", "text": "Externo" },
    link: '',
    blank: true,
    share_text: '',
    layout: 1,
    background: {
      type: "Cor sólida",
      color: "#EFEFEF",
      hover_color: "#E0E0E0",
      grad1: "#FFFFFF",
      grad2: "#FFFFFF",
      gradAngle: "0",
    },
    shadow: {
      hasShadow: false,
      x: 0,
      y: 5,
      blur: 6,
      color: "#0000005E",
    },
    border: {
      hasBorder: false,
      type: "",
      width: 1,
      color: "#000000",
      radius: {
        top: 10,
        right: 10,
        bottom: 10,
        left: 10,
      }
    },
  },
  title: {
    text: {
      content_text: '<p><span>T&iacute;tulo do Card</span></p>',
    },
    styles: {
      background_color_text: "#FFF0",
      color_text: "#000000",
      font_family: "Montserrat",
      font_weight: 400,
      letter_space: 1,
      line_height: 1.2,
      shadow: {
        shadow: "none",
        hasShadow: false,
        shadow_x: 0,
        shadow_y: 5,
        shadow_blur: 6,
        shadow_color: "#0000005E",
      },
      desktop: {
        align: 'center',
        size: 20,
        font_measure: 'px',
      },
      tablet: {
        align: 'center',
        size: 20,
        font_measure: 'px',
      },
      mobile: {
        align: 'center',
        size: 20,
        font_measure: 'px',
      },
    },
  },
  content: {
    text: {
      content_text: '<p><strong>Lorem ipsum</strong> dolor sit amet, consectetur adipiscing elit. Sed et blandit turpis, ac sagittis magna. Nunc eleifend sed metus eget interdum. Vivamus sit amet dolor nunc.&nbsp;</p>',
    },
    styles: {
      background_color_text: "#FFF0",
      color_text: "#000000",
      font_family: "Montserrat",
      font_weight: 400,
      letter_space: 1,
      line_height: 1.2,
      shadow: {
        shadow: "none",
        hasShadow: false,
        shadow_x: 0,
        shadow_y: 5,
        shadow_blur: 6,
        shadow_color: "#0000005E",
      },
      desktop: {
        align: 'center',
        size: 14,
        font_measure: 'px',
      },
      tablet: {
        align: 'center',
        size: 14,
        font_measure: 'px',
      },
      mobile: {
        align: 'center',
        size: 14,
        font_measure: 'px',
      },
    },
  },  
  icon: {
    type: 'Ícone',
    imageUrl: 'https://gdigital.s3.amazonaws.com/gdigital/1/G-digital.webp',
    iconSelected: 'check',
    color: '#000000',
    desktop: {
      align: 'center',
      iconSize: 35,
    },
    mobile: {
      align: 'center',
      iconSize: 30,
    },
    tablet: {
      align: 'center',
      iconSize: 30,
    },
  },
  spacing: {
    desktop: {
      margin_top: 10,
      margin_right: 10,
      margin_bottom: 10,
      margin_left: 10,
      padding_top: 20,
      padding_right: 20,
      padding_bottom: 20,
      padding_left: 20,
    },
    tablet: {
      margin_top: 10,
      margin_right: 10,
      margin_bottom: 10,
      margin_left: 10,
      padding_top: 20,
      padding_right: 20,
      padding_bottom: 20,
      padding_left: 20,
    },
    mobile: {
      margin_top: 10,
      margin_right: 10,
      margin_bottom: 10,
      margin_left: 10,
      padding_top: 20,
      padding_right: 20,
      padding_bottom: 20,
      padding_left: 20,
    },
  },
  spacingIcon: {
    desktop: {
      margin_top: 0,
      margin_right: 0,
      margin_bottom: 0,
      margin_left: 0,
      padding_top: 0,
      padding_right: 10,
      padding_bottom: 10,
      padding_left: 10,
    },
    tablet: {
      margin_top: 0,
      margin_right: 0,
      margin_bottom: 0,
      margin_left: 0,
      padding_top: 10,
      padding_right: 10,
      padding_bottom: 10,
      padding_left: 10,
    },
    mobile: {
      margin_top: 0,
      margin_right: 0,
      margin_bottom: 0,
      margin_left: 0,
      padding_top: 10,
      padding_right: 10,
      padding_bottom: 10,
      padding_left: 10,
    },
  },
  spacingTitle: {
    desktop: {
      margin_top: 0,
      margin_right: 0,
      margin_bottom: 0,
      margin_left: 0,
      padding_top: 0,
      padding_right: 0,
      padding_bottom: 0,
      padding_left: 0,
    },
    tablet: {
      margin_top: 0,
      margin_right: 0,
      margin_bottom: 0,
      margin_left: 0,
      padding_top: 0,
      padding_right: 0,
      padding_bottom: 0,
      padding_left: 0,
    },
    mobile: {
      margin_top: 0,
      margin_right: 0,
      margin_bottom: 0,
      margin_left: 0,
      padding_top: 0,
      padding_right: 0,
      padding_bottom: 0,
      padding_left: 0,
    },
  },
  spacingContent: {
    desktop: {
      margin_top: 0,
      margin_right: 0,
      margin_bottom: 0,
      margin_left: 0,
      padding_top: 0,
      padding_right: 0,
      padding_bottom: 0,
      padding_left: 0,
    },
    tablet: {
      margin_top: 0,
      margin_right: 0,
      margin_bottom: 0,
      margin_left: 0,
      padding_top: 0,
      padding_right: 0,
      padding_bottom: 0,
      padding_left: 0,
    },
    mobile: {
      margin_top: 0,
      margin_right: 0,
      margin_bottom: 0,
      margin_left: 0,
      padding_top: 0,
      padding_right: 0,
      padding_bottom: 0,
      padding_left: 0,
    },
  },
  visibility: {
    monitor: true,
    tablet: true,
    phone: true,
  },
};
export default propsTextoConteudo;
