
let propsMaps ={
    location:{
        localStorage: null,
    },
    custom: {
        c_class: '',
        c_id: '',
      },
      size: {
        desktop: { width: 450, height: 300, und_width: 'px', und_height: 'px' },
        tablet: { width: 450, height: 300, und_width: 'px', und_height: 'px' },
        mobile: { width: 450, height: 300, und_width: 'px', und_height: 'px' },
      },
      alignment: {
        desktop: { justify: "center", },
        tablet: { justify: "center", },
        mobile: { justify: "center", },
      },
      border: {
        hasBorder: false,
        border_thickness: 2 ,
        border_style: "━━━━━━━━━━━━━━━━━━━━━",
        border_color: "#000000",
        border_top: 0,
        border_right: 0,
        border_bottom: 0,
        border_left: 0,
        border_radius_top: 0,
        border_radius_right: 0,
        border_radius_bottom: 0,
        border_radius_left: 0,
      },
      spacing: {
        desktop: {
          padding_top: 20,
          padding_right: 20,
          padding_bottom: 20,
          padding_left: 20,
        },
        tablet: {
          padding_top: 20,
          padding_right: 20,
          padding_bottom: 20,
          padding_left: 20,
        },
        mobile: {
          padding_top: 20,
          padding_right: 20,
          padding_bottom: 20,
          padding_left: 20,
        },
      },
      visibility: {
        tablet: true,
        phone: true,
        monitor: true,
      },
}

export default propsMaps