<template>
  <div id="SideRightTabs">
    <b-card no-body>
      <b-tabs pills justified small style="width: 100%">
        <!-- Geral -->
        <b-tab
          title="Conteúdo"
          active
        >
          <div class="separador"></div>
          <div class="content">
            <div class="half">
              <label class="titulo">Links</label>
              <div class="contain-buttons" style="margin-top: -10px">
                <button
                  class="btn button-add-produto"
                  @click="addLink"
                ></button>
                <button
                  v-if="editaItem.propsData.links.length > 1"
                  class="btn button-remove-produto"
                  @click="removeItem"
                ></button>
                <button
                  v-else
                  class="btn button-remove-produto"
                  @click="mostrarMensagem"
                ></button>
              </div>
            </div>
            <div v-for="(link, idx) in editaItem.propsData.links" :key="idx">
              <div class="label-range">
                <label class="subtitulo" style="margin-top: 10px !important"
                  >Link {{ idx + 1 }}</label
                >
                <button
                  class="btn button-remove-produto"
                  @click="removeImgIndex(idx)"
                ></button>
              </div>
              <div style="padding: 0px 5px">
                <input
                  id="menu"
                  placeholder="Sobre nós"
                  v-model="editaItem.propsData.links[idx].name"
                />
                <div class="space"></div>
                <input
                  id="menu"
                  placeholder="https://seulink"
                  v-model="editaItem.propsData.links[idx].link"
                />
                <div class="space"></div>
                <div
                  class="half-menu"
                  v-if="editaItem.propsData.links[0].hasOwnProperty('blank')"
                >
                  <div class="child">
                    <multiselect
                      :options="optionsType"
                      v-model="editaItem.propsData.links[idx].type"
                      :searchable="false"
                      :show-labels="false"
                      :allow-empty="false"
                      placeholder="Tipo de link"
                      label="text"
                      track-by="value"
                    ></multiselect>
                  </div>
                  <div
                    class="child"
                    style="display: flex; width: 50%; transition: all 0.3s"
                  >
                    <label class="subtitulo" style="margin: 0 !important"
                      >Abrir em nova guia</label
                    >
                    <b-form-checkbox
                      style="
                        margin-top: -5px;
                        margin-left: auto;
                        margin-right: 0;
                        z-index: 0;
                      "
                      v-model="editaItem.propsData.links[idx].blank"
                      name="is_main"
                      size="lg"
                      switch
                    >
                    </b-form-checkbox>
                  </div>
                </div>
                <div class="space"></div>
              </div>
            </div>
          </div>
        </b-tab>

        <!-- Estilo -->
        <b-tab title="Estilo">
          <div>
            <div class="separador"></div>

            <!-- Cores -->
            <div class="content">
              <label class="titulo">Cores</label>
              <label class="sub">Fundo</label>
              <LvColorpicker
                id="LvColorpicker"
                @change="backgroundCreate()"
                v-model="editaItem.propsData.styles.background_header"
                :colors="palette"
              ></LvColorpicker>
              <div class="space"></div>
              <label class="sub">Links</label>
              <LvColorpicker
                id="LvColorpicker"
                v-model="editaItem.propsData.styles.color_itens"
                label="Cor dos Links"
                :colors="palette"
              ></LvColorpicker>
            </div>
            <div class="separador"></div>

            <div
              class="content max-multi"
              v-if="this.editaItem.propsData.styles.hasOwnProperty('font')"
            >
              <label class="titulo">Fonte</label>
              <multiselect
                v-model="editaItem.propsData.styles.font"
                :options="fontOptions"
                :searchable="true"
                :show-labels="false"
                placeholder="Fonte"
                :hide-selected="true"
                :allow-empty="false"
                @input="$emit('callCharge',$event)"
              ></multiselect>
            </div>
            <div
              class="separador"
              v-if="this.editaItem.propsData.styles.hasOwnProperty('font')"
            ></div>
          </div>
        </b-tab>

        <!-- Avançado -->
        <b-tab title="Avançado">
          <advancedBasic
            :visibility="editaItem.propsData.visibility" 
            :animation="editaItem.propsColumn" 
            :attributes="editaItem.propsData.custom" 
            :typeView="typeView"
            :global="global"
            @nextIcon="nextIcon"
            @update:visibility="editaItem.propsData.visibility = $event"
            @update:animation="editaItem.propsColumn = $event"
            @update:attributes="editaItem.propsData.custom = $event"
            hideSpacing
          ></advancedBasic>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import LvColorpicker from "lightvue/color-picker";
import Multiselect from "vue-multiselect";
import advancedBasic from "../EditComponents/advancedBasic.vue";

export default {
  props: ["editaItem", "typeView", "fontOptions", "palette"],

  components: {
    advancedBasic,
    LvColorpicker: LvColorpicker,
    Multiselect,
  },
  data() {
    return {
      global: false,
      optionsType: [
        { value: "externo", text: "Externo" },
        { value: "interno", text: "Na própria página" },
      ],
    };
  },
  methods: {
    nextIcon(type) {
      this.$emit("attIconView", type);
    },   
    mostrarMensagem() {
      this.$grToast.toast(`Necessário ao menos um link`, {
        title: "Editor",
        variant: "danger",
        autoHideDelay: 5000,
        appendToast: true,
      });
    },
    removeImgIndex(index) {
      this.editaItem.propsData.links.splice(index, 1);
    },
    addLink() {
      this.editaItem.propsData.links.push({
        blank: false,
        link: "https://greenn.com.br/",
        name: "Novo link",
        type: {
          text: "Externo",
          value: "externo",
        },
      });
    },
    removeItem() {
      this.editaItem.propsData.links.pop();
    },
  },
  created() {
    if (this.typeView == "desktop") {
      this.global = true;
    }
  },
};
</script>

<style lang="scss" scoped>
.half-menu {
  display: flex;
  max-width: 100% !important;
  gap: 10px;
  .child {
    width: 50% !important;
    align-items: center;
    justify-content: center;
  }
}
</style>
