<template>
  <div id="SideRightTabs">
    <b-tabs pills fill small style="width: 100%">
      <!-- Geral -->
      <b-tab title="Geral" active>
        <div class="b-tab-content">
          <div class="separador" />

          <!-- Conteúdo -->
          <div class="content">
            <div class="half">
              <label class="titulo">Ícones</label>
              <div class="contain-buttons" style="margin-top: -10px">
                <button class="btn button-add-produto" @click="addIcon"></button>
              </div>
            </div>
            <div v-for="(item, idx) in editaItem.propsData.icon" :key="idx">
              <div class="label-range">
                <label class="subtitulo">Ícone {{ idx + 1 }}</label>
                <button style="margin-top: -10px" class="btn button-remove-produto"
                  @click="editaItem.propsData.icon.length > 1 ? removeIconIndex(idx) : ''"></button>
              </div>
              <div style="padding: 0px 5px">
                <div class="select-image" v-if="editaItem.propsData.icon[idx].iconSelected.hasOwnProperty('name')">
                  <div @click="openModal('Modal-Icons-svg', idx)">
                    <span
                      class="material-icons icon-preview"
                      v-html="editaItem.propsData.icon[idx].iconSelected.code"
                      ></span
                    >
                  </div>
                  <div>
                    <input type="text" v-model="editaItem.propsData.icon[idx].iconSelected.name">
                    <span @click="openModal('Modal-Icons-svg', idx)">{{ editaItem.propsData.icon[idx].iconSelected ? 'Alterar ícone' : 'Selecionar ícone'}}</span>
                  </div>
                </div>
                <div class="select-image" v-else>
                  <div @click="openModal('Modal-Icons-svg', idx)">
                    <span
                      class="material-icons icon-preview"
                      >{{ editaItem.propsData.icon[idx].iconSelected }}</span
                    >
                  </div>
                  <div>
                    <input type="text" v-model="editaItem.propsData.icon[idx].iconSelected">
                    <span @click="openModal('Modal-Icons-svg', idx)">{{ editaItem.propsData.icon[idx].iconSelected ? 'Alterar ícone' : 'Selecionar ícone'}}</span>
                  </div>
                </div>
                <div class="space"></div>
                <input id="menu" placeholder="Link de redirecionamento (opcional)"
                  v-model="editaItem.propsData.icon[idx].link" />
                <div class="space"></div>
              </div>
            </div>
          </div>
          <div class="separador"></div>
        </div>
      </b-tab>

      <!-- Estilo -->
      <b-tab title="Estilo">
        <div class="b-tab-content">
          <div class="separador"></div>
          <div class="content">
            <label class="titulo">Cor do ícone</label>
            <div class="space"></div>
            <label class="subtitulo"> Cor padrão</label>
            <LvColorpicker id="LvColorpicker" v-model="editaItem.propsData.styles.iconColor" :colors="palette"></LvColorpicker>
            <div class="space"></div>
            <label class="subtitulo">Cor ao passar o mouse</label>
            <LvColorpicker id="LvColorpicker" v-model="editaItem.propsData.styles.iconHover" :colors="palette"></LvColorpicker>
          </div>
          <div class="separador"></div>
          <div class="content">
            <div class="label-range">
              <div class="label-icon">
                <label class="titulo">Tamanho</label>
                <div>
                  <img src="@/assets/editor/globe.svg" alt="monitor" style="filter: invert(50%) !important"
                    @click="nextIcon('desktop'), (global = !global)" v-if="global" />
                  <img src="@/assets/editor/monitor.svg" alt="monitor" style="filter: invert(50%) !important"
                    @click="nextIcon('tablet')" v-if="typeView === 'desktop' && !global" />
                  <img src="@/assets/editor/tablet.svg" alt="tablet" style="filter: invert(50%) !important"
                    @click="nextIcon('mobile')" v-if="typeView === 'tablet' && !global" />
                  <img src="@/assets/editor/phone.svg" alt="phone" style="filter: invert(50%) !important"
                    @click="nextIcon('desktop'), (global = !global)" v-if="typeView === 'mobile' && !global" />
                </div>
              </div>
              <input type="number" v-model="editaItem.propsData.styles[typeView].iconSize" @input="globalInput($event, 'iconSize')" />
            </div>
            <b-form-input v-model="editaItem.propsData.styles[typeView].iconSize" @input="globalInput($event, 'iconSize')"
              type="range" min="0" max="300" step="1"></b-form-input>
          </div>
          <div class="separador"></div>
          <div class="content">
            <div class="label-range">
              <div class="label-icon">
                <label class="titulo">Espaço entre ícones</label>
                <div>
                  <img src="@/assets/editor/globe.svg" alt="monitor" style="filter: invert(50%) !important"
                    @click="nextIcon('desktop'), (global = !global)" v-if="global" />
                  <img src="@/assets/editor/monitor.svg" alt="monitor" style="filter: invert(50%) !important"
                    @click="nextIcon('tablet')" v-if="typeView === 'desktop' && !global" />
                  <img src="@/assets/editor/tablet.svg" alt="tablet" style="filter: invert(50%) !important"
                    @click="nextIcon('mobile')" v-if="typeView === 'tablet' && !global" />
                  <img src="@/assets/editor/phone.svg" alt="phone" style="filter: invert(50%) !important"
                    @click="nextIcon('desktop'), (global = !global)" v-if="typeView === 'mobile' && !global" />
                </div>
              </div>
              <input type="number" v-model="editaItem.propsData.styles[typeView].gap" @input="globalInput($event, 'gap')" />
            </div>
            <b-form-input v-model="editaItem.propsData.styles[typeView].gap" @input="globalInput($event, 'gap')"
              type="range" min="0" max="100" step="1"></b-form-input>
          </div>
          <div class="separador"></div>
          <div class="content">
            <div class="label-icon">
              <label class="titulo">Posição</label>
              <div>
                <img src="@/assets/editor/globe.svg" alt="monitor" style="filter: invert(50%) !important"
                  @click="nextIcon('desktop'), (global = !global)" v-if="global" />
                <img src="@/assets/editor/monitor.svg" alt="monitor" style="filter: invert(50%) !important"
                  @click="nextIcon('tablet')" v-if="typeView === 'desktop' && !global" />
                <img src="@/assets/editor/tablet.svg" alt="tablet" style="filter: invert(50%) !important"
                  @click="nextIcon('mobile')" v-if="typeView === 'tablet' && !global" />
                <img src="@/assets/editor/phone.svg" alt="phone" style="filter: invert(50%) !important"
                  @click="nextIcon('desktop'), (global = !global)" v-if="typeView === 'mobile' && !global" />
              </div>
            </div>
            <div style="display: flex; width: 100%; gap: 10px">
              <div style="display: flex; width: 50%; transition: all 0.3s">
                <div class="select-align">
                  <button :class="{ 'selected-align': editaItem.propsData.alignment[typeView].justify == 'flex-start' }"
                  @click="globalJustify('flex-start', 'justify')"><img
                      src="@/assets/editor/align-left-new.svg" /></button>
                  <button :class="{ 'selected-align': editaItem.propsData.alignment[typeView].justify == 'center' }"
                  @click="globalJustify('center', 'justify')"><img
                      src="@/assets/editor/align-center-horizontal.svg" /></button>
                  <button :class="{ 'selected-align': editaItem.propsData.alignment[typeView].justify == 'flex-end' }"
                  @click="globalJustify('flex-end', 'justify')"><img
                      src="@/assets/editor/align-right-new.svg" /></button>
                </div>
              </div>

              <div style="display: flex; width: 50%; transition: all 0.3s">
                <div class="select-align">
                  <button :class="{ 'selected-align': editaItem.propsData.alignment[typeView].justify_vertical == 'start' }"
                    @click="globalJustify('start', 'justify_vertical')"><img
                      src="@/assets/editor/align-top.svg" /></button>
                  <button :class="{ 'selected-align': editaItem.propsData.alignment[typeView].justify_vertical == 'center' }"
                    @click="globalJustify('center', 'justify_vertical')"><img
                      src="@/assets/editor/align-center-vertical.svg" /></button>
                  <button :class="{ 'selected-align': editaItem.propsData.alignment[typeView].justify_vertical == 'end' }"
                    @click="globalJustify('end', 'justify_vertical')"><img
                      src="@/assets/editor/align-bottom.svg" /></button>
                </div>
              </div>
            </div>
            <div class="space"></div>
            <div>
              <label for="form-altura" class="sub">Altura mínima da coluna</label>
              <b-form-group id="form-altura" class="und_form">
                <div class="und_form">
                  <b-form-input
                    id="form-altura"
                    v-model="editaItem.propsColumn.height[typeView].height"
                    @focus="openDropdownSlider('editaItem.propsColumn.height[typeView].height')" 
                    type="number"
                    min="0"
                  ></b-form-input>
                  <span
                    @click="next_und_height"
                    class="und text"
                    v-b-tooltip.hover
                    title="Altura em pixel"
                    >{{editaItem.propsColumn.height[typeView].height_und}}</span
                  >
                </div>
              </b-form-group>
            </div>
          </div>
          <div class="separador"></div>
        </div>
        -
      </b-tab>

      <!-- Avançado -->
      <b-tab title="Avançado">
        <advancedBasic
          :spacing="editaItem.propsData.spacing" 
          :visibility="editaItem.propsData.visibility" 
          :animation="editaItem.propsColumn" 
          :attributes="editaItem.propsData.custom" 
          :typeView="typeView"
          :global="global"
          @toggleGlobal="global = !global"
          @nextIcon="nextIcon"
          @update:spacing="editaItem.propsData.spacing = $event"
          @update:visibility="editaItem.propsData.visibility = $event"
          @update:animation="editaItem.propsColumn = $event"
          @update:attributes="editaItem.propsData.custom = $event"
        ></advancedBasic>
      </b-tab>
    </b-tabs>

    <ModalIcons @iconSelect="iconSelect"></ModalIcons>
    <dropdownSlider
      ref="dropdownSlider"   
      @updateValue="updateValue"
    /> 
  </div>
</template>

<script>
import dropdownSliderMixin from "@/mixins/dropdownSliderMixin.js";
import advancedBasic from "../EditComponents/advancedBasic.vue";
import LvColorpicker from "lightvue/color-picker";
import Multiselect from "vue-multiselect";
import ModalIcons from "@/components/Editor/ModalSvg";

import { getGoogleFonts } from "@/components/Editor/GoogleFonts.js";

export default {
  mixins: [dropdownSliderMixin],
  props: ["editaItem", "typeView", "palette"],
  components: {
    advancedBasic,
    LvColorpicker: LvColorpicker,
    Multiselect,
    ModalIcons,
  },
  data() {
    return {
      global: false,
      currentIndex: 0,
    };
  },
  async mounted() {
    this.fontOptions = await getGoogleFonts();
  },
  watch: {},
  methods: {
    next_und_height() {
      const props = this.editaItem.propsColumn.height;
      props[this.typeView].height_und = props[this.typeView].height_und == "px" ? "vh" : props[this.typeView].height_und == "vh" ? "vw" : "px";
      if (this.global && this.typeView == 'desktop') {
        props.tablet.height_und = props.desktop.height_und
        props.mobile.height_und = props.desktop.height_und
      }
    },
    globalJustify(value, place) {
      let props = this.editaItem.propsData.alignment
      props[this.typeView][place] = value
      if (this.global && this.typeView == 'desktop') {
        props[this.typeView][place] = value
        props[this.typeView][place] = value
      }
    },
    removeIconIndex(index) {
      this.editaItem.propsData.icon.splice(index, 1);
    },
    addIcon() {
      this.editaItem.propsData.icon.push({
        iconSelected: "check",
        link: "",
      });
    },
    removeIcon() {
      this.editaItem.propsData.icon.pop();
    },
    iconSelect(item) {
      this.editaItem.propsData.icon[this.currentIndex].iconSelected = item;
    },
    openModal(data, idx) {
      this.$bvModal.show(data);
      this.currentIndex = idx
    },
    nextIcon(type) {
      this.$emit("attIconView", type);
    },
    globalInput(px, place) {
      if (this.global && this.typeView == 'desktop') {
        var props = this.editaItem.propsData.styles;
        props.tablet[place] = px;
        props.mobile[place] = px;
      }
    },
  },
  created() {
    if (this.typeView == "desktop") {
      this.global = true;
    }
  },
};
</script>
