let propsCarrousel = {
  custom: {
    c_class: '',
    c_id: '',
  },
  slides: [
    {
      imagem:
        "https://gdigital.s3.amazonaws.com/gdigital/1/imagem-vazia2.webp",
    },
    {
      imagem:
        "https://gdigital.s3.amazonaws.com/gdigital/1/imagem-vazia2.webp",
    },
    {
      imagem:
        "https://gdigital.s3.amazonaws.com/gdigital/1/imagem-vazia2.webp",
    },
  ],
  styles: {
    color_btns: "#FFFFFF",
    colorFocus: false,
    timer: 4,
  },
  size: {
    desktop: { smallerWidth: 30, smallerHeight: 50, und_width: 'px', width: 200, height: 300, und_width: 'px', und_height: 'px', colorIndex: 0 },
    tablet: { smallerWidth: 30, smallerHeight: 50, width: 200, height: 300, und_width: 'px', und_height: 'px', colorIndex: 0 },
    mobile: { smallerWidth: 30, smallerHeight: 50, width: 200, height: 300, und_width: 'px', und_height: 'px', colorIndex: 0 },
  },
  spacing: {
    desktop: {
      margin_top: 0,
      margin_right: 0,
      margin_bottom: 0,
      margin_left: 0,
      padding_top: 0,
      padding_right: 0,
      padding_bottom: 0,
      padding_left: 0,
    },
    tablet: {
      margin_top: 0,
      margin_right: 0,
      margin_bottom: 0,
      margin_left: 0,
      padding_top: 0,
      padding_right: 0,
      padding_bottom: 0,
      padding_left: 0,
    },
    mobile: {
      margin_top: 0,
      margin_right: 0,
      margin_bottom: 0,
      margin_left: 0,
      padding_top: 0,
      padding_right: 0,
      padding_bottom: 0,
      padding_left: 0,
    },
  },
  visibility: {
    tablet: true,
    phone: true,
    monitor: true,
  },
};
export default propsCarrousel;
