let propsPopup = {
  alignment: {
    desktop: { justify: "center", justify_vertical: "center" },
    tablet: { justify: "center", justify_vertical: "center" },
    mobile: { justify: "center", justify_vertical: "center" },
  },
  button_size: {
    desktop: { font_size: 14 },
    tablet: { font_size: 14 },
    mobile: { font_size: 14 },
    textFontMeasure: "px",
  },
  button_sizeModal: {
    desktop: { font_size: 14 },
    tablet: { font_size: 14 },
    mobile: { font_size: 14 },
    textFontMeasure: "px",
  },
  button: {
    content_button: "Clique aqui",
    href_button: "",
    type: { value: "externo", text: "Externo" },
    blank: false,
    share_text: "",
    viewPopup: false,
  },
  custom: {
    c_class: '',
    c_id: '',
  },
  shadow: {
    shadow: "none",
    hasShadow: false,
    shadow_x: 0,
    shadow_y: 5,
    shadow_blur: 6,
    shadow_color: "#0000005E",
  },
  border: {
    border: "none",
    hasBorder: false,
    border_radius_top: 10,
    border_radius_right: 10,
    border_radius_bottom: 10,
    border_radius_left: 10,
    border_style: "━━━━━━━━━━━━━━━━━━",
    border_thickness: "2",
    border_color: "#ffffff",
    borderLinked: true,
    borderModal:{
      border: "none",
      hasBorder: false,
      border_radius_top: 10,
      border_radius_right: 10,
      border_radius_bottom: 10,
      border_radius_left: 10,
      border_style: "━━━━━━━━━━━━━━━━━━",
      border_thickness: "2",
      border_color: "#ffffff",
      borderLinked: true,
    },
  },
  styles: {
    font_family: "Montserrat",
    color_button: "#ffffff",
    font_weight: 600,
    letter_space: "0.2",
    text_type: "none",
    back_type: "color",
    back_content: "#4ea934",
    hover_color: '#05855F',
    hover_font: '#ffffff',
    hover_border: '#ffffff',
    grad_angle: "180",
    grad_color1: "#4ea934",
    grad_color2: "#05855F",
    //Button
    justify: "center",
    font_weight: 600,
    textFontMeasure: "px",
    letter_space: "0.2",
    text_type: "none",
    back_type: "color",
    back_content: "#4ea934",
    back_selected: "Cor sólida",
    hasBorder: false,
    border: "none",
    grad_angle: "180",
    grad_color1: "#021810",
    grad_color2: "#05855F",
    border_style: "━━━━━━━━━━━━━━━━━━",
    border_thickness: "2.75",
    border_color: "#ffffff",
    hasShadow: false,
    border_button_radius_top: 5,
    border_button_radius_right: 5,
    border_button_radius_bottom: 5,
    border_button_radius_left: 5,
    shadow: "none",
    shadow_x: 0,
    shadow_y: 5,
    shadow_blur: 6,
    shadow_color: "#4ea93410",
    monitor: true,
    borderLinked: true,
    height_button: 45,
    width_button: 100,
    widthMeasure: "%",
    color_button: "#FFFFFF",
    bg_button: "#4ea934",
    font_family: "Montserrat",
    bg_container: "#00000000",
    icon_color: "#ffffff",
    modalButton:{
      justify: "center",
      font_weight: 600,
      textFontMeasure: "px",
      letter_space: "0.2",
      text_type: "none",
      back_type: "color",
      back_content: "#4ea934",
      back_selected: "Cor sólida",
      hasBorder: false,
      border: "none",
      grad_angle: "180",
      grad_color1: "#021810",
      grad_color2: "#05855F",
      border_style: "━━━━━━━━━━━━━━━━━━",
      border_thickness: "2.75",
      border_color: "#ffffff",
      hasShadow: false,
      border_button_radius_top: 5,
      border_button_radius_right: 5,
      border_button_radius_bottom: 5,
      border_button_radius_left: 5,
      shadow: "none",
      shadow_x: 0,
      shadow_y: 5,
      shadow_blur: 6,
      shadow_color: "#4ea93410",
      monitor: true,
      borderLinked: true,
      height_button: 45,
      width_button: 100,
      widthMeasure: "%",
      color_button: "#FFFFFF",
      button_modal: "#FFFFFF",
      bg_button: "#4ea934",
      font_family: "Montserrat",
      bg_container: "#00000000",
      icon_color: "#ffffff",
      hover_color: '#05855F',
      hover_font: '#ffffff',
      hover_border: '#ffffff',
    },
  },
  stylesForm:{
    form_border_size: 1,
    form_border_style: "solid",
    form_border_color: "#E2E2E2",
    input_radius: 5,
    input_width: 100,
    input_back: "#ffffff",
    input_color: "#000000",
    input_border_color: "#ededf0",
    back_form_content: "#ffffff",
    placeholder_color: "#0000000",
  },
  icon: {
    size: {
      desktop: { font_size: 25, margin: 5 },
      tablet: { font_size: 25, margin: 5 },
      mobile: { font_size: 25, margin: 5 },
    },
    color: "#ffffff",
    svg: "",
    isRight: false,
  },
  icon_modal: {
    size: {
      desktop: { font_size: 25, margin: 5 },
      tablet: { font_size: 25, margin: 5 },
      mobile: { font_size: 25, margin: 5 },
    },
    color: "#ffffff",
    svg: "",
    isRight: false,
  },
  size: {
    desktop: { width: 125, height: 45, und_width: 'px', und_height: 'px', isAuto: true},
    tablet: { width: 125, height: 45, und_width: 'px', und_height: 'px', isAuto: true },
    mobile: { width: 125, height: 45, und_width: 'px', und_height: 'px', isAuto: true },
  },
  spacing: {
    desktop: {
      margin_top: 0,
      margin_right: 0,
      margin_bottom: 0,
      margin_left: 0,
      padding_top: 15,
      padding_right: 20,
      padding_bottom: 15,
      padding_left: 20,
    },
    tablet: {
      margin_top: 0,
      margin_right: 0,
      margin_bottom: 0,
      margin_left: 0,
      padding_top: 15,
      padding_right: 20,
      padding_bottom: 15,
      padding_left: 20,
    },
    mobile: {
      margin_top: 0,
      margin_right: 0,
      margin_bottom: 0,
      margin_left: 0,
      padding_top: 15,
      padding_right: 20,
      padding_bottom: 15,
      padding_left: 20,
    },
  },
  sizeForm: {
    width_form:330,
    desktop: {
      top:230,
      height_form: 35,
      width_max:700,
      font_size_button: 14,
      icon_size: 25,
    },
    tablet: {
        top:130,
        height_form: 40,
        width_max:600,
        font_size_button: 14,
        icon_size: 25,
    },
    mobile: {
        top:130,
        height_form: 40,
        width_max:360,
        font_size_button: 14,
        icon_size: 25,
    },
  },
  visibility: {
      monitor: true,
      tablet: true,
      phone: true,
  },
  spacingBModal: {
    desktop: {
      margin_top: 0,
      margin_right: 0,
      margin_bottom: 0,
      margin_left: 0,
      padding_top: 15,
      padding_right: 20,
      padding_bottom: 15,
      padding_left: 20,
    },
    tablet: {
      margin_top: 0,
      margin_right: 0,
      margin_bottom: 0,
      margin_left: 0,
      padding_top: 15,
      padding_right: 20,
      padding_bottom: 15,
      padding_left: 20,
    },
    mobile: {
      margin_top: 0,
      margin_right: 0,
      margin_bottom: 0,
      margin_left: 0,
      padding_top: 15,
      padding_right: 20,
      padding_bottom: 15,
      padding_left: 20,
    },
  },
  form_id: 0,
  tenant_id: 0,
  page_id: 0,
  icon_selected: "",
  icon_selected2: "",
  form: { fields: null, id: null, title: "Selecione um formulário" },
  header: "Insira seu melhor e-mail",
  footer: "Odiamos spam assim como você",
  form_linked: true,
  opcoes_agradecimento: [
    { value: "thank_you_page", text: "Página" },
    { value: "popup", text: "Apenas confirmação" },
    { value: "whatsapp", text: "Mensagem no whatsapp" }
  ],
  opcao_agradecimento: { value: "thank_you_page", text: "Página" },
  thank_you_page: {},
  success_mensage: "Obrigado por se cadastrar",
  error_mensage: "Por favor, confira seus dados",
  phone: "",
  whatsapp_mensage: "Me cadastrei no formulário x",
  footer_link: "#",
  fields: [
    {
      id: 54,
      form_id: 51,
      name_field: "text",
      form_field: `<li class="prev-holder" style=""><div class="fb-text form-group field-nome-preview"><label class="fb-text-label">Nome</label><span class="required-asterisk" style="display: none;"> </span><input type="text" style="teste: 2px;" placeholder="Seu nome" class="form-control" name="nome-preview" mascara="form-control" id="nome-preview"></div></li>`,
      json: {
          name: "nome",
          type: "text",
          label: "Nome",
          mascara: "form-control",  
          className: "form-control"
      },
      order: 0,
      created_at: "2023-03-26T03:32:54.000000Z",
      updated_at: "2023-03-26T03:32:54.000000Z"
    },
    {
      id: 55,
      form_id: 51,
      name_field: "text",
      form_field: `<li class="prev-holder" style=""><div class="fb-text form-group field-nome-preview"><label class="fb-text-label">Email</label><span class="required-asterisk" style="display: none;"> </span><input type="text" placeholder="Seu e-mail" class="form-control" name="email-preview" mascara="form-control" id="email-preview"></div></li>`,
      json: {
          name: "nome",
          type: "text",
          label: "Nome",
          mascara: "form-control",
          className: "form-control"
      },
      order: 0,
      created_at: "2023-03-26T03:32:54.000000Z",
      updated_at: "2023-03-26T03:32:54.000000Z"
    },
    {
      id: 56,
      form_id: 51,
      name_field: "text",
      form_field: `<li class="prev-holder" style=""><div class="fb-text form-group field-nome-preview"><label class="fb-text-label">Telefone</label><span class="required-asterisk" style="display: none;"> </span><input type="text" placeholder="Seu Telefone" class="form-control" name="nome-preview" mascara="form-control" id="nome-preview"></div></li>`,
      json: {
          name: "nome",
          type: "text",
          label: "Nome",
          mascara: "form-control",
          className: "form-control"
      },
      order: 0,
      created_at: "2023-03-26T03:32:54.000000Z",
      updated_at: "2023-03-26T03:32:54.000000Z"
    },
  ],
  content_button: "Cadastre-se",
};
export default propsPopup;
