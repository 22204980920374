<template>
  <div>
    <!-- CSS -->
    <div style="padding: 30px">
      <label class="titulo">CSS</label>
      <textarea
        style="height: auto !important; font-family: monospace"
        v-model="css"
        rows="15"
        :class="{ notValid: errorCss }"
      ></textarea>
      <span v-if="errorCss" class="message-error"
        >Aviso: uso de tags não é permitida neste campo de texto.</span
      >
    </div>
    <div class="separador"></div>
    <!-- JS -->
    <div style="padding: 30px">
      <label class="titulo">JavaScript</label>
      <textarea
        style="height: auto !important; font-family: monospace"
        v-model="js"
        rows="15"
        :class="{ notValid: errorJs }"
      ></textarea>
      <span v-if="errorJs" class="message-error"
        >Aviso: uso de tags não é permitida neste campo de texto.</span
      >
    </div>
  </div>
</template>

<script>
import _ from "lodash";

export default {
  props: ["dados"],
  data() {
    return {
      errorJs: false,
      errorCss: false,
      css: "",
      js: "",
    };
  },
  created() {
    this.init();
  },
  methods: {
    debounceCode: _.debounce(function (value, type) {
      if (value || value == '') {
        this.codeGenerator(value, type);
      }
    }, 100),

    codeGenerator(event, type) {
      if (event || event =='') {
        // Valida se foi inserido tags no textarea -->>
        var css = this.css
          .replace(/<\/?style\s*.*?>|<\/?style>/g, "")
          .replace(/<\/?script\s*.*?>|<\/?script>/g, "");
        var js = this.js
          .replace(/<\/?script\s*.*?>|<\/?script>/g, "")
          .replace(/<\/?style\s*.*?>|<\/?style>/g, "");

        if (type == "css") {
          var originalEvent = this.css;
          if (css == originalEvent) {
            this.errorCss = false;
            this.$emit("sendCss", css);
          } else {
            this.errorCss = true;
          }
        } else if (type == "js") {
          var originalEvent = this.js;
          if (js == originalEvent) {
            this.errorJs = false;
            this.$emit("sendJs", js);
          } else {
            this.errorJs = true;
          }
        }
      }
    },
    init() {
      if (this.dados.metas) {
        if (this.dados.metas.find((x) => x.meta_key == "dynamic_css")) {
          this.css = this.dados.metas.find(
            (x) => x.meta_key == "dynamic_css"
          ).meta_value;
          this.codeGenerator(this.css, "css");
        } else {
          this.css = "";
        }
        if (this.dados.metas.find((x) => x.meta_key == "dynamic_js")) {
          this.js = this.dados.metas.find(
            (x) => x.meta_key == "dynamic_js"
          ).meta_value;
          this.codeGenerator(this.css, "js");
        } else {
          this.js = "";
        }
        this.codeGenerator();
      }
    },
  },
  watch:{
    js:{
      handler(newValue){
        this.debounceCode(newValue, 'js')
      }
    },
    css:{
      handler(newValue){
        this.debounceCode(newValue, 'css')
      },
    }
  }
};
</script>

<style lang="scss" scoped>
.message-error {
  color: var(--red);
  font-size: 14px;
  font-weight: 600;
}
.notValid {
  border-color: var(--red-dark) !important;
  color: var(--gray04) !important;
}
textarea {
  height: auto !important;
}
.content {
  padding: 30px 40px;
}
</style>
