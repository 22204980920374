let propsSeparador = {
  custom: {
    c_class: '',
    c_id: '',
  },
  alignment: {
    desktop: { justify: "center", justify_vertical: "center" },
    tablet: { justify: "center", justify_vertical: "center" },
    mobile: { justify: "center", justify_vertical: "center" },
  },
  styles: {
    border_style: { value: "solid", text: "━━━━━━━━━━━━━━━━━━━━━" },
    border_size: 1,
    border_color: "#000000",
    deg: 180,
    marginLinked: true,
  },
  size: {
    desktop: { width: 50 },
    tablet: { width: 50 },
    mobile: { width: 50 },
  },
  spacing: {
    desktop: {
      margin_top: 20,
      margin_right: 0,
      margin_bottom: 20,
      margin_left: 0,
      padding_top: 0,
      padding_right: 0,
      padding_bottom: 0,
      padding_left: 0,
    },
    tablet: {
      margin_top: 20,
      margin_right: 0,
      margin_bottom: 20,
      margin_left: 0,
      padding_top: 0,
      padding_right: 0,
      padding_bottom: 0,
      padding_left: 0,
    },
    mobile: {
      margin_top: 20,
      margin_right: 0,
      margin_bottom: 20,
      margin_left: 0,
      padding_top: 0,
      padding_right: 0,
      padding_bottom: 0,
      padding_left: 0,
    },
  },
  visibility: {
    tablet: true,
    phone: true,
    monitor: true,
  },
};

export default propsSeparador;
