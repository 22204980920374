let propsMenuHorizontal = {
  alignment: {
    desktop: { justify: "center", justify_vertical: "center" },
    tablet: { justify: "center", justify_vertical: "center" },
    mobile: { justify: "center", justify_vertical: "center" },
  },
  custom: {
    c_class: '',
    c_id: '',
  },
  styles: {
    background_header: "#000",
    color_itens: "#FFFFFF",
    space_itens: 10,
    link: '',
  },
  image: {
    img_logo:
      "https://gdigital.s3.amazonaws.com/gdigital/1/imagem-vazia%20%281%29.webp",
  },
  links: [
    { value: "AUTOMAÇÃO WHATS" },
    { value: "E-MAIL MARKETING" },
    { value: "TESTE A/B" },
    { value: "CRIADOR DE PÁGINAS" },
    { value: "CRM" },
    { value: "PIPELINE" },
    { value: "BLOG" },
    { value: "FUNIL DE VENDAS" },
  ],
  size: {
    desktop: { width: 320, height: 200 },
    tablet: { width: 320, height: 200 },
    mobile: { width: 320, height: 200 },
  },
  border_radius: {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
  border_radius_item: {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
  spacing: {
    desktop: {
      margin_top: 0,
      margin_right: 0,
      margin_bottom: 0,
      margin_left: 0,
      padding_top: 0,
      padding_right: 0,
      padding_bottom: 0,
      padding_left: 0,
    },
    tablet: {
      margin_top: 0,
      margin_right: 0,
      margin_bottom: 0,
      margin_left: 0,
      padding_top: 0,
      padding_right: 0,
      padding_bottom: 0,
      padding_left: 0,
    },
    mobile: {
      margin_top: 0,
      margin_right: 0,
      margin_bottom: 0,
      margin_left: 0,
      padding_top: 0,
      padding_right: 0,
      padding_bottom: 0,
      padding_left: 0,
    },
  },
  visibility: {
    monitor: true,
    tablet: true,
    phone: true,
  },
};

export default propsMenuHorizontal;
