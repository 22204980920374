<template>
  <div class="dropdown-slider" ref="dropdownSlider" :style="{top:`${positionTop}px`}" v-if="isDropdownVisible" @mouseleave="closeRange">
    <div class="arrow" :style="{left:`${positionLeft}px`}"></div>
    <div class="slider-content">  
      <input 
        id="range-slider"
        type="range"
        :min="rangeMin"
        :max="rangeMax"
        :step="rangeStep"
        v-model="localValue"
        @input="rangeChangeValue"
      />     
    </div>
  </div>
</template>

<script>
export default {  
  data() {
    return { 
      positionTop: 10,
      positionLeft: 10,   
      rangeMax: 100,
      rangeMin: 0,   
      rangeStep: 1,   
      rangeValue: 0, 
      localValue: 0,
      isDropdownVisible: false, 
      target: '',
      tab: null,
    };
  },
  methods: {  
    openRangeLocal(){
      this.target = document.activeElement
      this.localValue = this.target.value
      this.isDropdownVisible = true;
      this.setConfigs()
      this.getPosition()
      this.setListeners()
    },
    setConfigs() {
      if (!this.target || !(this.target instanceof HTMLElement)) {
        this.rangeMin = 0;
        this.rangeMax = 100;
        this.rangeStep = 1;
        return;
      }
      this.rangeMin = parseFloat(this.target.getAttribute('min')) || 0;
      this.rangeMax = parseFloat(this.target.getAttribute('max')) || 100;
      this.rangeStep = parseFloat(this.target.getAttribute('step')) || 1;
    },
    closeRange() {      
      this.isDropdownVisible = false;  
      this.removeListener();     
    },      
    rangeChangeValue(event) {
      const newValue = Number(event.target.value); 
      this.$emit('updateValue', newValue); 
    },
    async getPosition() {  
      await this.$nextTick()
      const baseElement = document.querySelector('#SideRightTabs .tab-content');
      const baseRect = baseElement.getBoundingClientRect();
      const targetRect = this.target.getBoundingClientRect();
      const dropdownSliderParent = this.$refs.dropdownSlider.parentElement;
      let addTop = 65
      if (dropdownSliderParent) {
        const hasDirectContentChild = Array.from(dropdownSliderParent.children).some(
          child => child.classList.contains('content')
        );
        if (hasDirectContentChild) {
          addTop = 5 + baseElement.scrollTop
        }
      }
      addTop = addTop + this.target.getBoundingClientRect().height;
      console.log(addTop);
      this.positionTop = targetRect.top - baseRect.top  + addTop;
      this.positionLeft = targetRect.left - baseRect.left - 20;
    },
    inputCallback(event) {
      this.localValue = event.target.value;
      this.$emit('updateValue', this.localValue); 
    },     
    setListeners() {
      this.target.addEventListener('input', this.inputCallback); 
      this.tab = null
      this.tab = document.querySelector('#SideRightTabs .tab-content')
      if (this.tab) {
        this.tab.addEventListener('scroll', this.closeRange);
      }
    },
    removeListener() {
      if (this.target && this.inputCallback) {
        this.target.removeEventListener('input', this.inputCallback);
      }
      if (this.tab) {
        this.tab.removeEventListener('scroll', this.closeRange);
      }
    }
  }, 
};
</script>

<style scoped>
.dropdown-slider {
  position: absolute;    
  left: 50%;
  transform: translateX(-50%);
  z-index: 9999; 
  width: 50%; 
  width: 302px;
}
.slider-content {
  margin-top: 15px;
}
.dropdown {
  margin-top: 16px;
  z-index: 3;
  width: 302px;
  height: 45px; 
  background-color: white;
  padding: 0 10px;
  border-radius: 10px;   
}
#range-slider,
#range-slider{
  border: 1px solid var(--greenn) !important; 
  border-color: var(--greenn) !important; 
}
#range-slider:hover,
#range-slider:focus{
  border: 1px solid var(--greenn) !important; 
  border-color: var(--greenn) !important; 
}
.slider-content::-webkit-slider-thumb {
  width: 16px; 
  height: 16px;
  background: var(--greenn); 
  cursor: pointer; 
}
.arrow{
  position: absolute;  
  width: 27px;
  height: 16px;
  z-index: 4; 
}
.arrow::before,
.arrow::after {
  clip-path: polygon(100% 100%, 50% 0%, 0% 100%);
  content: '';
  position: absolute; 
}
.arrow::before {
  width: 27px;
  height: 16px;
  background-color:var(--greenn) !important; 
  z-index: 5;
}
.arrow::after {
  width: 27px;
  height: 16px;
  background-color: white;
  margin-top: 2px;
  z-index: 10;
}
</style>