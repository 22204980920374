<template>
  <div id="SideRightTabs">
    <b-card no-body>
      <b-tabs pills justified small style="width: 100%">
        <b-tab title="Estilo">
          <div>
            <!-- COLUMNS -->
            <div :id="`button-columns-${indexColumn}`" role="tabpanel">
              <!-- TAMANHO -->
              <div class="separador"></div>
              <div class="content">
                <div class="label-icon">
                  <label class="titulo">Tamanho</label>
                  <img
                    src="@/assets/editor/globe.svg"
                    alt="monitor"
                    style="filter: invert(50%) !important"
                    @click="nextIcon('desktop'), (global = !global)"
                    v-if="global"
                  />
                  <img
                    src="@/assets/editor/monitor.svg"
                    alt="monitor"
                    style="filter: invert(50%) !important"
                    @click="nextIcon('tablet')"
                    v-if="typeView === 'desktop' && !global"
                  />
                  <img
                    src="@/assets/editor/tablet.svg"
                    alt="tablet"
                    style="filter: invert(50%) !important"
                    @click="nextIcon('mobile')"
                    v-if="typeView === 'tablet' && !global"
                  />
                  <img
                    src="@/assets/editor/phone.svg"
                    alt="phone"
                    style="filter: invert(50%) !important"
                    @click="nextIcon('desktop'), (global = !global)"
                    v-if="typeView === 'mobile' && !global"
                  />
                </div>

                <div class="grid-input mb-2">
                  <div>
                    <label
                      :class="{
                        labelOff:
                          typeView != 'desktop' ||
                          !editaItem.coluns[indexColumn][0].propsColumn
                            .visibility.monitor,
                      }"
                      for="largura"
                      class="sub"
                      >Largura</label
                    >
                    <b-form-group id="form-largura" class="und_form">
                      <div class="und_form">
                        <b-form-input
                          :disabled="typeView != 'desktop'"
                          id="form-largura"                     
                          @focus="openDropdownSlider('colunWidth')"
                          v-model="colunWidth"
                          type="number"
                          max="100"
                          min="5"
                        ></b-form-input>
                        <span
                          class="und"
                          v-b-tooltip.hover
                          title="Largura em porcentagem"
                          >%</span
                        >
                      </div>
                    </b-form-group>
                  </div>
                  <div>
                    <label for="form-altura" class="sub">Altura mínima</label>
                    <b-form-group id="form-altura" class="und_form">
                      <div class="und_form">
                        <b-form-input
                          id="form-altura"
                          v-model="editaItem.coluns[indexColumn][indexComponent].propsColumn.height[typeView].height"
                          @focus="openDropdownSlider(`editaItem.coluns.${indexColumn}.${indexComponent}.propsColumn.height[typeView].height'`)"
                          type="number"
                          min="0"
                        ></b-form-input>
                        <span
                          @click="next_und_height"
                          class="und text"
                          v-b-tooltip.hover
                          title="Altura em pixel"
                          >{{
                            editaItem.coluns[indexColumn][indexComponent]
                              .propsColumn.height[typeView].height_und
                          }}</span
                        >
                      </div>
                    </b-form-group>
                  </div>
                </div>
                <div
                  v-if="
                    editaItem.coluns[
                      indexColumn
                    ][0].propsColumn.hasOwnProperty('alignment')
                  "
                >
                  <label class="sub">Alinhamento vertical da coluna</label>
                  <div class="select-align">
                    <button
                      :class="{
                        'selected-align':
                          editaItem.coluns[indexColumn][0].propsColumn
                            .alignment == 'start',
                      }"
                      @click="
                        editaItem.coluns[
                          indexColumn
                        ][0].propsColumn.alignment = 'start'
                      "
                    >
                      <img src="@/assets/editor/align-top.svg" />
                    </button>
                    <button
                      :class="{
                        'selected-align':
                          editaItem.coluns[indexColumn][0].propsColumn
                            .alignment == 'center',
                      }"
                      @click="
                        editaItem.coluns[
                          indexColumn
                        ][0].propsColumn.alignment = 'center'
                      "
                    >
                      <img src="@/assets/editor/align-center-vertical.svg" />
                    </button>
                    <button
                      :class="{
                        'selected-align':
                          editaItem.coluns[indexColumn][0].propsColumn
                            .alignment == 'end',
                      }"
                      @click="
                        editaItem.coluns[
                          indexColumn
                        ][0].propsColumn.alignment = 'end'
                      "
                    >
                      <img src="@/assets/editor/align-bottom.svg" />
                    </button>
                  </div>
                  <div class="space"></div>
                </div>

                <div style="display: flex; width: 100%; transition: all 0.3s">
                  <div class="label-help">
                    <label for="equalize" class="sub">Equalizar alturas</label>
                    <img
                      width="17px"
                      height="17px"
                      src="@/assets/editor/help-circle.svg"
                      alt="icon"
                      v-b-tooltip.hover
                      title='Definir isto para "sim" ajustará todas as colunas deste grupo para a mesma altura, independentemente de quanto conteúdo elas contenham.'
                    />
                  </div>
                  <b-form-checkbox
                    style="margin-left: auto; margin-right: 0; z-index: 0"
                    v-model="editaItem.propsRow.equalize"
                    name="is_main"
                    size="lg"
                    switch
                  >
                  </b-form-checkbox>
                </div>

                <div class="space"></div>

                <div
                  style="
                    display: flex;
                    width: 100%;
                    transition: all 0.3s;
                    justify-content: space-between;
                  "
                >
                  <div class="label-help">
                    <label for="equalize" class="sub">Equalizar larguras</label>
                    <img
                      width="17px"
                      height="17px"
                      src="@/assets/editor/help-circle.svg"
                      alt="icon"
                      v-b-tooltip.hover
                      title="Ajustará todas as colunas deste grupo para a mesma largura."
                    />
                  </div>

                  <button class="equalize-button" @click="equalizeWidth">
                    Confirmar
                  </button>
                </div>
              </div>
              <div class="separador"></div>

              <!-- FUNDO -->
              <div class="content">
                <label class="titulo">Fundo</label>
                <div>
                  <label class="sub" for="multiselect-tipo-fundo"
                    >Tipo de fundo</label
                  >
                  <multiselect
                    id="multiselect-tipo-fundo"
                    v-model="
                      editaItem.coluns[indexColumn][indexComponent].propsColumn
                        .backgd_und
                    "
                    :options="['Nenhum', 'Cor sólida', 'Gradiente', 'Imagem']"
                    :searchable="false"
                    :show-labels="false"
                    @input="trocarUndBackground()"
                    :hide-selected="true"
                    :allow-empty="false"
                  ></multiselect>
                </div>

                <!-- Cor sólida -->
                <div
                  v-show="
                    editaItem.coluns[indexColumn][indexComponent].propsColumn
                      .backgd_und == 'Cor sólida'
                  "
                >
                  <div class="space"></div>
                  <LvColorpicker
                    :id="`LvColorpicker-${indexColumn}`"
                    v-model="
                      editaItem.coluns[indexColumn][indexComponent].propsColumn
                        .backgd_solid
                    "
                    @input="backgroundSolid($event)"
                    label="Cor do fundo"
                    :colors="palette"
                  ></LvColorpicker>
                </div>

                <!-- Gradiente -->
                <div
                  v-show="
                    editaItem.coluns[indexColumn][indexComponent].propsColumn
                      .backgd_und == 'Gradiente'
                  "
                >
                  <!-- Pickers -->
                  <div class="pickers pb-2">
                    <div class="picker">
                      <LvColorpicker
                        @input="
                          backgroundGradient(
                            editaItem.coluns[indexColumn][indexComponent]
                              .propsColumn
                          )
                        "
                        id="LvColorpicker"
                        v-model="
                          editaItem.coluns[indexColumn][indexComponent]
                            .propsColumn.backgd_gradient_c1
                        "
                        :colors="palette"
                      ></LvColorpicker>
                    </div>
                    <div class="picker">
                      <LvColorpicker
                        @input="
                          backgroundGradient(
                            editaItem.coluns[indexColumn][indexComponent]
                              .propsColumn
                          )
                        "
                        id="LvColorpicker"
                        v-model="
                          editaItem.coluns[indexColumn][indexComponent]
                            .propsColumn.backgd_gradient_c2
                        "
                        :colors="palette"
                      ></LvColorpicker>
                    </div>
                  </div>
                  <div class="space"></div>

                  <!-- Direção -->
                  <div class="label-range">
                    <label class="subtitulo">Direção do gradiente</label>
                    <input
                      type="number"
                      v-model="
                        editaItem.coluns[indexColumn][indexComponent]
                          .propsColumn.backgd_gradient_deg
                      "
                      @input="
                        backgroundGradient(
                          editaItem.coluns[indexColumn][indexComponent]
                            .propsColumn
                        )
                      "
                    />
                  </div>
                  <b-form-input
                    v-model="
                      editaItem.coluns[indexColumn][indexComponent].propsColumn
                        .backgd_gradient_deg
                    "
                    @input="
                      backgroundGradient(
                        editaItem.coluns[indexColumn][indexComponent]
                          .propsColumn
                      )
                    "
                    type="range"
                    min="0"
                    max="360"
                    step="1"
                  ></b-form-input>
                </div>

                <!-- Imagem -->
                <div
                  v-show="
                    editaItem.coluns[indexColumn][indexComponent].propsColumn
                      .backgd_und == 'Imagem'
                  "
                >
                  <div class="space"></div>
                  <div class="select-image">
                    <div @click="openModal('Upload-Editor')">
                      <img
                        :src="
                          editaItem.coluns[indexColumn][indexComponent]
                            .propsColumn.backgd_image
                            ? editaItem.coluns[indexColumn][indexComponent]
                                .propsColumn.backgd_image
                            : 'https://gdigital.s3.amazonaws.com/gdigital/8/imagem-vazia%20%28200%C2%A0%C3%97%C2%A0200%C2%A0px%29.webp'
                        "
                        alt="Imagem preview"
                      />
                    </div>
                    <div>
                      <input
                        type="text"
                        v-model="
                          editaItem.coluns[indexColumn][indexComponent]
                            .propsColumn.backgd_image
                        "
                        @input="backgroundImage(editaItem.coluns[indexColumn][indexComponent]
                            .propsColumn.backgd_image, editaItem.coluns[indexColumn][indexComponent]
                            .propsColumn)"
                      />
                      <span @click="openModal('Upload-Editor')">{{
                        editaItem.coluns[indexColumn][indexComponent]
                          .propsColumn.backgd_image
                          ? "Alterar imagem"
                          : "Selecionar imagem"
                      }}</span>
                    </div>
                  </div>
                  <div class="space"></div>
                  <label for="multiselect-repeat-img" class="sub"
                    >Repetir</label
                  >
                  <multiselect
                    id="multiselect-repeat-img"
                    v-model="
                      editaItem.coluns[indexColumn][indexComponent].propsColumn
                        .backgd_image_repeat_und
                    "
                    :options="[
                      'Nenhuma',
                      'Ladrilhos',
                      'Horizontal',
                      'Vertical',
                    ]"
                    :searchable="false"
                    :show-labels="false"
                    :hide-selected="true"
                    :allow-empty="false"
                    @input="
                      imageRepeat(
                        $event,
                        editaItem.coluns[indexColumn][indexComponent]
                          .propsColumn
                      )
                    "
                  ></multiselect>
                  <div class="space"></div>
                  <label for="multiselect-position-img" class="sub"
                    >Posição</label
                  >
                  <multiselect
                    id="multiselect-position-img"
                    v-model="
                      editaItem.coluns[indexColumn][indexComponent].propsColumn
                        .backgd_image_position_und
                    "
                    :options="[
                      'Topo à esquerda',
                      'Meio à esquerda',
                      'Base à esquerda',
                      'Topo à direita',
                      'Meio à direita',
                      'Base à direita',
                      'Topo ao centro',
                      'Centralizado',
                      'Base ao centro',
                    ]"
                    :searchable="false"
                    :show-labels="false"
                    :hide-selected="true"
                    :allow-empty="false"
                    @input="
                      imagePosition(
                        $event,
                        editaItem.coluns[indexColumn][indexComponent]
                          .propsColumn
                      )
                    "
                  ></multiselect>
                  <div class="space"></div>
                  <label for="multiselect-anexação-img" class="sub"
                    >Anexação ao Fundo</label
                  >
                  <multiselect
                    id="multiselect-anexação-img"
                    v-model="
                      editaItem.coluns[indexColumn][indexComponent].propsColumn
                        .backgd_anexacao_und
                    "
                    :options="['Deslizar', 'Fixa']"
                    :searchable="false"
                    :show-labels="false"
                    :hide-selected="true"
                    :allow-empty="false"
                    @input="
                      imageAnexacao(
                        $event,
                        editaItem.coluns[indexColumn][indexComponent]
                          .propsColumn
                      )
                    "
                  ></multiselect>
                  <div v-if="editaItem.coluns[indexColumn][indexComponent].propsColumn.backgd_anexacao_und == 'Fixa'" style="padding: 7px; border: 1px solid #db2; border-radius: 10px; background-color: #db21; margin-top: 5px;">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#db2" style="display: inline-block; margin-right: 5px;" viewBox="0 0 256 256"><path d="M236.8,188.09,149.35,36.22h0a24.76,24.76,0,0,0-42.7,0L19.2,188.09a23.51,23.51,0,0,0,0,23.72A24.35,24.35,0,0,0,40.55,224h174.9a24.35,24.35,0,0,0,21.33-12.19A23.51,23.51,0,0,0,236.8,188.09ZM222.93,203.8a8.5,8.5,0,0,1-7.48,4.2H40.55a8.5,8.5,0,0,1-7.48-4.2,7.59,7.59,0,0,1,0-7.72L120.52,44.21a8.75,8.75,0,0,1,15,0l87.45,151.87A7.59,7.59,0,0,1,222.93,203.8ZM120,144V104a8,8,0,0,1,16,0v40a8,8,0,0,1-16,0Zm20,36a12,12,0,1,1-12-12A12,12,0,0,1,140,180Z"></path></svg> 
                    <span style="color: #db2; display: inline; font-size: 14px;">Incompatível na versão mobile</span>
                  </div>
                  <div class="space"></div>
                  <label for="multiselect-escaca-img" class="sub">Escaça</label>
                  <multiselect
                    id="multiselect-escaca-img"
                    v-model="
                      editaItem.coluns[indexColumn][indexComponent].propsColumn
                        .backgd_escaca_und
                    "
                    :options="['Nenhuma', 'Ajustar', 'Preencher']"
                    :searchable="false"
                    :show-labels="false"
                    :hide-selected="true"
                    :allow-empty="false"
                    @input="
                      imageEscaca(
                        $event,
                        editaItem.coluns[indexColumn][indexComponent]
                          .propsColumn
                      )
                    "
                  ></multiselect>
                  <div class="space"></div>
                  <label class="sub" for="multiselect-Sobrepocição-img"
                    >Sobrepocição de Fundo</label
                  >
                  <multiselect
                    id="multiselect-Sobrepocição-img"
                    v-model="
                      editaItem.coluns[indexColumn][indexComponent].propsColumn
                        .backgd_overlap_und
                    "
                    :options="['Nenhuma', 'Cor', 'Gradiente']"
                    :hide-selected="true"
                    :allow-empty="false"
                    :searchable="false"
                    :show-labels="false"
                  ></multiselect>
                  
                  <div class="space"></div>
                  <div
                    v-if="
                      editaItem.coluns[indexColumn][indexComponent].propsColumn
                        .backgd_overlap_und == 'Cor'
                    "
                  >
                    <LvColorpicker
                      id="LvColorpicker"
                      v-model="
                        editaItem.coluns[indexColumn][indexComponent]
                          .propsColumn.backgd_overlap_cor
                      "
                      @input="
                        colorOverlap(
                          $event,
                          editaItem.coluns[indexColumn][indexComponent]
                            .propsColumn
                        )
                      "
                      label="Cor"
                      :colors="palette"
                    ></LvColorpicker>
                  </div>

                  <div
                    v-if="
                      editaItem.coluns[indexColumn][indexComponent].propsColumn
                        .backgd_overlap_und == 'Gradiente'
                    "
                  >
                    <div
                      v-show="
                        editaItem.coluns[indexColumn][indexComponent]
                          .propsColumn.backgd_und == 'Gradiente'
                      "
                    >
                      <!-- Pickers -->
                      <div class="pickers pb-2">
                        <div class="picker">
                          <LvColorpicker
                            @input="
                              backgroundGradient(
                                editaItem.coluns[indexColumn][indexComponent]
                                  .propsColumn
                              )
                            "
                            id="LvColorpicker"
                            v-model="
                              editaItem.coluns[indexColumn][indexComponent]
                                .propsColumn.backgd_gradient_c1
                            "
                            :colors="palette"
                          ></LvColorpicker>
                        </div>
                        <div class="picker">
                          <LvColorpicker
                            @input="
                              backgroundGradient(
                                editaItem.coluns[indexColumn][indexComponent]
                                  .propsColumn
                              )
                            "
                            id="LvColorpicker"
                            v-model="
                              editaItem.coluns[indexColumn][indexComponent]
                                .propsColumn.backgd_overlap_c2
                            "
                            :colors="palette"
                          ></LvColorpicker>
                        </div>
                      </div>
                      <div class="space"></div>

                      <!-- Direção -->
                      <div class="label-range">
                        <label class="subtitulo">Direção do gradiente</label>
                        <input
                          type="number"
                          v-model="
                            editaItem.coluns[indexColumn][indexComponent]
                              .propsColumn.backgd_gradient_deg
                          "
                          @input="
                            backgroundGradient(
                              editaItem.coluns[indexColumn][indexComponent]
                                .propsColumn
                            )
                          "
                        />
                      </div>
                      <b-form-input
                        v-model="
                          editaItem.coluns[indexColumn][indexComponent]
                            .propsColumn.backgd_gradient_deg
                        "
                        @input="
                          backgroundGradient(
                            editaItem.coluns[indexColumn][indexComponent]
                              .propsColumn
                          )
                        "
                        type="range"
                        min="0"
                        max="360"
                        step="1"
                      ></b-form-input>
                    </div>

                    <!-- Pickers -->
                    <div class="pickers pb-2">
                      <div class="picker">
                        <LvColorpicker
                          id="LvColorpicker"
                          v-model="
                            editaItem.coluns[indexColumn][indexComponent]
                              .propsColumn.backgd_overlap_c1
                          "
                          :colors="palette"
                        ></LvColorpicker>
                      </div>
                      <div class="picker">
                        <LvColorpicker
                          label="Cor do Fundo"
                          id="LvColorpicker"
                          v-model="
                            editaItem.coluns[indexColumn][indexComponent]
                              .propsColumn.backgd_overlap_c2
                          "
                          :colors="palette"
                        ></LvColorpicker>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="separador"></div>

              <!-- BORDA -->
              <div class="content">
                <label class="titulo">Bordas</label>
                <label for="borderType" class="subtitulo">Estilo</label>
                <multiselect
                  id="borderType"
                  v-model="
                    editaItem.coluns[indexColumn][indexComponent].propsColumn
                      .border_und
                  "
                  :options="[
                    'Nenhuma',
                    'Sólido',
                    'Tracejadas',
                    'Pontilhado',
                  ]"
                  :searchable="false"
                  :show-labels="false"
                  :hide-selected="true"
                  :allow-empty="false"
                  @input="
                    borderAlter(
                      editaItem.coluns[indexColumn][indexComponent].propsColumn
                    )
                  "
                ></multiselect>
                <!-- Cor da borda -->
                <div
                  v-show="
                    editaItem.coluns[indexColumn][indexComponent].propsColumn
                      .border_und != 'Nenhuma' &&
                    editaItem.coluns[indexColumn][indexComponent].propsColumn
                      .border_und
                  "
                >
                  <div class="space"></div>
                  <LvColorpicker
                    id="LvColorpicker"
                    v-model="
                      editaItem.coluns[indexColumn][indexComponent].propsColumn
                        .border_color
                    "
                    @input="
                      borderColor(
                        $event,
                        editaItem.coluns[indexColumn][indexComponent]
                          .propsColumn
                      )
                    "
                    label="Cor"
                    :colors="palette"
                  ></LvColorpicker>
                  <div class="space"></div>
                </div>

                <!-- Largura da borda -->
                <div
                  v-show="
                    editaItem.coluns[indexColumn][indexComponent].propsColumn
                      .border_und != 'Nenhuma' &&
                    editaItem.coluns[indexColumn][indexComponent].propsColumn
                      .border_und
                  "
                >
                  <div class="label-range">
                    <label class="subtitulo" for="container-border">Largura</label>
                    <input
                    type="number"
                    v-model="editaItem.coluns[indexColumn][indexComponent].propsColumn.border_px_top"
                    @input="borderPxLink()"
                  />
                  </div>
                  <div
                    style="display: flex; gap: 5px; align-items: center"
                  >
                  
                    <b-form-input
                      id="range-1"
                      v-model="editaItem.coluns[indexColumn][indexComponent].propsColumn.border_px_top"
                      type="range"
                      min="0"
                      max="100"
                      step="1"
                      @input="borderPxLink()"
                    ></b-form-input>
                  </div>
                </div>

                <!-- Raio da borda -->
                <div class="space"></div>
                <div
                v-show="
                    editaItem.coluns[indexColumn][indexComponent].propsColumn
                      .border_und != 'Nenhuma' &&
                    editaItem.coluns[indexColumn][indexComponent].propsColumn
                      .border_und"
                >
                  <div class="label-range">
                    <label for="container-border" class="subtitulo">Raio</label>
                    <input
                    type="number"
                    @input="borderRadiusLink()"
                    v-model="editaItem.coluns[indexColumn][indexComponent].propsColumn.border_radius_top"
                  />
                  </div>
                  <div
                    style="display: flex; gap: 5px; align-items: center"
                  >
                    <b-form-input
                      id="range-1"
                      v-model="editaItem.coluns[indexColumn][indexComponent].propsColumn.border_radius_top"
                      type="range"
                      min="0"
                      max="100"
                      step="1"
                      @input="borderRadiusLink()"
                    ></b-form-input>
                  </div>
                </div>
              </div>
              <div class="separador"></div>

              <!-- Sombra -->
              <div class="content">
                <label class="titulo">Sombra</label>
                <div>
                  <label class="subtitulo">Cores</label>
                  <LvColorpicker
                    id="LvColorpicker"
                    v-model="
                      editaItem.coluns[indexColumn][indexComponent].propsColumn
                        .border_shadow_color
                    "
                    @input="
                      borderShadowColor(
                        $event,
                        editaItem.coluns[indexColumn][indexComponent]
                          .propsColumn
                      )
                    "
                    label="Cor"
                    :colors="palette"
                  ></LvColorpicker>
                </div>
                <div class="space"></div>
                <div id="container-sombra" style="display: flex; gap: 5px">
                  <b-form-group class="form_shadow">
                    <b-form-input
                      type="number"
                      id="shadow_column"
                      v-model="editaItem.coluns[indexColumn][indexComponent].propsColumn.border_shadow_x"
                      @focus="openDropdownSlider(`editaItem.coluns.${indexColumn}.${indexComponent}.propsColumn.border_shadow_x`)" 
                    ></b-form-input>
                    <p>X</p>
                  </b-form-group>
                  <b-form-group class="form_shadow">
                    <b-form-input
                      id="shadow_column"
                      type="number"
                      v-model="editaItem.coluns[indexColumn][indexComponent].propsColumn.border_shadow_y"
                      @focus="openDropdownSlider(`editaItem.coluns.${indexColumn}.${indexComponent}.propsColumn.border_shadow_y`)"
                    ></b-form-input>
                    <p>Y</p>
                  </b-form-group>
                  <b-form-group class="form_shadow">                   
                    <b-form-input
                      id="shadow_column"
                      type="number"
                      v-model="editaItem.coluns[indexColumn][indexComponent].propsColumn.border_shadow_blur"
                      @focus="openDropdownSlider(`editaItem.coluns.${indexColumn}.${indexComponent}.propsColumn.border_shadow_blur'`)"
                    ></b-form-input>
                    <p>Borrão</p>
                  </b-form-group>
                  <b-form-group class="form_shadow">
                    <b-form-input
                      id="shadow_column"
                      type="number"
                      v-model="editaItem.coluns[indexColumn][indexComponent].propsColumn.border_shadow_spread"
                      @focus="openDropdownSlider(`editaItem.coluns.${indexColumn}.${indexComponent}.propsColumn.border_shadow_spread'`)"
                    ></b-form-input>
                    <p>Spread</p>
                  </b-form-group>
                </div>
                <div class="space"></div>
                <div class="space"></div>
                <div class="space"></div>
              </div>
            </div>
          </div>
        </b-tab>
        <b-tab title="Avançado">     
          <advancedBasic 
            :spacing="editaItem.coluns[indexColumn][0].propsColumn.spacing"         
            :visibility="editaItem.coluns[indexColumn][0].propsColumn.visibility" 
            :typeView="typeView"
            :global="global"
            :hideAttributes="true"           
            :hideAnimation="true"
            @toggleGlobal="global = !global"
            @nextIcon="nextIcon"         
            @update:visibility="editaItem.propsColumn.visibility = $event" 
            @update:spacing="editaItem.propsData.spacing = $event"       
          ></advancedBasic>
          <div class="separador"></div>
        </b-tab>
      </b-tabs>
      <dropdownSlider
        ref="dropdownSlider"   
        @updateValue="updateValue"
      />
    </b-card>
    <Upload @setImg="setImg" />
  </div>
</template>
<script>
import dropdownSliderMixin from "@/mixins/dropdownSliderMixin.js";
import advancedBasic from "./EditComponents/advancedBasic.vue";
import LvColorpicker from "lightvue/color-picker";
import Multiselect from "vue-multiselect";
import Upload from "../Upload.vue";
import _ from "lodash";

export default {
  mixins: [dropdownSliderMixin],
  components: {
    advancedBasic,
    LvColorpicker: LvColorpicker,
    Multiselect,
    Upload,
  },
  props: [
    "editaItem",
    "index_page",
    "indexColumn",
    "indexComponent",
    "indexLine",
    "typeView",
    "palette",
  ],
  data() {
    return {  
      global: false,
      rotate_border: 0,
      shadow_border: 0,
      und_height: 1,
      linkMarginColumn: true,
      linkPaddingColumn: true,
      colunWidth: "",
    };
  },
  watch: {
    visibilityMonitor: {
      handler: function (newValue) {
        if (!this.visibilityMonitor) {
          this.editaItem.coluns[this.indexColumn][0].propsColumn.width =
            this.editaItem.coluns[this.indexColumn][0].propsColumn.width *
            (100 /
              (100 -
                this.editaItem.coluns[this.indexColumn][0].propsColumn.width));
        }
        this.$emit("fixWidth");
      },
      deep: true,
    },
  },
  computed: {
    visibilityMonitor() {
      if (this.editaItem) {
        return this.editaItem.coluns[this.indexColumn][0].propsColumn
          .visibility.monitor;
      }
    },
  },
  methods: {
    equalizeWidth() {
      const customizedWidths = this.editaItem.coluns
        .filter(
          (colArray) =>
            colArray.some(
              (item) => item.empty || item.propsData.visibility.monitor === true
            ) && colArray[0].propsColumn.visibility.monitor
        )
        .map((colArray) => parseInt(colArray[0].propsColumn.width * 100) / 100);

      if (customizedWidths.length) {
        const sum = customizedWidths.reduce((total, width) => total + width, 0);
        if (sum != 100) {
          // Se a soma de todas as larguras diferir de 100, o código seguinte compensa a diferença modificando todos as larguras proporcionalmente
          const scaleFactor = 100 / sum;
          customizedWidths.forEach((width, i) => {
            customizedWidths[i] = Math.round(width * scaleFactor * 100) / 100;
          });

          // Se ainda sim a soma não se igualar a 100 (por conta do math.round), o código seguinte joga a diferença para a primeira coluna
          const valoresFinais = customizedWidths;
          const totalCustomizedWidth = valoresFinais.reduce(
            (a, b) => parseFloat(a) + parseFloat(b)
          );
          const resultToDistribuition = 100 - totalCustomizedWidth;
          if (resultToDistribuition !== 0) {
            customizedWidths[0] = customizedWidths[0] + resultToDistribuition;
          }
        }

        let visible = this.editaItem.coluns.map(
          (item) => item[0].propsColumn.visibility.monitor
        );

        for (let index = 0; index < visible.length; index++) {
          if (visible[index]) {
            if (
              this.editaItem.coluns[index].some((item2) => item2.empty) ==
                false &&
              this.editaItem.coluns[index].some(
                (item2) => item2.propsData.visibility.monitor
              ) == false
            ) {
              visible[index] = false;
            }
          }
        }

        const countTrue = visible.reduce((count, item) => {
          return count + (item ? 1 : 0);
        }, 0);

        var coluns_length_value = Math.round(10000 / countTrue) / 100;

        // Insere os novos valores de largura de volta em seu lugar de origem
        let y = 0;
        for (let i = 0; i < this.editaItem.coluns.length; i++) {
          const element1 = this.editaItem.coluns[i];
          if (visible[i]) {
            this.editaItem.coluns[i][0].propsColumn.width = coluns_length_value;
            y++;
          }
        }
      }
    },
    nextIcon(type) {
      this.$emit("attIconView", type);
    },    
    debounce: _.debounce(function (value) {
      if (value == "" || !value) {
        value = "";
      } else if ((value >= 0 && value <= 5) || value < 0) {
        this.validWidth(5);
      } else {
        this.validWidth(value);
      }
    }, 500),
    validWidth(event) {
      var editaItem = JSON.parse(JSON.stringify(this.editaItem));
      // console.log(editaItem.coluns[this.indexColumn][this.indexComponent].propsColumn.width)

      // Seleciona somente as colunas que possuem pelo menos um item visível em dispositivos grandes
      const customizedWidths = editaItem.coluns
        .filter((colArray) =>
          colArray.some((item) => item.propsColumn.visibility.monitor === true)
        )
        .map((colArray) => parseInt(colArray[0].propsColumn.width));

      // O if e else validam se a coluna que está sendo modificada é ou não a última coluna. Caso não seja, o valor
      // será compensado pela coluna seguinte, caso contrário, será compensado pela coluna anterior
      if (this.indexColumn < customizedWidths.length - 1) {
        const diferenca = customizedWidths[this.indexColumn] - parseInt(event);
        customizedWidths[this.indexColumn] = parseInt(event) ?? 100;
        const saveValue = customizedWidths[this.indexColumn + 1];
        customizedWidths[this.indexColumn + 1] =
          diferenca + parseInt(customizedWidths[this.indexColumn + 1]);
        // Estabelece um valor mínimo para evitar que as colunas sumam
        if (customizedWidths[this.indexColumn + 1] < 2) {
          customizedWidths[this.indexColumn + 1] = 2;
        }
      } else {
        const diferenca = customizedWidths[this.indexColumn] - parseInt(event);
        customizedWidths[this.indexColumn] = parseInt(event) ?? 100;
        customizedWidths[this.indexColumn - 1] =
          diferenca + parseInt(customizedWidths[this.indexColumn - 1]);
        // Estabelece um valor mínimo para evitar que as colunas sumam
        if (customizedWidths[this.indexColumn - 1] < 2) {
          customizedWidths[this.indexColumn - 1] = 2;
        }
      }

      // Caso algo tenha dado errado e tenha surgido um NaN, ocorre a distribuição igualitária
      const hasNonNumericItem = customizedWidths.some((item) => isNaN(item));
      if (hasNonNumericItem) {
        for (let i = 0; i < customizedWidths.length; i++) {
          customizedWidths[i] = Math.round(100 / customizedWidths.length);
        }
      }

      // Se a soma de todas as larguras diferir de 100, o código seguinte compensa a diferença modificando todos as larguras proporcionalmente
      const sum = customizedWidths.reduce((total, width) => total + width, 0);
      const scaleFactor = 100 / sum;
      customizedWidths.forEach((width, i) => {
        customizedWidths[i] = Math.round(width * scaleFactor);
      });

      // Se ainda sim a soma não se igualar a 100 (por conta do math.round), o código seguinte joga a diferença para a primeira coluna
      const valoresFinais = customizedWidths;
      const totalCustomizedWidth = valoresFinais.reduce(
        (a, b) => parseInt(a) + parseInt(b)
      );
      const resultToDistribuition = 100 - totalCustomizedWidth;
      if (resultToDistribuition !== 0) {
        customizedWidths[0] = customizedWidths[0] + resultToDistribuition;
      }

      // Insere os valores alterados nos objetos de origem
      var coluns = editaItem.coluns;
      let y = 0;
      for (let i = 0; i < coluns.length; i++) {
        let colArray = coluns[i];
        if (
          colArray.some((item) => item.propsColumn.visibility.monitor === true)
        ) {
          for (let x = 0; x < colArray.length; x++) {
            colArray[x].propsColumn.width = customizedWidths[y];
          }
          y++;
        }
      }

      // manda as informações para o editor
      this.$emit("coluns2", coluns);
      this.colunWidth =
        this.editaItem.coluns[this.indexColumn][0].propsColumn.width;
    },
    setImg(img) {
      const props =
        this.editaItem.coluns[this.indexColumn][this.indexComponent]
          .propsColumn;
      props.backgd_image = img;
      props.backgd = `
          background-image:url(${img});
          background-attachment: ${props.backgd_anexacao};
          background-size: ${props.backgd_escaca_type};
          background-repeat:${props.backgd_image_repeat_type};
          background-position:${props.backgd_image_position_type}
            `;
    },
    openModal(data) {
      this.$bvModal.show(data);
    },
    colorOverlap(cor, coluna) {
      const props = coluna;
      props.backgd_overlap_cor = cor;
    },  
    linkPaddingC(e) {
      if (e === "active") {
        this.linkPaddingColumn = false;
      }
      if (e === "disable") {
        this.linkPaddingColumn = true;
      }
    },
    linkMarginC(e) {
      if (e === "active") {
        this.linkMarginColumn = false;
      }
      if (e === "disable") {
        this.linkMarginColumn = true;
      }
    },
    marginColumn(coluna) {
      var props = coluna;
      props.margin = `
        margin:${props.mg_top}px ${props.mg_right}px ${props.mg_bottom}px ${props.mg_left}px
        `;
    },
    paddingColumn(coluna) {
      var props = coluna;
      props.padding = `
        padding:${props.pd_top}px ${props.pd_right}px ${props.pd_bottom}px ${props.pd_left}px
        `;
    },
    borderPxLink() {
      let px = this.editaItem.coluns[this.indexColumn][this.indexComponent].propsColumn.border_px_top;
      let coluna = this.editaItem.coluns[this.indexColumn][this.indexComponent].propsColumn
      const {
        border_type,
        border_color,
        border_radius_top,
        border_radius_right,
        border_radius_bottom,
        border_radius_left,
        border_shadow_color,
        border_shadow_x,
        border_shadow_y,
        border_shadow_blur,
        border_shadow_spread,
      } = coluna;
      coluna.border_px_top = px;
      coluna.border_px_right = px;
      coluna.border_px_bottom = px;
      coluna.border_px_left = px;

      coluna.border = `border:${px}px ${border_type} ${border_color};
      border-radius:${border_radius_top}px ${border_radius_right}px ${border_radius_bottom}px ${border_radius_left}px;
      box-shadow:${border_shadow_color} ${border_shadow_x}px ${border_shadow_y}px ${border_shadow_blur}px ${border_shadow_spread}px;`;
    },
    borderRadiusLink() {
      let px = this.editaItem.coluns[this.indexColumn][this.indexComponent].propsColumn.border_radius_top
      const props = this.editaItem.coluns[this.indexColumn][this.indexComponent].propsColumn;
      const border = `
      border: ${this.editaItem.coluns[this.indexColumn][this.indexComponent].propsColumn.border_px_top}px ${props.border_type} ${props.border_color};
      border-radius: ${this.editaItem.coluns[this.indexColumn][this.indexComponent].propsColumn.border_radius_top}px;
      box-shadow: ${props.border_shadow_color} ${props.border_shadow_x}px ${props.border_shadow_y}px ${props.border_shadow_blur}px ${props.border_shadow_spread}px;
    `;
      props.border = border;
      props.border_radius_right = px;
      props.border_radius_bottom = px;
      props.border_radius_left = px;
    },
    borderShadowColor(cor, coluna) {
      var props = coluna;
      props.border_shadow_color = cor;
      props.border = `
          border: ${props.border_px_top}px ${props.border_type} ${props.border_color}
                  ${props.border_px_right}px ${props.border_type} ${props.border_color}
                  ${props.border_px_bottom}px ${props.border_type} ${props.border_color}
                  ${props.border_px_left}px ${props.border_type} ${props.border_color};
          border-radius:${props.border_radius_top}px;
          box-shadow:${props.border_shadow_color} ${props.border_shadow_x}px ${props.border_shadow_y}px ${props.border_shadow_blur}px ${props.border_shadow_spread}px
    `;
      // console.log("shadow -->", props.border);
    },

    borderShadow(coluna) {
      const props = coluna;

      const borderProps = {
        top: `${props.border_px_top}px ${props.border_type} ${props.border_color}`,
        right: `${props.border_px_right}px ${props.border_type} ${props.border_color}`,
        bottom: `${props.border_px_bottom}px ${props.border_type} ${props.border_color}`,
        left: `${props.border_px_left}px ${props.border_type} ${props.border_color}`,
        radius: `${props.border_radius_top}px ${props.border_radius_right}px ${props.border_radius_bottom}px ${props.border_radius_left}px`,
      };

      const shadowProps = {
        color: props.border_shadow_color,
        x: `${props.border_shadow_x}px`,
        y: `${props.border_shadow_y}px`,
        blur: `${props.border_shadow_blur}px`,
        spread: `${props.border_shadow_spread}px`,
      };

      props.border = `
          border-top: ${borderProps.top};
          border-right: ${borderProps.right};
          border-bottom: ${borderProps.bottom};
          border-left: ${borderProps.left};
          border-radius: ${borderProps.radius};
          box-shadow: ${shadowProps.color} ${shadowProps.x} ${shadowProps.y} ${shadowProps.blur} ${shadowProps.spread};
    `;
    },
    borderRadius(coluna) {
      var props = coluna;
      props.border = `
            border-top: ${props.border_px_top}px ${props.border_type} ${props.border_color};
            border-right: ${props.border_px_right}px ${props.border_type} ${props.border_color};
            border-bottom: ${props.border_px_bottom}px ${props.border_type} ${props.border_color};
            border-left: ${props.border_px_left}px ${props.border_type} ${props.border_color};
            border-radius:${props.border_radius_top}px ${props.border_radius_right}px ${props.border_radius_bottom}px ${props.border_radius_left}px;
            box-shadow:${props.border_shadow_color} ${props.border_shadow_x}px ${props.border_shadow_y}px ${props.border_shadow_blur}px ${props.border_shadow_spread}px
          `;
    },
    borderPx(coluna) {
      var props = coluna;
      var btype = props.border_type;
      props.border = `
            border-top: ${props.border_px_top}px ${btype} ${props.border_color};
            border-right: ${props.border_px_right}px ${btype} ${props.border_color};
            border-bottom: ${props.border_px_bottom}px ${btype} ${props.border_color};
            border-left: ${props.border_px_left}px ${btype} ${props.border_color};
            border-radius:${props.border_radius_top}px ${props.border_radius_right}px ${props.border_radius_bottom}px ${props.border_radius_left}px;
            box-shadow:${props.border_shadow_color} ${props.border_shadow_x}px ${props.border_shadow_y}px ${props.border_shadow_blur}px ${props.border_shadow_spread}px
          `;
    },
    borderColor(cor, coluna) {
      const {
        border_px_top,
        border_px_right,
        border_px_bottom,
        border_px_left,
        border_type,
        border_radius_top,
        border_radius_right,
        border_radius_bottom,
        border_radius_left,
        border_shadow_color,
        border_shadow_x,
        border_shadow_y,
        border_shadow_blur,
        border_shadow_spread,
      } = coluna;
      const border = `
        border-top: ${border_px_top}px ${border_type} ${cor};
            border-right: ${border_px_right}px ${border_type} ${cor};
            border-bottom: ${border_px_bottom}px ${border_type} ${cor};
            border-left: ${border_px_left}px ${border_type} ${cor};
            border-radius:${border_radius_top}px ${border_radius_right}px ${border_radius_bottom}px ${border_radius_left}px;
            box-shadow:${border_shadow_color} ${border_shadow_x}px ${border_shadow_y}px ${border_shadow_blur}px ${border_shadow_spread}px
        `;
      coluna.border = border;
    },
    borderAlter(coluna) {
      const props = coluna;
      const bUnd = props.border_und;
      let border_type = "";
      let border_style = "";

      switch (bUnd) {
        case "Nenhuma":
          props.border = "border:none";
          break;
        case "Sólido":
          border_type = "solid";
          break;
        case "Tracejadas":
          border_type = "dashed";
          break;
        case "Pontilhado":
          border_type = "dotted";
          break;
        case "Dobro":
          border_type = "double";
          break;
        case "Groove":
          border_type = "groove";
          break;
        case "Ridge":
          border_type = "ridge";
          break;
        case "Inset":
          border_type = "inset";
          break;
        case "Outset":
          border_type = "outset";
          break;
        default:
          break;
      }

      if (bUnd === "Nenhuma") {
        props.border_default = true;
      } else {
        props.border_default = false;
      }
      // console.log(props.border_default);
      if (border_type) {
        props.border_type = border_type;
        props.border = `
        border-top: ${props.border_px_top}px ${border_type} ${props.border_color} ;
        border-right: ${props.border_px_right}px ${border_type} ${props.border_color} ;
        border-bottom: ${props.border_px_bottom}px ${border_type} ${props.border_color} ;
        border-left: ${props.border_px_left}px ${border_type} ${props.border_color} ;
        border-radius:${props.border_radius_top}px ${props.border_radius_right}px ${props.border_radius_bottom}px ${props.border_radius_left}px ;
        box-shadow:${props.border_shadow_color} ${props.border_shadow_x}px ${props.border_shadow_y}px ${props.border_shadow_blur}px ${props.border_shadow_spread}px
      `;
      }

      if (border_style) {
        props.border_style = border_style;
        props.border = `
        border-top-style: ${border_style};
        border-right-style: ${border_style};
        border-bottom-style: ${border_style};
        border-left-style: ${border_style};
        border-top-color: ${props.border_color};
        border-right-color: ${props.border_color};
        border-bottom-color: ${props.border_color};
        border-left-color: ${props.border_color};
        border-top-width: ${props.border_px_top}px;
        border-right-width: ${props.border_px_right}px;
        border-bottom-width: ${props.border_px_bottom}px;
        border-left-width: ${props.border_px_left}px;
        border-radius:${props.border_radius_top}px ${props.border_radius_right}px ${props.border_radius_bottom}px ${props.border_radius_left}px;
        box-shadow:${props.border_shadow_color} ${props.border_shadow_x}px ${props.border_shadow_y}px ${props.border_shadow_blur}px ${props.border_shadow_spread}px
      `;
      }
    },
    imageEscaca(escaca, coluna) {
      const props = coluna;
      props.backgd_escaca_type =
        escaca === "Nenhuma"
          ? "auto"
          : escaca === "Ajustar"
          ? "contain"
          : escaca === "Preencher"
          ? "cover"
          : "auto";
      props.backgd = `
      background-image:url(${props.backgd_image});
      background-attachment: ${props.backgd_anexacao};
      background-repeat:${props.backgd_image_repeat_type};
      background-size: ${props.backgd_escaca_type};
      background-position:${props.backgd_image_position_type}
    `;
    },
    imageAnexacao(anexacao, coluna) {
      const props = coluna;
      props.backgd_anexacao = anexacao === "Deslizar" ? "scroll" : "fixed";
      props.backgd = `
      background-image:url(${props.backgd_image});
      background-attachment: ${props.backgd_anexacao};
      background-repeat:${props.backgd_image_repeat_type};
      background-size: ${props.backgd_escaca_type};
      background-position:${props.backgd_image_position_type}
    `;
    },
    imagePosition(position, coluna) {
      const props = coluna;
      const positions = {
        "Topo à esquerda": "left top",
        "Meio à esquerda": "left center",
        "Base à esquerda": "left bottom",
        "Topo à direita": "right top",
        "Meio à direita": "right center",
        "Base à direita": "right bottom",
        "Topo ao centro": "center top",
        Centralizado: "center center",
        "Base ao centro": "center bottom",
      };

      props.backgd_image_position_type = positions[position] || "center center";
      props.backgd = `
      background-image:url(${props.backgd_image});
      background-attachment: ${props.backgd_anexacao};
      background-repeat:${props.backgd_image_repeat_type};
      background-size: ${props.backgd_escaca_type};
      background-position:${props.backgd_image_position_type}
    `;
    },
    imageRepeat(repeat, coluna) {
      const props = coluna;

      props.backgd_image_repeat_type =
        repeat === "Ladrilhos"
          ? "repeat"
          : repeat === "Horizontal"
          ? "repeat-x"
          : repeat === "Vertical"
          ? "repeat-y"
          : "no-repeat";

      props.backgd = `
      background-image:url(${props.backgd_image});
      background-attachment: ${props.backgd_anexacao};
      background-repeat:${props.backgd_image_repeat_type};
      background-size: ${props.backgd_escaca_type};
      background-position:${props.backgd_image_position_type}
    `;
    },
    backgroundImage(url, coluna) {
      var props = coluna;
      props.backgd_image = url;
      props.backgd = `
          background-image:url(${props.backgd_image});
          background-attachment: ${props.backgd_anexacao};
          background-size: ${props.backgd_escaca_type};
          background-repeat:${props.backgd_image_repeat_type};
          background-position:${props.backgd_image_position_type}
          `;
    },
    backgroundSolid(cor) {
      var props =
        this.editaItem.coluns[this.indexColumn][this.indexComponent]
          .propsColumn;
      props.backgd = `background:${cor}`;
    },
    backgroundGradient(coluna) {
      var props = coluna;
      var gradient = `${props.backgd_gradient_type}(${props.backgd_gradient_deg}deg, ${props.backgd_gradient_c1}, ${props.backgd_gradient_c2})`;
      coluna.backgd = `background:${gradient}`;
    },
    trocarUndBackground() {
      const props =
        this.editaItem.coluns[this.indexColumn][this.indexComponent]
          .propsColumn;
      const backgdOptions = {
        Nenhum: "background:transparent",
        "Cor sólida": `background:${props.backgd_solid}`,
        Gradiente: `background:${props.backgd_gradient_type}(${props.backgd_gradient_deg}, ${props.backgd_gradient_c1}, ${props.backgd_gradient_c2})`,
        Imagem: `
        background-image:url(${props.backgd_image});
        background-attachment: ${props.backgd_anexacao};
        background-size: ${props.backgd_escaca_type};
        background-repeat:${props.backgd_image_repeat_type};
        background-position:${props.backgd_image_position_type}
      `,
      };
      props.backgd =
        backgdOptions[props.backgd_und] || "background:transparent";
    },
    next_und_height() {
      const props =
        this.editaItem.coluns[this.indexColumn][this.indexComponent].propsColumn
          .height;
      props[this.typeView].height_und =
        props[this.typeView].height_und == "px"
          ? "vh"
          : props[this.typeView].height_und == "vh"
          ? "vw"
          : "px";
      if (this.global && this.typeView == "desktop") {
        props.tablet.height_und = props.desktop.height_und;
        props.mobile.height_und = props.desktop.height_und;
      }
    },  
  },
  mounted() {
    this.colunWidth =
      this.editaItem.coluns[this.indexColumn][0].propsColumn.width;
    if (this.typeView == "desktop") {
      this.global = true;
    }
  },
  created() {
    if (
      this.editaItem.coluns[this.indexColumn][this.indexComponent].propsColumn
        .height_und === "vh"
    ) {
      this.und_height = 2;
    }
    if (
      this.editaItem.coluns[this.indexColumn][this.indexComponent].propsColumn
        .height_und === "vw"
    ) {
      this.und_height = 3;
    }
  },
};
</script>

<style lang="scss" scoped>
.equalize-button {
  font-size: 14px;
  background: #e6e6e6;
  box-sizing: border-box;
  transition: all 0.3s;
  border-radius: 10px;
  padding: 0 5px 0 5px;
  &:hover {
    background: #cccbcb;
  }
}
.links-advanced {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 18px;
  height: 18px;
  cursor: pointer;
}
.links {
  cursor: pointer;
}
.und_form {
  position: relative;
  .und {
    position: absolute;
    top: 12px !important;
    right: 10px;
    font-size: 20px;
    font-weight: 600;
    color: #ccc;
    cursor: pointer;
  }
}
.label-help {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  label {
    margin: 0 !important;
  }
}

.grid-input {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

#SideRightTabs {
  width: 100%;
  padding-left: 15px;
  .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
    color: #ffffff !important;
    background-color: var(--greenn) !important;
  }
  a {
    color: var(--greenn) !important;
  }
}

.container-border {
  border: 0.5px solid #ededf0 !important;
  display: flex;
  align-items: center;
  padding: 15px 20px !important;
  height: 45px !important;
  border-radius: 10px !important;
  gap: 35px;
  margin-bottom: 12px;

  .borderx {
    border-right: 0.5px solid #ededf0 !important;
    padding-right: 15px;
  }
}

.form_shadow {
  position: relative;

  p {
    position: absolute;
    color: #747474;
    font-size: 12px;
    top: 45px;
    left: 5px;
  }
}
</style>
