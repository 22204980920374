let propsTestimony = {
  custom: {
    c_class: '',
    c_id: '',
  },
  alignment: {
    desktop: { justify_vertical: "center" },
    tablet: { justify_vertical: "center" },
    mobile: { justify_vertical: "center" },
  },
  slides: [
    {
      imagem:"https://gdigital.s3.amazonaws.com/gdigital/1/user-null.webp",
      name:"Miguel Santos",
      rating: 5,
      text:"Estou extremamente satisfeita com este produto! Recomendo a todos os interessados, um investimento que não decepcionará.",
    },
    {
      imagem:"https://gdigital.s3.amazonaws.com/gdigital/1/user-null.webp",
      name:"Arthur Oliveira",
      rating: 4.5,
      text:"O atendimento que recebi foi excelente e fiquei muito feliz com minha compra. Gostei demais :)",
    },
    {
      imagem:"https://gdigital.s3.amazonaws.com/gdigital/1/user-null.webp",
      name:"Laura Silva",
      rating: 5,
      text:"Uau! Amei tanto esse produto, superou minhas expectativas. Uma das melhores compras que já fiz.",
    },
  ],
  styles: {
    hasPicture: true,
    hasStar: true,
    isCarousel: false,
    timer: 8,
    layout: 1,
    align: 'center',
    carousel: {
      color_btns: "#FFFFFF",
      timer: 4,
      colorFocus: false,
    },
    text: {
      size: {
        desktop: 16,
        tablet: 16,
        mobile:16
      },
      font_measure: {
        desktop: 'px',
        tablet: 'px',
        mobile:'px'
      },
      color: '#141414',
      font: 'Montserrat',
      font_weight: 400,
    },
    name: {
      size: {
        desktop: 20,
        tablet: 18,
        mobile:16
      },
      font_measure: {
        desktop: 'px',
        tablet: 'px',
        mobile: 'px'
      },
      color: '#141414',
      font_weight: 400,
    },
    stars: { size: {
      desktop: 24,
      tablet: 24,
      mobile:24
    }, color: '#141414' },
    picture: { size: {
      desktop: 100,
      tablet: 100,
      mobile:100
    }, },
    card: {
      background: {
        type: "Cor sólida",
        color: "#EFEFEF",
        hover_color: "#E0E0E0",
        grad1: "#FFFFFF",
        grad2: "#FFFFFF",
        gradAngle: "0",
      },
      shadow: {
        hasShadow: false,
        x: 0,
        y: 5,
        blur: 6,
        color: "#0000005E",
      },
      border: {
        hasBorder: false,
        type: "",
        width: 1,
        color: "#000000",
        radius: 5,
      },
    },
  },
  spacing: {
    desktop: {
      margin_top: 10,
      margin_right: 10,
      margin_bottom: 10,
      margin_left: 10,
      padding_top: 30,
      padding_right: 30,
      padding_bottom: 30,
      padding_left: 30,
    },
    tablet: {
       margin_top: 10,
      margin_right: 10,
      margin_bottom: 10,
      margin_left: 10,
      padding_top: 30,
      padding_right: 30,
      padding_bottom: 30,
      padding_left: 30,
    },
    mobile: {
       margin_top: 10,
      margin_right: 10,
      margin_bottom: 10,
      margin_left: 10,
      padding_top: 30,
      padding_right: 30,
      padding_bottom: 30,
      padding_left: 30,
    },
  },
  visibility: {
    tablet: true,
    phone: true,
    monitor: true,
  },
};
export default propsTestimony;
