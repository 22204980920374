import PropsAccordion from "@/components/Editor/Components/AccordionList/PropsAccordion.js";
//
import PropsPrices from "@/components/Editor/Components/Prices/PropsPrices.js";
//
import PropsSeparador from "@/components/Editor/Components/Separador/PropsSeparador.js";
//
import PropsButton from "@/components/Editor/Components/Button/PropsButton.js";
//
import PropsTextoConteudo from "@/components/Editor/Components/TextoConteudo/PropsTextoConteudo.js";
//
import PropsCarrousel from "@/components/Editor/Components/Carousel/PropsCarrousel.js";
//
import PropsCarrouselVertical from "@/components/Editor/Components/CarouselVertical/PropsCarrouselVertical.js";
//
import PropsMenuHorizontal from "@/components/Editor/Components/MenuHorizontal/PropsMenuHorizontal.js";
//
import PropsMenuFlutuante from "@/components/Editor/Components/MenuFlutuante/PropsMenuFlutuante.js";
//
import PropsMenuVertical from "@/components/Editor/Components/MenuVertical/PropsMenuVertical.js";
//
import PropsTextoVideo from "@/components/Editor/Components//TextoVideo/PropsTextoVideo.js";
//
import PropsGallery from "@/components/Editor/Components/Gallery/PropsGallery.js";
//
import PropsTextDynamic from "@/components/Editor/Components/TextoDinamico/PropsTextDynamic";
//
import PropsPopUp from "@/components/Editor/Components/PopUp/PropsPopUp.js";
//
import PropsCountdown from "@/components/Editor/Components/CountDown/PropsCountdown.js";
//
import PropsIcon from "@/components/Editor/Components/Icon/PropsIcon.js";
//
import PropsImagem from "@/components/Editor/Components/Image/PropsImagem.js";
//
import PropsHtml from "@/components/Editor/Components/Html/PropsHtml.js";
//
import PropsForm from "@/components/Editor/Components/Form/PropsForm.js";
// 
import PropsStepsForm from "@/components/Editor/Components/StepsForm/PropsStepsForm.js";
//
//import PropsCalendar from "@/components/Editor/Components/Calendar/PropsCalendar.js";
//
import propsMaps from "@/components/Editor/Components/Maps/PropsMaps";
//
import PropsCard from "@/components/Editor/Components/Card/PropsCard.js";
//
import PropsTestimony from "@/components/Editor/Components/Testimony/PropsTestimony.js";
//

import propsEditWhatsapp from "./WhatsappFloating/PropsWhatsFloating";

let litComponents = [
  // Básicos
  {
    head: "Básico",
    name: "Texto",
    icon: "type2",
    type: "TextoConteudo",
    component_type: "component",
    propsData: PropsTextoConteudo,
  },
  {
    head: "Básico",
    name: "Imagem",
    icon: "carosel",
    type: "Imagem",
    component_type: "component",
    propsData: PropsImagem,
  },
  {
    head: "Básico",
    name: "Botão",
    icon: "button",
    type: "Button",
    component_type: "component",
    propsData: PropsButton,
    class: "",
  },
  {
    head: "Básico",
    name: "Vídeo",
    icon: "youtube",
    type: "TextoVideo",
    component_type: "component",
    propsData: PropsTextoVideo,
  },
  // Formulários
  {
    head: "Formulários",
    name: "Formulário",
    icon: "list2",
    type: "Form",
    component_type: "component",
    propsData: PropsForm,
  },
  {
    head: "Formulários",
    name: "Formulário em etapas",
    icon: "steps",
    type: "StepsForm",
    component_type: "component",
    propsData: PropsStepsForm,
  },
  // HIDDEN-FEATURE
  // {
  //   head: "Formulários",
  //   name: "Calendário",
  //   icon: "calendar",
  //   type: "Calendar",
  //   component_type: "component",
  //   propsData: PropsCalendar,
  // },
  {
    head: "Formulários",
    name: "PopUp",
    icon: "button",
    type: "PopUp",
    component_type: "component",
    propsData: PropsPopUp,
  },
  // Mídia
  {
    head: "Mídia",
    name: "Carrossel",
    icon: "carosel",
    type: "Carousel",
    component_type: "component",
    propsData: PropsCarrousel,
  },
  {
    head: "Mídia",
    name: "Carrossel múltiplo",
    icon: "carosel",
    type: "CarouselVertical",
    component_type: "component",
    propsData: PropsCarrouselVertical,
  },
  {
    head: "Mídia",
    name: "Galeria",
    icon: "carosel",
    type: "Gallery",
    component_type: "component",
    propsData: PropsGallery,
  },
  {
    head: "Mídia",
    name: "Ícones",
    icon: "circle2",
    type: "Icon",
    component_type: "component",
    propsData: PropsIcon,
  },
  {
    head: "Mídia",
    name: "Card",
    icon: "card",
    type: "Card",
    component_type: "component",
    propsData: PropsCard,
  },
  {
    head: "Mídia",
    name: "Card de imagem",
    icon: "menu",
    type: "MenuFlutuante",
    component_type: "component",
    propsData: PropsMenuFlutuante,
  },
  {
    head: "Mídia",
    name: "Depoimentos",
    icon: "star",
    type: "Testimony",
    component_type: "component",
    propsData: PropsTestimony,
  },
  {
    head: "Mídia",
    name: "Mapa",
    icon: "map-pin",
    type: "Maps",
    component_type: "component",
    propsData: propsMaps,
  },
  // Menus
  {
    head: "Menu",
    name: "Menu horizontal",
    icon: "menu",
    type: "MenuHorizontal",
    component_type: "component",
    propsData: PropsMenuHorizontal,
  },
  {
    head: "Menu",
    name: "Menu lateral",
    icon: "sidebar",
    type: "MenuVertical",
    component_type: "component",
    propsData: PropsMenuVertical,
  },
  // outros
  {
    head: "Outros",
    name: "Contagem Regressiva",
    icon: "clock22",
    type: "CountDown",
    component_type: "component",
    propsData: PropsCountdown,
  },
  {
    head: "Outros",
    name: "HTML",
    icon: "code-html",
    type: "Html",
    component_type: "component",
    propsData: PropsHtml,
  },
  {
    head: "Outros",
    name: "Sanfona",
    icon: "list",
    type: "AccordionList",
    component_type: "component",
    propsData: PropsAccordion,
  },
  {
    head: "Outros",
    name: "Tabela de preços",
    icon: "money",
    type: "Prices",
    component_type: "component",
    propsData: PropsPrices,
  },
  {
    head: "Outros",
    name: "Separador",
    icon: "minus",
    type: "Separador",
    component_type: "component",
    propsData: PropsSeparador,
  },
  {
    head: "Outros",
    name: "Texto dinâmico",
    icon: "type2",
    type: "TextDynamic",
    component_type: "component",
    propsData: PropsTextDynamic,
  },
  {
    head: "Outros",
    name: "Botão Whatsapp",
    icon: "whats-floating",
    type: "WhatsFloating",
    component_type: "component",
    propsData: propsEditWhatsapp
  }
]

export default litComponents;