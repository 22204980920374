<template>
  <div id="SideRightTabs">
    <b-card no-body>
      <b-tabs pills justified small style="width: 100%">
        <!-- Geral -->
        <b-tab
          title="Conteúdo"
          active
        >
          <!-- Imagem -->
          <div class="separador"></div>
          <div class="content">
            <label class="titulo"> Imagem </label>
            <div class="select-image">
              <div @click="openModal('Upload-Editor')">
                <img
                  :src="
                    editaItem.propsData.image.img_logo
                      ? editaItem.propsData.image.img_logo
                      : 'https://gdigital.s3.amazonaws.com/gdigital/8/imagem-vazia%20%28200%C2%A0%C3%97%C2%A0200%C2%A0px%29.webp'
                  "
                  alt="Imagem preview"
                />
              </div>
              <div>
                <input
                  type="text"
                  v-model="editaItem.propsData.image.img_logo"
                />
                <span @click="openModal('Upload-Editor')">{{
                  editaItem.propsData.image.img_logo
                    ? "Alterar imagem"
                    : "Selecionar imagem"
                }}</span>
              </div>
            </div>
            <div class="space"></div>
            <label class="subtitulo"> Link (opcional)</label>
            <input
              placeholder="https://seulink"
              v-model="editaItem.propsData.styles.link"
            />
          </div>

          <div class="separador"></div>
          <div class="content">
            <div class="half">
              <label class="titulo">Legendas</label>
              <div class="contain-buttons" style="margin-top: -10px">
                <button
                  class="btn button-add-produto"
                  @click="addLink"
                ></button>
              </div>
            </div>
            <div v-for="(link, idx) in editaItem.propsData.links" :key="idx">
              <div class="p-2" style="position: relative">
                <div
                  class="separador"
                  style="position: absolute; top: 17px; left: -3px"
                ></div>
                <label class="subtitulo component-group-title"
                  >Legenda {{ idx + 1 }}</label
                >
              </div>
              <div style="display: flex">
                <div
                  style="
                    width: calc(100% - 35px);
                    display: flex;
                    align-items: center;
                  "
                >
                  <input
                    id="menu"
                    placeholder="https://seulink"
                    v-model="editaItem.propsData.links[idx].value"
                  />
                </div>
                <div class="image-control" style="height: 45px">
                  <div>
                    <img
                      :class="{ 'disabled-arrow': idx == 0 }"
                      class="first-arrow"
                      src="@/assets/editor/chevron-down.svg"
                      @click="idx == 0 ? '' : moveUp(idx)"
                      title="Mover para cima"
                    />
                    <button
                      class="button-remove-produto"
                      @click="removeImgIndex(idx)"
                    ></button>
                    <img
                      :class="{
                        'disabled-arrow':
                          idx + 1 == editaItem.propsData.links.length,
                      }"
                      class="last-arrow"
                      src="@/assets/editor/chevron-down.svg"
                      @click="
                        idx + 1 == editaItem.propsData.links.length
                          ? ''
                          : moveDown(idx)
                      "
                      title="Mover para baixo"
                    />
                  </div>
                </div>
              </div>
              <div class="space"></div>
            </div>
          </div>
        </b-tab>

        <!-- Estilo -->
        <b-tab title="Estilo">
          <div>
            <div
              class="separador"
              v-if="editaItem.propsData.hasOwnProperty('alignment')"
            ></div>
            <div
              class="content"
              v-if="editaItem.propsData.hasOwnProperty('alignment')"
            >
              <div class="label-icon">
                <label class="titulo">Posição</label>
                <div>
                  <img
                    src="@/assets/editor/globe.svg"
                    alt="monitor"
                    style="filter: invert(50%) !important"
                    @click="nextIcon('desktop'), (global = !global)"
                    v-if="global"
                  />
                  <img
                    src="@/assets/editor/monitor.svg"
                    alt="monitor"
                    style="filter: invert(50%) !important"
                    @click="nextIcon('tablet')"
                    v-if="typeView === 'desktop' && !global"
                  />
                  <img
                    src="@/assets/editor/tablet.svg"
                    alt="tablet"
                    style="filter: invert(50%) !important"
                    @click="nextIcon('mobile')"
                    v-if="typeView === 'tablet' && !global"
                  />
                  <img
                    src="@/assets/editor/phone.svg"
                    alt="phone"
                    style="filter: invert(50%) !important"
                    @click="nextIcon('desktop'), (global = !global)"
                    v-if="typeView === 'mobile' && !global"
                  />
                </div>
              </div>
              <div style="display: flex; width: 100%; gap: 10px">
                <div style="display: flex; width: 50%; transition: all 0.3s">
                  <div class="select-align">
                    <button
                      :class="{
                        'selected-align':
                          editaItem.propsData.alignment[typeView].justify ==
                          'flex-start',
                      }"
                      @click="globalJustify('flex-start', 'justify')"
                    >
                      <img src="@/assets/editor/align-left-new.svg" />
                    </button>
                    <button
                      :class="{
                        'selected-align':
                          editaItem.propsData.alignment[typeView].justify ==
                          'center',
                      }"
                      @click="globalJustify('center', 'justify')"
                    >
                      <img src="@/assets/editor/align-center-horizontal.svg" />
                    </button>
                    <button
                      :class="{
                        'selected-align':
                          editaItem.propsData.alignment[typeView].justify ==
                          'flex-end',
                      }"
                      @click="globalJustify('flex-end', 'justify')"
                    >
                      <img src="@/assets/editor/align-right-new.svg" />
                    </button>
                  </div>
                </div>

                <div style="display: flex; width: 50%; transition: all 0.3s">
                  <div class="select-align">
                    <button
                      :class="{
                        'selected-align':
                          editaItem.propsData.alignment[typeView]
                            .justify_vertical == 'start',
                      }"
                      @click="globalJustify('start', 'justify_vertical')"
                    >
                      <img src="@/assets/editor/align-top.svg" />
                    </button>
                    <button
                      :class="{
                        'selected-align':
                          editaItem.propsData.alignment[typeView]
                            .justify_vertical == 'center',
                      }"
                      @click="globalJustify('center', 'justify_vertical')"
                    >
                      <img src="@/assets/editor/align-center-vertical.svg" />
                    </button>
                    <button
                      :class="{
                        'selected-align':
                          editaItem.propsData.alignment[typeView]
                            .justify_vertical == 'end',
                      }"
                      @click="globalJustify('end', 'justify_vertical')"
                    >
                      <img src="@/assets/editor/align-bottom.svg" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="separador"></div>

            <!-- Logo -->
            <div class="content">
              <div class="label-icon">
                <label class="titulo">Imagem</label>
                <div>
                  <img
                    src="@/assets/editor/globe.svg"
                    alt="monitor"
                    style="filter: invert(50%) !important"
                    @click="nextIcon('desktop'), (global = !global)"
                    v-if="global"
                  />
                  <img
                    src="@/assets/editor/monitor.svg"
                    alt="monitor"
                    style="filter: invert(50%) !important"
                    @click="nextIcon('tablet')"
                    v-if="typeView === 'desktop' && !global"
                  />
                  <img
                    src="@/assets/editor/tablet.svg"
                    alt="tablet"
                    style="filter: invert(50%) !important"
                    @click="nextIcon('mobile')"
                    v-if="typeView === 'tablet'"
                  />
                  <img
                    src="@/assets/editor/phone.svg"
                    alt="phone"
                    style="filter: invert(50%) !important"
                    @click="nextIcon('desktop'), (global = !global)"
                    v-if="typeView === 'mobile'"
                  />
                </div>
              </div>

              <!-- largura e altura -->
              <div class="half">
                <div>
                  <label class="sub">Altura</label>
                  <input
                    id="menu"
                    placeholder="50"
                    max="1000"
                    min="50"
                    step="10"
                    v-model="editaItem.propsData.size[typeView].height"
                    type="number"                
                    @focus="openDropdownSlider('editaItem.propsData.size[typeView].height')"
                  />
                </div>
                <div>
                  <label class="sub">Largura</label>
                  <input
                    id="menu"
                    placeholder="50"
                    max="1000"
                    min="50"
                    step="10"
                    v-model="editaItem.propsData.size[typeView].width"
                    type="number"
                    @focus="openDropdownSlider('editaItem.propsData.size[typeView].width')"
                  />
                </div>
              </div>
              <div class="space"></div>

              <!-- Arredondar bordas -->
              <div class="label-range">
                <label class="subtitulo">Arredondar bordas </label>
                <img
                  :class="{ borderLinkActive: borderLink === true }"
                  style="
                    height: 18px;
                    transition: all 0.3s;
                    filter: invert(50%);
                    margin-right: 10px;
                  "
                  src="@/assets/editor/link-2.svg"
                  alt="linkar"
                  @click="borderLink = !borderLink"
                />
              </div>
              <div class="inputs-group">
                <input
                  type="number"
                  v-model="editaItem.propsData.border_radius.top"
                  @focus="openDropdownSlider('editaItem.propsData.border_radius.top', 'borderLink')"
                />
                <input
                  type="number"
                  v-model="editaItem.propsData.border_radius.right"
                  @focus="openDropdownSlider('editaItem.propsData.border_radius.right', 'borderLink')"
                />
                <input
                  type="number"
                  v-model="editaItem.propsData.border_radius.bottom"
                  @focus="openDropdownSlider('editaItem.propsData.border_radius.bottom', 'borderLink')"
                />
                <input
                  type="number"
                  v-model="editaItem.propsData.border_radius.left"
                  @focus="openDropdownSlider('editaItem.propsData.border_radius.left', 'borderLink')"
                />
              </div>
            </div>
            <div class="separador"></div>

            <!-- Legendas -->
            <div class="content">
              <label class="titulo">Itens</label>

              <div class="label-range">
                <label class="subtitulo">Arredondar bordas </label>
                <img
                  :class="{ borderLinkActive: borderLinkItens === true }"
                  style="
                    height: 18px;
                    transition: all 0.3s;
                    filter: invert(50%);
                    margin-right: 10px;
                  "
                  src="@/assets/editor/link-2.svg"
                  alt="linkar"
                  @click="borderLinkItens = !borderLinkItens"
                />
              </div>
              <div class="inputs-group">
                <input
                  type="number"
                  v-model="editaItem.propsData.border_radius_item.top"
                  @focus="openDropdownSlider('editaItem.propsData.border_radius_item.top', 'borderLinkItens')"
                />
                <input
                  type="number"
                  v-model="editaItem.propsData.border_radius_item.right"
                  @focus="openDropdownSlider('editaItem.propsData.border_radius_item.right', 'borderLinkItens')"
                />
                <input
                  type="number"
                  v-model="editaItem.propsData.border_radius_item.bottom"
                  @focus="openDropdownSlider('editaItem.propsData.border_radius_item.bottom', 'borderLinkItens')"
                />
                <input
                  type="number"
                  v-model="editaItem.propsData.border_radius_item.left"
                  @focus="openDropdownSlider('editaItem.propsData.border_radius_item.left', 'borderLinkItens')"
                />
              </div>
              <div class="space"></div>

              <label class="sub">Fundo</label>
              <LvColorpicker
                id="LvColorpicker"
                @change="backgroundCreate()"
                v-model="editaItem.propsData.styles.background_header"
                :colors="palette"
              ></LvColorpicker>
              <div class="space"></div>

              <label class="sub">Links</label>
              <LvColorpicker
                id="LvColorpicker"
                v-model="editaItem.propsData.styles.color_itens"
                label="Cor dos Links"
                :colors="palette"
              ></LvColorpicker>
              <div class="space"></div>

              <!-- <label class="subtitulo">Espaçamento entre links</label>
              <input
                id="menu"
                placeholder="Seu Texto"
                v-model="editaItem.propsData.size[typeView].gap_itens"
                type="number"
                @input="globalInput($event, 'gap_itens')"
              /> -->
            </div>
            <div class="separador"></div>
          </div>
        </b-tab>

        <!-- Avançado -->
        <b-tab title="Avançado">
          <advancedBasic
            :spacing="editaItem.propsData.spacing" 
            :visibility="editaItem.propsData.visibility" 
            :animation="editaItem.propsColumn" 
            :attributes="editaItem.propsData.custom" 
            :typeView="typeView"
            :global="global"
            @toggleGlobal="global = !global"
            @nextIcon="nextIcon"
            @update:spacing="editaItem.propsData.spacing = $event"
            @update:visibility="editaItem.propsData.visibility = $event"
            @update:animation="editaItem.propsColumn = $event"
            @update:attributes="editaItem.propsData.custom = $event"
          ></advancedBasic>
        </b-tab>
      </b-tabs>
    </b-card>
    <Upload @setImg="setImg" />
    <dropdownSlider
      ref="dropdownSlider"   
      @updateValue="updateValue"
    /> 
  </div>
</template>

<script>
import dropdownSliderMixin from "@/mixins/dropdownSliderMixin.js";
import advancedBasic from "../EditComponents/advancedBasic.vue";
import Upload from "../../Upload.vue";
import LvColorpicker from "lightvue/color-picker";

export default {
  props: ["editaItem", "typeView", "palette"],
  mixins: [dropdownSliderMixin],
  components: {
    advancedBasic,
    Upload,
    LvColorpicker: LvColorpicker,
  },
  data() {
    return {
      global: false,
      borderLink: true,
      borderLinkItens: true,
      varLinks: {
        borderLink: [
          'editaItem.propsData.border_radius.top',
          'editaItem.propsData.border_radius.right',
          'editaItem.propsData.border_radius.bottom',
          'editaItem.propsData.border_radius.left',
        ],
        borderLinkItens: [
          'editaItem.propsData.border_radius_item.top',
          'editaItem.propsData.border_radius_item.right',
          'editaItem.propsData.border_radius_item.bottom',
          'editaItem.propsData.border_radius_item.left',
        ],
      },
    };
  },
  methods: {
    moveUp(idx) {
      var moving = this.editaItem.propsData.links[idx];
      this.editaItem.propsData.links.splice(idx, 1);
      this.editaItem.propsData.links.splice(idx - 1, 0, moving);
    },
    moveDown(idx) {
      var moving = this.editaItem.propsData.links[idx];
      this.editaItem.propsData.links.splice(idx, 1);
      this.editaItem.propsData.links.splice(idx + 1, 0, moving);
    },
    globalJustify(value, place) {
      let props = this.editaItem.propsData.alignment;
      props[this.typeView][place] = value;
      if (this.global && this.typeView == "desktop") {
        props[this.typeView][place] = value;
        props[this.typeView][place] = value;
      }
    },   
    nextIcon(type) {
      this.$emit("attIconView", type);
    },   
    setImg(img) {
      this.editaItem.propsData.image.img_logo = img;
    },
    removeImgIndex(index) {
      this.editaItem.propsData.links.splice(index, 1);
    },
    addLink() {
      this.editaItem.propsData.links.push({
        name: "Novo link",
        link: "https://greenn.com.br/",
      });
    },
    openModal(data) {
      // console.log('modal ?',data)
      this.$bvModal.show(data);
    },
  },
  created() {
    if (this.typeView == "desktop") {
      this.global = true;
    }
  },
};
</script>
