<template>
  <div id="SideRightTabs">
    <b-tabs pills fill small style="width: 100%" v-if="editmodal == false" >
      <!-- Geral -->
      <b-tab title="Geral" active >
        <div class="b-tab-content">
          <!-- Conteúdo -->
          <div class="separador"></div>
          <div class="content">
            <label class="titulo">Modal</label>
            <div style="display: flex; width: 100%; transition: all 0.3s">
            <label class="subtitulo"
                >Visualizar PopUp
            </label>
            <b-form-checkbox
              @change="$emit('openClosePopUP',$event, editaItem.propsData)"
              v-model="editaItem.propsData.button.viewPopup"
              style="margin-top: -5px; margin-left: auto; margin-right: 0"
              name="is_main"
              size="lg"
              switch
            >
            </b-form-checkbox>
          </div>
          <b-button class="editmodal" @click="isEdit(true, editaItem.propsData)">
            <span class="subtitulo txteditmodal" @click="isEdit(true, editaItem.propsData)">Editar modal</span>
          </b-button>
          
          </div>
          <div class="separador"></div>
            <div class="content">
              <label class="titulo">Botão</label>
            <div >
            <label class="subtitulo">Texto</label>
            <textarea
              v-focus
              v-model="editaItem.propsData.button.content_button"
              rows="2"
              class="w-100"
            ></textarea>
            <label class="subtitulo">Ícone do botão</label>
            <div
              class="select-image"
              v-if="editaItem.propsData.icon.svg.hasOwnProperty('name')"
            > 
              <div @click="openModal('Modal-Icons-svg')">
                <span
                  class="material-icons icon-preview"
                  v-html="editaItem.propsData.icon.svg.code"
                ></span>
              </div>
              <div>
                <input
                  type="text"
                  v-model="editaItem.propsData.icon.svg.name"
                />
                <div class="with-trash">
                  <span @click="openModal('Modal-Icons-svg')">{{
                    editaItem.propsData.icon.svg
                      ? "Alterar thumbnail"
                      : "Selecionar thumbnail"
                  }}</span>
                  <img
                    v-show="editaItem.propsData.icon.svg"
                    title="Remover thumbnail"
                    @click="editaItem.propsData.icon.svg = ''"
                    class="trash"
                    src="@/assets/editor/trash-2.svg"
                  />
                </div>
              </div>
            </div>
            <div class="select-image" v-else>
              <div @click="openModal('Modal-Icons-svg')">
                <span class="material-icons icon-preview">{{
                  editaItem.propsData.icon.svg
                }}</span>
              </div>
              <div>
                <input type="text" v-model="editaItem.propsData.icon.svg" />
                <div class="with-trash">
                  <span @click="openModal('Modal-Icons-svg')">{{
                    editaItem.propsData.icon.svg
                      ? "Alterar ícone"
                      : "Selecionar ícone"
                  }}</span>
                  <img
                    v-show="editaItem.propsData.icon.svg"
                    title="Remover thumbnail"
                    @click="editaItem.propsData.icon.svg = ''"
                    class="trash"
                    src="@/assets/editor/trash-2.svg"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
      </b-tab>
      <!-- Estilo -->
      <b-tab title="Estilo">
        <div class="b-tab-content">
          <div class="separador" v-if="editaItem.propsData.icon.svg"></div>
          <div class="content" v-if="editaItem.propsData.icon.svg">
            <div class="label-icon">
              <label class="titulo">Ícone</label>
              <div>
                <img
                  src="@/assets/editor/globe.svg"
                  alt="monitor"
                  style="filter: invert(50%) !important"
                  @click="nextIcon('desktop'), (global = !global)"
                  v-if="global"
                />
                <img
                  src="@/assets/editor/monitor.svg"
                  alt="monitor"
                  style="filter: invert(50%) !important"
                  @click="nextIcon('tablet')"
                  v-if="typeView === 'desktop' && !global"
                />
                <img
                  src="@/assets/editor/tablet.svg"
                  alt="tablet"
                  style="filter: invert(50%) !important"
                  @click="nextIcon('mobile')"
                  v-if="typeView === 'tablet' && !global"
                />
                <img
                  src="@/assets/editor/phone.svg"
                  alt="phone"
                  style="filter: invert(50%) !important"
                  @click="nextIcon('desktop'), (global = !global)"
                  v-if="typeView === 'mobile' && !global"
                />
              </div>
            </div>
            <label class="sub">Cor do ícone</label>
            <LvColorpicker
              id="LvColorpicker"
              v-model="editaItem.propsData.icon.color"
              :colors="palette"
            ></LvColorpicker>
            <div class="space"></div>

            <div
              style="display: flex; width: 100%; transition: all 0.3s"
              v-if="editaItem.propsData.icon.hasOwnProperty('isRight')"
            >
              <label
                class="subtitulo"
                :class="{ labelOff: !editaItem.propsData.icon }"
                >Ícone à direita</label
              >
              <b-form-checkbox
                style="margin-top: -5px; margin-left: auto; margin-right: 0"
                v-model="editaItem.propsData.icon.isRight"
                name="is_main"
                size="lg"
                switch
              >
              </b-form-checkbox>
            </div>
            <div
              class="space"
              v-if="editaItem.propsData.icon.hasOwnProperty('isRight')"
            ></div>

            <div class="label-range">
              <label class="subtitulo">Tamanho</label>
              <input
                type="number"
                v-model="editaItem.propsData.icon.size[typeView].font_size"
                @input="globalInput($event, 'size', 'font_size', true)"
              />
            </div>
            <b-form-input
              v-model="editaItem.propsData.icon.size[typeView].font_size"
              @input="globalInput($event, 'size', 'font_size', true)"
              type="range"
              min="0"
              max="50"
              step="1"
            ></b-form-input>
            <div
              class="space"
              v-if="editaItem.propsData.button.content_button.length"
            ></div>
            <div
              class="label-range"
              v-if="editaItem.propsData.button.content_button.length"
            >
              <label class="subtitulo">Margem lateral</label>
              <input
                type="number"
                v-model="editaItem.propsData.icon.size[typeView].margin"
                @input="globalInput($event, 'size', 'margin', true)"
              />
            </div>
            <b-form-input
              v-model="editaItem.propsData.icon.size[typeView].margin"
              @input="globalInput($event, 'size', 'margin', true)"
              v-if="editaItem.propsData.button.content_button.length"
              type="range"
              min="0"
              max="25"
              step="1"
            ></b-form-input>
          </div>
          <div class="separador"></div>
          <div class="content">
            <label class="titulo">Fundo</label>
            <label class="sub">Tipo do fundo</label>
            <!-- Seleção do tipo de fundo -->
            <multiselect
              v-model="editaItem.propsData.styles.back_selected"
              :options="['Cor sólida', 'Gradiente']"
              :searchable="false"
              :show-labels="false"
              placeholder="Tipo do fundo"
              @click.capture="backgroundCreate()"
              @input="delayedBackgroundCreate()"
              :hide-selected="true"
              :allow-empty="false"
            ></multiselect>
            <!-- Espaço -->
            <div
              v-show="
                !editaItem.propsData.styles.back_selected ||
                editaItem.propsData.styles.back_selected == 'Imagem' ||
                editaItem.propsData.styles.back_selected == 'Cor sólida'
              "
              style="height: 1rem"
            ></div>

            <!-- Cor sólida -->
            <div
              v-show="editaItem.propsData.styles.back_selected == 'Cor sólida'"
            >
              <label class="subtitulo">Cor padrão</label>
              <LvColorpicker
                id="LvColorpicker"
                @change="backgroundCreate()"
                @input="backgroundCreate()"
                v-model="editaItem.propsData.styles.back_content"
                :colors="palette"
              ></LvColorpicker>
              <div
                v-if="editaItem.propsData.styles.hasOwnProperty('hover_color')"
              >
                <div class="space"></div>
                <label class="subtitulo">Cor ao passar o mouse</label>
                <LvColorpicker
                  id="LvColorpicker"
                  v-model="editaItem.propsData.styles.hover_color"
                  :colors="palette"
                ></LvColorpicker>
              </div>
            </div>

            <!-- Gradiente -->
            <div
              v-show="editaItem.propsData.styles.back_selected == 'Gradiente'"
            >
              <!-- Pickers -->
              <div class="pickers pb-2">
                <div class="picker">
                  <LvColorpicker
                    @input="backgroundCreate()"
                    id="LvColorpicker"
                    v-model="editaItem.propsData.styles.grad_color1"
                    :colors="palette"
                  ></LvColorpicker>
                </div>
                <div class="picker">
                  <LvColorpicker
                    @input="backgroundCreate()"
                    id="LvColorpicker"
                    v-model="editaItem.propsData.styles.grad_color2"
                    :colors="palette"
                  ></LvColorpicker>
                </div>
              </div>
              <div class="space"></div>

              <!-- Direção -->
              <div class="label-range">
                <label class="subtitulo">Direção do gradiente</label>
                <input
                  type="number"
                  @input="backgroundCreate()"
                  v-model="editaItem.propsData.styles.grad_angle"
                />
              </div>
              <b-form-input
                @input="backgroundCreate()"
                v-model="editaItem.propsData.styles.grad_angle"
                type="range"
                min="0"
                max="360"
                step="1"
              ></b-form-input>
            </div>
          </div>
          <div class="separador"></div>
          <!-- Tipografia -->
          <div class="content max-multi">
            <div class="label-icon">
              <label class="titulo">Tipografia</label>
              <div>
                <img
                  src="@/assets/editor/globe.svg"
                  alt="monitor"
                  style="filter: invert(50%) !important"
                  @click="nextIcon('desktop'), (global = !global)"
                  v-if="global"
                />
                <img
                  src="@/assets/editor/monitor.svg"
                  alt="monitor"
                  style="filter: invert(50%) !important"
                  @click="nextIcon('tablet')"
                  v-if="typeView === 'desktop' && !global"
                />
                <img
                  src="@/assets/editor/tablet.svg"
                  alt="tablet"
                  style="filter: invert(50%) !important"
                  @click="nextIcon('mobile')"
                  v-if="typeView === 'tablet' && !global"
                />
                <img
                  src="@/assets/editor/phone.svg"
                  alt="phone"
                  style="filter: invert(50%) !important"
                  @click="nextIcon('desktop'), (global = !global)"
                  v-if="typeView === 'mobile' && !global"
                />
              </div>
            </div>
            <div
              class="mt-2"
              style="
                display: flex;
                gap: 10px;
                width: 100%;
                justify-content: space-between;
                margin-bottom: -1rem;
              "
            >
              <b-button-group>
                <b-button
                  :disabled="
                    editaItem.propsData.button_size[typeView].font_size == 0
                  "
                  size="sm"
                  style="
                    height: min-content !important;
                    padding: 10.5px 7px !important;
                  "
                  @click="
                    editaItem.propsData.button_size[typeView].font_size--,
                      globalInput(
                        editaItem.propsData.button_size[typeView].font_size,
                        'button_size',
                        'font_size',
                        false
                      )
                  "
                  ><img src="@/assets/editor/minus3.svg" alt="align"
                /></b-button>
                <b-form-input
                  @input="
                    globalInput($event, 'button_size', 'font_size', false)
                  "
                  class="text-right"
                  v-model="editaItem.propsData.button_size[typeView].font_size"
                  style="
                    width: 44px;
                    text-align: center !important;
                    border: none !important;
                    padding: 0 !important;
                  "
                ></b-form-input>
                <b-button
                  @click="
                    editaItem.propsData.button_size[typeView].font_size++,
                      globalInput(
                        editaItem.propsData.button_size[typeView].font_size,
                        'button_size',
                        'font_size',
                        false
                      )
                  "
                  size="sm"
                  style="
                    height: min-content !important;
                    padding: 10.5px 7px !important;
                  "
                  ><img src="@/assets/editor/plus.svg" alt="align"
                /></b-button>
              </b-button-group>

              <div style="width: 30%">
                <multiselect
                  v-model="editaItem.propsData.button_size.textFontMeasure"
                  :options="['px', 'rem', '%', 'em']"
                  :searchable="false"
                  :show-labels="false"
                  placeholder=" "
                  :hide-selected="true"
                  :allow-empty="false"
                ></multiselect>
              </div>

              <div>
                <b-button
                  size="sm"
                  @click="trocarType"
                  style="
                    height: min-content !important;
                    padding: 10.5px 10px !important;
                  "
                  ><img src="@/assets/editor/type.svg" alt="align"
                /></b-button>
              </div>
            </div>

            <div class="space" />

            <div class="space" />
            <!-- Fonte e cor -->
            <multiselect
              v-model="editaItem.propsData.styles.font_family"
              :options="fontOptions"
              :searchable="true"
              :show-labels="false"
              placeholder="Fonte"
              :hide-selected="true"
              :allow-empty="false"
              @input="$emit('callCharge',$event)"
            ></multiselect>
            <div class="space" />
            <div
              v-if="editaItem.propsData.styles.hasOwnProperty('hover_font')"
              class="half"
            >
              <div>
                <label class="sub">Cor</label>
                <LvColorpicker
                  id="LvColorpicker"
                  v-model="editaItem.propsData.styles.color_button"
                  :colors="palette"
                ></LvColorpicker>
              </div>
              <div>
                <label class="sub">Sob o mouse</label>
                <LvColorpicker
                  id="LvColorpicker"
                  v-model="editaItem.propsData.styles.hover_font"
                  :colors="palette"
                ></LvColorpicker>
              </div>
            </div>
            <LvColorpicker
              v-else
              id="LvColorpicker"
              v-model="editaItem.propsData.styles.color_button"
              :colors="palette"
            ></LvColorpicker>

            <div class="space"></div>

            <!-- Texto -->
            <textarea
              v-model="editaItem.propsData.button.content_button"
              rows="2"
              class="w-100"
            ></textarea>

            <div class="space"></div>

            <div class="label-range">
              <label class="subtitulo">Espessura da fonte</label>
              <input
                type="number"
                v-model="editaItem.propsData.styles.font_weight"
              />
            </div>
            <b-form-input
              v-model="editaItem.propsData.styles.font_weight"
              type="range"
              min="100"
              max="900"
              step="100"
            ></b-form-input>

            <div class="space"></div>

            <div class="label-range">
              <label class="subtitulo">Espaço entre letras</label>
              <input
                type="number"
                v-model="editaItem.propsData.styles.letter_space"
              />
            </div>
            <b-form-input
              v-model="editaItem.propsData.styles.letter_space"
              type="range"
              min="-15"
              max="15"
              step="0.25"
            ></b-form-input>
          </div>

          <!-- Dimensões -->
          <div class="separador"></div>
          <div class="content">
            <!-- Label dimensões -->
            <div class="label-icon">
              <label class="titulo">Dimensões</label>
              <div>
                <img
                  src="@/assets/editor/globe.svg"
                  alt="monitor"
                  style="filter: invert(50%) !important"
                  @click="nextIcon('desktop'), (global = !global)"
                  v-if="global"
                />
                <img
                  src="@/assets/editor/monitor.svg"
                  alt="monitor"
                  style="filter: invert(50%) !important"
                  @click="nextIcon('tablet')"
                  v-if="typeView === 'desktop' && !global"
                />
                <img
                  src="@/assets/editor/tablet.svg"
                  alt="tablet"
                  style="filter: invert(50%) !important"
                  @click="nextIcon('mobile')"
                  v-if="typeView === 'tablet' && !global"
                />
                <img
                  src="@/assets/editor/phone.svg"
                  alt="phone"
                  style="filter: invert(50%) !important"
                  @click="nextIcon('desktop'), (global = !global)"
                  v-if="typeView === 'mobile' && !global"
                />
              </div>
            </div>

            <!-- dimensões automáticas -->
            <div
              style="display: flex; width: 100%; transition: all 0.3s"
              v-if="editaItem.propsData.size[typeView].hasOwnProperty('isAuto')"
            >
              <label class="subtitulo"
                >Dimensões automáticas
                <img
                  width="17px"
                  height="17px"
                  src="@/assets/editor/help-circle.svg"
                  alt="icon"
                  v-b-tooltip.hover
                  title="Dimensões automáticas respeitarão as dimensões do conteúdo do botão e suas margens internas"
              /></label>
              <b-form-checkbox
                style="margin-top: -5px; margin-left: auto; margin-right: 0"
                v-model="editaItem.propsData.size[typeView].isAuto"
                name="is_main"
                size="lg"
                switch
              >
              </b-form-checkbox>
            </div>

             <!-- largura  100%-->
             <div class="d-flex">
              <button class="edit-modal" @click="largeFull()">Aplicar largura completa</button>
            </div>

            <!-- Altura e largura -->
            <div class="half">
              <div>
                <label
                  class="subtitulo"
                  :class="{
                    labelOff:
                      editaItem.propsData.size[typeView].hasOwnProperty(
                        'isAuto'
                      ) && editaItem.propsData.size[typeView].isAuto == true,
                  }"
                  >Altura</label
                >
                <section id="Altura mínima" class="und_form">
                  <section class="und_form">
                    <input
                      :disabled="
                        editaItem.propsData.size[typeView].hasOwnProperty(
                          'isAuto'
                        ) && editaItem.propsData.size[typeView].isAuto == true
                      "
                      id="Largura-fixa"
                      v-model="editaItem.propsData.size[typeView].height"
                      type="number"
                      @input="globalInput($event, 'size', 'height', false)"
                      min="0"
                    />
                    <span
                      @click="
                        editaItem.propsData.size[typeView].hasOwnProperty(
                          'isAuto'
                        ) && editaItem.propsData.size[typeView].isAuto == true
                          ? ''
                          : next_und('und_height')
                      "
                      class="und text validClick"
                      v-b-tooltip.hover
                      title="Largura em pixel"
                    >
                      {{ editaItem.propsData.size[typeView].und_height }}</span
                    >
                  </section>
                </section>
              </div>

              <div>
                <label
                  class="subtitulo"
                  :class="{
                    labelOff:
                      editaItem.propsData.size[typeView].hasOwnProperty(
                        'isAuto'
                      ) && editaItem.propsData.size[typeView].isAuto == true,
                  }"
                  >Largura</label
                >
                <section id="Altura mínima" class="und_form">
                  <section class="und_form">
                    <input
                      :disabled="
                        editaItem.propsData.size[typeView].hasOwnProperty(
                          'isAuto'
                        ) && editaItem.propsData.size[typeView].isAuto == true
                      "
                      id="Largura-fixa"
                      v-model="editaItem.propsData.size[typeView].width"
                      type="number"
                      @input="globalInput($event, 'size', 'width', false)"
                      min="0"
                    />
                    <span
                      @click="
                        editaItem.propsData.size[typeView].hasOwnProperty(
                          'isAuto'
                        ) && editaItem.propsData.size[typeView].isAuto == true
                          ? ''
                          : next_und('und_width')
                      "
                      class="und text validClick"
                      v-b-tooltip.hover
                      title="Largura em pixel"
                    >
                      {{ editaItem.propsData.size[typeView].und_width }}</span
                    >
                  </section>
                </section>
              </div>
            </div>
            <div class="space"></div>

            <!-- Posição -->
            <div>
              <label class="subtitulo">Posicionamento</label>
              <div style="display: flex; width: 100%; gap: 10px">
                <div style="display: flex; width: 50%; transition: all 0.3s">
                  <div class="select-align">
                    <button
                      :class="{
                        'selected-align':
                          editaItem.propsData.alignment[typeView].justify ==
                          'flex-start',
                      }"
                      @click="globalJustify('flex-start', 'justify')"
                    >
                      <img src="@/assets/editor/align-left-new.svg" />
                    </button>
                    <button
                      :class="{
                        'selected-align':
                          editaItem.propsData.alignment[typeView].justify ==
                          'center',
                      }"
                      @click="globalJustify('center', 'justify')"
                    >
                      <img src="@/assets/editor/align-center-horizontal.svg" />
                    </button>
                    <button
                      :class="{
                        'selected-align':
                          editaItem.propsData.alignment[typeView].justify ==
                          'flex-end',
                      }"
                      @click="globalJustify('flex-end', 'justify')"
                    >
                      <img src="@/assets/editor/align-right-new.svg" />
                    </button>
                  </div>
                </div>

                <div style="display: flex; width: 50%; transition: all 0.3s">
                  <div class="select-align">
                    <button
                      :class="{
                        'selected-align':
                          editaItem.propsData.alignment[typeView]
                            .justify_vertical == 'start',
                      }"
                      @click="globalJustify('start', 'justify_vertical')"
                    >
                      <img src="@/assets/editor/align-top.svg" />
                    </button>
                    <button
                      :class="{
                        'selected-align':
                          editaItem.propsData.alignment[typeView]
                            .justify_vertical == 'center',
                      }"
                      @click="globalJustify('center', 'justify_vertical')"
                    >
                      <img src="@/assets/editor/align-center-vertical.svg" />
                    </button>
                    <button
                      :class="{
                        'selected-align':
                          editaItem.propsData.alignment[typeView]
                            .justify_vertical == 'end',
                      }"
                      @click="globalJustify('end', 'justify_vertical')"
                    >
                      <img src="@/assets/editor/align-bottom.svg" />
                    </button>
                  </div>
                </div>
              </div>
              <div class="space"></div>
            </div>
            <div>
              <label for="form-altura" class="sub"
                >Altura mínima da coluna</label
              >
              <b-form-group id="form-altura" class="und_form">
                <div class="und_form">
                  <b-form-input
                    id="form-altura"
                    v-model="editaItem.propsColumn.height[typeView].height"
                    type="number"
                    min="0"
                  ></b-form-input>
                  <span
                    @click="next_und_height"
                    class="und text"
                    v-b-tooltip.hover
                    title="Altura em pixel"
                    >{{
                      editaItem.propsColumn.height[typeView].height_und
                    }}</span
                  >
                </div>
              </b-form-group>
            </div>
          </div>
          <!-- Borda -->
          <div class="separador"></div>
          <div class="content">
            <label class="titulo">Bordas</label>
            <div>
              <div class="label-range">
                <label class="subtitulo">Arredondar bordas </label>
                <img
                  :class="{ borderLinkActive: borderLink === true }"
                  style="
                    height: 18px;
                    transition: all 0.3s;
                    filter: invert(50%);
                  "
                  src="@/assets/editor/link-2.svg"
                  alt="linkar"
                   @click="borderLink = !borderLink"
                />
              </div>
              <div class="container-mg">
                <b-form-group label-for="email">
                  <b-form-input
                    style="text-align: center; border: 0.5px solid var(--white-medium) !important;"
                    type="number"
                    v-model="editaItem.propsData.border.border_radius_top"
                    @focus="openDropdownSlider('editaItem.propsData.border.border_radius_top', 'borderLink')"                 
                  ></b-form-input>
                </b-form-group>
                <b-form-group label-for="email">
                  <b-form-input
                    type="number"
                    style="text-align: center; border: 0.5px solid var(--white-medium) !important;"
                    v-model="editaItem.propsData.border.border_radius_right"
                    @focus="openDropdownSlider('editaItem.propsData.border.border_radius_right', 'borderLink')"    
                  ></b-form-input>
                </b-form-group>
                <b-form-group label-for="email">
                  <b-form-input
                    type="number"
                    style="text-align: center; border: 0.5px solid var(--white-medium) !important;"
                    v-model="editaItem.propsData.border.border_radius_bottom"
                    @focus="openDropdownSlider('editaItem.propsData.border.border_radius_bottom', 'borderLink')" 
                  ></b-form-input>
                </b-form-group>
                <b-form-group label-for="email">
                  <b-form-input
                    type="number"
                    style="text-align: center; border: 0.5px solid var(--white-medium) !important;"
                    v-model="editaItem.propsData.border.border_radius_left"
                    @focus="openDropdownSlider('editaItem.propsData.border.border_radius_left', 'borderLink')" 
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="space"></div>
            </div>

            <!-- Bordas visíveis e cor -->
            <div style="display: flex; width: 100%; transition: all 0.3s">
              <label class="subtitulo">Bordas visíveis</label>
              <b-form-checkbox
                style="margin-top: -5px; margin-left: auto; margin-right: 0"
                v-model="hasBorder"
                name="is_main"
                size="lg"
                switch
              >
              </b-form-checkbox>
            </div>
            <div v-if="hasBorder">
              <div class="space"></div>
              <div
                v-if="editaItem.propsData.styles.hasOwnProperty('hover_border')"
                class="half"
              >
                <div>
                  <label class="sub">Cor</label>
                  <LvColorpicker
                    id="LvColorpicker"
                    v-model="borderColor"
                    :colors="palette"
                  ></LvColorpicker>
                </div>
                <div>
                  <label class="sub">Sob o mouse</label>
                  <LvColorpicker
                    id="LvColorpicker"
                    v-model="editaItem.propsData.styles.hover_border"
                    :colors="palette"
                  ></LvColorpicker>
                </div>
              </div>
              <div v-else>
                <label class="sub">Cor da borda</label>
                <LvColorpicker
                  id="LvColorpicker"
                  v-model="borderColor"
                  :colors="palette"
                ></LvColorpicker>
              </div>
              <div class="space"></div>
              <!-- Espessura e traço -->
              <div class="label-range">
                <label class="subtitulo">Espessura</label>
                <input type="number" v-model="borderThickness" />
              </div>
              <b-form-input
                id="range-1"
                v-model="borderThickness"
                type="range"
                min="0"
                max="10"
                step="1"
              ></b-form-input>
              <div class="space"></div>
              <label class="subtitulo">Traço</label>
              <multiselect
                v-model="borderStyle"
                :options="[
                  '● ● ● ● ● ● ● ● ● ● ● ● ● ●',
                  '━━━━━━━━━━━━━━━━━━',
                  '━ ━ ━ ━ ━ ━ ━ ━ ━ ━',
                  '══════════════════',
                ]"
                :searchable="false"
                :show-labels="false"
                placeholder="Traço"
              ></multiselect>
            </div>
          </div>

          <!-- Sombra -->
          <div class="separador"></div>
          <div class="content">
            <div style="display: flex; width: 100%; transition: all 0.3s">
              <label class="titulo">Sombra</label>
              <b-form-checkbox
                style="margin-top: -5px; margin-left: auto; margin-right: 0"
                v-model="shadow"
                name="is_main"
                size="lg"
                switch
              >
              </b-form-checkbox>
            </div>
            <!-- Cor, espessura e traço -->
            <div v-if="shadow">
              <label class="subtitulo">Cor da sombra</label>
              <LvColorpicker
                id="LvColorpicker"
                v-model="shadowColor"
                :colors="palette"
              ></LvColorpicker>
              <div class="space"></div>
              <div class="three">
                <div>
                  <label class="subtitulo">Eixo X</label>
                  <input type="number" v-model="shadowX" />
                </div>
                <div>
                  <label class="subtitulo">Eixo Y</label>
                  <input type="number" v-model="shadowY" />
                </div>
                <div>
                  <label class="subtitulo">Borrão</label>
                  <input type="number" v-model="shadowBlur" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-tab>

      <!-- Avançado -->
      <b-tab title="Avançado">
        <div class="separador"></div>
        <advancedBasic
          :spacing="editaItem.propsData.spacing" 
          :visibility="editaItem.propsData.visibility" 
          :animation="editaItem.propsColumn" 
          :attributes="editaItem.propsData.custom" 
          :typeView="typeView"
          :global="global"          
          @toggleGlobal="global = !global"
          @nextIcon="nextIcon"
          @update:spacing="editaItem.propsData.spacing = $event"
          @update:visibility="editaItem.propsData.visibility = $event"
          @update:animation="editaItem.propsColumn = $event"
          @update:attributes="editaItem.propsData.custom = $event"
        ></advancedBasic>
        <div class="separador"></div>
      </b-tab>
    </b-tabs>
    <b-tabs pills justified small style="width: 100%" v-else>
      <b-tab title="Voltar" @click="isClose()"> </b-tab>
      <b-tab title="Conteúdo" active> 
      <div class="separador"></div>
        <div class="content">
          <label class="titulo">Modal</label>
          <div style="display: flex; width: 100%; transition: all 0.3s">
          <label class="subtitulo"
              >Visualizar PopUp
          </label>
          <b-form-checkbox
              v-model="editaItem.propsData.button.viewPopup"
              style="margin-top: -5px; margin-left: auto; margin-right: 0"
              name="is_main"
              size="lg"
              switch
            >
            </b-form-checkbox>
        </div>
        </div>
        <div class="separador"></div>
        <div class="content">
        <label class="titulo">Formulário</label>
        <label class="subtitulo">Formulário referente</label>
        <div class="max-multi">
          <BaseSelect
            id="ajax"
            :selectModel="editaItem.propsData.form"
            :show-labels="false"
            placeholder="Selecione um formulário"
            :hide-selected="false"
            track-by="id"
            :allow-empty="false"
            :array="forms"
            trackname="title"
            :searchable="true"
            noResult="Nenhum formulário encontrado."
            noOptions="Pesquise pelo formulário"
            selectLabel=""
            deselectLabel=""
            selectedLabel=""
            @change="setaForm($event)"
            @callback="getForms($event)"
            >
          </BaseSelect>
        </div>
        <div class="space"></div>

          <!-- Cabeçalho -->
          <div>
            <label class="subtitulo">Cabeçalho</label>
            <input
              :disabled="
                !editaItem.propsData.form && !editaItem.propsData.form.id
              "
              @input="debounce('adicionais')"
              type="text"
              placeholder="Cadastre-se aqui!"
              name="msg"
              id="msg"
              v-model="editaItem.propsData.header"
            />
            <div class="space"></div>
          </div>

          <!-- Rodapé -->
          <div>
            <label class="subtitulo">Rodapé</label>
            <input
              :disabled="
                !editaItem.propsData.form && !editaItem.propsData.form.id
              "
              placeholder="Política de privacidade"
              @input="debounce('adicionais')"
              type="text"
              name="msg"
              id="msg"
              v-model="editaItem.propsData.footer"
            />
            <div class="space"></div>
          </div>

          <!-- Link do rodapé -->
          <div v-if="editaItem.propsData.footer">
            <label class="subtitulo">Link do rodapé</label>
            <input
              placeholder="https://seulink"
              :disabled="
                !editaItem.propsData.form && !editaItem.propsData.form.id
              "
              @input="debounce('adicionais')"
              type="text"
              name="msg"
              id="msg"
              v-model="editaItem.propsData.footer_link"
            />
          </div>
        </div>
        <div class="separador"></div>

        <!-- Agradecimento -->
        <div class="content">
          <label class="titulo">Agradecimento</label>
          <label class="subtitulo">Tipos</label>
          <multiselect
            v-model="editaItem.propsData.opcao_agradecimento"
            :options="editaItem.propsData.opcoes_agradecimento"
            :searchable="false"
            label="text"
            track-by="value"
            :show-labels="false"
            placeholder="Selecione um tipo de agradecimento"
            :hide-selected="true"
            @select="saveOpt('page')"
          ></multiselect>
          <div class="space"></div>

          <!-- Página de agradecimento -->
          <div class=" mb-3"
            v-if="
              editaItem.propsData.opcao_agradecimento.value === 'thank_you_page'
            "
          >
            <label class="subtitulo">Página de agradecimento</label>
            <div class="max-multi">
              <BaseSelect
                id="ajax"
                :selectModel="editaItem.propsData.thank_you_page"
                :show-labels="false"
                placeholder="Página de agradecimentos"
                :hide-selected="true"
                track-by="id"
                :allow-empty="false"
                :array="pages"
                trackname="title"
                :searchable="false"
                noResult="Nenhum página encontrada."
                selectLabel=""
                deselectLabel=""
                selectedLabel=""
                @change="editaItem.propsData.thank_you_page = $event, saveOpt('page')"
                @callback="getPages($event)"
                >
              </BaseSelect>
            </div>
            <div v-if="editaItem.propsData.thank_you_page && editaItem.propsData.thank_you_page.title && !editaItem.propsData.thank_you_page.id">
              <div class="space"></div>
              <label class="subtitulo">Link externo</label>
              <input @input="saveOpt('page')" type="text" v-model="editaItem.propsData.thank_you_page.path_name">
            </div>
          </div>

          <!-- Pop up -->
          <label class="subtitulo mt-2">Mensagem de confirmação</label>
          <textarea
            @input="debounce('popup')"
            :disabled="
              !editaItem.propsData.form && !editaItem.propsData.form.id
            "
            placeholder="Cadastro realizado"
            type="text"
            name="msg"
            id="msg"
            rows="2"
            style="height: auto !important; resize: none;"
            v-model="editaItem.propsData.success_mensage"
            resize="false"
          />
          <label class="subtitulo"
            >Mensagem de erro</label
          >
          <textarea
            placeholder="Confira os dados digitados"
            :disabled="
              !editaItem.propsData.form && !editaItem.propsData.form.id
            "
            @input="debounce('popup')"
            type="text"
            name="msg"
            id="msg"
            v-model="editaItem.propsData.error_mensage"
          />

          <!-- Mensagem whatsapp -->
          <div
            v-if="editaItem.propsData.opcao_agradecimento.value === 'whatsapp'"
          >
            <label class="subtitulo">Número de telefone de origem</label>
            <input
              @input="debounce('whatsapp')"
              :disabled="
                !editaItem.propsData.form && !editaItem.propsData.form.id
              "
              type="text"
              v-mask="['+##(##) ####-####', '+##(##) #####-####']"
              name="msg"
              id="msg"
              placeholder="Apenas números"
              v-model="editaItem.propsData.phone"
            />
            <div class="space"></div>
            <label class="subtitulo">Mensagem para ser enviada</label>
            <textarea
              placeholder="Olá! Recebi o seu cadastro na página..."
              @input="debounce('whatsapp')"
              :disabled="
                !editaItem.propsData.form && !editaItem.propsData.form.id
              "
              type="text"
              name="msg"
              id="msg"
              v-model="editaItem.propsData.whatsapp_mensage"
            />
          </div>
        </div> 
        <div class="separador"></div>
        <div class="content">
              <label class="titulo">Botão</label>
            <div >
            <label class="subtitulo">Texto</label>
            <textarea
              v-model="editaItem.propsData.content_button"
              rows="2"
              class="w-100"
            ></textarea>
            <div class="space"></div>
            <label class="subtitulo">Icone do botão</label>
            <div
              class="select-image"
              v-if="editaItem.propsData.icon_modal.svg.hasOwnProperty('name')"
            > 
              <div @click="openModal('Modal-Icons-svg')">
                <span
                  class="material-icons icon-preview"
                  v-html="editaItem.propsData.icon_modal.svg.code"
                ></span>
              </div>
              <div>
                <input
                  type="text"
                  v-model="editaItem.propsData.icon_modal.svg.name"
                />
                <div class="with-trash">
                  <span @click="openModal('Modal-Icons-svg')">{{
                    editaItem.propsData.icon_modal.svg
                      ? "Alterar thumbnail"
                      : "Selecionar thumbnail"
                  }}</span>
                  <img
                    v-show="editaItem.propsData.icon_modal.svg"
                    title="Remover thumbnail"
                    @click="editaItem.propsData.icon_modal.svg = ''"
                    class="trash"
                    src="@/assets/editor/trash-2.svg"
                  />
                </div>
              </div>
            </div>
            <div class="select-image" v-else>
              <div @click="openModal('Modal-Icons-svg')">
                <span class="material-icons icon-preview">{{
                  editaItem.propsData.icon_modal.svg
                }}</span>
              </div>
              <div>
                <input type="text" v-model="editaItem.propsData.icon_modal.svg" />
                <div class="with-trash">
                  <span @click="openModal('Modal-Icons-svg')">{{
                    editaItem.propsData.icon_modal.svg
                      ? "Alterar ícone"
                      : "Selecionar ícone"
                  }}</span>
                  <img
                    v-show="editaItem.propsData.icon_modal.svg"
                    title="Remover thumbnail"
                    @click="editaItem.propsData.icon_modal.svg = ''"
                    class="trash"
                    src="@/assets/editor/trash-2.svg"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="separador" v-if="editaItem.propsData.icon_modal.svg"></div>
          <div class="content" v-if="editaItem.propsData.icon_modal.svg ">
            <div class="label-icon">
              <label class="titulo">Ícone</label>
              <div>
                <img
                  src="@/assets/editor/globe.svg"
                  alt="monitor"
                  style="filter: invert(50%) !important"
                  @click="nextIcon('desktop'), (global = !global)"
                  v-if="global"
                />
                <img
                  src="@/assets/editor/monitor.svg"
                  alt="monitor"
                  style="filter: invert(50%) !important"
                  @click="nextIcon('tablet')"
                  v-if="typeView === 'desktop' && !global"
                />
                <img
                  src="@/assets/editor/tablet.svg"
                  alt="tablet"
                  style="filter: invert(50%) !important"
                  @click="nextIcon('mobile')"
                  v-if="typeView === 'tablet' && !global"
                />
                <img
                  src="@/assets/editor/phone.svg"
                  alt="phone"
                  style="filter: invert(50%) !important"
                  @click="nextIcon('desktop'), (global = !global)"
                  v-if="typeView === 'mobile' && !global"
                />
              </div>
            </div>
            <label class="sub">Cor do ícone</label>
        
            <LvColorpicker
              id="LvColorpicker"
              v-model="editaItem.propsData.icon_modal.color"
              :colors="palette"
            ></LvColorpicker>
            <div class="space"></div>

            <div
              style="display: flex; width: 100%; transition: all 0.3s"
              v-if="editaItem.propsData.icon_modal.hasOwnProperty('isRight')"
            >
              <label
                class="subtitulo"
                :class="{ labelOff: !editaItem.propsData.icon_modal }"
                >Ícone à direita</label
              >
              <b-form-checkbox
                style="margin-top: -5px; margin-left: auto; margin-right: 0"
                v-model="editaItem.propsData.icon_modal.isRight"
                name="is_main"
                size="lg"
                switch
              >
              </b-form-checkbox>
            </div>
            <div
              class="space"
              v-if="editaItem.propsData.icon_modal.hasOwnProperty('isRight')"
            ></div>

            <div class="label-range">
              <label class="subtitulo">Tamanho</label>
              <input
                type="number"
                v-model="editaItem.propsData.icon_modal.size[typeView].font_size"
                @input="globalInput($event, 'size', 'font_size', true)"
              />
            </div>
            <b-form-input
              v-model="editaItem.propsData.icon_modal.size[typeView].font_size"
              @input="globalInput($event, 'size', 'font_size', true)"
              type="range"
              min="0"
              max="50"
              step="1"
            ></b-form-input>
            <div
              class="space"
              v-if="editaItem.propsData.content_button.length"
            ></div>
            <div
              class="label-range"
              v-if="editaItem.propsData.content_button.length"
            >
              <label class="subtitulo">Margem lateral</label>
              <input
                type="number"
                v-model="editaItem.propsData.icon_modal.size[typeView].margin"
                @input="globalInput($event, 'size', 'margin', true)"
              />
            </div>
            <b-form-input
              v-model="editaItem.propsData.icon_modal.size[typeView].margin"
              @input="globalInput($event, 'size', 'margin', true)"
              v-if="editaItem.propsData.content_button.length"
              type="range"
              min="0"
              max="25"
              step="1"
            ></b-form-input>
          </div>
        </b-tab>
        <b-tab title="Estilo">
          <div class="separador"></div>
          <div class="content">

<!-- Largura -->
      <div class="und_form">
        <div class="label-icon">
          <label class="titulo">Formulário</label>
          <img
            src="@/assets/editor/globe.svg"
            alt="monitor"
            style="filter: invert(50%) !important"
            @click="nextIcon('desktop'), (global = !global)"
            v-if="global"
          />
          <img
            src="@/assets/editor/monitor.svg"
            alt="monitor"
            style="filter: invert(50%) !important"
            @click="nextIcon('tablet')"
            v-if="typeView === 'desktop' && !global"
          />
          <img
            src="@/assets/editor/tablet.svg"
            alt="tablet"
            style="filter: invert(50%) !important"
            @click="nextIcon('mobile')"
            v-if="typeView === 'tablet' && !global"
          />
          <img
            src="@/assets/editor/phone.svg"
            alt="phone"
            style="filter: invert(50%) !important"
            @click="nextIcon('desktop'), (global = !global)"
            v-if="typeView === 'mobile' && !global"
          />
        </div>
      
        <div class="label-range">
          <label class="subtitulo">Largura máxima </label>
          
        </div>
        <b-form-input
          v-model="editaItem.propsData.sizeForm.width_form"
          type="range"
          min="330"
          max="700"
          step="10"
        ></b-form-input>
      </div>
      <!-- Fundo -->
      <label class="subtitulo">Fundo</label>
      <div>
        <LvColorpicker
          id="LvColorpicker"
          v-model="editaItem.propsData.stylesForm.back_form_content"
          :colors="palette"
        ></LvColorpicker>
      </div>
      </div>
      <div class="separador"></div>
      <div class="content">
      <div>
        <label class="titulo">Campos</label>
      
        <div class="space" />
        <label class="subtitulo">Cor da borda</label>
        <LvColorpicker
          id="LvColorpicker"
          v-model="editaItem.propsData.stylesForm.input_border_color"
          :colors="palette"
        ></LvColorpicker>
        <div class="space"></div>
        <label class="subtitulo">Fundo</label>
        <LvColorpicker
          @input="setaInputStyles"
          v-model="editaItem.propsData.stylesForm.input_back"
          id="LvColorpicker"
          :colors="palette"
        ></LvColorpicker>
        <div class="space"></div>
      </div>
      </div>
        </b-tab>
      </b-tabs>

    <ModalIcons @iconSelect="iconSelect"></ModalIcons>
    <dropdownSlider
      ref="dropdownSlider"   
      @updateValue="updateValue"
    /> 
  </div>
</template>

<script>
import dropdownSliderMixin from "@/mixins/dropdownSliderMixin.js";
import advancedBasic from "../EditComponents/advancedBasic.vue";
import LvColorpicker from "lightvue/color-picker";
import Multiselect from "vue-multiselect";
import ModalIcons from "@/components/Editor/ModalSvg";
import FormService from "@/services/resources/FormService";
const serviceForm = FormService.build();
import PageListService from "@/services/resources/PageListService";
const PaginaListService = PageListService.build();

export default {
  mixins: [dropdownSliderMixin],
  props: ["editaItem", "fontOptions", "typeView", "palette"],
  components: {
    advancedBasic,
    LvColorpicker: LvColorpicker,
    Multiselect,
    ModalIcons,
  },
  data() {
    return {
      varLinks: {
        borderLink: [
          'editaItem.propsData.border.border_radius_top',
          'editaItem.propsData.border.border_radius_right',
          'editaItem.propsData.border.border_radius_bottom',
          'editaItem.propsData.border.border_radius_left',
        ],
      }, 
      lastColor: "",
      lastColorModal: "",
      linkType: "Externo",
      tempType: "",
      options: [
        "Externo",
        "Na própria página",
        "Compartilhar no WhatsApp",
        "Chamar no WhatsApp",
        "Número de telefone",
        "Fechar pop-up",
        "Abrir pop-up",
      ],
      color1: "var(--greenn)",
      color2: "#093627",
      content: true,
      temp: false,
      gradientType: "linear-gradient",
      borderThickness: "",
      borderColor: "",
      borderStyle: "",
      textFont: "",
      textFontMeasure: "px",
      iconSize: "",
      iconML: "",
      shadow: false,
      letterSpace: "",
      lineSpace: "",
      shadowBlur: "",
      shadowColor: "",
      shadowX: "",
      shadowY: "",
      type: "none",
      typeM: "none",
      hasBorder: "",
      selectedDegree: "",
      form_linked: true,
      borderLink: true,
      width100: false,
      lastWidth: "",
      global: false,
      editmodal: false,
      stop: false,
      stop2:false,
      pages:[],
      forms:[],
      optionsType: [
        { value: "externo", text: "Externo" },
        { value: "interno", text: "Na própria página" },
        { value: "compartilhar", text: "Compartilhar no WhatsApp" },
        { value: "chamar", text: "Chamar no WhatsApp" },
        { value: "telefone", text: "Número de telefone" },
      ],
    };
  },
  watch: {
    font_family: function (newFont, oldFont) {
      this.editaItem.propsData.styles.font_family = newFont;
    },
    color1(valor) {
      this.backgroundCreate();
    },
    color2(valor) {
      this.backgroundCreate();
    },
    width100(valor) {
      this.widthButton();
    },
    borderThickness(valor) {
      this.borderCreate();
    },
    borderColor(valor) {
      this.borderCreate();
    },
    borderStyle(valor) {
      this.borderCreate();
    },
    hasBorder(valor) {
      this.borderCreate();
    },
    shadow(valor) {
      this.shadowCreate();
    },
    shadowX(valor) {
      this.shadowCreate();
    },
    shadowY(valor) {
      this.shadowCreate();
    },
    shadowBlur(valor) {
      this.shadowCreate();
    },
    shadowColor(valor) {
      this.shadowCreate();
    },
  },
  methods: {
    largeFull(){
      this.editaItem.propsData.size[this.typeView].width =  100;
      this.editaItem.propsData.size[this.typeView].und_width =  '%';
      this.editaItem.propsData.size[this.typeView].isAuto = false;
      if (this.global && this.typeView == "desktop") {
        this.editaItem.propsData.size.tablet.width =  100;
        this.editaItem.propsData.size.tablet.und_width =  '%';
        this.editaItem.propsData.size.tablet.isAuto = false;
        this.editaItem.propsData.size.mobile.width =  100;
        this.editaItem.propsData.size.mobile.und_width =  '%';
        this.editaItem.propsData.size.mobile.isAuto = false;
      }
    },

    isEdit(bool ,props){
      this.editmodal = bool
      this.$emit("openClosePopUP",bool, props)
      this.editaItem.propsData.button.viewPopup = bool
    },
    next_und_height() {
      const props = this.editaItem.propsColumn.height;
      props[this.typeView].height_und =
        props[this.typeView].height_und == "px"
          ? "vh"
          : props[this.typeView].height_und == "vh"
          ? "vw"
          : "px";
      if (this.global && this.typeView == "desktop") {
        props.tablet.height_und = props.desktop.height_und;
        props.mobile.height_und = props.desktop.height_und;
      }
    },
    globalJustify(value, place) {
      let props = this.editaItem.propsData.alignment;
      props[this.typeView][place] = value;
      if (this.global && this.typeView == "desktop") {
        props[this.typeView][place] = value;
        props[this.typeView][place] = value;
      }
    },
    next_und(place) {
      var props = this.editaItem.propsData;
      if (props.size[this.typeView][place] == "px") {
        props.size[this.typeView][place] = "%";
        if (this.global && this.typeView == "desktop") {
          props.size.tablet[place] = "%";
          props.size.mobile[place] = "%";
        }
        return;
      }
      if (props.size[this.typeView][place] == "%") {
        props.size[this.typeView][place] = "vh";
        if (this.global && this.typeView == "desktop") {
          props.size.tablet[place] = "vh";
          props.size.mobile[place] = "vh";
        }
        return;
      }
      if (props.size[this.typeView][place] == "vh") {
        props.size[this.typeView][place] = "vw";
        if (this.global && this.typeView == "desktop") {
          props.size.tablet[place] = "vw";
          props.size.mobile[place] = "vw";
        }
        return;
      }
      if (props.size[this.typeView][place] == "vw") {
        props.size[this.typeView][place] = "px";
        if (this.global && this.typeView == "desktop") {
          props.size.tablet[place] = "px";
          props.size.mobile[place] = "px";
        }
        return;
      }
    },
    nextIcon(type) {
      this.$emit("attIconView", type);
    },

    openModal(data, fluxo) {
      this.$bvModal.show(data);
    },

    getPages(page = 1) {
      if (this.stop2) {
        return;
      }
      var data = {
        campaign_id: this.$route.params.campaign_id,
        page: page,
        status: "save",
      };

      PaginaListService.create(data)
        .then((resp) => {
          if (resp.data.length < resp.per_page) {
          this.stop2 = true;
          }
          var pages = resp.data;
          pages.map((page) => {
            page.path_name = `https://${this.$route.params.dynamicRoute}.gdigital.com.br/${page.path_name}`;
          });
          if (page == 1) {
            pages.unshift({id: null, title: 'Página externa', path_name: ''})
          }
          this.pages = this.pages.concat(pages);
        })
    },

    saveOpt(opt) {
      if (!this.editaItem.propsData.form && !this.editaItem.propsData.form.id) {
        this.$grToast.toast("Por favor, selecione um formulário", {
          title: "Editor",
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
        this.editaItem.propsData.thank_you_page = {};
        return;
      }

      let data = {
        id: `insert-options/${this.editaItem.propsData.form.id}`,
        page_id: 1,
      };

      switch (opt) {
        case "page":
          data.metas = {
            editor_thank_you_page: this.editaItem.propsData.thank_you_page.link,
            thank_you_option:
              this.editaItem.propsData.opcao_agradecimento.value,
          };
          break;
        case "popup":
          data.metas = {
            success_mensage: this.editaItem.propsData.success_mensage,
            error_mensage: this.editaItem.propsData.error_mensage,
            thank_you_option:
              this.editaItem.propsData.opcao_agradecimento.value,
          };
          break;
        case "whatsapp":
          data.metas = {
            phone: this.editaItem.propsData.phone,
            whatsapp_mensage: this.editaItem.propsData.whatsapp_mensage,
            thank_you_option:
              this.editaItem.propsData.opcao_agradecimento.value,
          };
          break;
        case "adicionais":
          data.metas = {
            header: this.editaItem.propsData.header,
            footer: this.editaItem.propsData.footer,
          };
          break;
      }
      serviceForm.createId(data);
    },

    globalInput(px, place1, place2, icon) {
      if (this.editaItem.propsData.size[this.typeView].und_width == '%') {
        let widthPercentage = this.editaItem.propsData.size[this.typeView].width;
        const limitedWidthPercentage = Math.min(widthPercentage, 100);
        if (widthPercentage > 100) {
          this.$grToast.toast("A largura máxima permitida é 100%", {
            title: "Editor",
            variant: "danger",
            autoHideDelay: 5000,
            appendToast: true,
          });
          this.editaItem.propsData.size[this.typeView].width = 100;
        }
      }
      if (this.global && this.typeView == "desktop") {
        var pixel = px.target ? px.target.value : px;
        var props = this.editaItem.propsData;
        if (icon) {
          props = this.editaItem.propsData.icon;
        }
        props[place1].tablet[place2] = pixel;
        props[place1].mobile[place2] = pixel;
      }
    },    
    iconSelect(item) {
      if(this.editmodal == true){
        this.editaItem.propsData.icon_modal.svg = item;
      }else{
        this.editaItem.propsData.icon.svg = item;
      }
    },
    delayedBackgroundCreate() {
      if (this.editaItem.propsData.button.viewPopup == false) {
      if (this.editaItem.propsData.styles.back_selected == "Cor sólida") {
        if (this.lastColor) {
          this.editaItem.propsData.styles.back_content = this.lastColor;
        } else {
          this.editaItem.propsData.styles.back_content =
            this.editaItem.propsData.styles.grad_color1;
        }
      } else {
        this.lastColor = this.editaItem.propsData.styles.back_content;
      }
      setTimeout(() => {
        this.backgroundCreate();
      }, 100);}
      if(this.editaItem.propsData.button.viewPopup == true){
        if (this.editaItem.propsData.styles.modalButton.back_selected == "Cor sólida") {
        if (this.lastColorModal) {
          this.editaItem.propsData.styles.modalButton.back_content = this.lastColorModal;
        } else {
          this.editaItem.propsData.styles.modalButton.back_content =
            this.editaItem.propsData.styles.modalButton.grad_color1;
        }
      } else {
        this.lastColorModal = this.editaItem.propsData.styles.modalButton.back_content;
      }
      setTimeout(() => {
        this.backgroundCreate();
      }, 100);
      }
    },
    backgroundCreate() {
      if (this.editaItem.propsData.button.viewPopup == false) {
        if (this.editaItem.propsData.styles.back_selected == "Cor sólida") {
          this.editaItem.propsData.styles.back_type = "color";
          return;
        }
        if (this.editaItem.propsData.styles.back_selected == "Gradiente") {
          this.editaItem.propsData.styles.back_type = "image";
          this.editaItem.propsData.styles.back_content = `${this.gradientType}(${this.editaItem.propsData.styles.grad_angle}deg, ${this.editaItem.propsData.styles.grad_color2}, ${this.editaItem.propsData.styles.grad_color1})`;
          return;
        }
        } if (this.editaItem.propsData.button.viewPopup == true){
          if (this.editaItem.propsData.styles.modalButton.back_selected == "Cor sólida") {
          this.editaItem.propsData.styles.modalButton.back_type = "color";
          return;
        }
        if (this.editaItem.propsData.styles.modalButton.back_selected == "Gradiente") {
          this.editaItem.propsData.styles.modalButton.back_type = "image";
          this.editaItem.propsData.styles.modalButton.back_content = `${this.gradientType}(${this.editaItem.propsData.styles.modalButton.grad_angle}deg, ${this.editaItem.propsData.styles.modalButton.grad_color2}, ${this.editaItem.propsData.styles.modalButton.grad_color1})`;
          return;
        }
      }
    },
    borderCreate() {
      this.editaItem.propsData.border.border_style = this.borderStyle;
      this.editaItem.propsData.border.border_thickness = this.borderThickness;
      this.editaItem.propsData.border.border_color = this.borderColor;
      if (this.hasBorder == true) {
        this.editaItem.propsData.border.hasBorder = true;
        var styleName = "";
        if (this.borderStyle == "● ● ● ● ● ● ● ● ● ● ● ● ● ●") {
          styleName = "dotted";
          this.editaItem.propsData.border.border = `${this.borderThickness}px ${styleName} ${this.borderColor}`;
          return;
        }
        if (this.borderStyle == "━━━━━━━━━━━━━━━━━━") {
          styleName = "solid";
          this.editaItem.propsData.border.border = `${this.borderThickness}px ${styleName} ${this.borderColor}`;
          return;
        }
        if (this.borderStyle == "━ ━ ━ ━ ━ ━ ━ ━ ━ ━") {
          styleName = "dashed";
          this.editaItem.propsData.border.border = `${this.borderThickness}px ${styleName} ${this.borderColor}`;
          return;
        }
        if (this.borderStyle == "══════════════════") {
          styleName = "double";
          this.editaItem.propsData.border.border = `${this.borderThickness}px ${styleName} ${this.borderColor}`;
          return;
        }
      }
      if (this.hasBorder == false) {
        this.editaItem.propsData.border.hasBorder = false;
        this.editaItem.propsData.border.border = "none";
      }
    },
    shadowCreate() {
      this.editaItem.propsData.shadow.hasShadow = this.shadow;
      this.editaItem.propsData.shadow.shadow_x = this.shadowX;
      this.editaItem.propsData.shadow.shadow_y = this.shadowY;
      this.editaItem.propsData.shadow.shadow_blur = this.shadowBlur;
      this.editaItem.propsData.shadow.shadow_color = this.shadowColor;
      if (this.shadow == true) {
        this.editaItem.propsData.shadow.shadow = `${this.shadowX}px ${this.shadowY}px ${this.shadowBlur}px ${this.shadowColor}`;
        return;
      }
      if (this.shadow == false) {
        this.editaItem.propsData.shadow.hasShadow = false;
        this.editaItem.propsData.shadow.shadow = "none";
      }
    },
    setaInputStyles() {
      var style = `style="border-radius:${
        this.editaItem.propsData.stylesForm.input_radius
      }px !important; width:${
        this.editaItem.propsData.stylesForm.input_width > 100
          ? 100
          : this.editaItem.propsData.stylesForm.input_width
      }% !important; margin-bottom: ${
        this.editaItem.propsData.spacing.desktop.input_gap > 30
          ? 30
          : this.editaItem.propsData.spacing.desktop.input_gap
      }px; background-color: ${this.editaItem.propsData.stylesForm.input_back};"`;

      var fields = this.editaItem.propsData.fields;
      for (let i = 0; i < fields.length; i++) {
        this.editaItem.propsData.fields[i].form_field =
          this.editaItem.propsData.fields[i].form_field.replace(
            /style="([^"]*)"/gi,
            ""
          );
        this.editaItem.propsData.fields[i].form_field =
          this.editaItem.propsData.fields[i].form_field.replace(
            /input type="([^\s]+)"/,
            "$&" + style
          );
      }
    },
    trocarType() {
      if (this.editaItem.propsData.button.viewPopup == false){
        if (this.type == "none") {
        this.type = "uppercase";
        this.editaItem.propsData.styles.text_type = this.type;
        return;
      }
      if (this.type == "uppercase") {
        this.type = "none";
        this.editaItem.propsData.styles.text_type = this.type;
        return;
      }
      }if (this.editaItem.propsData.button.viewPopup == true) {
        if (this.typeM == "none") {
        this.typeM = "uppercase";
        this.editaItem.propsData.styles.modalButton.text_type = this.typeM;
        return;
      }
      if (this.typeM == "uppercase") {
        this.typeM = "none";
        this.editaItem.propsData.styles.modalButton.text_type = this.typeM;
        return;
      }
      }
      
    },  
    getForms(page = 1) {
      if (this.stop) {
        return;
      }
      var data = {
        campaign_id: this.$route.params.campaign_id,
        page: page,
        status: "active",
      };
      serviceForm
        .search(data)
        .then((resp) => {
          if (!resp.data.length) {
          this.stop = true;
          return;
          }
          this.forms = this.forms.concat(resp.data);
          
        })
    },

    debounce: _.debounce(function (opt) {
      this.saveOpt(opt);
    }, 500),

    setaForm(e) {
      this.editaItem.propsData.form = e;
      this.$emit('openClosePopUP',this.editaItem.propsData.button.viewPopup, this.editaItem.propsData)
      e.fields.push(
        {
          id: 0,
          name_field: "hidden",
          form_field: `<input type="hidden" id="tenant_id" value="${this.$store.getters.user.user.tenant_id}">`,
        },
        {
          id: 0,
          name_field: "hidden",
          form_field: `<input type="hidden" id="page_id" value="${this.$route.params.page_id}">`,
        }
      );
      this.editaItem.propsData.fields = e.fields;
      this.editaItem.propsData.tenant_id =
      this.$store.getters.user.user.tenant_id;
      this.editaItem.propsData.page_id = this.$route.params.page_id;
    },    
  },
  created() {
    if (this.typeView == "desktop") {
      this.global = true;
    }
    this.form_linked = this.editaItem.propsData.form_linked;
    this.hasBorder = this.editaItem.propsData.hasBorder;
    this.backgroundType = this.editaItem.propsData.styles.back_selected;
    this.backgroundBModal = this.editaItem.propsData.styles.modalButton.back_selected;
    this.selectedDegree = this.editaItem.propsData.styles.grad_angle;
    this.hasBorder = this.editaItem.propsData.border.hasBorder;
    this.borderStyle = this.editaItem.propsData.border.border_style;
    this.borderThickness = this.editaItem.propsData.border.border_thickness;
    this.borderColor = this.editaItem.propsData.border.border_color;
    this.shadow = this.editaItem.propsData.shadow.hasShadow;
    this.shadowX = this.editaItem.propsData.shadow.shadow_x;
    this.shadowY = this.editaItem.propsData.shadow.shadow_y;
    this.shadowBlur = this.editaItem.propsData.shadow.shadow_blur;
    this.shadowColor = this.editaItem.propsData.shadow.shadow_color;
    this.borderLink = this.editaItem.propsData.border.borderLinked;
  },
};
</script>

<style lang="scss">
.edit-modal{
    width: 100%;
    cursor: pointer !important;
    padding: 5px !important ;
    margin-top: 5px !important;
    margin-bottom: 12px;
    border:  1px solid #ececec !important;
    text-align: center;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    border-radius: 10px;
    font-size: 14px;
}
.edit-modal:hover{
  border:  1px solid var(--greenn) !important;
  color:  var(--greenn) !important;
}
.btn-secondary:focus,
.btn-secondary.focus {
  color: rgb(0, 0, 0);
  background-color: transparent !important;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0) !important;
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0) !important;
}

.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  background-color: #545b6200 !important;
}

input:disabled {
  background-color: transparent !important;
}

.form-control:disabled,
.form-control[readonly] {
  color: #dadada !important;
}

.lv-input__group .lv-input__field .lv-input__element {
  padding: 15px 0 15px 20px !important;
}

.lv-input__group .lv-input__field {
  padding: 0 12px 0 0 !important;
}

.pickers {
  width: 99.5%;
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}

.picker {
  height: 100%;
  width: 47.5% !important;
}

.selectedDiv {
  background-color: var(--greenn) 50 !important;
}

.gridDirection {
  margin: 1rem 0px;
  justify-content: space-between;
  max-width: 100%;
  display: grid;
  grid-template-columns: repeat(9, 1fr);
  gap: 2px;

  div {
    transition: all 0.3s;
    border-radius: 10px;
    background-color: #f0f0f0;
    max-width: 36px;

    img {
      stroke: var(--greenn);
      padding: 6px;
    }
  }
}

.lv-input__group .lv-input__field {
  font-size: 14px !important;
  padding: 0 12px;
  border: 0.5px solid #ededf0 !important;
  height: 45px !important;
  background-color: transparent !important;
  margin-top: 0px !important;
}

.btn-secondary {
  transition: all 0.5s !important;
}

.btn-secondary:hover {
  background-color: var(--greenn) 30;
}

.multiselect__tags {
  border: 0.5px solid #ededf0 !important;
  min-height: 45px !important;
  height: fit-content !important;
  padding: 12px 40px 0 8px;
}

.b-tab-content {
  overflow-y: auto;
}

input:focus,
select:focus,
textarea:focus {
  box-shadow: none !important;
}

input:hover,
select:hover,
textarea:hover,
input:focus,
select:focus,
textarea:focus,
.multiselect__tags:focus,
.multiselect__tags:hover {
  border-color: var(--greenn) !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>

<style lang="scss" scoped>
* {
  overflow-x: visible !important;
}

  
.editmodal{
    width: 100%;
    cursor: pointer !important;
    padding: 10px !important ;
    margin-top: 5px !important;
    margin-bottom: 0px;
    border:  1px solid #ececec !important;
    text-align: right;
    padding-bottom: 0px !important;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    border-radius: 10px;
}

.editmodal:hover{
  border:  1px solid var(--greenn) !important;
}

.txteditmodal{
  align-items: center;
  justify-content: center;
  font-size: 14px !important;
}
.txteditmodal:hover{
 color: var(--greenn);
}

.label-icon {
  display: flex;
  gap: 10px;
  align-items: center;
  img {
    cursor: pointer;
    width: 20px;
    height: 20px;
    margin: 0 0 1em 0;
  }
}
button {
  border: none;
}

button:disabled,
.disabled {
  background-color: transparent !important;
}

#LvColorpicker {
  width: 100%;
}

.more-options {
  padding: 10px;
  background-color: transparent;
  width: 100%;
}

*:disabled {
  color: #dadada !important;
}
</style>
