let propsButton = {
  custom: {
    c_class: '',
    c_id: '',
  },
  icon:  [
    { iconSelected: "check", link: "" },
    { iconSelected: "check", link: "" },
  ],
  alignment: {
    desktop: { justify: "center", justify_vertical: "center" },
    tablet: { justify: "center", justify_vertical: "center" },
    mobile: { justify: "center", justify_vertical: "center" },
  },
  styles: {
    desktop: {
      iconSize: 50,
      gap: 10,
    },
    tablet: {
      iconSize: 50,
      gap: 10,
    },
    mobile: {
      iconSize: 50,
      gap: 10,
    },
    iconColor: "#000000",
    iconHover: "#000000",
  },
  spacing: {
    desktop: {
      margin_top: 0,
      margin_right: 0,
      margin_bottom: 0,
      margin_left: 0,
      padding_top: 0,
      padding_right: 0,
      padding_bottom: 0,
      padding_left: 0,
    },
    tablet: {
      margin_top: 0,
      margin_right: 0,
      margin_bottom: 0,
      margin_left: 0,
      padding_top: 0,
      padding_right: 0,
      padding_bottom: 0,
      padding_left: 0,
    },
    mobile: {
      margin_top: 0,
      margin_right: 0,
      margin_bottom: 0,
      margin_left: 0,
      padding_top: 0,
      padding_right: 0,
      padding_bottom: 0,
      padding_left: 0,
    },
  },
  visibility: {
    tablet: true,
    phone: true,
    monitor: true,
  },
};
export default propsButton;
