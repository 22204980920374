<template>
  <Editor />
</template>

<script>
import Editor from "@/components/Editor/Editor.vue";

export default {
  components: {
    Editor,
  },
};
</script>

<style></style>
