<template>
  <div class="tooltip-box">
    <slot />
    <div class="tooltip">
      <span class="text">{{ text }}</span>
      <span class="shortcut-key">{{ shortcut_key }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: ["text", "shortcut_key"],
};
</script>

<style scoped>
.tooltip-box {
  /* position: relative; */
  display: inline-block;
}

.tooltip-box:hover .tooltip {
  opacity: 1;
}

.tooltip {
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 0.5rem 1rem;
  text-align: center;
  border-radius: 6px;
  top: 60px;
  right: 300px;
  opacity: 0;
  transition: opacity 0.3s;
  position: absolute;
  z-index: 1;
  background: #ffffff;
  filter: drop-shadow(0 10px 8px rgb(0 0 0 / 0.04))
    drop-shadow(0 4px 3px rgb(0 0 0 / 0.1));
  pointer-events: none; /* Adicionado */
}

.tooltip::before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-right: 6px solid #ffffff;
  left: 78px;
  -webkit-filter: drop-shadow(0 10px 8px rgb(0 0 0 / 0.04));
  filter: drop-shadow(0 10px 8px rgb(0 0 0 / 0.04));
  bottom: 33px;
  -webkit-transform: rotate(180deg);
  transform: rotate(90deg);
}

.text {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 14px;
  color: var(--gray01);
  white-space: nowrap;
}

.shortcut-key {
  color: #9ca3af;
  font-weight: 600;
}
</style>
