let propsForm = {
    alignment: {
        desktop: { justify: "center", justify_vertical: "center" },
        tablet: { justify: "center", justify_vertical: "center" },
        mobile: { justify: "center", justify_vertical: "center" },
    },
    styles: {
        form_border_size: 1,
        form_border_style: "solid",
        border_form_style: "━━━━━━━━━━━━━━━━━━━━━",
        form_border_color: "#E2E2E2",
        background_color: "",
        border_top_left_radius: 5,
        border_top_right_radius: 5,
        border_bottom_left_radius: 5,
        border_bottom_right_radius: 5,
        input_radius: 5,
        input_width: 100,
        input_back: "#ffffff",
        input_color: "#000000",
        input_border_color: "#ededf0",
        back_form_content: "#ffffff",
        placeholder_color: "#0000000",

        //BUTTON
        justify: "center",
        font_weight: 600,
        textFontMeasure: "px",
        letter_space: "0.2",
        text_type: "none",
        back_type: "color",
        back_content: "#4ea934",
        back_content_solid:"#4ea934",
        back_selected: "Cor sólida",
        hasBorder: false,
        border: "none",
        grad_angle: "180",
        grad_color1: "#021810",
        grad_color2: "#05855F",
        border_style: "━━━━━━━━━━━━━━━━━━━━━",
        border_thickness: "2.75",
        border_color: "#ffffff",
        hasShadow: false,
        border_button_radius_top: 5,
        border_button_radius_right: 5,
        border_button_radius_bottom: 5,
        border_button_radius_left: 5,
        shadow: "none",
        shadow_x: 0,
        shadow_y: 5,
        shadow_blur: 6,
        shadow_color: "#4ea93410",
        monitor: true,
        borderLinked: true,
        height_button: 45,
        width_button: 100,
        widthMeasure: "%",
        color_button: "#FFFFFF",
        bg_button: "#4ea934",
        font_family: "Montserrat",
        bg_container: "#00000000",
        icon_color: "#ffffff",
    },
    spacing: {
        desktop: {
            margin_top: 0,
            margin_right: 0,
            margin_bottom: 0,
            margin_left: 0,
            padding_top: 20,
            padding_right: 20,
            padding_bottom: 20,
            padding_left: 20,
            input_gap: 17,

            //BUTTON
            padding_button_top: 10,
            padding_button_right: 0,
            padding_button_bottom: 10,
            padding_button_left: 0,
            margin_button_top: 8,
            margin_button_right: 0,
            margin_button_bottom: 8,
            margin_button_left: 0,
            //ICON
            icon_margin: 5,
        },
        tablet: {
            margin_top: 0,
            margin_right: 0,
            margin_bottom: 0,
            margin_left: 0,
            padding_top: 20,
            padding_right: 20,
            padding_bottom: 20,
            padding_left: 20,
            input_gap: 17,

            //BUTTON
            padding_button_top: 10,
            padding_button_right: 0,
            padding_button_bottom: 10,
            padding_button_left: 0,
            margin_button_top: 8,
            margin_button_right: 0,
            margin_button_bottom: 8,
            margin_button_left: 0,
            //ICON
            icon_margin: 5,
        },
        mobile: {
            margin_top: 0,
            margin_right: 0,
            margin_bottom: 0,
            margin_left: 0,
            padding_top: 20,
            padding_right: 20,
            padding_bottom: 20,
            padding_left: 20,
            input_gap: 17,

            //BUTTON
            padding_button_top: 10,
            padding_button_right: 0,
            padding_button_bottom: 10,
            padding_button_left: 0,
            margin_button_top: 8,
            margin_button_right: 0,
            margin_button_bottom: 8,
            margin_button_left: 0,
            //ICON
            icon_margin: 5,
        }
    },
    size: {
        desktop: {
            height_form: 35,
            width_form: 300,
            font_size_button: 14,
            icon_size: 25,
        },
        tablet: {
            height_form: 40,
            width_form: 300,
            font_size_button: 14,
            icon_size: 25,
        },
        mobile: {
            height_form: 40,
            width_form: 300,
            font_size_button: 14,
            icon_size: 25,
        },
    },
    visibility: {
        monitor: true,
        tablet: true,
        phone: true,
    },
    form_id: 0,
    attribute_und: "Nenhuma",
    tenant_id: 0,
    page_id: 0,
    icon_selected: "",
    form: {},
    header: "",
    footer: "",
    form_linked: true,
    opcoes_agradecimento: [
        { value: "thank_you_page", text: "Página" },
        { value: "popup", text: "Apenas confirmação" },
        { value: "whatsapp", text: "Mensagem no whatsapp" }
    ],
    opcao_agradecimento: { value: "thank_you_page", text: "Página" },
    thank_you_page: {},
    success_mensage: "Obrigado por se cadastrar",
    error_mensage: "Por favor, confira seus dados",
    phone: "",
    whatsapp_mensage: "Me cadastrei no formulário x",
    footer_link: "#",
    fields: [
        {
            id: 54,
            form_id: 51,
            name_field: "text",
            form_field: `<li class="prev-holder" style=""><div class="fb-text form-group field-nome-preview"><label class="fb-text-label">Nome</label><span class="required-asterisk" style="display: none;"> </span><input type="text" style="teste: 2px;" placeholder="Seu nome" class="form-control" name="nome-preview" mascara="form-control" id="nome-preview"></div></li>`,
            json: {
                name: "nome",
                type: "text",
                label: "Nome",
                mascara: "form-control",
                className: "form-control"
            },
            order: 0,
            created_at: "2023-03-26T03:32:54.000000Z",
            updated_at: "2023-03-26T03:32:54.000000Z"
        },
        {
            id: 55,
            form_id: 51,
            name_field: "text",
            form_field: `<li class="prev-holder" style=""><div class="fb-text form-group field-nome-preview"><label class="fb-text-label">E-mail</label><span class="required-asterisk" style="display: none;"> </span><input type="text" placeholder="Seu e-mail" class="form-control" name="email-preview" mascara="form-control" id="email-preview"></div></li>`,
            json: {
                name: "nome",
                type: "text",
                label: "Nome",
                mascara: "form-control",
                className: "form-control"
            },
            order: 0,
            created_at: "2023-03-26T03:32:54.000000Z",
            updated_at: "2023-03-26T03:32:54.000000Z"
        },
        {
            id: 56,
            form_id: 51,
            name_field: "text",
            form_field: `<li class="prev-holder" style=""><div class="fb-text form-group field-nome-preview"><label class="fb-text-label">Telefone</label><span class="required-asterisk" style="display: none;"> </span><input type="text" placeholder="Seu Telefone" class="form-control" name="nome-preview" mascara="form-control" id="nome-preview"></div></li>`,
            json: {
                name: "nome",
                type: "text",
                label: "Nome",
                mascara: "form-control",
                className: "form-control"
            },
            order: 0,
            created_at: "2023-03-26T03:32:54.000000Z",
            updated_at: "2023-03-26T03:32:54.000000Z"
        },
    ],
    content_button: "Cadastre-se",
};

export default propsForm;
