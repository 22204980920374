let propsAccordion = {
  custom: {
    c_class: "",
    c_id: "",
  },
  alignment: {
    desktop: { justify: "center", justify_vertical: "center" },
    tablet: { justify: "center", justify_vertical: "center" },
    mobile: { justify: "center", justify_vertical: "center" },
  },
  rows: [
    {
      title: "Plano novo!",
      price: "249,90",
      time: "Por mês",
      resource: [{text:'Recurso fantástico', icon:'emoji_flags', iconTrue:true}, {text:'Recurso fantástico', icon:'emoji_flags', iconTrue:true}, {text:'Recurso fantástico', icon:'emoji_flags', iconTrue:true}, {text:'Recurso fantástico', icon:'emoji_flags', iconTrue:true}, {text:'Recurso fantástico', icon:'emoji_flags', iconTrue:true}, {text:'Recurso fantástico', icon:'emoji_flags', iconTrue:true}, {text:'Recurso fantástico', icon:'emoji_flags', iconTrue:true}, {text:'Recurso fantástico', icon:'emoji_flags', iconTrue:true}, ],
      button: {
        href_button: "",
        blank: false,
        share_text: "",
        content_button: "Clique aqui",
        type: { value: "externo", text: "Externo" },
      },
    },
    {
      title: "Plano novo!",
      price: "249,90",
      time: "Por mês",
      resource: [{text:'Recurso fantástico', icon:'emoji_flags', iconTrue:true}, {text:'Recurso fantástico', icon:'emoji_flags', iconTrue:true}, {text:'Recurso fantástico', icon:'emoji_flags', iconTrue:true}, {text:'Recurso fantástico', icon:'emoji_flags', iconTrue:true}, {text:'Recurso fantástico', icon:'emoji_flags', iconTrue:true}, {text:'Recurso fantástico', icon:'emoji_flags', iconTrue:true}, {text:'Recurso fantástico', icon:'emoji_flags', iconTrue:true}, {text:'Recurso fantástico', icon:'emoji_flags', iconTrue:true}, ],
      button: {
        href_button: "",
        blank: false,
        share_text: "",
        content_button: "Clique aqui",
        type: { value: "externo", text: "Externo" },
      },
    }
    ,{
      title: "Plano novo!",
      price: "249,90",
      time: "Por mês",
      resource: [{text:'Recurso fantástico', icon:'emoji_flags', iconTrue:true}, {text:'Recurso fantástico', icon:'emoji_flags', iconTrue:true}, {text:'Recurso fantástico', icon:'emoji_flags', iconTrue:true}, {text:'Recurso fantástico', icon:'emoji_flags', iconTrue:true}, {text:'Recurso fantástico', icon:'emoji_flags', iconTrue:true}, {text:'Recurso fantástico', icon:'emoji_flags', iconTrue:true}, {text:'Recurso fantástico', icon:'emoji_flags', iconTrue:true}, {text:'Recurso fantástico', icon:'emoji_flags', iconTrue:true}, ],
      button: {
        href_button: "",
        blank: false,
        share_text: "",
        content_button: "Clique aqui",
        type: { value: "externo", text: "Externo" },
      },
    },
  ],
  size: {
    desktop: {
      width: 250,
      font_size: 20,
    },
    tablet: {
      width: 250,
      font_size: 20,
    },
    mobile: {
      width: 250,
      font_size: 20,
    },
  },
  styles: {
    color1: '#333333',
    color2: '#DADADA',
    color3: '#333333',
    emphasis: 1,
    template: 1,
    equalize: true,
    hasSeparator: false,
    hasStripe: false,
    table_radius: 10,
    font_family: 'Montserrat',
    icons: {
      type: 'Ícone de sim e não',
      icon: 'check'
    },
    shadowTable: {
      has: true,
      radius: 10,
      x: 5,
      y: 5,
      blur: 10,
    },
  },
  spacing: {
    desktop: {
      margin_top: 10,
      margin_right: 10,
      margin_bottom: 10,
      margin_left: 10,
      padding_top: 40,
      padding_right: 40,
      padding_bottom: 40,
      padding_left: 40,
    },
    tablet: {
      margin_top: 10,
      margin_right: 10,
      margin_bottom: 10,
      margin_left: 10,
      padding_top: 40,
      padding_right: 40,
      padding_bottom: 40,
      padding_left: 40,
    },
    mobile: {
      margin_top: 10,
      margin_right: 10,
      margin_bottom: 10,
      margin_left: 10,
      padding_top: 40,
      padding_right: 40,
      padding_bottom: 40,
      padding_left: 40,
    },
  },
  visibility: {
    tablet: true,
    phone: true,
    monitor: true,
  },
};
export default propsAccordion;
