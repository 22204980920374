<template>
  <div id="SideRightTabs" class="countDown">
    <b-tabs pills justified small style="width: 100%">
      <b-tab title="Geral">
        <div class="separador"></div>
        <div class="content">
          <label class="titulo">Contagem regressiva</label>
          <label class="subtitulo">Data final</label>
          <date-range-picker
            ref="picker"
            :locale-data="localeData"
            :showWeekNumbers="false"
            :showDropdowns="true"
            :autoApply="false"
            :ranges="false"
            :timePicker24Hour="true"
            :timePicker="true"
            v-model="dateRange1"
            :single-date-picker="true"
            @update="updateDate()"
            style="
              display: block;
              display: flex;
              height: 45px;
              align-items: center;
            "
          >
            <template
              v-if="dateRange1.startDate && dateRange1.endDate"
              v-slot:input="picker1"
            >
              {{ picker1.startDate | datetimem }}
            </template>
          </date-range-picker>
          <div class="space"></div>
          <label class="subtitulo">Tipo</label>
          <multiselect
            :options="['Círculo', 'Tradicional']"
            v-model="editaItem.propsData.styles.type"
            :hide-selected="true"
            :searchable="false"
            :allow-empty="false"
            :show-labels="false"
            placeholder=" "
          ></multiselect>
        </div>
        <div class="separador"></div>
      </b-tab>

      <b-tab title="Estilo">
        <div class="separador"></div>
        <!-- Dimensões -->
        <div class="content">
          <div class="label-icon">
            <label class="titulo">Tamanho</label>
            <img
              src="@/assets/editor/globe.svg"
              alt="monitor"
              style="filter: invert(50%) !important"
              @click="nextIcon('desktop'), (global = !global)"
              v-if="global"
            />
            <img
              src="@/assets/editor/monitor.svg"
              alt="monitor"
              style="filter: invert(50%) !important"
              @click="nextIcon('tablet')"
              v-if="typeView === 'desktop' && !global"
            />
            <img
              src="@/assets/editor/tablet.svg"
              alt="tablet"
              style="filter: invert(50%) !important"
              @click="nextIcon('mobile')"
              v-if="typeView === 'tablet' && !global"
            />
            <img
              src="@/assets/editor/phone.svg"
              alt="phone"
              style="filter: invert(50%) !important"
              @click="nextIcon('desktop'), (global = !global)"
              v-if="typeView === 'mobile' && !global"
            />
          </div>
          <div class="label-range">
            <label class="subtitulo">Fonte do CountDown</label>
            <input
              type="number"
              v-model="editaItem.propsData.count_size[typeView].count_size"
              @input="globalMeasure($event, 'count_size')"
            />
          </div>
          <b-form-input
            id="range-1"
            v-model="editaItem.propsData.count_size[typeView].count_size"
            type="range"
            min="20"
            max="100"
            @input="globalMeasure($event, 'count_size')"
          ></b-form-input>
          <div class="space"></div>
          <div class="label-range">
            <label class="subtitulo">Espaço entre os itens</label>
            <input
              type="number"
              v-model="editaItem.propsData.count_size[typeView].gap"
              @input="globalMeasure($event)"
            />
          </div>
          <b-form-input
            id="range-1"
            v-model="editaItem.propsData.count_size[typeView].gap"
            @input="globalMeasure($event)"
            type="range"
            min="0"
            max="1500"
          ></b-form-input>
          <div class="space"></div>
          <label class="subtitulo">Posicionamento</label>
          <div style="display: flex; width: 100%; gap: 10px">
            <div style="display: flex; width: 50%; transition: all 0.3s">
              <div class="select-align">
                <button
                  :class="{
                    'selected-align':
                      editaItem.propsData.alignment[typeView].justify ==
                      'flex-start',
                  }"
                  @click="globalJustify('flex-start', 'justify')"
                >
                  <img src="@/assets/editor/align-left-new.svg" />
                </button>
                <button
                  :class="{
                    'selected-align':
                      editaItem.propsData.alignment[typeView].justify ==
                      'center',
                  }"
                  @click="globalJustify('center', 'justify')"
                >
                  <img src="@/assets/editor/align-center-horizontal.svg" />
                </button>
                <button
                  :class="{
                    'selected-align':
                      editaItem.propsData.alignment[typeView].justify ==
                      'flex-end',
                  }"
                  style="z-index: 2"
                  @click="globalJustify('flex-end', 'justify')"
                >
                  <img src="@/assets/editor/align-right-new.svg" />
                </button>
              </div>
            </div>

            <div style="display: flex; width: 50%; transition: all 0.3s">
              <div class="select-align">
                <button
                  :class="{
                    'selected-align':
                      editaItem.propsData.alignment[typeView]
                        .justify_vertical == 'start',
                  }"
                  @click="globalJustify('start', 'justify_vertical')"
                >
                  <img src="@/assets/editor/align-top.svg" />
                </button>
                <button
                  :class="{
                    'selected-align':
                      editaItem.propsData.alignment[typeView]
                        .justify_vertical == 'center',
                  }"
                  @click="globalJustify('center', 'justify_vertical')"
                >
                  <img src="@/assets/editor/align-center-vertical.svg" />
                </button>
                <button
                  :class="{
                    'selected-align':
                      editaItem.propsData.alignment[typeView]
                        .justify_vertical == 'end',
                  }"
                  @click="globalJustify('end', 'justify_vertical')"
                >
                  <img src="@/assets/editor/align-bottom.svg" />
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="separador"></div>

        <!-- Cores -->
        <div class="content">
          <label class="titulo">Cores</label>
          <label class="subtitulo">Cor dos números</label>
          <LvColorpicker
            id="LvColorpicker"
            v-model="editaItem.propsData.styles.number_color"
            label="Cor dos números"
            :colors="palette"
          ></LvColorpicker>
          <div class="space"></div>
          <label
            v-if="editaItem.propsData.styles.type == 'Tradicional'"
            class="subtitulo"
            >Cor das legendas</label
          >
          <label
            v-if="editaItem.propsData.styles.type == 'Círculo'"
            class="subtitulo"
            >Cor do círculo de fundo</label
          >
          <LvColorpicker
            id="LvColorpicker"
            v-model="editaItem.propsData.styles.label_color"
            label="Cor de fundo"
            :colors="palette"
          ></LvColorpicker>
          <div class="space"></div>
          <div v-if="editaItem.propsData.styles.type == 'Círculo'">
            <label class="subtitulo">Cor do círculo</label>
            <LvColorpicker
              id="LvColorpicker"
              v-model="editaItem.propsData.styles.bar_color"
              label="Cor de fundo"
              :colors="palette"
            ></LvColorpicker>
          </div>
        </div>
        <div class="separador"></div>

        <div
          class="content max-multi"
          v-if="editaItem.propsData.styles.hasOwnProperty('font_number')"
        >
          <label class="titulo">Fontes</label>
          <label class="sub">Números</label>
          <multiselect
            v-model="editaItem.propsData.styles.font_number"
            :options="fontOptions"
            :searchable="true"
            :show-labels="false"
            :hide-selected="true"
            :allow-empty="false"
            @input="$emit('callCharge',$event)"
          ></multiselect>
          <div class="space"></div>
          <label class="sub">Legenda</label>
          <multiselect
            v-model="editaItem.propsData.styles.font_label"
            :options="fontOptions"
            :searchable="true"
            :show-labels="false"
            :hide-selected="true"
            :allow-empty="false"
            @input="$emit('callCharge',$event)"
          ></multiselect>
          <div
            class="separador"
            v-if="editaItem.propsData.styles.hasOwnProperty('font_number')"
          ></div>
        </div>
      </b-tab>

      <b-tab title="Avançado">
        <advancedBasic
          :spacing="editaItem.propsData.spacing" 
          :visibility="editaItem.propsData.visibility" 
          :animation="editaItem.propsColumn" 
          :attributes="editaItem.propsData.custom" 
          :typeView="typeView"
          :global="global"
          @toggleGlobal="global = !global"
          @nextIcon="nextIcon"
          @update:spacing="editaItem.propsData.spacing = $event"
          @update:visibility="editaItem.propsData.visibility = $event"
          @update:animation="editaItem.propsColumn = $event"
          @update:attributes="editaItem.propsData.custom = $event"
        ></advancedBasic>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import DateRangePicker from "vue2-daterange-picker";
import advancedBasic from "../EditComponents/advancedBasic.vue";
import moment from "moment-timezone";
import LvColorpicker from "lightvue/color-picker";
import Multiselect from "vue-multiselect";

export default {
  props: ["editaItem", "typeView", "palette", "fontOptions"],
  components: {
    LvColorpicker: LvColorpicker,
    DateRangePicker,
    Multiselect,
    advancedBasic,
  },
  data() {
    return {
      global: false,
      content: true,
      dateRange1: {
        startDate: "",
        endDate: "",
      },
      localeData: {
        direction: "ltr",
        format: "dd/mm/yyyy",
        separator: " - ",
        applyLabel: "Aplicar",
        cancelLabel: "Cancelar",
        weekLabel: "W",
        customRangeLabel: "Custom Range",
        daysOfWeek: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"],
        monthNames: [
          "Jan",
          "Fev",
          "Mar",
          "Abr",
          "Mai",
          "Jun",
          "Jul",
          "Ago",
          "Set",
          "Out",
          "Nov",
          "Dez",
        ],
        firstDay: 0,
      },
    };
  },
  methods: {
    globalJustify(value, place) {
      let props = this.editaItem.propsData.alignment;
      props[this.typeView][place] = value;
      if (this.global && this.typeView == "desktop") {
        props[this.typeView][place] = value;
        props[this.typeView][place] = value;
      }
    },
    nextIcon(type) {
      this.$emit("attIconView", type);
    },
    globalMeasure(pixel, place) {
      var px = pixel.target ? pixel.target.value : pixel;
      if (this.global && this.typeView == "desktop") {
        var props = this.editaItem.propsData.count_size;
        props.tablet[place] = px;
        props.mobile[place] = px;
      }
    },
    updateDate() {
      var data = moment(
        this.dateRange1.startDate ? this.dateRange1.startDate : ""
      ).unix();
      this.editaItem.propsData.count.date = data * 1000;
    },
  },
  created() {
    if (this.typeView == "desktop") {
      this.global = true;
    }
    if (!this.editaItem.propsData.count.date) {
      const timeNow = new Date().getTime();
      this.editaItem.propsData.count.date = timeNow;
      this.dateRange1.startDate = timeNow;
    } else {
      this.dateRange1.startDate = this.editaItem.propsData.count.date;
    }
  },
};
</script>

<style lang="scss">
.countDown {
  .daterangepicker.ltr.show-calendar.single.openscenter.linked {
    border: 0.5px solid #ededf0 !important;
    z-index: 99999999999999 !important;
    top: 45px;
  }
}
</style>
