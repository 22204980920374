<template>
  <div id="side-left" class="">
    <!-- Geral -->
    <div>
      <div
        class="btn-config d-flex justify-content-between"
        style="margin-top: -15px; padding: 15px !important"
        v-b-toggle.geral
      >
        <label class="titulo">Geral</label>
        <img
          class="chevron mt-0 icon"
          src="@/assets/editor/chevron-right.svg"
        />
      </div>
      <b-collapse accordion="configpage" id="geral" role="tabpanel" visible>
        <div class="p-1">
          <label class="subtitulo">Publicar</label>
          <div style="display: flex">
            <b-form-checkbox
              v-model="publicar"
              @change="sendData"
              name="is_main"
              size="lg"
              switch
            >
            </b-form-checkbox>
            <p
              class="d-flex align-items-center"
              style="
                color: #00000075;
                font-size: small;
                margin: 5px;
                font-weight: 400 !important;
              "
            >
              {{ publicar ? "Publicado" : "Modo rascunho" }}
            </p>
          </div>
          <div class="mt-2 l" v-if="userLevel !== 'templater'">
            <div class="add-link-body">
              <label class="subtitulo">Domínio</label>
              <router-link
                v-if="!dominios.length && stop && verificUserLevel"
                :to="{ name: 'Dominios' }"
                class="add-link link"
                >Deseja criar um domínio?</router-link
              >
            </div>
            <!-- <multiselect
              v-model="dominio"
              label="name"
              track-by="id"
              placeholder="Selecionar domínio"
              selectLabel=""
              deselectLabel=""
              selectedLabel=""
              open-direction="bottom"
              :options="dominios"
              :multiple="false"
              :searchable="true"
              :internal-search="true"
              :clear-on-select="false"
              :close-on-select="true"
              :show-no-results="true"
              :hide-selected="false"
              @select="sendData"
            >
              <span slot="noResult">Oops! Nenhum domínio encontrado.</span>
            </multiselect> -->

            <BaseSelect
              :selectModel="dominio"
              id="ajax"
              trackname="domain"
              track-by="id"
              placeholder="Selecione um domínio"
              selectLabel=""
              deselectLabel=""
              selectedLabel=""
              open-direction="bottom"
              :array="dominios"
              :multiple="false"
              :searchable="true"
              :internal-search="true"
              :clear-on-select="false"
              :close-on-select="true"
              :show-no-results="true"
              :hide-selected="false"
              :watch="true"
              @callback="fetchDomains($event)"
              @change="dominio = $event, sendData()"
              :loading="loadingDomains"
              @search="debounceDomains($event)"
            >
              <span slot="noResult"
                >Oops! Nenhum domínio encontrado.</span
              >
            </BaseSelect>


            <div class="mb-3"></div>
          </div>
          <label class="subtitulo">Título</label>
          <input
            class="w-100 mb-3"
            type="text"
            @input="sendData"
            v-model="title"
          />
          <div v-if="userLevel == 'templater'">
            <label class="subtitulo">Criar Categoria</label>
            <input type="text" @input="sendData" v-model="selectedCategory" />
            <BaseButton
              style="margin: 10px 0px 20px; height: 45px; width: 100%"
              @click="categoryData()"
              variant="primary"
            >
              Adicionar categoria
            </BaseButton>
          </div>
          <div v-if="userLevel == 'templater'" class="select-category">
            <b-tabs pills justified small style="width: 100%">
              <b-tab title="Ativas" class="mt-2">
                <div
                  v-for="(categorie, index) in activeCategories"
                  :key="index"
                >
                  <div
                    @click="categoryGreenn(categorie.id)"
                    :class="{
                      categoriesStyle: true,
                      greennBorder: categorie.id === categoryId,
                    }"
                  >
                    <p>{{ categorie.name }}</p>
                    <div class="d-flex" style="cursor: pointer">
                      <img
                        @click.stop="desativeCategory(categorie.id)"
                        src="@/assets/icons/lixeiraRed.svg"
                        alt="Lixeira"
                      />
                    </div>
                  </div>
                </div>
              </b-tab>
              <b-tab title="Inativas" class="mt-2">
                <div
                  v-for="(categorie, index) in inactiveCategories"
                  :key="index"
                >
                  <div class="categoriesStyle">
                    <p>{{ categorie.name }}</p>
                    <div class="d-flex" style="cursor: pointer">
                      <img
                        @click="addCategory(categorie.id, index)"
                        src="@/assets/icons/addGreenn.svg"
                        alt="Adicionar"
                      />
                    </div>
                  </div>
                </div>
              </b-tab>
            </b-tabs>
          </div>
          <div v-if="userLevel !== 'templater'">
            <div>
              <label class="subtitulo">Link</label>
              <input
                @input="sendData"
                class="w-100 mb-3"
                type="text"
                v-model="link"
              />
            </div>
              <b-form-checkbox
                v-b-toggle.allowCopy
                v-model="allowCopy"
                name="is_main"
                size="lg"
                validated
                variant="suclightcess"
                switch
                @change="sendData"
              >
                <p class="info-checkbox">
                  Permitir clonagem:
                  {{ allowCopy ? " Ativo " : "Inativo" }}
                </p>
              </b-form-checkbox>

            <!-- <div v-if="dominio" class="d-flex w-100 mb-3">
              <b-form-checkbox
                v-model="homepage"
                name="is_main"
                size="lg"
                switch
                variant="suclightcess"
                validated
                @change="sendData"
              >
                <p
                  class="d-flex align-items-center"
                  style="
                    color: #00000075;
                    font-size: small;
                    margin-top: 5px;
                    font-weight: 400 !important;
                  "
                >
                  Utilizar a página como homepage
                </p>
              </b-form-checkbox>
            </div> -->
          </div>
          <label v-if="userLevel == 'templater'" class="subtitulo mt-3"
            >Capa do template</label
          >
          <div class="select-image mb-3 mt-2" v-if="userLevel == 'templater'">
            <div @click="openModal('Upload-Editor-config', 'template-img')">
              <img
                :src="
                  image
                    ? image
                    : 'https://gdigital.s3.amazonaws.com/gdigital/8/imagem-vazia%20%28200%C2%A0%C3%97%C2%A0200%C2%A0px%29.webp'
                "
                alt="Imagem preview"
              />
            </div>
            <div>
              <input type="text" v-model="image" />
              <span @click="openModal('Upload-Editor', 'template-img')">{{
                image ? "Alterar imagem" : "Selecionar imagem"
              }}</span>
            </div>
          </div>
        </div>
      </b-collapse>
    </div>
    <div v-if="userLevel !== 'templater'" class="separador-left" />

    <!-- SEO -->
    <div v-if="userLevel !== 'templater'">
      <div class="btn-config d-flex justify-content-between" v-b-toggle.seo>
        <label class="titulo">SEO</label>

        <img
          class="chevron mt-0 icon"
          src="@/assets/editor/chevron-right.svg"
        />
      </div>
      <b-collapse accordion="configpage" id="seo" role="tabpanel">

        <label class="subtitulo">Favicon</label>
        <div class="select-image">
          <div @click="openModal('Upload-Editor-config', 'favicon')">
            <img
              v-if="!isGreenn"
              :src="
                favIcon
                  ? favIcon
                  : 'https://gdigital.s3.amazonaws.com/gdigital/1/thumb.webp'
                "
                alt="Imagem preview"
              />
              <img
                v-else
                src="@/assets/greenn_sales/SIMBOLO_.png"
              />
          </div>
          <div>
            <input
              type="text"
              v-model="favIcon"
              style="margin-bottom: 0 !important"
            />
            <span @click="openModal('Upload-Editor-config', 'favicon')">{{
              favIcon ? "Alterar imagem" : "Selecionar imagem"
            }}</span>
          </div>
        </div>
        <p class="mb-3 label-resolution">Resolução recomendada: 16x16</p>

        <!-- 
        <label class="subtitulo">Título para buscadores</label>
        <input
          class="w-100 mb-3"
          type="text"
          @input="sendData"
          v-model="titlePage"
        /> -->

        <label class="subtitulo">Thumbnail de compartilhamento</label>
        <div class="select-image">
          <div @click="openModal('Upload-Editor-config', 'thumbLink')">
            <img
              :src="getImageSrc(thumbLink)"
              alt="Imagem preview"
            />
          </div>
          <div>
            <input
              type="text"
              v-model="thumbLink"
              style="margin-bottom: 0 !important"
            />
            <span @click="openModal('Upload-Editor-config', 'thumbLink')">{{
              thumbLink ? "Alterar imagem" : "Selecionar imagem"
            }}</span>
          </div>
        </div>
        <p class="mb-3 label-resolution">Resolução recomendada: 256x256</p>

        <label class="subtitulo">Descrição para buscadores</label>
        <textarea
          class="w-100 mb-3"
          type="text"
          @input="sendData"
          v-model="descricao"
          style="height: auto !important"
          rows="3"
        ></textarea>
      </b-collapse>
    </div>
    <div v-if="userLevel !== 'templater'" class="separador-left" />

    <!-- Scripts -->
    <!-- <div v-if="userLevel !== 'templater'" class="mt-3">
      <div class="d-flex justify-content-between" v-b-toggle.code>
        <label class="titulo">Scripts</label>
        <img
          class="chevron icon mt-0"
          src="@/assets/editor/chevron-right.svg"
        />
      </div>
      <b-collapse accordion="configpage" id="code" role="tabpanel" appear>
        <div class="p-1">
          <label class="subtitulo"
            >Header: (Pixel Remarketing, Analytics, outros...)</label
          >
          <b-form-textarea
            v-model="pixel_header"
            @change="sendData"
            rows="8"
          ></b-form-textarea> -->
    <!-- 
          <label class="subtitulo">Inicio do Body: (Chats, outros...)</label>
          <b-form-textarea
            v-model="pixel_inicio_body"
            rows="8"
          ></b-form-textarea> -->

    <!-- <label class="subtitulo">Final do Body: (Hot Leads, outros...)</label>
          <b-form-textarea
            @change="sendData"
            v-model="pixel_final_body"
            rows="8"
          ></b-form-textarea>
        </div>
      </b-collapse>
    </div> -->
    <!-- <div v-if="userLevel !== 'templater'" class="separador-left" /> -->

    <!-- Pixel -->
    <div v-if="userLevel !== 'templater'">
      <div class="btn-config d-flex justify-content-between" v-b-toggle.pixel>
        <label class="titulo">Pixel</label>

        <img
          class="chevron mt-0 icon"
          src="@/assets/editor/chevron-right.svg"
        />
      </div>
      <b-collapse accordion="configpage" id="pixel" role="tabpanel">
        <div class="max-multi mb-3">
          <div id="select-pixel">
            <BaseSelect
              v-if="!loadingPixel"
              id="ajax"
              :selectModel="pixels"
              placeholder="Selecionar pixel"
              track-by="id"
              :array="pixelOptions"
              trackname="title"
              :searchable="true"
              :multiple="true"
              noResult="Oops! Nenhum pixel encontrado."
              noOptions="Pesquise pelo pixel"
              selectLabel=""
              deselectLabel=""
              selectedLabel=""
              @search="debouncePixel"
              @callback="fetchPixels($event)"
              @change="(pixels = $event), sendData()"
            >
            </BaseSelect>

            <div class="mt-3" v-for="(adsPixel, index) in getGoogleAdsPixels" :key="index">
              <label for="pixel_id">Label de conversão -
              {{
                adsPixel.title && adsPixel.title.length > 15
                  ? adsPixel.title.substr(0, 15) + "..."
                  : adsPixel.title
              }}
              (opcional)
              </label>
              <input @input="sendData()" style="width: 100%" type="text" id="pixel_id" v-model="adsPixel.conversion_label"/>
            </div>
          </div>
        </div>
      </b-collapse>
    </div>
    <div v-if="userLevel !== 'templater'" class="separador-left" />

    <!-- Redirecionamento -->
    <div v-if="userLevel !== 'templater'">
      <div
        class="btn-config d-flex justify-content-between"
        v-b-toggle.redirect
      >
        <label class="titulo">Redirecionamento</label>
        <img
          class="chevron icon mt-0"
          src="@/assets/editor/chevron-right.svg"
        />
      </div>

      <div>
        <b-collapse accordion="configpage" id="redirect" role="tabpanel">
          <label class="label-resolution"
            >Apenas um tipo de redirecionamento pode ser ativado por vez (por
            tempo de acesso ou por data)</label
          >
          <b-tabs
            pills
            justified
            small
            content-class="mb-3"
            style="width: 100%"
          >
            <b-tab
              title="Por tempo"
              class="mr-1"
              :active="time"
            >
              <div
                style="display: flex; margin-bottom: 10px !important"
                class="mt-3"
              >
                <b-form-checkbox
                  v-model="time"
                  size="lg"
                  switch
                  @change="handleDateChangeAndSendData(time, 'date')"
                >
                  <p
                    class="d-flex align-items-center"
                    style="
                      color: #00000075;
                      font-size: small;
                      padding-top: 5px;
                      font-weight: 400 !important;
                    "
                  >
                    {{ time ? "Ativado" : "Não ativado" }}
                  </p>
                </b-form-checkbox>
              </div>
              <label class="mt-3 subtitulo" :class="{ labelOff: time == false }"
                >Digite o tempo, em segundos, para redirecionar à página</label
              >
              <input
                class="w-100 mb-3 side-left-input"
                :disabled="!time"
                type="number"
                v-model="redirectTime"
                @input="sendData()"
              />
              <label class="subtitulo" :class="{ labelOff: time == false }"
                >Insira a página de redirecionamento</label
              >
              <input
                class="w-100 mb-3 side-left-input"
                :disabled="!time"
                type="text"
                v-model="redirectPage"
                @change="sendData"
              />
            </b-tab>

            <b-tab
              title="Por data"
              class="ml-1"
              :active="date"
            >
              <div
                style="display: flex; margin-bottom: 10px !important"
                class="mt-3"
              >
                <b-form-checkbox
                  v-model="date"
                  size="lg"
                  @change="handleDateChangeAndSendData(date, 'time')"
                  switch
                >
                  <p
                    class="d-flex align-items-center"
                    style="
                      color: #00000075;
                      font-size: small;
                      padding-top: 5px;
                      font-weight: 400 !important;
                    "
                  >
                    {{ date ? "Ativado" : "Não ativado" }}
                  </p>
                </b-form-checkbox>
              </div>
              <label class="subtitulo" :class="{ labelOff: date == false }"
                >Selecione a data final</label
              >

              <div>
                <date-range-picker
                  ref="picker"
                  opens="left"
                  :locale-data="localeData"
                  :showWeekNumbers="false"
                  :showDropdowns="true"
                  :autoApply="false"
                  :ranges="false"
                  :timePicker24Hour="true"
                  :timePicker="true"
                  v-model="dateRange1"
                  :single-date-picker="true"
                  class="w-100"
                  :class="date ? '' : 'date-disabled'"
                  :disabled="!date"
                >
                  <template
                    v-if="dateRange1.startDate && dateRange1.endDate"
                    v-slot:input="picker1"
                  >
                    {{ picker1.startDate | datetimem }}
                  </template>
                </date-range-picker>
              </div>

              <label class="subtitulo" :class="{ labelOff: date == false }"
                >Insira a página de redirecionamento</label
              >
              <input
                :disabled="!date"
                class="w-100 mb-3 side-left-input"
                type="text"
                @change="sendData"
                v-model="redirectPage"
              />
            </b-tab>
          </b-tabs>
        </b-collapse>
      </div>
    </div>
    <div v-if="userLevel !== 'templater'" class="separador-left" />

    <!-- UTM -->
    <div v-if="userLevel !== 'templater'">
      <div class="btn-config d-flex justify-content-between" v-b-toggle.utm>
        <label class="titulo">UTM</label>
        <img
          class="chevron icon mt-0"
          src="@/assets/editor/chevron-right.svg"
        />
      </div>
      <b-collapse accordion="configpage" id="utm" role="tabpanel">
        <label class="subtitulo">Link da página</label>
        <input class="w-100" type="text" v-model="linkPage" />

        <div class="line mb-3"></div>
        <div class="utms mb-3" v-for="(utm, index) in utms" :key="index">
          <div
            style="
              display: flex;
              width: 100%;
              justify-content: space-between;
              padding: 0 5px;
            "
          >
            <label class="sub">Título</label>
            <img
              @click="deleteUTM(index)"
              style="filter: invert(50%); height: 15px; cursor: pointer"
              src="@/assets/editor/x-circle.svg"
            />
          </div>
          <input
            class="w-100 mb-3"
            type="text"
            v-model="utm.utm"
            placeholder="utm_source"
          />

          <label class="sub" style="padding: 0 5px">Conteúdo</label>
          <input
            style="margin-bottom: 0px !important"
            class="w-100"
            type="text"
            v-model="utm.camp"
            placeholder="google"
          />
        </div>

        <button
          v-if="utms.length < 4"
          @click="addUtm"
          class="add-utm utm-button mb-3"
        >
          <img src="@/assets/editor/plus-circle.svg" />
          <span>Adicionar UTM</span>
        </button>
        <div class="line mb-3"></div>
        <div style="display: flex; justify-content: space-between">
          <label class="subtitulo">Link gerado</label>
          <img
            @click="copyUTM(linkUTM)"
            style="height: 16px; cursor: pointer"
            class="chevron icon mt-0"
            src="@/assets/editor/duplicate.svg"
          />
        </div>
        <div class="utms mb-3" style="min-height: 45px">{{ linkUTM }}</div>
      </b-collapse>
    </div>
    <div v-if="userLevel !== 'templater'" class="separador-left" />

    <!-- LGPD -->
    <div v-if="userLevel !== 'templater'">
      <div class="btn-config d-flex justify-content-between" v-b-toggle.lgpd>
        <label class="titulo">LGPD</label>
        <img
          class="chevron icon mt-0"
          src="@/assets/editor/chevron-right.svg"
        />
      </div>

      <b-collapse accordion="configpage" id="lgpd" role="tabpanel">
        <div>
          <label class="subtitulo"
            >Ativar modal de coleta de cookies com os padrões LGPD</label
          >
          <div class="d-flex pb-3">
            <b-form-checkbox
              v-model="LGPD"
              name="is_main"
              size="lg"
              switch
              variant="suclightcess"
              validated
              @change="sendData"
            >
              <p
                class="d-flex align-items-center"
                style="
                  color: #00000075;
                  font-size: small;
                  margin-top: 5px;
                  font-weight: 400 !important;
                "
              >
                {{ LGPD ? "Definido" : "Não definido" }}
              </p>
            </b-form-checkbox>
          </div>
        </div>
      </b-collapse>
    </div>
    <div class="separador-left"></div>

    <!--MARCA-->
    <div>
      <div v-if="userLevel !== 'templater'">
        <div class="btn-config d-flex justify-content-between" v-b-toggle.marca>
          <label class="titulo">Marca d'água</label>
          <img
            class="chevron icon mt-0"
            src="@/assets/editor/chevron-right.svg"
          />
        </div>
        <b-collapse accordion="configpage" id="marca" role="tabpanel">
          <div>
            <label class="subtitulo"
              >{{ marca ? "Remover" : "Ativar" }} marca d'água de sua página</label
            >
            <div class="d-flex pb-3">
              <b-form-checkbox              
                v-if="showSwitchMarca == false"
                class="disabledMarca"
                name="is_main"
                disabled
                size="lg"
                v-model="marcaTruePermanent"
                switch
                variant="suclightcess"
                validated
              >
                <p
                  class="d-flex align-items-center"
                  style="
                    color: #00000075;
                    font-size: small;
                    margin-top: 5px;
                    font-weight: 400 !important;
                  "
                >
                  {{ marca ? "Ativado" : "Desativado" }}
                </p>
              </b-form-checkbox>

              <b-form-checkbox
                v-else
                :disabled="disabledMarca"
                v-model="marca"
                name="is_main"
                size="lg"
                switch
                variant="suclightcess"
                validated
                @change="sendData, $emit('envMarcaD',marca)"
              >
                <p
                  class="d-flex align-items-center"
                  style="
                    color: #00000075;
                    font-size: small;
                    margin-top: 5px;
                    font-weight: 400 !important;
                  "
                >
                  {{ marca ? "Ativado" : "Desativado" }}
                </p>
              </b-form-checkbox>
            </div>
          </div>
        </b-collapse>
      </div>
    </div>
    <div v-if="userLevel !== 'templater'" class="separador-left" />
    <Upload @setImg="setImg" v-if="item_selecionado == 'config'"  :fromConfig="true" />
  </div>
</template>

<script>
import Vue from "vue";
import DateRangePicker from "vue2-daterange-picker";
import DomainService from "@/services/resources/DomainService";
import TemplateService from "@/services/resources/TemplateService";
import PixelService from "@/services/resources/PixelService";
import moment from "moment";
import Upload from "../../Upload.vue";
const serviceTemplate = TemplateService.build();
const servicePixel = PixelService.build();
const serviceDomain = DomainService.build();
import _ from "lodash";

export default {
  components: {
    DateRangePicker,
    Upload,
  },
  props: ["dados", "item_selecionado", "selected_pageDomain", "categories"],
  data() {
    return {
      pagePixel: "",
      loadingPixel: true,
      searchPixel: "",
      activeCategories: [],
      inactiveCategories: [],
      page_id: this.$route.params.page_id,
      stop: false,
      stop2: false,
      canChangeMark:false,
      stopCategory: false,
      categoryId: 0,
      titlePage: "",
      publicar: true,
      LGPD: false,
      allowCopy: false,
      marca: true,
      marcaTruePermanent: true,
      showSwitchMarca: false,
      disabledMarca: false,
      title: "",
      selectedCategory: null,
      link: "",
      linkType: "",
      pixel: "",
      descricao: "",
      thumbLink: "",
      favIcon: "",
      dominios: [],
      dominio: {id: null},
      stopDomain: false,
      pageDomain: 1,
      loadingDomains: false,
      image:
        "https://gdigital.s3.amazonaws.com/gdigital/1/img_null.f2037356.webp",
      utms: [],
      utm: 1,
      linkPage: "",
      utm1: "teste",
      utm2: "oioioi",
      utm3: "sdds",
      camp1: "aaa",
      camp2: "ss",
      camp3: "sfsdfsdf",
      redirectTime: "",
      redirectPage: "",
      redirectDate: "",
      redirectDatePage: "",
      time: false,
      date: false,
      current_upload: "",
      pixel_header: "",
      pixel_inicio_body: "",
      pixel_final_body: "",
      pixelOptions: [],
      pixels: [],
      dateRange1: {
        startDate: moment(),
        endDate: moment(),
      },
      selected: null,
      localeData: {
        direction: "ltr",
        format: "dd/mm/yyyy",
        separator: " - ",
        applyLabel: "Aplicar",
        cancelLabel: "Cancelar",
        weekLabel: "W",
        customRangeLabel: "Custom Range",
        daysOfWeek: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"],
        monthNames: [
          "Jan",
          "Fev",
          "Mar",
          "Abr",
          "Mai",
          "Jun",
          "Jul",
          "Ago",
          "Set",
          "Out",
          "Nov",
          "Dez",
        ],
        firstDay: 0,
      },
      homepage: false,

      gdigitalIcon: 'https://gdigital.s3.amazonaws.com/gdigital/1/thumb.webp',
      greennSalesIcon: require('@/assets/greenn_sales/SIMBOLO_.png')

    };
  },
  methods: {
    getImageSrc(img) {
      const { greennSalesIcon, gdigitalIcon, isGreenn } = this;
      const defaultIcon = isGreenn ? greennSalesIcon : gdigitalIcon;

      if (img === 0) return defaultIcon;
      if (typeof img === 'string' && img.startsWith('https://')) {
        return img;
      } else {
        return defaultIcon;
      }
    },

    fetchDomains(page, query = "") {
      if(this.stopDomain){
        return;
      }
      var data = {
        id: `list?page=${page ? page : 1}&order_by=domain&order=ASC&search=${query}`,
      };

      this.loadingDomains = true;

      serviceDomain
        .read(data)
        .then((resp) => {
          this.loadingDomains = false;
          if (resp.data.length < resp.per_page && query == "") {
            this.stopDomain = true;
          }
          this.dominios = this.dominios.concat(resp.data);        
        });
    },
    debounceDomains: _.debounce(function (query) {
      this.resetDomainSelect();
      if (query === "") {
        this.pageDomain = 1;
      }
      this.fetchDomains(this.pageDomain, query);
    }, 500),
    resetDomainSelect(){
      this.pageDomain = 1;
      this.stopDomain = false;
      this.dominios = [];
    },
    categoryData() {
      if (!this.selectedCategory) {
        this.$grToast.toast("Digite o nome da categoria", {
          title: "Editor",
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
        return;
      }
      let data = {
        id: "category",
        name: this.selectedCategory,
        status: "active",
      };
      serviceTemplate.createId(data).then((response) => {
        this.categories.unshift(response);
      });
      this.selectedCategory = "";
    },
    desativeCategory(category_id) {
      Vue.swal({
        title: "Editor",
        text: `Deseja realmente desativar essa categoria?`,
        type: "danger",
        showCancelButton: true,
        confirmButtonText: "Desativar",
        cancelButtonText: "Cancelar",
        customClass: "sweet-container",
        confirmButtonClass: "button button-danger mt-3 mb-3",
        cancelButtonClass: "button button-link-info mr-4 mt-3 mb-3",
        buttonsStyling: false,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          let data = {
            status: "inactive",
            id: `category/${category_id}`,
          };
          serviceTemplate
            .update(data)
            .then(() => {
              this.$grToast.toast("Categoria desativada com sucesso", {
                title: "Editor",
                variant: "success",
                autoHideDelay: 5000,
                appendToast: true,
              });

              const indexToDelete = this.activeCategories.findIndex(
                (category) => category.id === category_id
              );
              if (indexToDelete !== -1) {
                this.inactiveCategories.unshift(
                  this.activeCategories[indexToDelete]
                );
                this.activeCategories.splice(indexToDelete, 1);
              }
            })
            .catch(() => {
              this.$grToast.toast("Erro ao desativar categoria", {
                title: "Evento",
                variant: "danger",
                autoHideDelay: 5000,
                appendToast: true,
              });
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
    addCategory(category_id) {
      Vue.swal({
        title: "Editor",
        text: `Deseja realmente ativar essa categoria?`,
        type: "success",
        showCancelButton: true,
        confirmButtonText: "Ativar",
        cancelButtonText: "Cancelar",
        customClass: "sweet-container",
        confirmButtonClass: "button button-primary mt-3 mb-3",
        cancelButtonClass: "button button-link-info mr-4 mt-3 mb-3",
        buttonsStyling: false,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          let data = {
            status: "active",
            id: `category/${category_id}`,
          };
          serviceTemplate
            .update(data)
            .then(() => {
              Vue.set(
                this.categories[
                  this.categories.findIndex(
                    (category) => category.id == category_id
                  )
                ],
                "status",
                "active"
              );
              this.$grToast.toast("Categoria ativada com sucesso", {
                title: "Editor",
                variant: "success",
                autoHideDelay: 5000,
                appendToast: true,
              });
              const indexToDelete = this.inactiveCategories.findIndex(
                (category) => category.id === category_id
              );
              if (indexToDelete !== -1) {
                this.activeCategories.unshift(
                  this.inactiveCategories[indexToDelete]
                );
                this.inactiveCategories.splice(indexToDelete, 1);
              }
            })
            .catch(() => {
              this.$grToast.toast("Erro ao ativar categoria", {
                title: "Evento",
                variant: "danger",
                autoHideDelay: 5000,
                appendToast: true,
              });
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
    categoryGreenn(category_id) {
      if (this.categoryId === category_id) {
        if (this.$route.params.page_id === undefined) {
          this.categoryId = 0;
          return;
        }
        let data = {
          id: `edit/${this.$route.params.page_id}`,
          category_id: 0,
        };
        serviceTemplate.update(data).then(() => {
          this.categoryId = 0;
          this.$grToast.toast("Categoria desassociada com sucesso", {
            title: "Editor",
            variant: "danger",
            autoHideDelay: 5000,
            appendToast: true,
          });
        });
        return;
      }
      this.categoryId = category_id;
      if (this.$route.params.page_id) {
        let data = {
          id: `edit/${this.$route.params.page_id}`,
          category_id: category_id,
        };
        serviceTemplate.update(data).then(() => {
          this.$grToast.toast("Categoria associada com sucesso", {
            title: "Editor",
            variant: "info",
            autoHideDelay: 5000,
            appendToast: true,
          });
        });
        return;
      }
    },
    copyUTM(text) {
      navigator.clipboard.writeText(text);
      this.$grToast.toast("UTM copiada com sucesso.", {
        title: "Editor",
        variant: "success",
        autoHideDelay: 5000,
        appendToast: true,
      });
    },
    checkMarca() {
      var contract = JSON.parse(sessionStorage.getItem("userContracts"));
      let productIds = [119, 120, 121, 122, 126, 127, 128, 129];

    let foundContract = contract.find(element => productIds.includes(element.produto_id));
      if(foundContract){
        this.canChangeMark = true;
        this.showSwitchMarca = true;
      }
      this.marcaTrocar();
    },

    handleDateChangeAndSendData(time, type) {
      this.validRedirect(time, type);
      this.sendData();
    },

    validRedirect(newValue, variable) {
      if (newValue) {
        this[variable] = false
      }
    },
    updateMark(data) {
      this.marca = data.marca;
      this.title = data.title;
      this.marcaTrocar();
    },
    marcaTrocar() {
      this.$emit("sendMarca", this.marca);
      this.sendData();
    },
    setImg(img) {
      switch (this.current_upload) {
        case "template-img":
          this.image = img;
          break;
        case "favicon":
          this.favIcon = img;
          break;
        case "thumbLink":
          this.thumbLink = img;
          break;
      }
      this.sendData();
    },
    openModal(data, name) {
      this.current_upload = name;
      this.$bvModal.show(data);
    },
    // setDomain() {
    //   var domain = this.dominios.find(
    //     (x) => x.id == this.getMeta(this.dados.metas, "page_domain")
    //   );

    //   if (domain) {
    //     this.dominio = domain;
    //     return;
    //   } else if (this.dominios.length) {
    //     this.dominio = this.dominios[0];
    //   }
    //   // if (this.selected_pageDomain) {
    //   //   var encontrar_domain = this.dominios.find(
    //   //     (obj) => obj.id == this.selected_pageDomain.meta_value
    //   //   );
    //   //   if (encontrar_domain) {
    //   //     this.dominio = encontrar_domain;
    //   //   }
    //   // }
    // },
    getMeta(metas, chave) {
      if (!metas || !chave) {
        return;
      }
      var result = metas.find((x) => x.meta_key == chave);
      if (!result) {
        return "";
      }
      return result.meta_value;
    },

    debouncePixel: _.debounce(function (query) {
      this.searchPixel = query;
      this.pagePixel = 1;
      this.stop2 = false;
      this.fetchPixels(this.pagePixel, query);
    }, 500),
    fetchPixels(page = 1, query = "") {
      if (this.stop2) {
        return;
      }
      var data = {
        page: `${page}&order_by=title&order=ASC&search=${query}`,
      };

      servicePixel
        .search(data)
        .then((resp) => {
          if (!resp.data.length) {
            this.stop2 = true;
            return;
          }

          this.pixelOptions = this.pixelOptions.concat(resp.data);

          this.pixelOptions = this.pixelOptions.filter(
            (item, index, opt) =>
              index === opt.findIndex((t) => t.id === item.id)
          );
        })
        .catch(() => {
          this.pixelOptions = [];
        })
        .finally(() => {
        });
    },
    updateTitle(newTitle) {
      this.title = newTitle;
    },
    addUtm() {
      this.utms.push({
        utm: "",
        camp: "",
      });
    },
    deleteUTM(idx) {
      this.utms.splice(idx, 1);
    },
    sendData() {
      let data = {
        date_switch: this.date ? "true" : "false",
        date: this.dateRange1.startDate ?? "",
        description: this.descricao,
        domain: this.dominio && this.dominio.id ? this.dominio.id : "",
        favicon: this.favIcon,
        homepage: this.homepage,
        html_header_custom: this.pixel_header ?? "",
        html_foot_custom: this.pixel_final_body ?? "",
        image: this.image ?? "",
        lgpd: this.LGPD ? "true" : "false",
        allowCopy: this.allowCopy ? "true" : "false",
        link: this.link,
        marca: this.marca ? "true" : "false",
        open_config: "true",
        pixels: this.pixels ?? "",
        publicar: this.publicar,
        redirect_page: this.redirectPage ?? "",
        search_title: this.titlePage,
        time: this.time ? "true" : "false",
        time_redirect: this.redirectTime ?? "",
        thumb: this.thumbLink && this.thumbLink !== "https://gdigital.s3.amazonaws.com/gdigital/1/thumb.webp" ? this.thumbLink : "",
        title: this.title,
        category_id: this.categoryId,
      };

      this.$emit("sendData", data);
    },
    fetchPixelsPage() {
      servicePixel
        .read({ id: "/page/" + this.$route.params.page_id })
        .then((response) => {
          this.pixels = response
        })
        .finally(() => {
          this.loadingPixel = false
        });
    },
    setConfig() {
      if (this.dados) {
        this.title = this.dados && this.dados.title ? this.dados.title : "";
        this.categoryId = this.dados.category_id;
        if (this.dados.hasOwnProperty("time")) {
          this.favicon = this.dados.favIcon;
          this.link = this.dados.link;
          this.publicar = this.dados.publicar;
          this.dateRange1.startDate = this.dados.date;
          this.descricao = this.dados.description;
          this.image = this.dados.image;
          this.thumbLink = this.dados.thumb;
          this.LGPD = this.dados.lgpd == "true" ? true : false;
          this.allowCopy = this.dados.allowCopy == "true" ? true : false;
          this.marca = this.dados.marca == "true" ? true : false;
          this.time = this.dados.time == "true" ? true : false;
          this.date = this.dados.date_switch == "true" ? true : false;
          this.dominio.id = this.dados.domain ?? "";
          this.pixel_header = this.dados.html_header_custom;
          this.pixel_final_body = this.dados.html_foot_custom;
          this.redirectTime = this.dados.time_redirect;
          this.redirectPage = this.dados.redirect_page;
          this.titlePage = this.dados.search_title;
        } else {
          this.link = this.dados.path_name ?? "";
          this.publicar = this.dados.status == "publish" ? true : false;
          this.pixel_header =
            this.getMeta(this.dados.metas, "html_header_custom") ?? "";
          this.favIcon = this.getMeta(this.dados.metas, "favicon") ?? "";
          this.titlePage = this.getMeta(this.dados.metas, "search_title") ?? "";
          this.descricao = this.getMeta(this.dados.metas, "description") ?? "";
          this.pixel_final_body =
            this.getMeta(this.dados.metas, "html_foot_custom") ?? "";
          this.LGPD =
            this.getMeta(this.dados.metas, "lgpd") == "true" ? true : false;
          this.allowCopy =
            this.getMeta(this.dados.metas, "allowCopy") == "true" ? true : false;
          this.marca =
            !this.dados.metas ||
            this.getMeta(this.dados.metas, "marca") === "true"
              ? true
              : false;
          this.redirectPage = this.getMeta(this.dados.metas, "redirect_page");
          this.time =
            this.getMeta(this.dados.metas, "time_redirect_switch") == "true"
              ? true
              : false;
          this.date =
            this.getMeta(this.dados.metas, "page_date_switch") == "true"
              ? true
              : false;
          this.redirectTime = this.getMeta(this.dados.metas, "time_redirect");
          this.dateRange1.startDate = this.getMeta(
            this.dados.metas,
            "redirect_page_date_inactive"
          );
          this.thumbLink = this.getMeta(this.dados.metas, "thumb");

          var dominioId = parseFloat(
            this.getMeta(this.dados.metas, "page_domain")
          );
          this.dominio = this.dominios?.find((x) => x.id == dominioId) ?? {};

        }
      }
    },
  },
  computed: {
     isGreenn() {
      return this.$store.getters["getIsGreenn"];
    },
    
    getGoogleAdsPixels() {
      return this.pixels.filter((x) => x.type === "GOOGLEADWORDS" && x.conversion);
    },
    verificUserLevel() {
      var user = this.$store.getters.user.user.level;
      if (user === "owner" || user === "admin" || user == "gerent_seller") {
        return true;
      } else {
        return false;
      }
    },
    linkUTM() {
      let newUTMs = JSON.parse(JSON.stringify(this.utms));
      let stringUTMs = "";
      for (let index = 0; index < newUTMs.length; index++) {
        const element = newUTMs[index];
        element.utm = encodeURIComponent(element.utm);
        element.camp = encodeURIComponent(element.camp);
        if (index == 0) {
          stringUTMs = `?${element.utm}=${element.camp}`;
        } else {
          stringUTMs = `${stringUTMs}&${element.utm}=${element.camp}`;
        }
      }
      return `${this.linkPage}${stringUTMs}`;
    },
    userLevel() {
      return this.$store.getters.user.user.level;
    },
  },
  mounted() {
    this.checkMarca();
    this.fetchPixelsPage();
  },
  watch: {
    categories() {
      this.activeCategories = this.categories.filter(
        (category) => category.status == "active"
      );
      this.inactiveCategories = this.categories.filter(
        (category) => category.status == "inactive"
      );
    },
    categoryId() {
      this.sendData();
    },
    dados: {
      handler: function () {
        this.item_selecionado == 'config' ? this.setConfig() : '';
      },
      deep: true,
    },
    item_selecionado(){
      this.item_selecionado == 'config' ? this.setConfig() : '';
    },
    time() {
      if (this.time && this.date) {
        this.date = false;
        this.dateRange1.startDate = moment();
      }
    },
    dateRange1() {
      this.sendData();
    },
    marca() {
      this.checkMarca();
    },
  },
};
</script>

<style>
.max-multi .multiselect__tags {
  max-height: 45px !important;
}

.multiselect__input {
  transition: none !important;
}
</style>

<style scoped lang="scss">
.btn-config {
  transition: background-color 0.3s ease-in-out;
  padding: 15px;
  position: relative;
  margin-left: -15px;
  margin-right: -15px;
  &:hover {
    background-color: rgb(244, 244, 244);
  }
}
.categoriesStyle {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border: 1px solid var(--white-medium);
  border-radius: 10px;
  margin: 5px;
  p {
    font-size: 15px;
    color: black;
    max-width: calc(100% - 25px);
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.select-category {
  max-height: 250px;
  overflow-y: auto;
  border-radius: 10px;
  border: 1px solid var(--white-medium);
  padding: 15px;
  margin-bottom: 40px;
}

.greennBorder {
  border: 2px solid var(--greenn);
  background-color: var(--greenn2);
}
.link {
  font-weight: 400 !important;
}
.btn-config.not-collapsed {
  background-color: rgb(255, 255, 255) !important;
}
.mt-3 {
  margin: 0 !important;
  padding: 1rem 0 0 0;
}
.d-flex.justify-content-between.collapsed {
  transition: all 0.3s;
  position: relative;
}
.label-resolution {
  font-size: 10px !important;
  text-transform: uppercase;
  font-weight: 400 !important;
  text-align: justify;
}
.select-image {
  display: flex;
  padding: 10px;
  border: 1px solid #ececec;
  border-radius: 10px;
  width: 100%;
  transition: all 0.3s;
  &:hover {
    border-color: var(--greenn) !important;
  }
  img {
    height: 60px;
    width: 60px;
    object-fit: cover;
    padding: 5px;
    cursor: pointer;
    border-radius: 10px;
  }
  span {
    font-size: 10px;
    text-transform: uppercase;
    text-align: right;
    padding: 5px;
    padding-bottom: 0px;
    transition: all 0.3s;
    cursor: pointer;
    &:hover {
      color: var(--greenn);
    }
  }
  input {
    font-size: 12px !important;
    height: 30px !important;
    padding: 10px !important;
    margin-top: 5px;
    margin-bottom: 0px !important;
  }
}
.separador-left {
  width: calc(100% + 30px);
  height: 1px;
  margin-left: -15px;
  background-color: #ededf0;
}
.titulo,
.subtitulo {
  cursor: pointer;
}
.custom-control-input:focus ~ .custom-control-label::before {
  -webkit-box-shadow: 0 0 0 0.2rem var(--greenn) 35 !important;
  box-shadow: 0 0 0 0.2rem var(--greenn) 35 !important;
}

.custom-control-label::before {
  border: 1px solid #adbdbc !important;
}

input:disabled,
select:disabled input[disabled] {
  color: #666666;
}
.labelOff {
  color: rgb(185, 185, 185) !important;
}
.add-utm {
  border: 0.5px solid #ededf0;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  transition: background-color 0.3s;
  &:hover {
    background-color: #ededf0;
  }
}
.delete-utm {
  background-color: #e4000035;
}
.utm-button {
  width: 100%;
  font-size: small;
  padding: 3px;
  height: 30px;
  border-radius: 10px;
  img {
    width: 15px;
    margin-right: 5px;
  }
}
.line {
  border-bottom: 1px solid #ededf0;
}
.utms {
  border: 0.5px solid #ededf0;
  border-radius: 10px;
  padding: 10px;
  word-break: break-all;
  font-size: 14px;
}
.chevron {
  transition: all 0.3s;
  margin-top: 1px;
  height: 20px;
}

.chevron {
  transition: transform 0.3s ease-in-out;
}

.collapsed > .chevron {
  transform: rotate(0deg);
}

.not-collapsed > .chevron {
  transform: rotate(90deg);
}

.config {
  color: rgb(0, 0, 0);
}

.disabledMarca {
  opacity: 0.5;
}

input,
select {
  padding: 15px 20px;
  height: 45px !important;
  border: 0.5px solid #ededf0;
  color: #000 !important;
  outline: none !important;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
  font-size: 14px !important;
  -webkit-transition: 0.3s !important;
  transition: 0.3s !important;
  border-radius: 10px !important;
  margin-bottom: 12px;
}

textarea {
  padding: 15px 20px;
  border: 0.5px solid #ededf0;
  color: #000 !important;
  outline: none !important;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
  font-size: 14px !important;
  -webkit-transition: 0.3s !important;
  transition: 0.3s !important;
  border-radius: 10px !important;
  margin-bottom: 12px;
}
</style>

<style lang="scss">
#side-left {
  user-select: none;
  .titulo {
    margin-bottom: 0 !important;
  }
  .container-editor .list-itens {
    padding: 0px 15px;
  }
}
.side-left-input:disabled {
  color: #dedede !important;
}
.reportrange-text[data-v-1ebd09d2] {
  background: #fff;
  transition: all 0.3s !important;
  cursor: pointer;
  padding: 11px 20px;
  border: 1px solid #eee;
  width: 100%;
  overflow: hidden;
  height: 45px;
  font-size: 14px;
  font-weight: 400;
  color: #000000;
}

.reportrange-text[data-v-1ebd09d2]:hover {
  border-color: var(--greenn) !important;
}

.date-disabled > div {
  color: #dedede !important;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: var(--greenn) !important;
  background-color: var(--greenn) !important;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: rgb(0, 0, 0) !important;
  background-color: #4ea93430;
  font-weight: 700;
}
a {
  color: #000000 !important;
  font-weight: 500;
}
.multiselect--active {
  padding: 0px;
  border-color: transparent !important;
}
.multiselect__input,
.multiselect__input:focus {
  border-color: transparent !important;
  left: -2px;
}
.multiselect__placeholder {
  color: #adadad;
  display: inline-block;
  margin-bottom: 10px;
  padding-top: 2px;
  padding-left: 11px;
}
p {
  font-size: 14px;
  font-weight: normal;
  color: #81858e;
  line-height: 1.5;
}
</style>
