<template>
  <div id="SideRightTabs">
    <b-card no-body>
      <b-tabs pills justified small style="width: 100%">
        <b-tab title="Conteúdo" active>
          <!-- Controle de imagens -->
          <div class="separador"></div>
          <div class="content">
            <div class="container-links">
              <div class="half">
                <label class="titulo">Imagens</label>
                <div class="contain-buttons" style="margin-top: -15px">
                  <button
                    class="btn button-add-produto"
                    @click="addImg"
                  ></button>
                </div>
              </div>  
            </div>
            <div v-for="(item, idx) in editaItem.propsData.gallery" :key="idx">
              <div class="p-2" style="position: relative;">
                <div class="separador" style="position:absolute; top: 17px; left: -3px;"></div>
                <label class="subtitulo component-group-title">Imagem {{ idx + 1 }}</label>
              </div>
              <div style="display: flex">
                <div class="select-image" style="width: calc( 100% - 35px )">
                  <div @click="openModal('Upload-Editor', idx)">
                    <img :src="item.imagem ? item.imagem : 'https://gdigital.s3.amazonaws.com/gdigital/1/imagem-vazia3%20%281%29.webp'" alt="Imagem preview">
                  </div>
                  <div>
                    <input type="text" v-model="editaItem.propsData.gallery[idx].imagem">
                    <span @click="openModal('Upload-Editor', idx)">{{ item.imagem ? 'Alterar imagem' : 'Selecionar imagem'}}</span>
                  </div>
                </div>
                <div class="image-control">
                  <div>
                    <img
                      :class="{ 'disabled-arrow' : idx == 0}"
                      class="first-arrow"
                      src="@/assets/editor/chevron-down.svg"
                      @click="idx == 0 ? '' : moveUp(idx)"
                      title="Mover para cima"
                    />
                    <button
                      class="button-remove-produto"
                      @click="removeImgIndex(idx)"
                    ></button>
                    <img
                      :class="{ 'disabled-arrow' : idx + 1 == editaItem.propsData.gallery.length}"
                      class="last-arrow"
                      src="@/assets/editor/chevron-down.svg"
                      @click="idx + 1 == editaItem.propsData.gallery.length ? '' : moveDown(idx)"
                      title="Mover para baixo"
                    />
                  </div>
                </div>
              </div>
              <div class="space"></div>
            </div>
          </div>
          <div class="separador"></div>
        </b-tab>

        <b-tab title="Estilo">
          <div class="separador"></div>
          <!--Dimensões-->
          <div class="content">
            <div class="label-icon">
              <label class="titulo">Dimensões</label>
              <img
                src="@/assets/editor/globe.svg"
                alt="monitor"
                style="filter: invert(50%) !important"
                @click="nextIcon('desktop'), (global = !global)"
                v-if="global"
              />
              <img
                src="@/assets/editor/monitor.svg"
                alt="monitor"
                style="filter: invert(50%) !important"
                @click="nextIcon('tablet')"
                v-if="typeView === 'desktop' && !global"
              />
              <img
                src="@/assets/editor/tablet.svg"
                alt="tablet"
                style="filter: invert(50%) !important"
                @click="nextIcon('mobile')"
                v-if="typeView === 'tablet' && !global"
              />
              <img
                src="@/assets/editor/phone.svg"
                alt="phone"
                style="filter: invert(50%) !important"
                @click="nextIcon('desktop'), (global = !global)"
                v-if="typeView === 'mobile' && !global"
              />
            </div>

            <div class="label-range">
              <label
                class="subtitulo"
                >Quantidade de colunas</label
              >
              <input
                type="number"
                v-model="editaItem.propsData.styles.size[typeView]"
                @input="globalize('size')"
              />
            </div>
            <b-form-input
              @input="globalize('size')"
              id="range-1"
              v-model="editaItem.propsData.styles.size[typeView]"
              type="range"
              min="1"
              max="8"
              step="1"
            ></b-form-input>

            <div class="label-range">
              <label
                class="subtitulo"
                >Espaçamento entre as fotos</label
              >
              <input
                type="number"
                @input="globalize('gap')"
                v-model="editaItem.propsData.styles.gap[typeView]"
              />
            </div>
            <b-form-input
              id="range-1"
              v-model="editaItem.propsData.styles.gap[typeView]"
              @input="globalize('gap')"
              type="range"
              min="0"
              max="40"
              step="1"
            ></b-form-input>

            <label
              class="subtitulo"
              >Alinhamento vertical</label
            >
            <div style="display: flex; width: 50%; transition: all 0.3s">
                <div class="select-align">
                  <button
                    :class="{
                      'selected-align':
                        editaItem.propsData.styles.align[typeView] == 'start',
                    }"
                    @click="globalize('align'), editaItem.propsData.styles.align[typeView] = 'start'"
                  >
                    <img src="@/assets/editor/align-top.svg" />
                  </button>
                  <button
                    :class="{
                      'selected-align':
                        editaItem.propsData.styles.align[typeView] == 'center',
                    }"
                    @click="globalize('align'), editaItem.propsData.styles.align[typeView] = 'center'"
                  >
                    <img src="@/assets/editor/align-center-vertical.svg" />
                  </button>
                  <button
                    :class="{
                      'selected-align':
                        editaItem.propsData.styles.align[typeView] == 'end',
                    }"
                    @click="globalize('align'), editaItem.propsData.styles.align[typeView] = 'end'"
                  >
                    <img src="@/assets/editor/align-bottom.svg" />
                  </button>
                </div>
              </div>
          </div>
          <div class="separador"></div>
          <!-- Sombra -->
        <div class="content">
          <div style="display: flex; width: 100%; transition: all 0.3s">
            <label class="titulo">Sombra</label>
            <b-form-checkbox
              style="margin-top: -5px; margin-left: auto; margin-right: 0"
              v-model="editaItem.propsData.shadow.hasShadow"
              size="lg"
              switch
            >
            </b-form-checkbox>
          </div>
          <label
            :class="{ labelOff: editaItem.propsData.shadow.hasShadow == false }"
            class="subtitulo"
            >Cor da sombra</label
          >
          <LvColorpicker
            id="LvColorpicker"
            :class="{ colorDisabled: editaItem.propsData.shadow.hasShadow == false, }"
            :disabled="editaItem.propsData.shadow.hasShadow == false"
            v-model="editaItem.propsData.shadow.shadowColor"
            :colors="palette"
          ></LvColorpicker>
          <div class="space"></div>
          <div class="three">
            <div>
              <label
                :class="{ labelOff: editaItem.propsData.shadow.hasShadow == false }"
                class="subtitulo"
                >Eixo X</label
              >
              <input
                :disabled="editaItem.propsData.shadow.hasShadow == false"
                type="number"
                v-model="editaItem.propsData.shadow.shadowX"
                @focus="openDropdownSlider('editaItem.propsData.shadow.shadowX')"
              />
            </div>
            <div>
              <label
                :class="{ labelOff: editaItem.propsData.shadow.hasShadow == false }"
                class="subtitulo"
                >Eixo Y</label
              >
              <input
                :disabled="editaItem.propsData.shadow.hasShadow == false"
                type="number"
                v-model="editaItem.propsData.shadow.shadowY"
                @focus="openDropdownSlider('editaItem.propsData.shadow.shadowY')"
              />
            </div>
            <div>
              <label
                :class="{ labelOff: editaItem.propsData.shadow.hasShadow == false }"
                class="subtitulo"
                >Borrão</label
              >
              <input
                type="number"
                :disabled="editaItem.propsData.shadow.hasShadow == false"
                v-model="editaItem.propsData.shadow.shadowBlur"
                @focus="openDropdownSlider('editaItem.propsData.shadow.shadowBlur')"
              />
            </div>
          </div>
        </div>
        <div class="separador"></div>
          <!-- Bordas -->
        <div class="content">
          <div style="display: flex; width: 100%; transition: all 0.3s"> 
            <label class="titulo"  >Bordas</label>
            <b-form-checkbox style="margin-top: -5px; margin-left: auto; margin-right: 0"
              v-model="editaItem.propsData.border.border"
              size="lg"
              switch>

            </b-form-checkbox>
          </div>
          <!-- Arredondar borda -->
          <div class="label-range">
            <label
              class="subtitulo"
              >Arredondar bordas</label
            >
            <input
              type="number"
              v-model="editaItem.propsData.border.radius"
            />
          </div>
          <b-form-input
            id="range-1"
            v-model="editaItem.propsData.border.radius"
            type="range"
            min="0"
            max="100"
            step="1"
          ></b-form-input>
          <div class="space"></div>
          <div>
            <label
            :class="{ labelOff: editaItem.propsData.border.border == false }"
            class="subtitulo"
            >Estilo da borda</label>
            <multiselect
            
              :options="[
                'Sólido',
                'Tracejada',
                'Pontilhada',
                'Dupla',
              ]"
              :allow-empty="false"
              :searchable="false"
              :show-labels="false"
              v-model="editaItem.propsData.border.borderStyle"
              
              :disabled="editaItem.propsData.border.border == false"
            ></multiselect>
              <div class="space"></div>
              <div class="label-range">
            <label
              :class="{
                labelOff: editaItem.propsData.border.border == false,
              }"
              class="subtitulo"
              >Espessura</label
            >
            <input
              type="number"
              v-model="editaItem.propsData.border.borderSize"
              :disabled="editaItem.propsData.border.border == false"
            />
          </div>
          <b-form-input
            :disabled="editaItem.propsData.border.border == false"
            id="range-1"
            v-model="editaItem.propsData.border.borderSize"
            type="range"
            min="0"
            max="10"
            step="1"
          ></b-form-input>
            
          <div class="space"></div>
          <label
            :class="{ labelOff: editaItem.propsData.border.border == false }"
            class="subtitulo"
          >Cor da borda</label>
          <LvColorpicker
            id="LvColorpicker"
            :disabled="editaItem.propsData.border.border == false"
            :class="{ colorDisabled: editaItem.propsData.border.border== false, }"
            
            v-model="editaItem.propsData.border.borderColor"
            :colors="palette"
          ></LvColorpicker>
          </div>
        </div>
        </b-tab>

        <b-tab title="Avançado">
          <advancedBasic
            :spacing="editaItem.propsData.spacing" 
            :visibility="editaItem.propsData.visibility" 
            :animation="editaItem.propsColumn" 
            :attributes="editaItem.propsData.custom" 
            :typeView="typeView"
            :global="global"
            @toggleGlobal="global = !global"
            @nextIcon="nextIcon"
            @update:spacing="editaItem.propsData.spacing = $event"
            @update:visibility="editaItem.propsData.visibility = $event"
            @update:animation="editaItem.propsColumn = $event"
            @update:attributes="editaItem.propsData.custom = $event"
          ></advancedBasic>
        </b-tab>
      </b-tabs>
    </b-card>
    <Upload @setImg="setImg" />
    <dropdownSlider
      ref="dropdownSlider"   
      @updateValue="updateValue"
    /> 
  </div>
</template>
  
<script>
import dropdownSliderMixin from "@/mixins/dropdownSliderMixin.js";
import advancedBasic from "../EditComponents/advancedBasic.vue";
import Upload from "../../Upload.vue";
import LvColorpicker from "lightvue/color-picker";
import Multiselect from "vue-multiselect";
  
export default {
  mixins: [dropdownSliderMixin],
  props: ["editaItem", "typeView", "palette"],
  components: {
    advancedBasic,
    Upload,
    LvColorpicker: LvColorpicker,
    Multiselect,
  },
  data() {
    return {
      global: false,
      img_carousel_index: null,
      proportion:"Colagem",
      borderLink: true,
    };
  },
  methods: {
    moveUp(idx) {
      var moving = this.editaItem.propsData.gallery[idx]
      this.editaItem.propsData.gallery.splice(idx, 1);
      this.editaItem.propsData.gallery.splice(idx - 1, 0, moving);
    },
    moveDown(idx) {
      var moving = this.editaItem.propsData.gallery[idx]
      this.editaItem.propsData.gallery.splice(idx, 1);
      this.editaItem.propsData.gallery.splice(idx + 1, 0, moving);
    },
    nextIcon(type) {
      this.$emit("attIconView", type);
    },     
    addImg() {
      const idx = this.editaItem.propsData.gallery.length > 0 ? this.editaItem.propsData.gallery[this.editaItem.propsData.gallery.length - 1].id + 1 : 1;
      this.editaItem.propsData.gallery.push({
      imagem:
        "https://gdigital.s3.amazonaws.com/gdigital/1/imagem-vazia3%20%281%29.webp",
      ratio: 1620 / 911,
    },);
    },

    removeImgIndex(index) {
      this.editaItem.propsData.gallery.splice(index, 1);
    },
    removeImg() {
      this.editaItem.propsData.gallery.pop();
    },
    openModal(data, index) {
      this.img_carousel_index = index;
      this.$bvModal.show(data);
    },
    setImg(img) {
      this.editaItem.propsData.gallery[this.img_carousel_index].imagem = img;
      const newImg = new Image();
      newImg.onload = () => {
        this.editaItem.propsData.gallery[this.img_carousel_index].ratio = newImg.width / newImg.height
      };
      newImg.src = img;
    },
    globalize(place) {
      if (this.global && this.typeView == 'desktop') {
        this.editaItem.propsData.styles[place].tablet = this.editaItem.propsData.styles[place].desktop
        this.editaItem.propsData.styles[place].mobile = this.editaItem.propsData.styles[place].desktop
      }
    }
  },
  created() {
    if (this.typeView == "desktop") {
      this.global = true;
    }
  },
};
</script>