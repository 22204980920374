<template>
  <div>
    <!-- {{ page }} -->
    <div class="content" style="padding-top:15px">
      <div style="display:flex; justify-content: space-between;">
        <label class="titulo">
          Paleta de cores
          <img src="@/assets/editor/swatches.svg" alt="paleta de cores" />
        </label>
        <div class="trash" :class="trash ? 'trash-on' : 'trash-off'" @click="trash = !trash">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none !important"
            stroke="inherit" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
            class="feather feather-trash-2">
            <polyline points="3 6 5 6 21 6"></polyline>
            <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
            <line x1="10" y1="11" x2="10" y2="17"></line>
            <line x1="14" y1="11" x2="14" y2="17"></line>
          </svg>
        </div>
      </div>
      <div class="container-colors">
        <div class="add-color" @click="trash ? '' : addColor()" v-if="colors.length < 24"
          :style="trash ? 'filter: saturate(0);' : ''">
          <div class="add-symbol">
            +
          </div>
        </div>
        <div v-for="(color, idx) in colors" :key="idx" class="color" :style="`background-color: ${color};`"
          v-b-tooltip.hover :title="trash ? '' : ''" :class="{ 'color-trash': trash }"
          @click="trash ? deleteColor(idx) : ''">
          <input v-show="!trash" type="color" v-model="colors[idx]" @input="debounceSetPalette">
        </div>
      </div>
    </div>
    <div class="separador-left"></div>
    <div class="content">
      <label class="titulo">
        Outras cores
        <img src="@/assets/editor/paint-brush.svg" alt="paleta de cores" />
      </label>
      <div class="container-colors">
        <div v-for="(color, idx) in default_colors" :key="idx" class="color default-color" :style="`background-color: ${color};`" @click="addDeafault(color)">
        </div>
      </div>
    </div>
    <div class="separador-left"></div>
    <!-- <div class="content">{{ colors }}</div>
    <div class="separador-left"></div> -->

  </div>
</template>

<script>
import LvColorpicker from "lightvue/color-picker";
import _ from "lodash";

export default {
  props: ["palette"],
  components: {
    LvColorPicker: LvColorpicker,
  },
  data() {
    return {
      colors: '',
      trash: false,
      default_colors: [
        '#FF6666',
        '#FF9933',
        '#FFEE67',
        '#8CcF60',
        '#a0cCf4',
        '#cC87f0',
        '#FF1E33',
        '#FF6600',
        '#FFC107',
        '#2CcF50',
        '#00BCD4',
        '#9C27B0',
        '#660011',
        '#AA3200',
        '#AA7107',
        '#0C5F10',
        '#004C80',
        '#5C1770',
        '#FFFFFF',
        '#DDDDDD',
        '#AAAAAA',
        '#666666',
        '#222222',
        '#000000',
      ],
      // default_colors2: [
      //   '#E91E63',
      //   '#F44336',
      //   '#FF6600',
      //   '#FFC107',
      //   '#8BC34A',
      //   '#4CAF50',
      //   '#9C27B0',
      //   '#673AB7',
      //   '#3F51B5',
      //   '#2196F3',
      //   '#00BCD4',
      //   '#009688',
      //   '#000000',
      //   '#222222',
      //   '#666666',
      //   '#AAAAAA',
      //   '#DDDDDD',
      //   '#FFFFFF',
      // ],
    };
  },
  created() {
    this.colors = JSON.parse(JSON.stringify(this.palette))
  },
  watch: {
    // bugas
    // colors: {
    //   handler: function(newValue) {
    //     this.debounceSetPalette();
    //   },
    //   immediate: false,
    //   deep: true, 
    // },
    palette: {
      handler: function (newValue) {
        this.colors = JSON.parse(JSON.stringify(this.palette))
      },
      immediate: false,
      deep: true,
    },
  },
  methods: {
    deleteColor(idx) {
      this.colors.splice(idx, 1)
      this.$emit("updatePalette", this.colors);
    },
    addColor() {
      this.colors.unshift('#DDDDDD')
      this.$emit("updatePalette", this.colors);
    },
    debounceSetPalette: _.debounce(function () {
      this.$emit("updatePalette", this.colors);
    }, 500),
    addDeafault(color) {
      this.colors.unshift(color)
      this.$emit("updatePalette", this.colors);
    },
  },
};
</script>

<style lang="scss">
.lv-color .lv-overlaypanel {
  left: 120px !important;
}

.lv-color {
  .lv-overlaypanel {
    left: 120px !important;
  }
}
</style>

<style lang="scss" scoped>
.trash {
  border-radius: 5px;
  fill: none;
  cursor: pointer;
  padding: 3px 4px 5px 4px;
  margin-bottom: 5px;
  transition: all 0.3s;
}

.trash-off {
  background-color: #ededf0;
  stroke: #c50000;

  &:hover {
    background-color: #cbcbce;
  }
}

.trash-on {
  background-color: #c50000;
  stroke: #ededf0;

  &:hover {
    background-color: #8b0505;
  }
}

.container-colors {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(6, 1fr);
  gap: 6px;
}

.add-color {
  background-image: conic-gradient(#FF0000, #FF7F00, #FFFF00, #00FF00, #00f7ff, #0000FF, #4B0082, #ff0077);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 100%;
  aspect-ratio: 1 / 1;
  padding: 1px;
  border-radius: 4px;
  transition: all 0.3s;
}

.add-symbol {
  width: 60%;
  height: 60%;
  border-radius: 100px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  font-weight: 600;
  color: black;
  user-select: none;
}

.color {
  cursor: pointer;
  width: 100%;
  aspect-ratio: 1 / 1;
  padding: 1px;
  border-radius: 4px;
  background-size: 22px;
  background-position: center;
  background-repeat: no-repeat;
  box-shadow: 2px 2px #0001;
  // transition: all 0.2s;

  input {
    cursor: pointer;
    margin: 0 !important;
    padding: 0 !important;
    width: 100% !important;
    height: 100% !important;
    max-height: 100% !important;
    border-radius: 0 !important;
    box-shadow: none !important;
    opacity: 0;
  }

  &:hover {
    background-image: url('../../../../assets/editor/sliders-horizontal.svg');
    box-shadow: inset 0 0 0 1.5px #fff;
    border: 1px solid #aaaaaa;
  }
}

.default-color:hover {
  background-image: url('../../../../assets/editor/plus-white.svg') !important;
}

.color-trash:hover {
  background-image: url('../../../../assets/editor/remove-icon.svg') !important;
  background-position-y: 66%;
}

.titulo {
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 16px !important;
  line-height: 20px;
  color: var(--gray01);
  display: flex !important;
  align-items: center;
  gap: 10px;
  // margin: 0 0 1em 0 !important;
  padding: 0 !important;
  transition: all 0.3s;

  img {
    height: 24px;
  }
}

.subtitulo,
.sub {
  display: flex !important;
  gap: 5px;
  font-family: "Montserrat";
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 17px;
  color: var(--gray01);
  // background: var(--greenn);
  margin: 0 0 0.5em 0 !important;
  padding: 0 !important;
  transition: all 0.3s;
}

.content {
  padding: 30px 20px;
}

.separador-left {
  width: calc(100% + 30px);
  height: 1px;
  margin-left: -15px;
  background-color: #ededf0;
}
</style>
