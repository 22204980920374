import dropdownSlider from "@/components/Editor/Components/EditComponents/dropdownSlider.vue";
export default {
  data() {
    return {
      lastPart: '',
      target: null,
      isResponsive: false,
      tabletTarget: '',
      desktopTarget: '',
      mobileTarget: '',
      linkPath: '',
      linkPathValue: false
    };
  },
  components: {
    dropdownSlider,
  },
  methods: {
    openDropdownSlider(stringPath, linkPath) {
      this.linkPath = linkPath || ''
      this.linkPathValue = this.validLinkByPath(this.linkPath)
      stringPath = this.validTypeView(stringPath)
      const parts = stringPath.split(/[\.\[\]\'\"]/).filter(Boolean);
      this.lastPart = parts.pop();
      this.target = parts.reduce((acc, part) => acc && acc[part], this);
      if (this.target && this.lastPart) {
        this.$refs.dropdownSlider.openRangeLocal();
      }
    },
    validTypeView(stringPath) {
      if (!stringPath.includes('typeView')) {
        this.isResponsive = false
        return stringPath
      } 
      this.tabletTarget = this.formatTarget(stringPath, 'mobile')
      this.desktopTarget = this.formatTarget(stringPath, 'desktop')
      this.mobileTarget = this.formatTarget(stringPath, 'tablet')
      this.isResponsive = true
      return stringPath.replace(/\[typeView\]/g, `.${this.typeView}`);
    },
    formatTarget(path, newTypeView) {
      path = path.replace(/\[typeView\]/g, `.${newTypeView}`)
      const parts = path.split(/[\.\[\]\'\"]/).filter(Boolean);
      parts.pop();
      return parts.reduce((acc, part) => acc && acc[part], this);
    },
    setNewValue(value) {
      if (this.global && this.isResponsive) {
        this.$set(this.mobileTarget, this.lastPart, value);
        this.$set(this.tabletTarget, this.lastPart, value);
        this.$set(this.desktopTarget, this.lastPart, value);
        return
      }
      this.$set(this.target, this.lastPart, value);
    },
    setNewValueLinkeds(value, valuePath) {
      let formatedValuePath = valuePath.replace(/\[typeView\]/g, `.${this.typeView}`);
      let parts = formatedValuePath.split(/[\.\[\]\'\"]/).filter(Boolean);
      let lastPart = parts.pop();
      if (!this.isResponsive || !this.global) {
        let target = parts.reduce((acc, part) => acc && acc[part], this);
        this.$set(target, lastPart, value);
        return
      }
      let tabletTarget = this.formatTarget(valuePath, 'mobile')
      let desktopTarget = this.formatTarget(valuePath, 'desktop')
      let mobileTarget = this.formatTarget(valuePath, 'tablet') 
      this.$set(mobileTarget, lastPart, value);
      this.$set(tabletTarget, lastPart, value);
      this.$set(desktopTarget, lastPart, value);
    },
    updateValue(value) {
      if (this.linkPathValue && this.varLinks[this.linkPath]) {  
        for (let i = 0; i < this.varLinks[this.linkPath].length; i++) {
          const element = this.varLinks[this.linkPath][i];
          this.setNewValueLinkeds(value, element)
        }
        return
      }
      this.setNewValue(value)
    },
    validLinkByPath(path) {
      if (!path) {
        return false
      }
      const parts = path.split(/[\.\[\]\'\"]/).filter(Boolean);
      return parts.reduce((acc, part) => acc && acc[part], this);
    },
  }, 
};