let PropsTextDynamic = {
  custom: {
    c_class: '',
    c_id: '',
  },
  text: [
  "Quero Aproveitar",
  "Quero Aproveitar",
  "Quero Aproveitar",
  ],

  textloop:{
    loop:"Ficar Rodando",
    typingSpeed:200,
    resultloop:true
  },

  styles: {
    font_family: "Montserrat",
    background_color_text: "#FFF0",
    color_text: "#000000",
    transform: 'normal',
    font_weight: 400,
    letter_space: 1,
    line_height: 1.2,
    hover_bar: "#000000",
    max_width: {
      desktop: 1500,
      tablet: 1000,
      mobile: 500,
    },
    justify: {
      desktop: 'center',
      tablet: 'center',
      mobile: 'center',
    },
    justify_vertical: {
      desktop: 'center',
      tablet: 'center',
      mobile: 'center',
    },
    shadow: {
      shadow: "none",
      hasShadow: false,
      shadow_x: 0,
      shadow_y: 5,
      shadow_blur: 6,
      shadow_color: "#0000005E",
    },
    desktop: {
      align: 'left',
      size: 20,
      font_measure: 'px',
    },
    tablet: {
      align: 'left',
      size: 20,
      font_measure: 'px',
    },
    mobile: {
      align: 'left',
      size: 20,
      font_measure: 'px',
    },
  },
  spacing: {
    desktop: {
      margin_top: 0,
      margin_right: 0,
      margin_bottom: 0,
      margin_left: 0,
      padding_top: 0,
      padding_right: 0,
      padding_bottom: 0,
      padding_left: 0,
    },
    tablet: {
      margin_top: 0,
      margin_right: 0,
      margin_bottom: 0,
      margin_left: 0,
      padding_top: 0,
      padding_right: 0,
      padding_bottom: 0,
      padding_left: 0,
    },
    mobile: {
      margin_top: 0,
      margin_right: 0,
      margin_bottom: 0,
      margin_left: 0,
      padding_top: 0,
      padding_right: 0,
      padding_bottom: 0,
      padding_left: 0,
    },
  },
  visibility: {
    monitor: true,
    tablet: true,
    phone: true,
  },
};
export default PropsTextDynamic;
