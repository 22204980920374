<template>
  <div id="SideRightTabs">
    <b-card no-body>
      <b-tabs pills justified small style="width: 100%">
        <b-tab title="Geral">
          <div>
            <!-- Seleção da imagem -->
            <div class="separador"></div>
            <div class="content">
              <label class="titulo">Imagem</label>
              <div class="select-image">
                <div @click="openModal('Upload-Editor')">
                  <img
                    :src="
                      editaItem.propsData.image.src
                        ? editaItem.propsData.image.src
                        : 'https://gdigital.s3.amazonaws.com/gdigital/8/imagem-vazia%20%28200%C2%A0%C3%97%C2%A0200%C2%A0px%29.webp'
                    "
                    alt="Imagem preview"
                  />
                </div>
                <div>
                  <input
                    @input="obterProporcoesOriginais()"
                    type="text"
                    v-model="editaItem.propsData.image.src"
                  />
                  <span @click="openModal('Upload-Editor')">{{
                    editaItem.propsData.image.src
                      ? "Alterar imagem"
                      : "Selecionar imagem"
                  }}</span>
                </div>
              </div>
              <div class="space"></div>
              <div v-if="editaItem.propsData.hasOwnProperty('redirectImage')">
              <label class="subtitulo">Link de redirecionamento</label>
                <input type="text" v-model="editaItem.propsData.redirectImage.link" placeholder="https://seulink" @change="checkLink"/>
                <div style="display:flex;align-items: center;justify-content: left ;transition: all 0.3s">
                  <b-form-checkbox
                    style="margin-top: -5px; margin-left: 0; margin-right: 0"
                    v-model="editaItem.propsData.redirectImage.isBlank"
                    size="lg"
                    switch
                    >
                  </b-form-checkbox>
                  <label class="subtitulo" style="margin:0 !important">Abrir em nova guia</label>
                </div>
              </div>
            </div>

            <!-- Dimensões -->
            <div class="separador"></div>
            <div class="content">
              <div class="label-icon">
                <label class="titulo">Dimensões</label>
                <img
                  src="@/assets/editor/globe.svg"
                  alt="monitor"
                  style="filter: invert(50%) !important"
                  @click="nextIcon('desktop'), (global = !global)"
                  v-if="global"
                />
                <img
                  src="@/assets/editor/monitor.svg"
                  alt="monitor"
                  style="filter: invert(50%) !important"
                  @click="nextIcon('tablet')"
                  v-if="typeView === 'desktop' && !global"
                />
                <img
                  src="@/assets/editor/tablet.svg"
                  alt="tablet"
                  style="filter: invert(50%) !important"
                  @click="nextIcon('mobile')"
                  v-if="typeView === 'tablet' && !global"
                />
                <img
                  src="@/assets/editor/phone.svg"
                  alt="phone"
                  style="filter: invert(50%) !important"
                  @click="nextIcon('desktop'), (global = !global)"
                  v-if="typeView === 'mobile' && !global"
                />
              </div>

              <div
                v-if="
                  editaItem.propsData.alignment[typeView].proportion !=
                    'Respeitar coluna' &&
                  editaItem.propsData.alignment[typeView].proportion != 'Nativo'
                "
              >
                <label class="subtitulo">Conter ou cobrir</label>
                <multiselect
                  label="text"
                  :options="fitOptions"
                  :allow-empty="false"
                  :hide-selected="true"
                  :searchable="false"
                  :show-labels="false"
                  v-model="editaItem.propsData.alignment[typeView].fit"
                ></multiselect>
                <div class="space"></div>
              </div>

              <label class="subtitulo"
                >Proporções
                <img
                  width="17px"
                  height="17px"
                  src="@/assets/editor/help-circle.svg"
                  alt="icon"
                  v-b-tooltip.hover
                  title='É necessário que as proporções sejam configuradas para "Personalizado" para usar as unidades %, vh e vw'
              /></label>
              <multiselect
                :options="[
                  'Respeitar coluna',
                  'Nativo',
                  'Personalizado',
                  'Círculo',
                  'Quadrado',
                  'Paisagem',
                  'Retrato',
                  'Panorama',
                ]"
                v-model="editaItem.propsData.alignment[typeView].proportion"
                :hide-selected="true"
                :searchable="false"
                :allow-empty="false"
                :show-labels="false"
                placeholder=" "
                @input="proportions('altura')"
              ></multiselect>
              <div class="space"></div>

              <!-- Altura e largura -->
              <div
                class="half"
                v-if="
                  editaItem.propsData.alignment[typeView].proportion !=
                  'Respeitar coluna'
                "
              >
                <div>
                  <label class="subtitulo">Altura</label>
                  <section id="Altura mínima" class="und_form">
                    <section class="und_form">
                      <input
                        type="number"
                        v-model="editaItem.propsData.size[typeView].height"
                        @focus="openDropdownSlider('editaItem.propsData.size[typeView].height')"                       
                      />
                      <span
                        @click="next_und('und_height')"
                        class="und text validClick"
                        v-b-tooltip.hover
                        :title="`Altura em ${undName[0]}`"
                      >
                        {{
                          editaItem.propsData.size[typeView].und_height
                        }}</span
                      >
                    </section>
                  </section>
                </div>

                <div>
                  <label class="subtitulo">Largura</label>
                  <section id="Altura mínima" class="und_form">
                    <section class="und_form">
                      <input
                        type="number"
                        v-model="editaItem.propsData.size[typeView].width"
                        @focus="openDropdownSlider('editaItem.propsData.size[typeView].width')"  
                      />
                      <span
                        @click="next_und('und_width')"
                        class="und text validClick"
                        v-b-tooltip.hover
                        :title="`Largura em ${undName[1]}`"
                      >
                        {{ editaItem.propsData.size[typeView].und_width }}</span
                      >
                    </section>
                  </section>
                </div>
              </div>
              <div>
                <label class="subtitulo">Posicionamento</label>
                <div style="display: flex; width: 100%; gap: 10px">
                  <div style="display: flex; width: 50%; transition: all 0.3s">
                    <div class="select-align">
                      <button
                        :class="{
                          'selected-align':
                            editaItem.propsData.alignment[typeView].justify ==
                            'flex-start',
                        }"
                        @click="globalJustify('flex-start', 'justify')"
                      >
                        <img src="@/assets/editor/align-left-new.svg" />
                      </button>
                      <button
                        :class="{
                          'selected-align':
                            editaItem.propsData.alignment[typeView].justify ==
                            'center',
                        }"
                        @click="globalJustify('center', 'justify')"
                      >
                        <img
                          src="@/assets/editor/align-center-horizontal.svg"
                        />
                      </button>
                      <button
                        :class="{
                          'selected-align':
                            editaItem.propsData.alignment[typeView].justify ==
                            'flex-end',
                        }"
                        style="z-index: 2"
                        @click="globalJustify('flex-end', 'justify')"
                      >
                        <img src="@/assets/editor/align-right-new.svg" />
                      </button>
                    </div>
                  </div>

                  <div style="display: flex; width: 50%; transition: all 0.3s">
                    <div class="select-align">
                      <button
                        :class="{
                          'selected-align':
                            editaItem.propsData.alignment[typeView]
                              .justify_vertical == 'start',
                        }"
                        @click="globalJustify('start', 'justify_vertical')"
                      >
                        <img src="@/assets/editor/align-top.svg" />
                      </button>
                      <button
                        :class="{
                          'selected-align':
                            editaItem.propsData.alignment[typeView]
                              .justify_vertical == 'center',
                        }"
                        @click="globalJustify('center', 'justify_vertical')"
                      >
                        <img src="@/assets/editor/align-center-vertical.svg" />
                      </button>
                      <button
                        :class="{
                          'selected-align':
                            editaItem.propsData.alignment[typeView]
                              .justify_vertical == 'end',
                        }"
                        @click="globalJustify('end', 'justify_vertical')"
                      >
                        <img src="@/assets/editor/align-bottom.svg" />
                      </button>
                    </div>
                  </div>
                </div>
                <div>
                  <div class="space"></div>
                </div>
              </div>
            </div>
            <div class="separador"></div>
          </div>
        </b-tab>

        <b-tab title="Estilo">
          <div class="separador"></div>
          <!-- Sombra -->
          <div class="content">
            <div style="display: flex; width: 100%; transition: all 0.3s">
              <label class="titulo">Sombra</label>
              <b-form-checkbox
                style="margin-top: -5px; margin-left: auto; margin-right: 0"
                v-model="editaItem.propsData.shadow.hasShadow"
                size="lg"
                switch
              >
              </b-form-checkbox>
            </div>
            <label
              :class="{
                labelOff: editaItem.propsData.shadow.hasShadow == false,
              }"
              class="subtitulo"
              >Cor da sombra</label
            >
            <LvColorpicker
              id="LvColorpicker"
              :class="{
                colorDisabled: editaItem.propsData.shadow.hasShadow == false,
              }"
              :disabled="editaItem.propsData.shadow.hasShadow == false"
              v-model="editaItem.propsData.shadow.shadowColor"
              :colors="palette"
            ></LvColorpicker>
            <div class="space"></div>
            <div class="three">
              <div>
                <label
                  :class="{
                    labelOff: editaItem.propsData.shadow.hasShadow == false,
                  }"
                  class="subtitulo"
                  >Eixo X</label
                >
                <input
                  :disabled="editaItem.propsData.shadow.hasShadow == false"
                  type="number"
                  v-model="editaItem.propsData.shadow.shadowX"
                  @focus="openDropdownSlider('editaItem.propsData.shadow.shadowX')"
                />
              </div>
              <div>
                <label
                  :class="{
                    labelOff: editaItem.propsData.shadow.hasShadow == false,
                  }"
                  class="subtitulo"
                  >Eixo Y</label
                >
                <input
                  :disabled="editaItem.propsData.shadow.hasShadow == false"
                  type="number"
                  v-model="editaItem.propsData.shadow.shadowY"
                  @focus="openDropdownSlider('editaItem.propsData.shadow.shadowY')"
                />
              </div>
              <div>
                <label
                  :class="{
                    labelOff: editaItem.propsData.shadow.hasShadow == false,
                  }"
                  class="subtitulo"
                  >Borrão</label
                >
                <input
                  type="number"
                  :disabled="editaItem.propsData.shadow.hasShadow == false"
                  v-model="editaItem.propsData.shadow.shadowBlur"
                  @focus="openDropdownSlider('editaItem.propsData.shadow.shadowBlur')"
                />
              </div>
            </div>
          </div>
          <div class="separador"></div>

          <!-- Bordas -->
          <div class="content">
            <label
              class="titulo"
              v-if="
                editaItem.propsData.alignment[typeView].proportion != 'Círculo'
              "
              >Bordas</label
            >

            <!-- Arredondar borda -->
            <div
              v-if="
                editaItem.propsData.alignment[typeView].proportion != 'Círculo'
              "
            >
              <!-- Arredondar bordas -->
              <div class="label-range">
                <label class="subtitulo">Arredondar bordas </label>
                <img
                  :class="{ borderLinkActive: borderLink === true }"
                  style="
                    height: 18px;
                    transition: all 0.3s;
                    filter: invert(50%);
                    margin-right: 10px;
                  "
                  src="@/assets/editor/link-2.svg"
                  alt="linkar"
                  @click="borderLink = !borderLink"
                />
              </div>
              <div class="inputs-group">
                <input
                  type="number"
                  v-model="editaItem.propsData.border.border_radius_top"
                  @focus="openDropdownSlider('editaItem.propsData.border.border_radius_top', 'borderLink')"
                />
                <input
                  type="number"
                  v-model="editaItem.propsData.border.border_radius_right"
                  @focus="openDropdownSlider('editaItem.propsData.border.border_radius_right', 'borderLink')"
                />
                <input
                  type="number"
                  v-model="editaItem.propsData.border.border_radius_bottom"
                  @focus="openDropdownSlider('editaItem.propsData.border.border_radius_bottom', 'borderLink')"
                />
                <input
                  type="number"
                  v-model="editaItem.propsData.border.border_radius_left"
                   @focus="openDropdownSlider('editaItem.propsData.border.border_radius_left', 'borderLink')"
                />
              </div>
              <div class="space"></div>
            </div>

            <div style="display: flex; width: 100%; transition: all 0.3s">
              <label class="titulo">Bordas visíveis</label>
              <b-form-checkbox
                style="margin-top: -5px; margin-left: auto; margin-right: 0"
                v-model="editaItem.propsData.border.hasBorder"
                name="is_main"
                size="lg"
                switch
              >
              </b-form-checkbox>
            </div>
            <label
              :class="{
                labelOff: editaItem.propsData.border.hasBorder == false,
              }"
              class="sub"
              >Cor da borda</label
            >
            <LvColorpicker
              :class="{
                colorDisabled: editaItem.propsData.border.hasBorder == false,
              }"
              :disabled="!editaItem.propsData.border.hasBorder"
              id="LvColorpicker"
              v-model="editaItem.propsData.border.border_color"
              :colors="palette"
            ></LvColorpicker>
            <div class="space"></div>
            <!-- Espessura e traço -->
            <div class="label-range">
              <label
                :class="{
                  labelOff: editaItem.propsData.border.hasBorder == false,
                }"
                class="subtitulo"
                >Espessura</label
              >
              <input
                type="number"
                v-model="editaItem.propsData.border.border_thickness"
                :disabled="!editaItem.propsData.border.hasBorder"
              />
            </div>
            <b-form-input
              :disabled="!editaItem.propsData.border.hasBorder"
              id="range-1"
              v-model="editaItem.propsData.border.border_thickness"
              type="range"
              min="0"
              max="10"
              step="1"
            ></b-form-input>
            <div class="space"></div>
            <!-- <label
              :class="{ labelOff: editaItem.propsData.border.hasBorder == false }"
              class="subtitulo"
              >Traço</label
            >
            <div class="custom-multi">
              <multiselect
                :disabled="!editaItem.propsData.border.hasBorder"
                v-model="editaItem.propsData.border.border_style"
                :options="[
                  '● ● ● ● ● ● ● ● ● ● ● ● ● ●',
                  '━━━━━━━━━━━━━━━━━━━━━',
                  '━ ━ ━ ━ ━ ━ ━ ━ ━ ━ ━ ━ ━ ━',
                  '══════════════════',
                ]"
                :searchable="false"
                :show-labels="false"
                placeholder="Traço"
              ></multiselect>
            </div> -->
          </div>
          <div class="separador"></div>
        </b-tab>

        <b-tab title="Avançado">
          <advancedBasic
            :spacing="editaItem.propsData.spacing" 
            :visibility="editaItem.propsData.visibility" 
            :animation="editaItem.propsColumn" 
            :attributes="editaItem.propsData.custom" 
            :typeView="typeView"
            :global="global"
            @toggleGlobal="global = !global"
            @nextIcon="nextIcon"
            @update:spacing="editaItem.propsData.spacing = $event"
            @update:visibility="editaItem.propsData.visibility = $event"
            @update:animation="editaItem.propsColumn = $event"
            @update:attributes="editaItem.propsData.custom = $event"
          ></advancedBasic>
        </b-tab>
      </b-tabs>
    </b-card>
    <Upload @setImg="setImg" />
    <dropdownSlider
      ref="dropdownSlider"   
      @updateValue="updateValue"
    /> 
  </div>
</template>

<script>
import dropdownSliderMixin from "@/mixins/dropdownSliderMixin.js";
import advancedBasic from "../EditComponents/advancedBasic.vue";
import Upload from "../../Upload.vue";
import LvInput from "lightvue/input";
import LvColorpicker from "lightvue/color-picker";
import Multiselect from "vue-multiselect";

export default {
  mixins: [dropdownSliderMixin],
  props: ["editaItem", "typeView", "palette"],
  components: {
    advancedBasic,
    Upload,
    LvInput,
    LvColorpicker: LvColorpicker,
    Multiselect,
  },
  computed: {
    undName() {
      return [this.returnUndName(this.editaItem.propsData.size[this.typeView].und_height), this.returnUndName(this.editaItem.propsData.size[this.typeView].und_width)]
    },
  },
  data() {
    return {
      fitOptions: [
        { value: "contain", text: "Conter" },
        { value: "fill", text: "Esticar" },
        { value: "cover", text: "Cobrir" },
      ],
      global: false,
      proportion: {
        desktop: "Nativo",
        tablet: "Nativo",
        mobile: "Nativo",
      },
      borderLink: true,
      proporcoes: "",
      varLinks: {
        borderLink: [
          'editaItem.propsData.border.border_radius_top',
          'editaItem.propsData.border.border_radius_right',
          'editaItem.propsData.border.border_radius_bottom',
          'editaItem.propsData.border.border_radius_left',
        ],
      }
    };
  },
  watch: {
    'editaItem.propsData.image.src'() {
      this.obterProporcoesOriginais();
    },
    'proporcoes'(){
      this.proportions("altura");
        this.newHeight(
          this.editaItem.propsData.alignment[this.typeView].percWidth
        );
    },
  },
  mounted() {
    this.obterProporcoesOriginais();
  },
  methods: {
    checkLink() {
      let link;
      if (this.editaItem.propsData.redirectImage && this.editaItem.propsData.redirectImage.link) {
        link = this.editaItem.propsData.redirectImage.link;

        const urlPattern = /^(https?:\/\/)?([\w.-]+)(\.[\w\.-]+)+([\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=]+)?$/;
        if (!link.match(urlPattern)) {
          this.$grToast.toast("URL de redirecionamento inválida", {
            title: "Editor",
            variant: "danger",
            autoHideDelay: 5000,
            appendToast: true,
          });
          this.$emit('disabledSave', true);
          return;
        }
        if(!link.includes('http')){
          this.$grToast.toast("URL precisa conter https://", {
            title: "Editor",
            variant: "danger",
            autoHideDelay: 5000,
            appendToast: true,
          });
          this.$emit('disabledSave', true);
          return;
        }
      }
      this.$emit('disabledSave', false);
    },
    returnUndName(und){
      switch (und) {
        case 'px':
          return 'pixel'
        case '%':
          return 'porcentagem'
        case 'vh':
          return 'viewer height'
        case 'vw':
          return 'viewer width'
        default:
          return 'pixel'
      }
    },   
    globalJustify(value, place) {
      let props = this.editaItem.propsData.alignment;
      props[this.typeView][place] = value;
      if (this.global && this.typeView == "desktop") {
        props[this.typeView][place] = value;
        props[this.typeView][place] = value;
      }
    },
    newHeight(value) {
      this.editaItem.propsData.alignment[this.typeView].setHeight =
        (value * this.proporcoes.altura) / this.proporcoes.largura;
      if (this.global && this.typeView == "desktop") {
        this.editaItem.propsData.alignment.tablet.percWidth = value;
        this.editaItem.propsData.alignment.mobile.percWidth = value;
        this.editaItem.propsData.alignment.tablet.setHeight =
          this.editaItem.propsData.alignment.desktop.setHeight;
        this.editaItem.propsData.alignment.mobile.setHeight =
          this.editaItem.propsData.alignment.desktop.setHeight;
      }
    },
    obterProporcoesOriginais() {
      const img = new Image();
      img.onload = () => {
        const proporcoes = {
          largura: img.width,
          altura: img.height,
        };
        this.proporcoes = proporcoes;
      };
      img.src = this.editaItem.propsData.image.src;
    },
    next_und(place) {
      if (
        this.editaItem.propsData.alignment[this.typeView].proportion ==
        "Personalizado"
      ) {
        var props = this.editaItem.propsData;
        if (props.size[this.typeView][place] == "px") {
          props.size[this.typeView][place] = "%";
          if (this.global && this.typeView == "desktop") {
            props.size.tablet[place] = "%";
            props.size.mobile[place] = "%";
          }
          return;
        }
        if (props.size[this.typeView][place] == "%") {
          props.size[this.typeView][place] = "vh";
          if (this.global && this.typeView == "desktop") {
            props.size.tablet[place] = "vh";
            props.size.mobile[place] = "vh";
          }
          return;
        }
        if (props.size[this.typeView][place] == "vh") {
          props.size[this.typeView][place] = "vw";
          if (this.global && this.typeView == "desktop") {
            props.size.tablet[place] = "vw";
            props.size.mobile[place] = "vw";
          }
          return;
        }
        if (props.size[this.typeView][place] == "vw") {
          props.size[this.typeView][place] = "px";
          if (this.global && this.typeView == "desktop") {
            props.size.tablet[place] = "px";
            props.size.mobile[place] = "px";
          }
          return;
        }
      }
    },
    nextIcon(type) {
      this.$emit("attIconView", type);
    },
    setImg(img) {
      this.editaItem.propsData.image.src = img;
    },
    openModal(data) {
      this.$bvModal.show(data);
    },
    proportions(item) {
      var proportion;
      proportion = this.editaItem.propsData.alignment[this.typeView].proportion;
      if (proportion == "Personalizado") {
        this.editaItem.propsData.size[this.typeView].height =
          Math.round(this.editaItem.propsData.size[this.typeView].height) + 1;
        this.editaItem.propsData.size[this.typeView].height = Math.round(
          this.editaItem.propsData.size[this.typeView].height - 1
        );
        this.globalize();
        return;
      }
      this.editaItem.propsData.size.desktop.und_height = "px";
      this.editaItem.propsData.size.tablet.und_height = "px";
      this.editaItem.propsData.size.mobile.und_height = "px";
      this.editaItem.propsData.size.desktop.und_width = "px";
      this.editaItem.propsData.size.tablet.und_width = "px";
      this.editaItem.propsData.size.mobile.und_width = "px";
      if (item == "largura") {
        if (proportion == "Quadrado" || proportion == "Círculo") {
          this.editaItem.propsData.size[this.typeView].height =
            Math.round(this.editaItem.propsData.size[this.typeView].width) + 1;
          this.editaItem.propsData.size[this.typeView].height =
            Math.round(this.editaItem.propsData.size[this.typeView].width) - 1;
          this.globalize();
          return;
        }
        if (proportion == "Nativo") {
          this.editaItem.propsData.size[this.typeView].height = Math.round(
            (this.editaItem.propsData.size[this.typeView].width /
              this.proporcoes.largura) *
              this.proporcoes.altura
          );
          this.globalize();
          return;
        }
        if (proportion == "Panorama") {
          this.editaItem.propsData.size[this.typeView].height = Math.round(
            this.editaItem.propsData.size[this.typeView].width / 2
          );
          this.globalize();
          return;
        }
        if (proportion == "Paisagem") {
          this.editaItem.propsData.size[this.typeView].height = Math.round(
            (this.editaItem.propsData.size[this.typeView].width / 1080) * 608
          );
          this.globalize();
          return;
        }
        if (proportion == "Retrato") {
          this.editaItem.propsData.size[this.typeView].height = Math.round(
            (this.editaItem.propsData.size[this.typeView].width / 4) * 5
          );
          this.globalize();
          return;
        }
        return;
      }
      if (item == "altura") {
        if (proportion == "Quadrado" || proportion == "Círculo") {
          this.editaItem.propsData.size[this.typeView].width =
            Math.round(this.editaItem.propsData.size[this.typeView].height) + 1;
          this.editaItem.propsData.size[this.typeView].width = Math.round(
            this.editaItem.propsData.size[this.typeView].height
          );
          this.globalize();
          return;
        }
        if (proportion == "Nativo") {
          this.editaItem.propsData.size[this.typeView].width = Math.round(
            (this.editaItem.propsData.size[this.typeView].height /
              this.proporcoes.altura) *
              this.proporcoes.largura
          );
          this.globalize();
          return;
        }
        if (proportion == "Panorama") {
          this.editaItem.propsData.size[this.typeView].width = Math.round(
            this.editaItem.propsData.size[this.typeView].height * 2
          );
          this.globalize();
          return;
        }
        if (proportion == "Paisagem") {
          this.editaItem.propsData.size[this.typeView].width = Math.round(
            (this.editaItem.propsData.size[this.typeView].height / 608) * 1080
          );
          this.globalize();
          return;
        }
        if (proportion == "Retrato") {
          this.editaItem.propsData.size[this.typeView].width = Math.round(
            (this.editaItem.propsData.size[this.typeView].height / 5) * 4
          );
          this.globalize();
          return;
        }
        return;
      }
    },
    globalize() {
      if (this.global && this.typeView == "desktop") {
        var standardWidth = this.editaItem.propsData.size.desktop.width;
        var standardHeight = this.editaItem.propsData.size.desktop.height;
        var standardUndWidth = this.editaItem.propsData.size.desktop.und_width;
        var standardUndHeight =
          this.editaItem.propsData.size.desktop.und_height;
        this.editaItem.propsData.size.tablet.width = standardWidth;
        this.editaItem.propsData.size.mobile.width = standardWidth;
        this.editaItem.propsData.size.tablet.height = standardHeight;
        this.editaItem.propsData.size.mobile.height = standardHeight;
        this.editaItem.propsData.size.tablet.und_height = standardUndHeight;
        this.editaItem.propsData.size.mobile.und_height = standardUndHeight;
        this.editaItem.propsData.size.tablet.und_width = standardUndWidth;
        this.editaItem.propsData.size.mobile.und_width = standardUndWidth;
      }
    },   
  },
  created() {
    if (this.typeView == "desktop") {
      this.global = true;
    }
    // Atualiza os componentes antigos
    if (!this.editaItem.propsData.hasOwnProperty('redirectImage')) {
      this.editaItem.propsData.redirectImage = {
        isBlank: false,
        link: ''
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.reload {
  height: 15px;
  margin-top: 2px;
}

.reloaded {
  animation: reloaded 1s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: rotate(0deg);
}
.redirectImage{
  display: flex;
  margin-top: 5px !important;
}

@keyframes reloaded {
  100% {
    transform: rotate(-360deg);
  }
}
</style>

<style lang="scss">
.custom-multi .multiselect__single {
  max-height: 20px !important;
  overflow: hidden !important;
}
</style>
