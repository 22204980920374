import axios from "axios";

var array = [];
function addGoogleFontsToHeader(fonts) {
  const head = document.getElementsByTagName("head")[0];
  for (var i = 0; i < 100; i++) {
    const link = document.createElement("link");
    link.rel = "stylesheet";
    link.href = `https://fonts.googleapis.com/css?family=${fonts.data.items[
      i
    ].family.replace(/ /g, "+")}`;
    head.appendChild(link);
    array.push(fonts.data.items[i]);
  }
}

export async function getGoogleFonts() {
  array = [];
  const response = await axios.get(
    "https://www.googleapis.com/webfonts/v1/webfonts?sort=popularity&key=AIzaSyDtxxykoRlhOMmBUlnE--Nkyy3AHlZUIYA"
  );
  addGoogleFontsToHeader(response);
  return array.map((item) => item.family);
}
