<template>
  <div>
    <div class="separador"></div>
    <spacingBasic v-if="!hideSpacing" 
      @update:spacing="spacing = $event"
      @toggleGlobal="toggleGlobal"
      @nextIcon="nextIcon"
      :spacing="spacing"
      :typeView="typeView"
      :global="global"
    ></spacingBasic>
    <div class="separador" v-if="!hideSpacing"></div>

    <!-- Visilibilidade -->
    <div class="content">
      <label class="titulo"> Visibilidade </label>

      <!-- Dispositivos grandes -->
      <div>
        <div style="display: flex; width: 100%; transition: all 0.3s">
          <label
            class="sub"
            style="font-size: 14px !important; display: flex !important"
          >
            Dispositivos grandesﾠ
            <img
              width="17px"
              height="17px"
              src="@/assets/editor/help-circle.svg"
              alt="icon"
              v-b-tooltip.hover
              title="Maiores que 768 pixels de largura, como laptops e computadores"
            />
          </label>
          <b-form-checkbox
            style="margin-top: -5px; margin-left: auto; margin-right: 0"
            v-model="visibility.monitor"
            name="is_main"
            size="lg"
            switch
            @change="$emit('fixWidth')"
          >
          </b-form-checkbox>
        </div>
      </div>
      <!-- Dispositivos médios -->
      <div>
        <div style="display: flex; width: 100%; transition: all 0.3s">
          <label
            class="sub"
            style="font-size: 14px !important; display: flex !important"
          >
            Dispositivos médiosﾠ
            <img
              width="17px"
              height="17px"
              src="@/assets/editor/help-circle.svg"
              alt="icon"
              v-b-tooltip.hover
              title="Entre 480 a 768 pixels de largura, como tablets"
            />
          </label>
          <b-form-checkbox
            style="margin-top: -5px; margin-left: auto; margin-right: 0"
            v-model="visibility.tablet"
            name="is_main"
            size="lg"
            switch
          >
          </b-form-checkbox>
        </div>
      </div>
      <!-- Dispositivos pequenos -->
      <div>
        <div style="display: flex; width: 100%; transition: all 0.3s">
          <label
            class="sub"
            style="font-size: 14px !important; display: flex !important"
          >
            Dispositivos pequenosﾠ
            <img
              width="17px"
              height="17px"
              src="@/assets/editor/help-circle.svg"
              alt="icon"
              v-b-tooltip.hover
              title="Menores que 480 pixels de largura, como celulares"
            />
          </label>
          <b-form-checkbox
            style="margin-top: -5px; margin-left: auto; margin-right: 0"
            v-model="visibility.phone"
            name="is_main"
            size="lg"
            switch
          >
          </b-form-checkbox>
        </div>
      </div>
    </div>
    <div class="separador"></div>

    <!-- Animação -->
    <div class="content columnAnimate" v-if="!hideAnimation">
      <label class="titulo">Animação</label>
      <div class="select-animation">
        <div class="animation-container">
          <div>
            <p>Nenhum</p>
            <div
              class="animation-item"
              :class="{
                'selected-animation':
                  animation.attribute_und == 'Nenhuma',
              }"
              @click="animationAttribute('Nenhuma', '')"
            >
              <div class="simulated-item simu1"></div>
            </div>
          </div>
          <div>
            <p>Zoom</p>
            <div
              class="animation-item"
              :class="{
                'selected-animation':
                  animation.attribute_und ==
                  'Gradual Increase',
              }"
              @click="animationAttribute('Gradual Increase', 'gradual-increase')"
            >
              <div class="simulated-item simu2">
                <img
                  src="@/assets/editor/square-logo.svg"
                  alt="setinha"
                />
              </div>
            </div>
          </div>
          <div>
            <p>Fade Up</p>
            <div
              class="animation-item"
              :class="{
                'selected-animation':
                  animation.attribute_und == 'Fade Up',
              }"
              @click="animationAttribute('Fade Up', 'top')"
            >
              <div class="simulated-item simu3">
                <img
                  src="@/assets/editor/arrow-fat-lines-down.svg"
                  alt="setinha"
                />
              </div>
            </div>
          </div>
          <div>
            <p>Fade Right</p>
            <div
              class="animation-item"
              :class="{
                'selected-animation':
                  animation.attribute_und == 'Fade Right',
              }"
              @click="animationAttribute('Fade Right', 'right')"
            >
              <div class="simulated-item simu4">
                <img
                  src="@/assets/editor/arrow-fat-lines-down.svg"
                  alt="setinha"
                />
              </div>
            </div>
          </div>
          <div>
            <p>Fade Down</p>
            <div
              class="animation-item"
              :class="{
                'selected-animation':
                  animation.attribute_und == 'Fade Down',
              }"
              @click="animationAttribute('Fade Down', 'bottom')"               
            >
              <div class="simulated-item simu5">
                <img
                  src="@/assets/editor/arrow-fat-lines-down.svg"
                  alt="setinha"
                />
              </div>
            </div>
          </div>
          <div>
            <p>Fade Left</p>
            <div
              class="animation-item"
              :class="{
                'selected-animation':
                  animation.attribute_und == 'Fade Left',
              }"
              @click="animationAttribute('Fade Left', 'left')"
            >
              <div class="simulated-item simu6">
                <img
                  src="@/assets/editor/arrow-fat-lines-down.svg"
                  alt="setinha"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="space"></div>
        <div class="half-animation">
          <div>
            <label
              class="subtitulo"
              :class="{
                labelOff:
                  animation.attribute_und == 'Nenhuma',
              }"
              >Atraso (seg)</label
            >
            <b-form-input
              v-model="animation.delay"
              @focus="openDropdownSlider('animation.delay')"
              :disabled="animation.attribute_und == 'Nenhuma'"
              type="number"
            ></b-form-input>
          </div>
          <div>
            <label
              class="subtitulo"
              :class="{
                labelOff:
                  animation.attribute_und == 'Nenhuma',
              }"
              >Duração (seg)</label
            >
            <b-form-input
              v-model="animation.duration"
              @focus="openDropdownSlider('animation.duration')"
              type="number"
              :disabled="animation.attribute_und == 'Nenhuma'"
            ></b-form-input>
          </div>
        </div>
      </div>
    </div>
    <div class="separador" v-if="!hideAnimation"></div>

    <!-- Classe e ID -->
    <div class="content" v-if="!hideAttributes">
      <label class="titulo">Classe e ID</label>
      <label class="sub"
        >IDﾠ<img
          width="17px"
          height="17px"
          src="@/assets/editor/help-circle.svg"
          alt="icon"
          v-b-tooltip.hover
          title="Cada ID pode ser aplicado a somente um elemento . Um elemento pode ter apenas um ID"
      /></label>
      <input
        v-model="attributes.c_id"
        class="w-100 mb-3"
        type="text"
        name="text_1"
        id="text_1"
        placeholder="exemplo: id_unico"
      />
      <label class="sub"
        >Classeﾠ<img
          width="17px"
          height="17px"
          src="@/assets/editor/help-circle.svg"
          alt="icon"
          v-b-tooltip.hover
          title="Uma classe pode ser aplicada a muitos elementos. Um elemento pode ter muitas classes."
      /></label>
      <input
        v-model="attributes.c_class"
        class="w-100 mb-3"
        type="text"
        name="text_1"
        id="text_1"
        placeholder="exemplo: classe_1 classe_2"
      />
    </div>
    <div class="separador" v-if="!hideAttributes"></div>
    <dropdownSlider
      ref="dropdownSlider"   
      @updateValue="updateValue"
    />  
  </div>
</template>

<script>

import dropdownSliderMixin from "@/mixins/dropdownSliderMixin.js";
import spacingBasic from './spacingBasic.vue'
export default {
  mixins: [dropdownSliderMixin],
  components: {
    spacingBasic,
  },
  props: {
    spacing: {
      type: Object,
    },
    visibility: {
      type: Object,
    },
    animation: {
      type: Object,
    },
    attributes: {
      type: Object,
    },
    global: {
      type: Boolean,
      default: ''
    },
    typeView: {
      type: String,
      default: ''
    },
    hideSpacing: {
      type: Boolean,
      default: false
    },
    hideAttributes: {
      type: Boolean,
      default: false
    },
    hideAnimation: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
    };
  },
  methods: {
    animationAttribute(und, animation){
      this.animation.attribute_und = und;
      this.animation.attribute_animation = animation;
    },
    nextIcon(type) {
      this.$emit("nextIcon", type);
    },
    toggleGlobal() {
      this.$emit("toggleGlobal");
    }
  },
};
</script>
