<template>
  <div id="SideRightTabs">
    <b-card no-body>
      <b-tabs pills justified small style="width: 100%">
        <b-tab title="Geral">
          <div>
            <!-- Seleção da imagem -->
            <div class="separador"></div>
            <div class="content">
              <label class="titulo">Endereço</label>
              <div class="select">
                <input type="text" placeholder="Seu endereço" v-model="editaItem.propsData.location.localStorage">
              </div>
            </div>

            <!-- Dimensões -->
            <div class="separador"></div>
            <div class="content">
              <div class="label-icon">
                <label class="titulo">Dimensões</label>
                <img src="@/assets/editor/globe.svg" alt="monitor" style="filter: invert(50%) !important"
                  @click="nextIcon('desktop'), (global = !global)" v-if="global" />
                <img src="@/assets/editor/monitor.svg" alt="monitor" style="filter: invert(50%) !important"
                  @click="nextIcon('tablet')" v-if="typeView === 'desktop' && !global" />
                <img src="@/assets/editor/tablet.svg" alt="tablet" style="filter: invert(50%) !important"
                  @click="nextIcon('mobile')" v-if="typeView === 'tablet' && !global" />
                <img src="@/assets/editor/phone.svg" alt="phone" style="filter: invert(50%) !important"
                  @click="nextIcon('desktop'), (global = !global)" v-if="typeView === 'mobile' && !global" />
              </div>


              <!-- Altura e largura -->
              <div class="half">
                <div style="max-width: 100% !important;width: 100% !important;">
                  <label class="subtitulo">Altura</label>
                  <section id="Altura mínima" class="und_form">
                    <section class="und_form">
                      <input 
                        max="1000"
                        min="50"
                        step="10"
                        type="number" 
                        v-model="editaItem.propsData.size[typeView].height" 
                        @focus="openDropdownSlider('editaItem.propsData.size[typeView].height')" 
                      />
                      <span @click="next_und('und_height')" class="und text validClick" v-b-tooltip.hover
                        title="Largura em pixel">
                        {{editaItem.propsData.size[typeView].und_height}}
                      </span>
                    </section>
                  </section>
                </div>
              </div>

            </div>
          </div>
        </b-tab>
        <b-tab title="Estilo">
          <div class="separador"></div>
          <!-- Bordas -->
          <div class="content">
            <label class="titulo" v-if="editaItem.propsData.alignment[typeView].proportion != 'Círculo'
              ">Bordas</label>

            <!-- Arredondar borda -->
            <div v-if="editaItem.propsData.alignment[typeView].proportion != 'Círculo'
              ">
              <!-- Arredondar bordas -->
              <div class="label-range">
                <label class="subtitulo">Arredondar bordas </label>
                <img :class="{ borderLinkActive: borderLink === true }" style="
                      height: 18px;
                      transition: all 0.3s;
                      filter: invert(50%);
                      margin-right: 10px;
                    " src="@/assets/editor/link-2.svg" alt="linkar" @click="borderLink = !borderLink" />
              </div>
              <div class="inputs-group">
                <input 
                  type="number" 
                  v-model="editaItem.propsData.border.border_radius_top"             
                  @focus="openDropdownSlider('editaItem.propsData.border.border_radius_top', 'borderLink')"
                />
                <input 
                  type="number"
                  v-model="editaItem.propsData.border.border_radius_right"
                  @focus="openDropdownSlider('editaItem.propsData.border.border_radius_right', 'borderLink')"
                />
                <input 
                  type="number" 
                  v-model="editaItem.propsData.border.border_radius_bottom"
                  @focus="openDropdownSlider('editaItem.propsData.border.border_radius_bottom', 'borderLink')" 
                />
                <input 
                  type="number" 
                  v-model="editaItem.propsData.border.border_radius_left"
                  @focus="openDropdownSlider('editaItem.propsData.border.border_radius_left', 'borderLink')"
                />
              </div>
              <div class="space"></div>
            </div>

            <div style="display: flex; width: 100%; transition: all 0.3s">
              <label class="titulo">Bordas visíveis</label>
              <b-form-checkbox style="margin-top: -5px; margin-left: auto; margin-right: 0"
                v-model="editaItem.propsData.border.hasBorder" name="is_main" size="lg" switch>
              </b-form-checkbox>
            </div>
            <label :class="{
              labelOff: editaItem.propsData.border.hasBorder == false,
            }" class="sub">Cor da borda</label>
            <LvColorpicker :class="{
              colorDisabled: editaItem.propsData.border.hasBorder == false,
            }" :disabled="!editaItem.propsData.border.hasBorder" id="LvColorpicker"
              v-model="editaItem.propsData.border.border_color" :colors="palette"></LvColorpicker>
            <div class="space"></div>
            <!-- Espessura e traço -->
            <div class="label-range">
              <label :class="{
                labelOff: editaItem.propsData.border.hasBorder == false,
              }" class="subtitulo">Espessura</label>
              <input type="number" v-model="editaItem.propsData.border.border_thickness"
                :disabled="!editaItem.propsData.border.hasBorder" />
            </div>
            <b-form-input :disabled="!editaItem.propsData.border.hasBorder" id="range-1"
              v-model="editaItem.propsData.border.border_thickness" type="range" min="0" max="100" step="1"></b-form-input>
            <div class="space"></div>
            <label
                :class="{ labelOff: editaItem.propsData.border.hasBorder == false }"
                class="subtitulo"
                >Traço</label
              >
              <div class="custom-multi">
                <multiselect
                  :disabled="!editaItem.propsData.border.hasBorder"
                  v-model="editaItem.propsData.border.border_style"
                  :options="[
                    '● ● ● ● ● ● ● ● ● ● ● ● ● ●',
                    '━━━━━━━━━━━━━━━━━━━━━',
                    '━ ━ ━ ━ ━ ━ ━ ━ ━ ━ ━ ━ ━ ━',
                    '══════════════════',
                  ]"
                  :searchable="false"
                  :show-labels="false"
                  placeholder="Traço"
                ></multiselect>
              </div>
          </div>
        
        </b-tab>
        <b-tab title="Avançado">
          <div class="separador"></div>
          <div class="content">
            <div class="label-icon">
              <label class="titulo">Alinhamento</label>
              <img src="@/assets/editor/globe.svg" alt="monitor" style="filter: invert(50%) !important"
                @click="nextIcon('desktop'), (global = !global)" v-if="global" />
              <img src="@/assets/editor/monitor.svg" alt="monitor" style="filter: invert(50%) !important"
                @click="nextIcon('tablet')" v-if="typeView === 'desktop' && !global" />
              <img src="@/assets/editor/tablet.svg" alt="tablet" style="filter: invert(50%) !important"
                @click="nextIcon('mobile')" v-if="typeView === 'tablet' && !global" />
              <img src="@/assets/editor/phone.svg" alt="phone" style="filter: invert(50%) !important"
                @click="nextIcon('desktop'), (global = !global)" v-if="typeView === 'mobile' && !global" />
            </div>
            <div style="display: flex; width: 50%; transition: all 0.3s">
              <div class="select-align">
                <button
                  :class="{
                    'selected-align':
                      editaItem.propsData.alignment[typeView].justify == 'flex-start',
                  }"
                  @click="globalize('align'), editaItem.propsData.alignment[typeView].justify = 'flex-start'"
                >
                  <img src="@/assets/editor/align-top.svg" />
                </button>
                <button
                  :class="{
                    'selected-align':
                      editaItem.propsData.alignment[typeView].justify == 'center',
                  }"
                  @click="globalize('align'), editaItem.propsData.alignment[typeView].justify = 'center'"
                >
                  <img src="@/assets/editor/align-center-vertical.svg" />
                </button>
                <button
                  :class="{
                    'selected-align':
                      editaItem.propsData.alignment[typeView].justify == 'flex-end',
                  }"
                  @click="globalize('align'), editaItem.propsData.alignment[typeView].justify = 'flex-end'"
                >
                  <img src="@/assets/editor/align-bottom.svg" />
                </button>
              </div>
            </div>
          </div>
          <div class="separador"></div>
          <advancedBasic  
            :visibility="editaItem.propsData.visibility" 
            :animation="editaItem.propsColumn" 
            :attributes="editaItem.propsData.custom" 
            :typeView="typeView"
            :global="global"
            :hideSpacing="true"
            @toggleGlobal="global = !global"
            @nextIcon="nextIcon"     
            @update:visibility="editaItem.propsData.visibility = $event"
            @update:animation="editaItem.propsColumn = $event"
            @update:attributes="editaItem.propsData.custom = $event"
          ></advancedBasic>          
        </b-tab>
      </b-tabs>
    </b-card>
    <dropdownSlider
      ref="dropdownSlider"   
      @updateValue="updateValue"
    />   
  </div>
</template>
  
<script>
import LvColorpicker from "lightvue/color-picker";
import Multiselect from "vue-multiselect";
import advancedBasic from "../EditComponents/advancedBasic.vue";
import dropdownSliderMixin from "@/mixins/dropdownSliderMixin.js";
export default {
  props: ["editaItem", "typeView", "palette"],
  mixins: [dropdownSliderMixin],
  components: { 
    advancedBasic,
    LvColorpicker: LvColorpicker,
    Multiselect,
  },
  data() {
    return {
      varLinks: {
        borderLink: [
          'editaItem.propsData.border.border_radius_top',
          'editaItem.propsData.border.border_radius_right',
          'editaItem.propsData.border.border_radius_bottom',
          'editaItem.propsData.border.border_radius_left',
        ],
      },
      fitOptions: [
        { value: "contain", text: "Conter" },
        { value: "fill", text: "Esticar" },
        { value: "cover", text: "Cobrir" },
      ],
      proportion: {
        desktop: "Nativo",
        tablet: "Nativo",
        mobile: "Nativo",
      },
      proporcoes: "",
    };
  },
  methods: {   
    next_und(place) {
      var props = this.editaItem.propsData;
      if (props.size[this.typeView][place] == "px") {
        props.size[this.typeView][place] = "%";
        if (this.global && this.typeView == "desktop") {
          props.size.tablet[place] = "%";
          props.size.mobile[place] = "%";
        }
        return;
      }
      if (props.size[this.typeView][place] == "%") {
        props.size[this.typeView][place] = "vh";
        if (this.global && this.typeView == "desktop") {
          props.size.tablet[place] = "vh";
          props.size.mobile[place] = "vh";
        }
        return;
      }
      if (props.size[this.typeView][place] == "vh") {
        props.size[this.typeView][place] = "vw";
        if (this.global && this.typeView == "desktop") {
          props.size.tablet[place] = "vw";
          props.size.mobile[place] = "vw";
        }
        return;
      }
      if (props.size[this.typeView][place] == "vw") {
        props.size[this.typeView][place] = "px";
        if (this.global && this.typeView == "desktop") {
          props.size.tablet[place] = "px";
          props.size.mobile[place] = "px";
        }
        return;
      }
    },
    nextIcon(type) {
      this.$emit("attIconView", type);
    },
    proportions(item) {
      var proportion;
      proportion = this.editaItem.propsData.alignment[this.typeView].proportion;
      
      if (item == "altura") {
        if (proportion == "Quadrado" || proportion == "Círculo") {
          this.editaItem.propsData.size[this.typeView].width =
            Math.round(this.editaItem.propsData.size[this.typeView].height) + 1;
          this.editaItem.propsData.size[this.typeView].width = Math.round(
            this.editaItem.propsData.size[this.typeView].height
          );
          this.globalize();
          return;
        }
        if (proportion == "Nativo") {
          this.editaItem.propsData.size[this.typeView].width = Math.round(
            (this.editaItem.propsData.size[this.typeView].height /
              this.proporcoes.altura) *
            this.proporcoes.largura
          );
          this.globalize();
          return;
        }
        if (proportion == "Panorama") {
          this.editaItem.propsData.size[this.typeView].width = Math.round(
            this.editaItem.propsData.size[this.typeView].height * 2
          );
          this.globalize();
          return;
        }
        if (proportion == "Paisagem") {
          this.editaItem.propsData.size[this.typeView].width = Math.round(
            (this.editaItem.propsData.size[this.typeView].height / 608) * 1080
          );
          this.globalize();
          return;
        }
        if (proportion == "Retrato") {
          this.editaItem.propsData.size[this.typeView].width = Math.round(
            (this.editaItem.propsData.size[this.typeView].height / 5) * 4
          );
          this.globalize();
          return;
        }
        return;
      }
    },
    globalize() {
      if (this.global && this.typeView == "desktop") {
        var standardWidth = this.editaItem.propsData.size.desktop.width;
        var standardHeight = this.editaItem.propsData.size.desktop.height;
        var standardUndWidth = this.editaItem.propsData.size.desktop.und_width;
        var standardUndHeight =
          this.editaItem.propsData.size.desktop.und_height;
        this.editaItem.propsData.size.tablet.width = standardWidth;
        this.editaItem.propsData.size.mobile.width = standardWidth;
        this.editaItem.propsData.size.tablet.height = standardHeight;
        this.editaItem.propsData.size.mobile.height = standardHeight;
        this.editaItem.propsData.size.tablet.und_height = standardUndHeight;
        this.editaItem.propsData.size.mobile.und_height = standardUndHeight;
        this.editaItem.propsData.size.tablet.und_width = standardUndWidth;
        this.editaItem.propsData.size.mobile.und_width = standardUndWidth;
      }
    },       
  },
  created() {
    if (this.typeView == "desktop") {
      this.global = true;
    }
  },
};
</script>