<template>
  <div id="SideRightTabs">
    <b-tabs pills fill small style="width: 100%">
      <!-- Geral -->
      <b-tab title="Geral" active>
        <div>
          <div class="separador"></div>
          <div class="content">
            <label class="titulo">Área de código</label>
            <textarea
              v-focus
              v-model="editaItem.propsData.content.htmlContent"
              class="codigo"
              name="html"
              id="html"
              cols="30"
              rows="20"
            ></textarea>
          </div>
        </div>
      </b-tab>

      <!-- Avançado -->
      <b-tab title="Avançado">
        <advancedBasic
          :spacing="editaItem.propsData.spacing" 
          :visibility="editaItem.propsData.visibility" 
          :animation="editaItem.propsColumn" 
          :typeView="typeView"
          :global="global"
          @toggleGlobal="global = !global"
          @nextIcon="nextIcon"
          @update:spacing="editaItem.propsData.spacing = $event"
          @update:visibility="editaItem.propsData.visibility = $event"
          @update:animation="editaItem.propsColumn = $event"
          hideAttributes
        ></advancedBasic>
        <div class="separador"></div>


        <div class="content">
          <label class="titulo"> Scripts Externos</label>
          <!-- Dispositivos pequenos -->
          <div>
            <div style="display: flex; width: 100%; transition: all 0.3s">
              <label style="display: flex !important" class="sub">
                Priorizar carregamentoﾠ
                <img
                  width="17px"
                  height="17px"
                  src="@/assets/editor/help-circle.svg"
                  alt="icon"
                  v-b-tooltip.hover
                  title="Prioriza o carregamento do script sem estar visivel"
                />
              </label>
              <b-form-checkbox
                style="margin-top: -5px; margin-left: auto; margin-right: 0"
                v-model="editaItem.propsData.scriptRender"
                name="is_main"
                size="lg"
                switch
              >
              </b-form-checkbox>
            </div>
          </div>
        </div>
        <div class="separador"></div>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import advancedBasic from "../EditComponents/advancedBasic.vue";

export default {
  props: ["editaItem", "typeView"],
  components: {
    advancedBasic
  },
  data() {
    return {
      global: false,
    };
  },
  methods: {
    nextIcon(type) {
      this.$emit("attIconView", type);
    },
  },
  created() {
    if (this.typeView == "desktop") {
      this.global = true;
    }
    if (!this.editaItem.propsData.scriptRender) {
      this.editaItem.propsData.scriptRender = false;
    }
  },
};
</script>

<style lang="scss" scoped>
.codigo {
  display: block;
  font-family: monospace;
  white-space: pre;
}

textarea {
  padding: 6px 0 !important;
  height: auto !important;
}
</style>
