<template>
  <div class="tooltip-box" ref="tooltipBox" @mouseover="calculatePosition">
    <slot />
    <div class="tooltip" ref="tooltip">
      <span ref="countText" class="text">{{ text }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: ["text"],
  data() {
    return {
      visible: false,
    };
  },
  methods: {
    calculatePosition() {
      const tooltipBox = this.$refs.tooltipBox;
      const tooltip = this.$refs.tooltip;
      const text = this.$refs.countText;

      if (!tooltipBox || !tooltip) {
        return;
      }
      const tooltipBoxRect = tooltipBox.getBoundingClientRect();
      const tooltipRect = tooltip.getBoundingClientRect();
      const tooltipWidth = tooltipRect.width;
      const tooltipHeight = tooltipRect.height;
      const tooltipBoxWidth = tooltipBoxRect.width;
      const tooltipBoxHeight = tooltipBoxRect.height;
      const tooltipLeft = (tooltipWidth - tooltipHeight) / 2 / 2 - 7;
      const tooltipLeft2 = (tooltipWidth - tooltipHeight) / 2 / 2 - 19;
      const tooltipTop = tooltipBoxHeight + 20;

      tooltip.style.left =
        text.textContent.length >= 17
          ? `${tooltipLeft2}px`
          : `${tooltipLeft}px`;
      tooltip.style.top = `${tooltipTop}px`;
    },
  },
};
</script>

<style scoped>
.tooltip-box {
  position: relative;
  display: inline-block;
}

.tooltip-box:hover .tooltip {
  opacity: 1;
}

.tooltip {
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 0.5rem 1rem;
  text-align: center;
  border-radius: 6px;
  opacity: 0;
  transition: opacity 0.3s;
  position: absolute;
  z-index: 1;
  background: #000000;
  filter: drop-shadow(0 10px 8px rgb(0 0 0 / 0.04))
    drop-shadow(0 4px 3px rgb(0 0 0 / 0.1));
  pointer-events: none;

  /* Novas propriedades adicionadas */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.tooltip::before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-right: 6px solid #000000;
  /* left: 56px; */
  left: calc(50% - 6px); /* Alterado para utilizar a variável "tooltipLeft" */
  -webkit-filter: drop-shadow(0 10px 8px rgb(0 0 0 / 0.04));
  filter: drop-shadow(0 10px 8px rgb(0 0 0 / 0.04));
  bottom: 32px;
  transform: rotate(90deg);
}

.text {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 14px;
  color: #ffffff;
  white-space: nowrap;
}
</style>
