<template>
  <b-modal
    name="Modal-rows"
    id="Modal-rows"
    idModal="Modal-rows"
    size="sm"
    title="Salvar linha"
    centered
    hide-footer
    hide-header
    scrollable
  >
    <div>
      <label class="sub">Nome da linha</label>
      <input v-model="name" style="height: 45px !important" type="text" />
      <div class="buttons">
        <button @click="hide" class="cancel">Cancelar</button>
        <button @click="save" class="save">Salvar</button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import LineCreateService from "@/services/resources/LineCreateService";
const serviceLine = LineCreateService.build();

export default {
  name: "MyComponent",
  props: ["theRow", "page"],
  data() {
    return {
      name: "",
    };
  },
  methods: {
    hide() {
      this.$bvModal.hide("Modal-rows");
    },
    save() {
      if (this.name == "") {
        this.$grToast.toast("Dê um nome para a linha", {
          title: "Editor",
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
        return;
      }
      var line = JSON.stringify(this.theRow);
      let data = {
        title: this.name,
        json: line,
        status: "active",
      };
      serviceLine.create(data).then((resp) => {
        this.name = "";
        this.$emit("newLine");
        this.$grToast
          .toast("A linha foi salva com sucesso", {
            title: "Editor",
            variant: "info",
            autoHideDelay: 5000,
            appendToast: true,
          })
          .catch((err) => {
            this.$grToast.toast("Erro ao salvar linha", {
              title: "Mensagem manual",
              variant: "danger",
              autoHideDelay: 5000,
              appendToast: true,
            });
          });
      });
      this.$bvModal.hide("Modal-rows");
    },
  },
};
</script>
<style>
#Modal-rows .modal-content {
  height: auto !important;
}
</style>

<style scoped lang="scss">
.buttons {
  width: 100%;
  display: flex;
  gap: 10px;
  margin-top: 15px;
  button {
    width: 100%;
    height: 45px;
    border-radius: 10px;
    font-family: "Montserrat";
    font-weight: 600;
    font-size: 14px;
  }
  .save {
    background-color: var(--greenn);
    color: white;
  }
}
</style>
