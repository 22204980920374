import PropsColumnGlobal from './PropsColumnGlobal'
import PropsRowGlobal from './PropsRowGlobal'

let listColumns = [
  {
    type: "colun",
    name: "Uma coluna",
    icon: "coluns",
    component_type: "column",
    propsRow: PropsRowGlobal,
    coluns: [
      [
        {
          empty: true,
          propsColumn: PropsColumnGlobal,
        },
      ],
    ],
  },
  {
    type: "colun",
    name: "Duas colunas",
    icon: "coluns",
    component_type: "column",
    propsRow: PropsRowGlobal,
    coluns: [
      [
        {
          empty: true,
          propsColumn: PropsColumnGlobal,
        },
      ],
      [
        {
          empty: true,
          propsColumn: PropsColumnGlobal,
        },
      ],
    ],
  },
  {
    type: "colun",
    name: "Três colunas",
    icon: "coluns",
    component_type: "column",
    propsRow: PropsRowGlobal,
    coluns: [
      [
        {
          empty: true,
          propsColumn: PropsColumnGlobal,
        },
      ],
      [
        {
          empty: true,
          propsColumn: PropsColumnGlobal,
        },
      ],
      [
        {
          empty: true,
          propsColumn: PropsColumnGlobal,
        },
      ],
    ],
  },
  {
    type: "colun",
    name: "Quatro colunas",
    icon: "coluns",
    component_type: "column",
    propsRow: PropsRowGlobal,
    coluns: [
      [
        {
          empty: true,
          propsColumn: PropsColumnGlobal,
        },
      ],
      [
        {
          empty: true,
          propsColumn: PropsColumnGlobal,
        },
      ],
      [
        {
          empty: true,
          propsColumn: PropsColumnGlobal,
        },
      ],

      [
        {
          empty: true,
          propsColumn: PropsColumnGlobal,
        },
      ],
    ],
  },
  {
    type: "colun",
    name: "Cinco colunas",
    icon: "coluns",
    component_type: "column",
    propsRow: PropsRowGlobal,
    coluns: [
      [
        {
          empty: true,
          propsColumn: PropsColumnGlobal,
        },
      ],
      [
        {
          empty: true,
          propsColumn: PropsColumnGlobal,
        },
      ],
      [
        {
          empty: true,
          propsColumn: PropsColumnGlobal,
        },
      ],
      [
        {
          empty: true,
          propsColumn: PropsColumnGlobal,
        },
      ],
      [
        {
          empty: true,
          propsColumn: PropsColumnGlobal,
        },
      ],
    ],
  },
  {
    type: "colun",
    name: "Seis colunas",
    icon: "coluns",
    component_type: "column",
    propsRow: PropsRowGlobal,
    coluns: [
      [
        {
          empty: true,
          propsColumn: PropsColumnGlobal,
        },
      ],
      [
        {
          empty: true,
          propsColumn: PropsColumnGlobal,
        },
      ],
      [
        {
          empty: true,
          propsColumn: PropsColumnGlobal,
        },
      ],
      [
        {
          empty: true,
          propsColumn: PropsColumnGlobal,
        },
      ],
      [
        {
          empty: true,
          propsColumn: PropsColumnGlobal,
        },
      ],
      [
        {
          empty: true,
          propsColumn: PropsColumnGlobal,
        },
      ],
    ],
  },
  {
    type: "colun",
    name: "Direita maior",
    icon: "coluns",
    component_type: "column",
    propsRow: PropsRowGlobal,
    coluns: [
      [
        {
          empty: true,
          propsColumn: PropsColumnGlobal,
        },
      ],
      [
        {
          empty: true,
          propsColumn: PropsColumnGlobal,
        },
      ],
    ],
  },
  {
    type: "colun",
    name: "Esquerda maior",
    icon: "coluns",
    component_type: "column",
    propsRow: PropsRowGlobal,
    coluns: [
      [
        {
          empty: true,
          propsColumn: PropsColumnGlobal,
        },
      ],
      [
        {
          empty: true,
          propsColumn: PropsColumnGlobal,
        },
      ],
    ],
  },
  {
    type: "colun",
    name: "Centro maior",
    icon: "coluns",
    component_type: "column",
    propsRow: PropsRowGlobal,
    coluns: [
      [
        {
          empty: true,
          propsColumn: PropsColumnGlobal,
        },
      ],
      [
        {
          empty: true,
          propsColumn: PropsColumnGlobal,
        },
      ],
      [
        {
          empty: true,
          propsColumn: PropsColumnGlobal,
        },
      ],
    ],
  },
]

export default listColumns;