<template>
  <b-modal
    name="Modal-before-save"
    id="Modal-before-save"
    idModal="Modal-before-save"
    title="Salvar página"
    centered
    hide-footer
    scrollable
  >
    <div>
      <label class="sub">Título da página</label>
      <input v-model="title" type="text" class="mb-3" @input="$emit('emitTitle', title)">
      <button :disabled="!title" class="save-btn" @click="level === 'templater' ? $emit('saveTemplate') : $emit('savePage'), $bvModal.hide('Modal-before-save'), title = ''">Salvar</button>
    </div>
  </b-modal>
</template>

<script>

export default {
  name: "MyComponent",
  props: ["level"],
  data() {
    return {
      title: '',
    };
  },
  methods: {
  },
};
</script>

<style scoped lang="scss">
.save-btn {
  float: right;
  width: 116px;
  height: 45px;
  border-radius: 10px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.2px;
  color: white;
  background: var(--greenn);
  transition: all 0.3s;
  margin-right: 5px;
  &:hover {
    transform: scale(1.04);
  }
  &:disabled {
    background-color: #999;
  }
}

</style>
