<template>
  <BaseModal
    name="Modal-Icons-svg"
    id="Modal-Icons-svg"
    idModal="Modal-Icons-svg"
    size="xl"
    title="Seleção de ícones"
    centered
    hide-footer
    hide-header
    scrollable
  >
    <!-- {{ filteredIcon }} -->
    <div class="all">
      <div style="display: flex; justify-content: space-between">
        <div style="display: flex; justify-content: flex-end; gap: 10px">
          <div class="container-pesquisa">
            <div>
              <div class="inputSearch" id="multi-modal">
                <multiselect
                  v-model="categoria"
                  :options="
                    noBrands
                      ? [
                          'Tudo',
                          'Ações',
                          'Alertas',
                          'Áudio e Vídeo',
                          'Comunicação',
                          'Interfaces',
                          'Editor',
                          'Lugares',
                          'Dispositivos',
                          'Setas',
                          'Android',
                          'Avaliações',
                          'Atividades',
                          'Outros',
                        ]
                      : [
                          'Tudo',
                          'Marcas',
                          'Ações',
                          'Alertas',
                          'Áudio e Vídeo',
                          'Comunicação',
                          'Interfaces',
                          'Editor',
                          'Lugares',
                          'Dispositivos',
                          'Setas',
                          'Android',
                          'Avaliações',
                          'Atividades',
                          'Outros',
                        ]
                  "
                  :searchable="false"
                  :show-labels="false"
                  placeholder="Selecione a categoria"
                ></multiselect>
              </div>
            </div>
          </div>
          <div class="container-pesquisa">
            <div>
              <div class="inputSearch">
                <img
                  src="@/assets/img/icons/search.svg"
                  class="Pointersearch"
                />
                <input
                  v-model="search_icon"
                  style="
                    width: 100%;
                    margin: 0 !important;
                    height: 49px !important;
                  "
                  type="text"
                  placeholder="Pesquisar por ícones"
                  class="input-busca"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="categoria == 'Tudo' && search_icon.length === 0">
        <div
          class="icons-geral"
          v-if="(categoria == 'Tudo' || categoria == 'Marcas') && !noBrands"
        >
          <h4>Marcas</h4>

          <span
            v-for="(icon, index) in brands"
            :key="index"
            class="icon-box"
            @click="change(icon)"
          >
            <span class="material-icons" v-html="icon.code"></span>
          </span>
        </div>

        <div
          class="icons-geral"
          v-if="categoria == 'Tudo' || categoria == 'Ações'"
        >
          <h4>Ações</h4>

          <span
            v-for="(icon, index) in icons.communActions"
            :key="index"
            class="icon-box"
            @click="change(icon)"
          >
            <span class="material-icons">{{ icon }} </span>
          </span>
        </div>

        <div
          class="icons-geral"
          v-if="categoria == 'Tudo' || categoria == 'Alertas'"
        >
          <h4>Notificações</h4>
          <span
            v-for="(icon, index) in icons.alert"
            :key="index"
            class="icon-box"
            @click="change(icon)"
          >
            <span class="material-icons">{{ icon }}</span>
          </span>
        </div>

        <div
          class="icons-geral"
          v-if="categoria == 'Tudo' || categoria == 'Áudio e Vídeo'"
        >
          <h4>Áudio e vídeo</h4>
          <span
            v-for="(icon, index) in icons.audio"
            :key="index"
            class="icon-box"
            @click="change(icon)"
          >
            <span class="material-icons">{{ icon }}</span>
          </span>
        </div>

        <div
          class="icons-geral"
          v-if="categoria == 'Tudo' || categoria == 'Comunicação'"
        >
          <h4>Comunicação</h4>
          <span
            v-for="(icon, index) in icons.comunicacao"
            :key="index"
            class="icon-box"
            @click="change(icon)"
          >
            <span class="material-icons">{{ icon }}</span>
          </span>
        </div>

        <div
          class="icons-geral"
          v-if="categoria == 'Tudo' || categoria == 'Interfaces'"
        >
          <h4>Interfaces</h4>
          <span
            v-for="(icon, index) in icons.ui"
            :key="index"
            class="icon-box"
            @click="change(icon)"
          >
            <span class="material-icons">{{ icon }}</span>
          </span>
        </div>

        <div
          class="icons-geral"
          v-if="categoria == 'Tudo' || categoria == 'Editor'"
        >
          <h4>Editor</h4>
          <span
            v-for="(icon, index) in icons.editor"
            :key="index"
            class="icon-box"
            @click="change(icon)"
          >
            <span class="material-icons">{{ icon }}</span>
          </span>
        </div>

        <div
          class="icons-geral"
          v-if="categoria == 'Tudo' || categoria == 'Dispositivos'"
        >
          <h4>Dispositivos</h4>
          <span
            v-for="(icon, index) in icons.aaa"
            :key="index"
            class="icon-box"
            @click="change(icon)"
          >
            <span class="material-icons">{{ icon }}</span>
          </span>
        </div>

        <div
          class="icons-geral"
          v-if="categoria == 'Tudo' || categoria == 'Lugares'"
        >
          <h4>Lugares</h4>
          <span
            v-for="(icon, index) in icons.bbb"
            :key="index"
            class="icon-box"
            @click="change(icon)"
          >
            <span class="material-icons">{{ icon }}</span>
          </span>
        </div>

        <div
          class="icons-geral"
          v-if="categoria == 'Tudo' || categoria == 'Setas'"
        >
          <h4>Setas</h4>
          <span
            v-for="(icon, index) in icons.ccc"
            :key="index"
            class="icon-box"
            @click="change(icon)"
          >
            <span class="material-icons">{{ icon }}</span>
          </span>
        </div>

        <div
          class="icons-geral"
          v-if="categoria == 'Tudo' || categoria == 'Android'"
        >
          <h4>Android</h4>
          <span
            v-for="(icon, index) in icons.ddd"
            :key="index"
            class="icon-box"
            @click="change(icon)"
          >
            <span class="material-icons">{{ icon }}</span>
          </span>
        </div>

        <div
          class="icons-geral"
          v-if="categoria == 'Tudo' || categoria == 'Avaliações'"
        >
          <h4>Avaliações</h4>
          <span
            v-for="(icon, index) in icons.ggg"
            :key="index"
            class="icon-box"
            @click="change(icon)"
          >
            <span class="material-icons">{{ icon }}</span>
          </span>
        </div>

        <div
          class="icons-geral"
          v-if="categoria == 'Tudo' || categoria == 'Atividades'"
        >
          <h4>Atividades</h4>
          <span
            v-for="(icon, index) in icons.hhh"
            :key="index"
            class="icon-box"
            @click="change(icon)"
          >
            <span class="material-icons">{{ icon }}</span>
          </span>
        </div>

        <div
          class="icons-geral"
          v-if="categoria == 'Tudo' || categoria == 'Outros'"
        >
          <h4>Outros</h4>
          <span
            v-for="(icon, index) in icons.iii"
            :key="index"
            class="icon-box"
            @click="change(icon)"
          >
            <span class="material-icons">{{ icon }}</span>
          </span>
        </div>
        <div class="fonte">
          <p>
            Ícones por
            <a
              target="_blank"
              href="https://fonts.google.com/icons?icon.set=Material+Icons"
              >Material Icons</a
            >
          </p>
        </div>
      </div>
      <div v-else>
        <div v-if="filteredIcon.length || categoria == 'Marcas'">
          <div class="icons-geral" v-if="categoria != 'Marcas'">
            <h4>{{ categoria }}</h4>
            <span
              v-for="(icon, index) in filteredIcon"
              :key="index"
              class="icon-box"
              @click="change(icon)"
            >
              <span class="material-icons">{{ icon }}</span>
            </span>
          </div>
          <div class="icons-geral" v-else>
            <h4>Marcas</h4>
            <span
              v-for="(icon, index) in filteredIcon"
              :key="index"
              class="icon-box"
              @click="change(icon)"
            >
              <span class="material-icons" v-html="icon.code"></span>
            </span>
          </div>
        </div>
        <div class="icons-geral" v-else>
          <h4>Ícones encontrados</h4>
          <b-row class="Table-body justify-content-center">
            <p class="nao-encontrado">Nenhum ícone foi encontrado</p>
            {{ filteredIcon.length }}
          </b-row>
        </div>
      </div>
    </div>
    <template v-slot:footer="{}">
      <div></div>
    </template>
  </BaseModal>
</template>

<script>
import Multiselect from "vue-multiselect";
export default {
  props: ["noBrands"],
  components: {
    Multiselect,
  },
  name: "MyComponent",
  data() {
    return {
      search: false,
      search_icon: "",
      categoria: "Tudo",
      icons: {
        communActions: [
          "accessible",
          "3d_rotation",
          "accessibility",
          "accessible",
          "account_balance",
          "account_balance_wallet",
          "account_box",
          "account_circle",
          "add_shopping_cart",
          "alarm",
          "alarm_add",
          "alarm_off",
          "alarm_on",
          "all_out",
          "android",
          "announcement",
          "arrow_right_alt",
          "aspect_ratio",
          "assessment",
          "assignment",
          "assignment_ind",
          "assignment_late",
          "assignment_return",
          "assignment_returned",
          "assignment_turned_in",
          "autorenew",
          "backup",
          "book",
          "bookmark",
          "bookmark_border",
          "bug_report",
          "build",
          "cached",
          "calendar_today",
          "calendar_view_day",
          "camera_enhance",
          "card_giftcard",
          "card_membership",
          "card_travel",
          "change_history",
          "check_circle",
          "check_circle_outline",
          "chrome_reader_mode",
          "class",
          "code",
          "compare_arrows",
          "contact_support",
          "contactless",
          "contacts",
          "content_copy",
          "content_cut",
          "content_paste",
          "control_point",
          "control_point_duplicate",
          "create",
          "credit_card",
          "delete_sweep",
          "description",
          "dns",
          "done",
          "done_all",
          "done_outline",
          "donut_large",
          "donut_small",
          "drag_indicator",
          "eco",
          "eject",
          "euro_symbol",
          "event",
          "event_seat",
          "exit_to_app",
          "explore",
          "extension",
          "face",
          "favorite",
          "favorite_border",
          "feedback",
          "find_in_page",
          "find_replace",
          "fingerprint",
          "flight_land",
          "flight_takeoff",
          "flip_to_back",
          "flip_to_front",
          "g_translate",
          "gavel",
          "get_app",
          "gif",
          "grade",
          "group_work",
          "help",
          "help_outline",
          "highlight_off",
          "history",
          "home",
          "horizontal_split",
          "hourglass_empty",
          "hourglass_full",
          "http",
          "https",
          "important_devices",
          "info",
          "input",
          "invert_colors",
          "label",
          "label_important",
          "label_outline",
          "language",
          "launch",
          "line_style",
          "line_weight",
          "list",
          "lock",
          "lock_open",
          "loyalty",
          "markunread_mailbox",
          "maximize",
          "minimize",
          "motorcycle",
          "note_add",
          "offline_bolt",
          "offline_pin",
          "opacity",
          "open_in_browser",
          "open_in_new",
          "open_with",
          "pageview",
          "pan_tool",
          "payment",
          "perm_camera_mic",
          "perm_contact_calendar",
          "perm_data_setting",
          "perm_device_information",
          "perm_identity",
          "perm_media",
          "perm_phone_msg",
          "perm_scan_wifi",
          "pets",
          "picture_in_picture",
          "picture_in_picture_alt",
          "play_for_work",
          "polymer",
          "power_settings_new",
          "pregnant_woman",
          "print",
          "query_builder",
          "question_answer",
          "quick_contacts_dialer",
          "quick_contacts_mail",
          "radio_button_checked",
          "radio_button_unchecked",
          "receipt",
          "record_voice_over",
          "redeem",
          "remove_shopping_cart",
          "reorder",
          "report_problem",
          "restore",
          "restore_from_trash",
          "restore_page",
          "room",
          "rounded_corner",
          "rowing",
          "schedule",
          "search",
          "settings",
          "settings_applications",
          "settings_backup_restore",
          "settings_bluetooth",
          "settings_brightness",
          "settings_cell",
          "settings_ethernet",
          "settings_input_antenna",
          "settings_input_component",
          "settings_input_composite",
          "settings_input_hdmi",
          "settings_input_svideo",
          "settings_overscan",
          "settings_phone",
          "settings_power",
          "settings_remote",
          "settings_system_daydream",
          "settings_voice",
          "shop",
          "shop_two",
          "shopping_basket",
          "shopping_cart",
          "speaker_notes",
          "speaker_notes_off",
          "spellcheck",
          "star_rate",
          "stars",
          "store",
          "subdirectory_arrow_left",
          "subdirectory_arrow_right",
          "subject",
          "supervised_user_circle",
          "supervisor_account",
          "swap_horiz",
          "swap_horizontal_circle",
          "swap_vert",
          "swap_vertical_circle",
          "tab",
          "tab_unselected",
          "text_rotate_up",
          "text_rotate_vertical",
          "text_rotation_angledown",
          "text_rotation_angleup",
          "text_rotation_down",
          "text_rotation_none",
          "theaters",
          "thumb_down",
          "thumb_up",
          "thumbs_up_down",
          "timeline",
          "toc",
          "today",
          "toll",
          "touch_app",
          "track_changes",
          "translate",
          "trending_down",
          "trending_flat",
          "trending_up",
          "turned_in",
          "turned_in_not",
          "update",
          "verified_user",
          "vertical_split",
          "view_agenda",
          "view_array",
          "view_carousel",
          "view_column",
          "view_day",
          "view_headline",
          "view_list",
          "view_module",
          "view_quilt",
          "view_stream",
          "view_week",
          "visibility",
          "visibility_off",
          "voice_over_off",
          "watch_later",
          "work",
          "work_off",
          "work_outline",
          "youtube_searched_for",
          "zoom_in",
          "zoom_out",
        ],
        alert: [
          "add_alert",
          "error",
          "error_outline",
          "notification_important",
          "warning",
          "cake",
          "domain",
          "group",
          "group_add",
          "location_city",
          "mood",
          "mood_bad",
          "notifications",
          "notifications_active",
          "notifications_none",
          "notifications_off",
          "notifications_paused",
          "pages",
          "party_mode",
          "people",
          "people_outline",
          "person",
          "person_add",
          "person_outline",
          "plus_one",
          "poll",
          "public",
          "school",
          "sentiment_dissatisfied",
          "sentiment_neutral",
          "sentiment_satisfied",
          "sentiment_very_dissatisfied",
          "sentiment_very_satisfied",
          "share",
          "whatshot",
        ],
        audio: [
          "4k",
          "add_to_queue",
          "airplay",
          "album",
          "art_track",
          "av_timer",
          "branding_watermark",
          "call_to_action",
          "closed_caption",
          "control_camera",
          "equalizer",
          "explicit",
          "fast_forward",
          "fast_rewind",
          "featured_play_list",
          "featured_video",
          "fiber_dvr",
          "fiber_manual_record",
          "fiber_new",
          "fiber_pin",
          "fiber_smart_record",
          "forward_10",
          "forward_30",
          "forward_5",
          "games",
          "hd",
          "hearing",
          "high_quality",
          "library_add",
          "library_books",
          "library_music",
          "loop",
          "mic",
          "mic_none",
          "mic_off",
          "missed_video_call",
          "movie",
          "music_video",
          "new_releases",
          "not_interested",
          "note",
          "pause",
          "pause_circle_filled",
          "pause_circle_outline",
          "play_arrow",
          "play_circle_filled",
          "play_circle_outline",
          "playlist_add",
          "playlist_add_check",
          "playlist_play",
          "queue",
          "queue_music",
          "queue_play_next",
          "radio",
          "recent_actors",
          "remove_from_queue",
          "repeat",
          "repeat_one",
          "replay",
          "replay_10",
          "replay_30",
          "replay_5",
          "shuffle",
          "skip_next",
          "skip_previous",
          "slow_motion_video",
          "snooze",
          "sort_by_alpha",
          "stop",
          "subscriptions",
          "subtitles",
          "surround_sound",
          "video_call",
          "video_label",
          "video_library",
          "videocam",
          "videocam_off",
          "volume_down",
          "volume_mute",
          "volume_off",
          "volume_up",
          "web_asset",
          "web",
        ],
        comunicacao: [
          "call",
          "call_end",
          "call_made",
          "call_merge",
          "call_missed",
          "call_missed_outgoing",
          "call_received",
          "call_split",
          "cancel_presentation",
          "cell_wifi",
          "chat",
          "chat_bubble",
          "chat_bubble_outline",
          "clear_all",
          "comment",
          "contact_mail",
          "contact_phone",
          "contacts",
          "desktop_access_disabled",
          "dialer_sip",
          "dialpad",
          "domain_disabled",
          "domain_verification",
          "duo",
          "email",
          "forum",
          "forward_to_inbox",
          "hourglass_bottom",
          "hourglass_top",
          "import_contacts",
          "import_export",
          "invert_colors_off",
          "list_alt",
          "live_help",
          "location_off",
          "location_on",
          "mail_outline",
          "message",
          "mobile_screen_share",
          "no_sim",
          "pause_presentation",
          "person_add_disabled",
          "phone",
          "phonelink_erase",
          "phonelink_lock",
          "phonelink_ring",
          "phonelink_setup",
          "portable_wifi_off",
          "present_to_all",
          "print_disabled",
          "ring_volume",
          "rss_feed",
          "screen_share",
          "sentiment_satisfied_alt",
          "speaker_phone",
          "stay_current_landscape",
          "stay_current_portrait",
          "stay_primary_landscape",
          "stay_primary_portrait",
          "stop_screen_share",
          "swap_calls",
          "textsms",
          "unsubscribe",
          "voicemail",
          "vpn_key",
        ],
        ui: [
          "add",
          "add_box",
          "add_circle",
          "add_circle_outline",
          "archive",
          "backspace",
          "ballot",
          "block",
          "clear",
          "create",
          "drafts",
          "file_copy",
          "filter_list",
          "flag",
          "font_download",
          "forward",
          "gesture",
          "how_to_reg",
          "how_to_vote",
          "inbox",
          "insights",
          "link",
          "link_off",
          "low_priority",
          "mail",
          "markunread",
          "move_to_inbox",
          "next_week",
          "outlined_flag",
          "policy",
          "redo",
          "remove",
          "remove_circle",
          "remove_circle_outline",
          "reply",
          "reply_all",
          "report",
          "report_off",
          "save",
          "save_alt",
          "select_all",
          "send",
          "sort",
          "square_foot",
          "text_format",
          "toc",
          "touch_app",
          "undo",
          "waves",
          "where_to_vote",
        ],
        editor: [
          "add_comment",
          "attach_file",
          "attach_money",
          "bar_chart",
          "border_all",
          "border_bottom",
          "border_clear",
          "border_color",
          "border_horizontal",
          "border_inner",
          "border_left",
          "border_outer",
          "border_right",
          "border_style",
          "border_top",
          "border_vertical",
          "bubble_chart",
          "drag_handle",
          "format_align_center",
          "format_align_justify",
          "format_align_left",
          "format_align_right",
          "format_bold",
          "format_clear",
          "format_color_fill",
          "format_color_reset",
          "format_color_text",
          "format_indent_decrease",
          "format_indent_increase",
          "format_italic",
          "format_line_spacing",
          "format_list_bulleted",
          "format_list_numbered",
          "format_paint",
          "format_quote",
          "format_shapes",
          "format_size",
          "format_strikethrough",
          "format_textdirection_l_to_r",
          "format_textdirection_r_to_l",
          "format_underlined",
          "functions",
          "highlight",
          "insert_chart",
          "insert_chart_outlined",
          "insert_comment",
          "insert_drive_file",
          "insert_emoticon",
          "insert_invitation",
          "insert_link",
          "insert_photo",
          "linear_scale",
          "merge_type",
          "mode_comment",
          "monetization_on",
          "money_off",
          "multiline_chart",
          "notes",
          "pie_chart",
          "publish",
          "scatter_plot",
          "score",
          "short_text",
          "show_chart",
          "space_bar",
          "strikethrough_s",
          "table_chart",
          "text_fields",
          "title",
          "vertical_align_bottom",
          "vertical_align_center",
          "vertical_align_top",
          "wrap_text",
        ],
        file: [
          "attachment",
          "cloud",
          "cloud_circle",
          "cloud_done",
          "cloud_download",
          "cloud_off",
          "cloud_queue",
          "cloud_upload",
          "create_new_folder",
          "folder",
          "folder_open",
          "folder_shared",
          "cast",
          "cast_connected",
          "cast_for_education",
          "computer",
          "desktop_mac",
          "desktop_windows",
          "developer_board",
          "device_hub",
          "device_unknown",
          "devices_other",
          "dock",
          "gamepad",
          "headset",
          "headset_mic",
          "keyboard",
          "keyboard_arrow_down",
          "keyboard_arrow_left",
          "keyboard_arrow_right",
          "keyboard_arrow_up",
          "keyboard_backspace",
          "keyboard_capslock",
          "keyboard_hide",
          "keyboard_return",
          "keyboard_tab",
          "keyboard_voice",
          "laptop",
          "laptop_chromebook",
          "laptop_mac",
          "laptop_windows",
          "memory",
          "mouse",
          "phone_android",
          "phone_iphone",
          "phonelink",
          "phonelink_off",
          "power_input",
          "router",
          "scanner",
          "security",
          "sim_card",
          "smartphone",
          "speaker",
          "speaker_group",
          "tablet",
          "tablet_android",
          "tablet_mac",
          "toys",
          "tv",
          "videogame_asset",
          "watch",
        ],
        aaa: [
          "add_a_photo",
          "add_photo_alternate",
          "add_to_photos",
          "adjust",
          "assistant",
          "assistant_photo",
          "audiotrack",
          "blur_circular",
          "blur_linear",
          "blur_off",
          "blur_on",
          "brightness_1",
          "brightness_2",
          "brightness_3",
          "brightness_4",
          "brightness_5",
          "brightness_6",
          "brightness_7",
          "broken_image",
          "brush",
          "burst_mode",
          "camera",
          "camera_alt",
          "camera_front",
          "camera_rear",
          "camera_roll",
          "center_focus_strong",
          "center_focus_weak",
          "collections",
          "collections_bookmark",
          "color_lens",
          "colorize",
          "compare",
          "control_point",
          "control_point_duplicate",
          "crop",
          "crop_16_9",
          "crop_3_2",
          "crop_5_4",
          "crop_7_5",
          "crop_din",
          "crop_free",
          "crop_landscape",
          "crop_original",
          "crop_portrait",
          "crop_rotate",
          "crop_square",
          "dehaze",
          "details",
          "edit",
          "exposure",
          "exposure_neg_1",
          "exposure_neg_2",
          "exposure_plus_1",
          "exposure_plus_2",
          "exposure_zero",
          "filter",
          "filter_1",
          "filter_2",
          "filter_3",
          "filter_4",
          "filter_5",
          "filter_6",
          "filter_7",
          "filter_8",
          "filter_9",
          "filter_9_plus",
          "filter_b_and_w",
          "filter_center_focus",
          "filter_drama",
          "filter_frames",
          "filter_hdr",
          "filter_list",
          "filter_none",
          "filter_tilt_shift",
          "filter_vintage",
          "flare",
          "flash_auto",
          "flash_off",
          "flash_on",
          "flip",
          "flip_camera_android",
          "flip_camera_ios",
          "gradient",
          "grain",
          "grid_off",
          "grid_on",
          "hdr_off",
          "hdr_on",
          "hdr_strong",
          "hdr_weak",
          "healing",
          "image",
          "image_aspect_ratio",
          "image_search",
          "iso",
          "landscape",
          "leak_add",
          "leak_remove",
          "lens",
          "linked_camera",
          "looks",
          "looks_3",
          "looks_4",
          "looks_5",
          "looks_6",
          "looks_one",
          "looks_two",
          "loupe",
          "monochrome_photos",
          "movie_creation",
          "movie_filter",
          "music_note",
          "music_off",
          "nature",
          "nature_people",
          "navigate_before",
          "navigate_next",
          "palette",
          "panorama",
          "panorama_fish_eye",
          "panorama_horizontal",
          "panorama_vertical",
          "panorama_wide_angle",
          "photo",
          "photo_album",
          "photo_camera",
          "photo_filter",
          "photo_library",
          "photo_size_select_actual",
          "photo_size_select_large",
          "photo_size_select_small",
          "picture_as_pdf",
          "portrait",
          "remove_red_eye",
          "rotate_90_degrees_ccw",
          "rotate_left",
          "rotate_right",
          "shutter_speed",
          "slideshow",
          "straighten",
          "style",
          "switch_camera",
          "switch_video",
          "tag_faces",
          "texture",
          "timelapse",
          "timer",
          "timer_10",
          "timer_3",
          "timer_off",
          "tonality",
          "transform",
          "view_compact",
          "vignette",
          "wb_auto",
          "wb_cloudy",
          "wb_incandescent",
          "wb_iridescent",
          "wb_sunny",
        ],
        bbb: [
          "360",
          "add_location",
          "atm",
          "beenhere",
          "category",
          "compass_calibration",
          "departure_board",
          "directions",
          "directions_bike",
          "directions_boat",
          "directions_bus",
          "directions_car",
          "directions_railway",
          "directions_run",
          "directions_subway",
          "directions_transit",
          "directions_walk",
          "edit_location",
          "ev_station",
          "fastfood",
          "flight",
          "hotel",
          "layers",
          "layers_clear",
          "local_activity",
          "local_airport",
          "local_atm",
          "local_bar",
          "local_cafe",
          "local_car_wash",
          "local_convenience_store",
          "local_dining",
          "local_drink",
          "local_florist",
          "local_gas_station",
          "local_grocery_store",
          "local_hospital",
          "local_hotel",
          "local_laundry_service",
          "local_library",
          "local_mall",
          "local_movies",
          "local_offer",
          "local_parking",
          "local_pharmacy",
          "local_phone",
          "local_pizza",
          "local_play",
          "local_post_office",
          "local_printshop",
          "local_see",
          "local_shipping",
          "local_taxi",
          "map",
          "money",
          "my_location",
          "navigation",
          "near_me",
          "not_listed_location",
          "person_pin",
          "person_pin_circle",
          "pin_drop",
          "place",
          "rate_review",
          "restaurant",
          "restaurant_menu",
          "satellite",
          "store_mall_directory",
          "streetview",
          "subway",
          "terrain",
          "traffic",
          "train",
          "tram",
          "transfer_within_a_station",
          "transit_enterexit",
          "trip_origin",
          "zoom_out_map",
          "ac_unit",
          "airport_shuttle",
          "all_inclusive",
          "beach_access",
          "business_center",
          "casino",
          "child_care",
          "child_friendly",
          "fitness_center",
          "free_breakfast",
          "golf_course",
          "hot_tub",
          "kitchen",
          "meeting_room",
          "no_meeting_room",
          "pool",
          "room_service",
          "rv_hookup",
          "smoke_free",
          "smoking_rooms",
          "spa",
        ],
        ccc: [
          "apps",
          "arrow_back",
          "arrow_back_ios",
          "arrow_downward",
          "arrow_drop_down",
          "arrow_drop_down_circle",
          "arrow_drop_up",
          "arrow_forward",
          "arrow_forward_ios",
          "arrow_left",
          "arrow_right",
          "arrow_upward",
          "cancel",
          "check",
          "chevron_left",
          "chevron_right",
          "close",
          "double_arrow",
          "expand_less",
          "expand_more",
          "first_page",
          "fullscreen",
          "fullscreen_exit",
          "menu",
          "more_horiz",
          "more_vert",
          "refresh",
          "subdirectory_arrow_left",
          "subdirectory_arrow_right",
          "unfold_less",
          "unfold_more",
        ],
        ddd: [
          "adb",
          "airline_seat_flat",
          "airline_seat_flat_angled",
          "airline_seat_individual_suite",
          "airline_seat_legroom_extra",
          "airline_seat_legroom_normal",
          "airline_seat_legroom_reduced",
          "airline_seat_recline_extra",
          "airline_seat_recline_normal",
          "bluetooth_audio",
          "confirmation_number",
          "disc_full",
          "drive_eta",
          "enhanced_encryption",
          "event_available",
          "event_busy",
          "event_note",
          "folder_special",
          "live_tv",
          "mms",
          "more",
          "network_check",
          "network_locked",
          "no_encryption",
          "ondemand_video",
          "personal_video",
          "phone_bluetooth_speaker",
          "phone_forwarded",
          "phone_in_talk",
          "phone_locked",
          "phone_missed",
          "phone_paused",
          "power",
          "power_off",
          "priority_high",
          "sd_card",
          "sms",
          "sms_failed",
          "sync",
          "sync_disabled",
          "sync_problem",
          "system_update_alt",
          "time_to_leave",
          "tv_off",
          "vibration",
          "voice_chat",
          "vpn_lock",
          "wc",
          "wifi",
          "wifi_off",
        ],
        ggg: [
          "check_box",
          "check_box_outline_blank",
          "indeterminate_check_box",
          "radio_button_checked",
          "radio_button_unchecked",
          "star",
          "star_border",
          "star_half",
          "toggle_off",
          "toggle_on",
        ],
        hhh: [
          "arrow_back_ios_new",
          "biotech",
          "calculate",
          "camera_indoor",
          "camera_outdoor",
          "car_rental",
          "car_repair",
          "charging_station",
          "cleaning_services",
          "connect_without_contact",
          "construction",
          "corporate_fare",
          "do_not_touch",
          "dry",
          "elevator",
          "engineering",
          "escalator",
          "face_retouching_natural",
          "fact_check",
          "fireplace",
          "follow_the_signs",
          "front_hand",
          "health_and_safety",
          "highlights",
          "home_repair_service",
          "horizontal_distribute",
          "house_siding",
          "hvac",
          "king_bed",
          "kitesurfing",
          "location_pin",
          "login",
          "manage_accounts",
          "manage_search",
          "mark_as_unread",
          "military_tech",
          "multiple_stop",
          "museum",
          "navigate_before",
          "navigate_next",
          "near_me_disabled",
          "next_plan",
          "night_shelter",
          "no_backpack",
          "no_luggage",
          "no_meals",
          "not_started",
          "online_prediction",
          "outdoor_grill",
          "panorama_photosphere",
          "panorama_photosphere_select",
          "payments",
          "pedal_bike",
          "person_off",
          "pest_control",
          "pets",
          "read_more",
          "real_estate_agent",
          "recommend",
          "reduce_capacity",
          "remove_done",
          "remove_moderator",
          "sailing",
          "sanitizer",
          "savings",
          "sensors",
          "set_meal",
          "show_chart",
          "shuffle",
          "skip_next",
          "skip_previous",
          "sledding",
          "snowboarding",
          "snowshoeing",
          "soap",
          "social_distance",
          "sort",
          "sort_by_alpha",
          "stacked_bar_chart",
          "stream",
          "subscript",
          "superscript",
          "support",
          "support_agent",
          "takeout_dining",
          "taxi_alert",
          "thumb_down_off_alt",
          "thumb_up_off_alt",
          "topic",
          "upcoming",
          "vertical_align_bottom",
          "vertical_align_center",
          "vertical_align_top",
          "view_agenda",
          "view_array",
          "view_carousel",
          "view_column",
          "view_day",
          "view_headline",
          "view_list",
          "view_module",
          "view_quilt",
          "view_sidebar",
          "view_stream",
          "view_week",
        ],
        iii: [
          "account_tree",
          "add_link",
          "alt_route",
          "analytics",
          "anchor",
          "api",
          "app_blocking",
          "attachment",
          "auto_delete",
          "auto_fix_high",
          "auto_fix_normal",
          "auto_fix_off",
          "auto_stories",
          "bathtub",
          "bookmark_add",
          "bookmark_added",
          "bookmark_remove",
          "bookmarks",
          "calendar_view_month",
          "calendar_view_week",
          "cancel_schedule_send",
          "commute",
          "dangerous",
          "dashboard",
          "dashboard_customize",
          "data_usage",
          "date_range",
          "delete",
          "delete_forever",
          "delete_outline",
          "developer_mode",
          "device_thermostat",
          "devices",
          "edit_attributes",
          "edit_off",
          "email",
          "emoji_emotions",
          "emoji_events",
          "emoji_flags",
          "emoji_food_beverage",
          "emoji_nature",
          "emoji_objects",
          "emoji_people",
          "emoji_symbols",
          "emoji_transportation",
          "event",
          "explore",
          "explore_off",
          "exposure",
          "exposure_minus_1",
          "exposure_minus_2",
          "exposure_plus_1",
          "file_download",
          "file_download_done",
          "file_present",
          "file_upload",
          "fit_screen",
          "flaky",
          "food_bank",
          "g_translate",
          "gif",
          "goat",
          "gps_fixed",
          "gps_not_fixed",
          "gps_off",
          "grading",
          "graphic_eq",
          "hail",
          "handyman",
          "hd",
          "hdr_enhanced_select",
          "help",
          "help_center",
          "home",
          "home_filled",
          "horizontal_rule",
          "hourglass_disabled",
          "house",
          "icecream",
          "image_not_supported",
          "imagesearch_roller",
          "indeterminate_check_box",
          "info",
          "info_outline",
          "input",
          "integration_instructions",
          "inventory",
          "inventory_2",
          "ios_share",
          "iron",
          "label",
          "label_important_outline",
          "last_page",
          "leaderboard",
          "leave_bags_at_home",
          "legend_toggle",
          "light",
          "lightbulb",
          "light_mode",
          "link",
          "liquor",
          "living",
          "lock",
          "lock_clock",
          "login",
          "logout",
          "lte_mobiledata",
          "lte_plus_mobiledata",
          "lunch_dining",
          "male",
          "masks",
          "media_bluetooth_off",
          "media_bluetooth_on",
          "mediation",
          "medical_services",
          "medication",
          "menu",
          "menu_book",
          "menu_open",
          "messenger",
          "messenger_outline",
          "mic",
          "mic_external_off",
          "mic_external_on",
          "microwave",
          "miscellaneous_services",
          "mobile_friendly",
          "mobile_off",
          "mode",
          "mode_night",
          "mode_standby",
          "model_training",
          "monitor",
          "monitor_weight",
          "moped",
          "motion_photos_auto",
          "motion_photos_off",
          "motion_photos_on",
          "motion_photos_pause",
          "motion_photos_paused",
          "mp",
          "multitrack_audio",
          "my_library_add",
          "my_library_books",
          "my_library_music",
          "nat",
          "nearby_error",
          "nearby_off",
          "network_cell",
          "network_check",
          "network_locked",
          "network_wifi",
          "new_label",
          "new_releases",
          "nfc",
          "nightlife",
          "nightlight",
          "nightlight_round",
          "nights_stay",
          "no_accounts",
          "no_cell",
          "no_drinks",
          "no_encryption_gmailerrorred",
          "no_flash",
          "no_food",
          "no_photography",
          "no_stroller",
          "not_accessible",
          "now_wallpaper",
          "now_widgets",
          "offline_share",
          "open_in_new_off",
          "outbond",
          "outbound",
          "outbox",
          "outgoing_mail",
          "outlet",
          "paid",
          "paragliding",
          "pending",
          "pending_actions",
          "people",
          "people_alt",
          "person",
          "person_add",
          "person_add_alt",
          "person_add_alt_1",
          "person_add_disabled",
          "person_off",
          "person_outline",
          "person_pin",
          "person_pin_circle",
          "person_remove",
          "person_remove_alt_1",
          "person_search",
          "phone_callback",
          "phone_disabled",
          "phone_enabled",
          "piano",
          "piano_off",
          "picture_in_picture",
          "picture_in_picture_alt",
          "pivot_table_chart",
          "plagiarism",
          "play_disabled",
          "plumbing",
          "podcasts",
          "point_of_sale",
          "post_add",
          "precision_manufacturing",
          "preview",
          "price_change",
          "price_check",
          "privacy_tip",
          "private_connectivity",
          "production_quantity_limits",
          "psychology",
          "public",
          "public_off",
          "published_with_changes",
          "push_pin",
          "qr_code",
          "qr_code_2",
          "qr_code_scanner",
          "query_stats",
          "quickreply",
          "railway_alert",
          "ramen_dining",
          "raw_off",
          "raw_on",
          "receipt_long",
          "recycling",
          "remember_me",
          "replay_circle_filled",
          "request_page",
          "request_quote",
          "reset_tv",
          "restart_alt",
          "reviews",
          "rice_bowl",
          "roofing",
          "room_preferences",
          "rsvp",
          "rtt",
          "rule_folder",
          "run_circle",
          "running_with_errors",
          "safety_divider",
          "save",
          "saved_search",
          "schema",
          "science",
          "screen_lock_landscape",
          "screen_lock_portrait",
          "screen_lock_rotation",
          "screen_rotation",
          "screen_search_desktop",
          "sd",
          "sd_storage",
          "segment",
          "self_improvement",
          "sell",
          "send",
          "send_and_archive",
          "send_to_mobile",
          "sensor_door",
          "sensor_window",
          "sensors_off",
          "shield",
          "sick",
          "signal_cellular_0_bar",
          "signal_cellular_alt",
          "signal_cellular_connected_no_internet_0_bar",
          "signal_cellular_connected_no_internet_4_bar",
          "signal_cellular_nodata",
          "signal_cellular_no_sim",
          "signal_cellular_null",
          "signal_cellular_off",
          "signal_wifi_0_bar",
          "signal_wifi_bad",
          "signal_wifi_connected_no_internet_4",
          "signal_wifi_off",
          "signal_wifi_statusbar_connected_no_internet_4",
          "signal_wifi_statusbar_null",
          "sim_card",
          "sim_card_alert",
          "sim_card_download",
          "single_bed",
          "skateboarding",
          "smart_button",
          "smart_display",
          "smart_screen",
          "smart_toy",
          "snippet_folder",
          "snowmobile",
          "source",
          "south",
          "south_east",
          "south_west",
          "spa",
          "space_dashboard",
          "splitscreen",
          "sports",
          "sports_bar",
          "sports_baseball",
          "sports_basketball",
          "sports_cricket",
          "sports_esports",
          "sports_football",
          "sports_golf",
          "sports_handball",
          "sports_hockey",
          "sports_kabaddi",
          "sports_mma",
          "sports_motorsports",
          "sports_rugby",
          "sports_score",
          "sports_soccer",
          "sports_tennis",
          "sports_volleyball",
          "square_foot",
          "stacked_line_chart",
          "stairs",
          "star",
          "star_outline",
          "sticky_note_2",
          "stop",
          "stop_circle",
          "storm",
          "subject",
          "subtitles_off",
          "summarize",
          "swipe",
          "switch_account",
          "switch_left",
          "switch_right",
          "system_security_update",
          "system_security_update_good",
          "system_security_update_warning",
          "table_rows",
          "table_view",
          "tablet",
          "tap_and_play",
          "tapas",
          "task",
          "task_alt",
          "text_snippet",
          "theater_comedy",
          "tour",
          "transgender",
          "travel_explore",
          "tty",
          "tune",
          "tungsten",
          "two_wheeler",
          "umbrella",
          "unarchive",
          "unpublished",
          "update",
          "update_disabled",
          "upgrade",
          "upload",
          "upload_file",
          "verified",
          "video_camera_back",
          "video_camera_front",
          "video_settings",
          "video_stable",
          "view_in_ar",
          "volunteer_activism",
          "wallet_giftcard",
          "wallet_membership",
          "wallet_travel",
          "water",
          "water_damage",
          "waterfall_chart",
          "wb_shade",
          "wb_twighlight",
          "wc",
          "weekend",
          "wheelchair_pickup",
          "wifi",
          "wifi_calling",
          "wifi_lock",
          "wifi_protected_setup",
          "wifi_tethering",
          "wine_bar",
          "work",
          "workspaces",
          "wrong_location",
          "wysiwyg",
        ],
      },
      brands: {
        facebook: {
          name: "facebook",
          code: `<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z"/></svg>`,
        },
        twitter: {
          name: "twitter",
          code: `<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"/></svg>`,
        },
        instagram: {
          name: "instagram",
          code: `<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"/></svg>`,
        },
        tiktok: {
          name: "tiktok",
          code: `<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><path d="M448,209.91a210.06,210.06,0,0,1-122.77-39.25V349.38A162.55,162.55,0,1,1,185,188.31V278.2a74.62,74.62,0,1,0,52.23,71.18V0l88,0a121.18,121.18,0,0,0,1.86,22.17h0A122.18,122.18,0,0,0,381,102.39a121.43,121.43,0,0,0,67,20.14Z"/></svg>`,
        },
        linkedin: {
          name: "linkedin",
          code: `<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><path d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"/></svg>`,
        },
        discord: {
          name: "discord",
          code: `<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 640 512"><path d="M524.531,69.836a1.5,1.5,0,0,0-.764-.7A485.065,485.065,0,0,0,404.081,32.03a1.816,1.816,0,0,0-1.923.91,337.461,337.461,0,0,0-14.9,30.6,447.848,447.848,0,0,0-134.426,0,309.541,309.541,0,0,0-15.135-30.6,1.89,1.89,0,0,0-1.924-.91A483.689,483.689,0,0,0,116.085,69.137a1.712,1.712,0,0,0-.788.676C39.068,183.651,18.186,294.69,28.43,404.354a2.016,2.016,0,0,0,.765,1.375A487.666,487.666,0,0,0,176.02,479.918a1.9,1.9,0,0,0,2.063-.676A348.2,348.2,0,0,0,208.12,430.4a1.86,1.86,0,0,0-1.019-2.588,321.173,321.173,0,0,1-45.868-21.853,1.885,1.885,0,0,1-.185-3.126c3.082-2.309,6.166-4.711,9.109-7.137a1.819,1.819,0,0,1,1.9-.256c96.229,43.917,200.41,43.917,295.5,0a1.812,1.812,0,0,1,1.924.233c2.944,2.426,6.027,4.851,9.132,7.16a1.884,1.884,0,0,1-.162,3.126,301.407,301.407,0,0,1-45.89,21.83,1.875,1.875,0,0,0-1,2.611,391.055,391.055,0,0,0,30.014,48.815,1.864,1.864,0,0,0,2.063.7A486.048,486.048,0,0,0,610.7,405.729a1.882,1.882,0,0,0,.765-1.352C623.729,277.594,590.933,167.465,524.531,69.836ZM222.491,337.58c-28.972,0-52.844-26.587-52.844-59.239S193.056,219.1,222.491,219.1c29.665,0,53.306,26.82,52.843,59.239C275.334,310.993,251.924,337.58,222.491,337.58Zm195.38,0c-28.971,0-52.843-26.587-52.843-59.239S388.437,219.1,417.871,219.1c29.667,0,53.307,26.82,52.844,59.239C470.715,310.993,447.538,337.58,417.871,337.58Z"/></svg>`,
        },
        gitHub: {
          name: "gitHub",
          code: `<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 496 512"><path d="M165.9 397.4c0 2-2.3 3.6-5.2 3.6-3.3.3-5.6-1.3-5.6-3.6 0-2 2.3-3.6 5.2-3.6 3-.3 5.6 1.3 5.6 3.6zm-31.1-4.5c-.7 2 1.3 4.3 4.3 4.9 2.6 1 5.6 0 6.2-2s-1.3-4.3-4.3-5.2c-2.6-.7-5.5.3-6.2 2.3zm44.2-1.7c-2.9.7-4.9 2.6-4.6 4.9.3 2 2.9 3.3 5.9 2.6 2.9-.7 4.9-2.6 4.6-4.6-.3-1.9-3-3.2-5.9-2.9zM244.8 8C106.1 8 0 113.3 0 252c0 110.9 69.8 205.8 169.5 239.2 12.8 2.3 17.3-5.6 17.3-12.1 0-6.2-.3-40.4-.3-61.4 0 0-70 15-84.7-29.8 0 0-11.4-29.1-27.8-36.6 0 0-22.9-15.7 1.6-15.4 0 0 24.9 2 38.6 25.8 21.9 38.6 58.6 27.5 72.9 20.9 2.3-16 8.8-27.1 16-33.7-55.9-6.2-112.3-14.3-112.3-110.5 0-27.5 7.6-41.3 23.6-58.9-2.6-6.5-11.1-33.3 2.6-67.9 20.9-6.5 69 27 69 27 20-5.6 41.5-8.5 62.8-8.5s42.8 2.9 62.8 8.5c0 0 48.1-33.6 69-27 13.7 34.7 5.2 61.4 2.6 67.9 16 17.7 25.8 31.5 25.8 58.9 0 96.5-58.9 104.2-114.8 110.5 9.2 7.9 17 22.9 17 46.4 0 33.7-.3 75.4-.3 83.6 0 6.5 4.6 14.4 17.3 12.1C428.2 457.8 496 362.9 496 252 496 113.3 383.5 8 244.8 8zM97.2 352.9c-1.3 1-1 3.3.7 5.2 1.6 1.6 3.9 2.3 5.2 1 1.3-1 1-3.3-.7-5.2-1.6-1.6-3.9-2.3-5.2-1zm-10.8-8.1c-.7 1.3.3 2.9 2.3 3.9 1.6 1 3.6.7 4.3-.7.7-1.3-.3-2.9-2.3-3.9-2-.6-3.6-.3-4.3.7zm32.4 35.6c-1.6 1.3-1 4.3 1.3 6.2 2.3 2.3 5.2 2.6 6.5 1 1.3-1.3.7-4.3-1.3-6.2-2.2-2.3-5.2-2.6-6.5-1zm-11.4-14.7c-1.6 1-1.6 3.6 0 5.9 1.6 2.3 4.3 3.3 5.6 2.3 1.6-1.3 1.6-3.9 0-6.2-1.4-2.3-4-3.3-5.6-2z"/></svg>`,
        },
        youtube: {
          name: "youtube",
          code: `<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512"><path d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z"/></svg>`,
        },
        paypall: {
          name: "paypall",
          code: `<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512"><path d="M111.4 295.9c-3.5 19.2-17.4 108.7-21.5 134-.3 1.8-1 2.5-3 2.5H12.3c-7.6 0-13.1-6.6-12.1-13.9L58.8 46.6c1.5-9.6 10.1-16.9 20-16.9 152.3 0 165.1-3.7 204 11.4 60.1 23.3 65.6 79.5 44 140.3-21.5 62.6-72.5 89.5-140.1 90.3-43.4.7-69.5-7-75.3 24.2zM357.1 152c-1.8-1.3-2.5-1.8-3 1.3-2 11.4-5.1 22.5-8.8 33.6-39.9 113.8-150.5 103.9-204.5 103.9-6.1 0-10.1 3.3-10.9 9.4-22.6 140.4-27.1 169.7-27.1 169.7-1 7.1 3.5 12.9 10.6 12.9h63.5c8.6 0 15.7-6.3 17.4-14.9.7-5.4-1.1 6.1 14.4-91.3 4.6-22 14.3-19.7 29.3-19.7 71 0 126.4-28.8 142.9-112.3 6.5-34.8 4.6-71.4-23.8-92.6z"/></svg>`,
        },
        vimeo: {
          name: "vimeo",
          code: `<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><path d="M403.2 32H44.8C20.1 32 0 52.1 0 76.8v358.4C0 459.9 20.1 480 44.8 480h358.4c24.7 0 44.8-20.1 44.8-44.8V76.8c0-24.7-20.1-44.8-44.8-44.8zM377 180.8c-1.4 31.5-23.4 74.7-66 129.4-44 57.2-81.3 85.8-111.7 85.8-18.9 0-34.8-17.4-47.9-52.3-25.5-93.3-36.4-148-57.4-148-2.4 0-10.9 5.1-25.4 15.2l-15.2-19.6c37.3-32.8 72.9-69.2 95.2-71.2 25.2-2.4 40.7 14.8 46.5 51.7 20.7 131.2 29.9 151 67.6 91.6 13.5-21.4 20.8-37.7 21.8-48.9 3.5-33.2-25.9-30.9-45.8-22.4 15.9-52.1 46.3-77.4 91.2-76 33.3.9 49 22.5 47.1 64.7z"/></svg>`,
        },
        whatsapp: {
          name: "whatsapp",
          code: `<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><path d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z"/></svg>`,
        },
        telegram: {
          name: "telegram",
          code: `<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 496 512"><path d="M248,8C111.033,8,0,119.033,0,256S111.033,504,248,504,496,392.967,496,256,384.967,8,248,8ZM362.952,176.66c-3.732,39.215-19.881,134.378-28.1,178.3-3.476,18.584-10.322,24.816-16.948,25.425-14.4,1.326-25.338-9.517-39.287-18.661-21.827-14.308-34.158-23.215-55.346-37.177-24.485-16.135-8.612-25,5.342-39.5,3.652-3.793,67.107-61.51,68.335-66.746.153-.655.3-3.1-1.154-4.384s-3.59-.849-5.135-.5q-3.283.746-104.608,69.142-14.845,10.194-26.894,9.934c-8.855-.191-25.888-5.006-38.551-9.123-15.531-5.048-27.875-7.717-26.8-16.291q.84-6.7,18.45-13.7,108.446-47.248,144.628-62.3c68.872-28.647,83.183-33.623,92.511-33.789,2.052-.034,6.639.474,9.61,2.885a10.452,10.452,0,0,1,3.53,6.716A43.765,43.765,0,0,1,362.952,176.66Z"/></svg>`,
        },
      },
    };
  },
  computed: {
    filteredIcon() {
      var all_category = {
        Marcas: "brands",
        Ações: "communActions",
        Alertas: "alert",
        "Áudio e Vídeo": "audio",
        Comunicação: "comunicacao",
        Interfaces: "ui",
        Editor: "editor",
        Dispositivos: "aaa",
        Lugares: "bbb",
        Setas: "ccc",
        Android: "ddd",
        Avaliações: "ggg",
        Atividades: "hhh",
        Outros: "iii",
      };
      var name_category = all_category[this.categoria];
      if (this.categoria != "Tudo") {
        if (this.categoria != "Marcas") {
          return !this.search_icon.length
            ? this.icons[name_category]
            : this.icons[name_category].filter((item) =>
                item
                  .toLowerCase()
                  .includes(this.search_icon.toLowerCase().trim())
              );
        } else {
          return Object.values(this.brands).filter((item) =>
            item.name
              .toLowerCase()
              .includes(this.search_icon.toLowerCase().trim())
          );
        }
      } else {
        var palavraChave = this.search_icon.toLowerCase().trim();
        var categorias = this.icons;

        const iconesFiltrados = [];

        for (const categoria in categorias) {
          const iconesCategoria = categorias[categoria];

          const iconesFiltradosCategoria = iconesCategoria.filter((icone) =>
            icone.toLowerCase().includes(palavraChave)
          );

          iconesFiltrados.push(...iconesFiltradosCategoria);
        }

        return iconesFiltrados;
      }
    },
  },
  methods: {
    change(icon) {
      this.$emit("iconSelect", icon);
      this.$bvModal.hide("Modal-Icons-svg");
    },
  },
};
</script>

<style scoped lang="scss">
.fonte {
  width: 100%;
  text-align: right;
  margin-top: 25px;
  font-size: smaller;

  p {
    margin-bottom: 0;
  }
}

.all {
  padding: 25px 25px 0 25px;
  transition: all 0.5s;
  min-height: calc(100vh - 138px);
}

.icon-box {
  padding: 5px;
  border-radius: 10px;
  height: 35px;
  width: 35px;
  margin: 3px;
  background-color: #f3f3f3;
  position: relative;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}

.Pointersearch {
  position: absolute;
  top: 15px;
  left: 18px;
}

.container-pesquisa {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.text-filter {
  font-size: 12px;
}

.inputSearch {
  position: relative;
}

.inputSearch input {
  width: 244px;
}

.inputSearch input {
  width: 244px;
}

.input-busca {
  width: 100%;
  height: 45px !important;
  background: #ffffff;
  border-radius: 10px !important;
  padding-left: 45px !important;
  font-family: Montserrat;
  font-weight: normal;
  color: #81858e;
  font-size: 14px;
  transition: 0.5s;
  border: 1px solid #ededf0;
  margin-right: 25px;
}

.input-busca:hover,
.input-busca:focus {
  border-color: #ededf0 !important;
  font-weight: normal !important;
}

.icons-geral {
  margin-top: 45px;
}
</style>

<style lang="scss">
#multi-modal {
  .multiselect {
    .multiselect__tags {
      min-width: 200px !important;
    }
  }
}

#multi-modal .multiselect .multiselect__tags:focus,
#multi-modal .multiselect .multiselect__tags:hover {
  border-color: #ededf0 !important;
}

#multi-modal .multiselect .multiselect__tags .multiselect__single {
  font-size: 14px;
  color: #81858e;
}
</style>
