<template>
  <div id="SideRightTabs">
    <b-tabs pills justified small style="width: 100%">
      <!-- Geral -->
      <b-tab title="Geral" active style="max-height: 80vh">
        <div class="separador"></div>

        <!-- Formulário -->
        <div class="content">
          <label class="titulo">Formulário</label>
          <label class="subtitulo">Formulário referente</label>
          <div class="max-multi">
            <BaseSelect
              id="ajax"
              :selectModel="editaItem.propsData.form"
              :show-labels="false"
              placeholder="Selecione um formulário"
              :hide-selected="false"
              track-by="id"
              :allow-empty="false"
              :array="forms"
              trackname="title"
              :searchable="true"
              noResult="Nenhum formulário encontrado."
              noOptions="Pesquise pelo formulário"
              selectLabel=""
              deselectLabel=""
              selectedLabel=""
              @change="(editaItem.propsData.form = $event), setaForm($event)"
              @callback="getForms($event)"
            >
            </BaseSelect>
          </div>
          <div class="space"></div>

          <!-- Rodapé -->
          <div>
            <label class="subtitulo">Rodapé</label>
            <input
              :disabled="
                !editaItem.propsData.form && !editaItem.propsData.form.id
              "
              placeholder="Política de privacidade"
              @input="debounce('adicionais')"
              type="text"
              name="msg"
              id="msg"
              v-model="editaItem.propsData.footer"
            />
            <div class="space"></div>
          </div>

          <!-- Link do rodapé -->
          <div v-if="editaItem.propsData.footer">
            <label class="subtitulo">Link do rodapé</label>
            <input
              placeholder="https://seulink"
              :disabled="
                !editaItem.propsData.form && !editaItem.propsData.form.id
              "
              @input="debounce('adicionais')"
              type="text"
              name="msg"
              id="msg"
              v-model="editaItem.propsData.footer_link"
            />
          </div>
        </div>
        <div class="separador"></div>

        <!-- Agradecimento -->
        <div class="content">
          <label class="titulo">Agradecimento</label>
          <label class="subtitulo">Tipos</label>
          <multiselect
            v-model="editaItem.propsData.opcao_agradecimento"
            :options="thankYouOptions"
            :searchable="false"
            label="text"
            track-by="value"
            :show-labels="false"
            placeholder="Selecione um tipo de agradecimento"
            :hide-selected="true"
            @select="saveOpt('page')"
          ></multiselect>
          <div class="space"></div>

          <!-- Página de agradecimento -->
          <div
            v-if="
              editaItem.propsData.opcao_agradecimento.value === 'thank_you_page'
            "
          >
            <label class="subtitulo">Página de agradecimento</label>
            <div class="max-multi">
              <BaseSelect
                id="ajax"
                :selectModel="editaItem.propsData.thank_you_page"
                :show-labels="false"
                placeholder="Página de agradecimentos"
                :hide-selected="true"
                track-by="id"
                :allow-empty="false"
                :array="pages"
                trackname="title"
                :searchable="false"
                noResult="Nenhum página encontrada."
                selectLabel=""
                deselectLabel=""
                selectedLabel=""
                @change="
                  (editaItem.propsData.thank_you_page = $event), saveOpt('page')
                "
                @callback="getPages($event)"
              >
              </BaseSelect>
            </div>
            <div v-if="editaItem.propsData.thank_you_page && editaItem.propsData.thank_you_page.title && !editaItem.propsData.thank_you_page.id">
              <div class="space"></div>
              <label class="subtitulo">Link externo</label>
              <input @input="saveOpt('page')" type="text" v-model="editaItem.propsData.thank_you_page.path_name">
            </div>
          </div>

          <!-- Pop up -->
          <div>
            <label class="subtitulo">Mensagem mostrada após o cadastro</label>
            <textarea
              @input="debounce('popup')"
              :disabled="
                !editaItem.propsData.form && !editaItem.propsData.form.id
              "
              placeholder="Muito obrigada por se cadastrar!..."
              type="text"
              name="msg"
              id="msg"
              v-model="editaItem.propsData.success_mensage"
            />
            <div class="space"></div>
          </div>

          <!-- Mensagem whatsapp -->
          <div
            v-if="editaItem.propsData.opcao_agradecimento.value === 'whatsapp'"
          >
            <label class="subtitulo">Número de telefone de origem</label>
            <input
              @input="debounce('whatsapp')"
              :disabled="
                !editaItem.propsData.form && !editaItem.propsData.form.id
              "
              type="text"
              v-mask="['+##(##) ####-####', '+##(##) #####-####']"
              name="msg"
              id="msg"
              placeholder="Apenas números"
              v-model="editaItem.propsData.phone"
            />
            <div class="space"></div>
            <label class="subtitulo">Mensagem para ser enviada</label>
            <textarea
              placeholder="Olá! Recebi o seu cadastro na página..."
              @input="debounce('whatsapp')"
              :disabled="
                !editaItem.propsData.form && !editaItem.propsData.form.id
              "
              type="text"
              name="msg"
              id="msg"
              v-model="editaItem.propsData.whatsapp_mensage"
            />
          </div>
        </div>
        <div class="separador"></div>

        <!-- Botão -->
        <div class="content">
          <label class="titulo">Botão</label>

          <label class="subtitulo">Texto do botão</label>
          <input
            type="text"
            name="btn-content"
            id="btn-content"
            v-model="editaItem.propsData.content_button"
          />
        </div>
        <div class="separador"></div>
      </b-tab>

      <!-- Estilo -->
      <b-tab title="Estilo">
        <div class="separador"></div>
        <!-- Cores -->
        <div class="content">
          <label class="titulo">Cores</label>
          <label class="subtitulo">Formulário</label>
          <div class="half">
            <LvColorpicker
            id="LvColorpicker"
            v-model="editaItem.propsData.styles.back_form_content"
            :colors="palette"
            ></LvColorpicker>   
            <LvColorpicker
              v-model="editaItem.propsData.styles.input_back"
              id="LvColorpicker"
              :colors="palette"
            ></LvColorpicker>
          </div>
          <div class="space" />
          <label class="subtitulo">Botões</label>
          <div class="half">
            <LvColorpicker
              id="LvColorpicker"
              v-model="editaItem.propsData.styles.color_button"
              :colors="palette"
            ></LvColorpicker>
            <LvColorpicker
              id="LvColorpicker"
              v-model="editaItem.propsData.styles.back_content"
              :colors="palette"
            ></LvColorpicker>
          </div>
          <div class="space" />
          <label class="sub">Bordas</label>
            <LvColorpicker
              id="LvColorpicker"
              v-model="editaItem.propsData.styles.form_border_color"
              :colors="palette"
            ></LvColorpicker>
        </div>
        <div class="separador"></div>

        <!-- Formulário -->
        <div class="content">
          <div class="und_form">
            <div class="label-icon">
              <label class="titulo">Formulário</label>
              <img
                src="@/assets/editor/globe.svg"
                alt="monitor"
                style="filter: invert(50%) !important"
                @click="nextIcon('desktop'), (global = !global)"
                v-if="global"
              />
              <img
                src="@/assets/editor/monitor.svg"
                alt="monitor"
                style="filter: invert(50%) !important"
                @click="nextIcon('tablet')"
                v-if="typeView === 'desktop' && !global"
              />
              <img
                src="@/assets/editor/tablet.svg"
                alt="tablet"
                style="filter: invert(50%) !important"
                @click="nextIcon('mobile')"
                v-if="typeView === 'tablet' && !global"
              />
              <img
                src="@/assets/editor/phone.svg"
                alt="phone"
                style="filter: invert(50%) !important"
                @click="nextIcon('desktop'), (global = !global)"
                v-if="typeView === 'mobile' && !global"
              />
            </div>
          </div>
          <div>
            <div class="space"></div>
            <div class="label-range">
              <label class="subtitulo">Altura</label>
              <input type="number" v-model="editaItem.propsData.size[typeView].height_form" />
            </div>
            <b-form-input v-model="editaItem.propsData.size[typeView].height_form" type="range" min="200" max="1200"
              step="10"></b-form-input>
          </div>

          <!-- Espaçamento entre letras -->
          <div>
            <div class="label-range">
              <label class="subtitulo">Tamanho da fonte</label>
              <input type="number" v-model="editaItem.propsData.size[typeView].font_size" />
            </div>
            <b-form-input v-model="editaItem.propsData.size[typeView].font_size" type="range" min="12" max="36"
              step="1"></b-form-input>
          </div>
          <label class="subtitulo">Fonte</label>
          <multiselect
            v-model="editaItem.propsData.styles.font_family"
            :options="fontOptions"
            :allow-empty="false"
            :hide-selected="true"
            :show-labels="false"
            placeholder="Fonte"
            class="max-multi"
          ></multiselect>
          <!-- Posição -->
          <div>
            <div class="space"></div>
            <label class="subtitulo">Posicionamento</label>
            <div style="display: flex; width: 50%; transition: all 0.3s">
              <div class="select-align">
                <button
                  :class="{
                    'selected-align':
                      editaItem.propsData.alignment[typeView]
                        .justify_vertical == 'start',
                  }"
                  @click="globalJustify('start', 'justify_vertical')"
                >
                  <img src="@/assets/editor/align-top.svg" />
                </button>
                <button
                  :class="{
                    'selected-align':
                      editaItem.propsData.alignment[typeView]
                        .justify_vertical == 'center',
                  }"
                  @click="globalJustify('center', 'justify_vertical')"
                >
                  <img src="@/assets/editor/align-center-vertical.svg" />
                </button>
                <button
                  :class="{
                    'selected-align':
                      editaItem.propsData.alignment[typeView]
                        .justify_vertical == 'end',
                  }"
                  @click="globalJustify('end', 'justify_vertical')"
                >
                  <img src="@/assets/editor/align-bottom.svg" />
                </button>
              </div>
            </div>
            <div class="space"></div>
          </div>
        </div>
        <div class="separador"></div>

        <!-- Bordas -->
        <div class="content">
          <label class="titulo">Bordas</label>
          <div>
            <div class="label-range">
              <label class="subtitulo">Aredondar cantos</label>
              <input type="number" v-model="editaItem.propsData.styles.border_radius" />
            </div>
            <b-form-input v-model="editaItem.propsData.styles.border_radius" type="range" min="0" max="100"
              step="1"></b-form-input>
          </div>
          <div>
            <div class="space"></div>
            <div class="label-range">
              <label class="subtitulo">Espessura da borda</label>
              <input
                type="number"
                v-model="editaItem.propsData.styles.form_border_size"
              />
            </div>
            <b-form-input
              v-model="editaItem.propsData.styles.form_border_size"
              type="range"
              min="0"
              max="10"
              step="1"
            ></b-form-input>
          </div>
        </div>
      </b-tab>

      <!-- Avançado -->
      <b-tab title="Avançado">
        <!-- Espaçamento formulário -->
        <div class="separador"></div>
          <advancedBasic
            :spacing="editaItem.propsData.spacing" 
            :visibility="editaItem.propsData.visibility" 
            :animation="editaItem.propsColumn"           
            :typeView="typeView"
            :global="global"
            :hideAttributes="true"
            @toggleGlobal="global = !global"
            @nextIcon="nextIcon"
            @update:spacing="editaItem.propsData.spacing = $event"
            @update:visibility="editaItem.propsData.visibility = $event"
            @update:animation="editaItem.propsColumn = $event"      
          ></advancedBasic>
        <div class="separador"></div>
      </b-tab>
    </b-tabs>
    <ModalIcons @iconSelect="iconSelect"></ModalIcons>
  </div>
</template>

<script>
import advancedBasic from "../EditComponents/advancedBasic.vue";
import LvColorpicker from "lightvue/color-picker";
import Multiselect from "vue-multiselect";
import FormService from "@/services/resources/FormService";
const serviceForm = FormService.build();
import PageListService from "@/services/resources/PageListService";
const PaginaListService = PageListService.build();
import ModalIcons from "@/components/Editor/ModalSvg";

export default {
  props: ["editaItem", "typeView", "fontOptions", "palette"],
  components: {
    advancedBasic,
    LvColorpicker: LvColorpicker,
    Multiselect,
    ModalIcons,
  },
  data() {
    return {
      stop: false,
      stop2: false,
      forms: [],
      pages: [],
      form_linked: true,  
      type: "none",
      global: false,
      thankYouOptions: [
        { value: "thank_you_page", text: "Página" },
        { value: "popup", text: "Apenas confirmação" },
        { value: "whatsapp", text: "Mensagem no whatsapp" }
      ],
    };
  },
  watch: {
  },
  computed: {
    inputStyle() {
      return {
        color: this.cor,
        backgroundColor: this.fundo,
        "::placeholder": {
          color: this.cor,
        },
        ":hover": {
          color: this.hoverCor,
          backgroundColor: this.hoverFundo,
        },
      };
    },
  },
  methods: {
    nextIcon(type) {
      this.$emit("attIconView", type);
    },
    getForms(page = 1) {
      if (this.stop) {
        return;
      }
      var data = {
        campaign_id: this.$route.params.campaign_id,
        page: page,
        status: "active",
      };
      serviceForm.search(data).then((resp) => {
        if (resp.data.length < resp.per_page) {
          this.stop = true;
        }
        this.forms = this.forms.concat(resp.data);
      });
    },
    getPages(page = 1) {
      if (this.stop2) {
        return;
      }
      var data = {
        campaign_id: this.$route.params.campaign_id,
        page: page,
        status: "save",
      };

      PaginaListService.create(data).then((resp) => {
        if (resp.data.length < resp.per_page) {
          this.stop2 = true;
        }
        var pages = resp.data;
        pages.map((page) => {
          page.path_name = `https://${this.$route.params.dynamicRoute}.gdigital.com.br/${page.path_name}`;
        });
        if (page == 1) {
          pages.unshift({id: 0, title: 'Página externa', path_name: ''})
        }
        this.pages = this.pages.concat(pages);
      });
    },
    setaForm(e) {
      e.fields.push(
        {
          id: 0,
          name_field: "hidden",
          form_field: `<input type="hidden" id="tenant_id" value="${this.$store.getters.user.user.tenant_id}">`,
        },
        {
          id: 0,
          name_field: "hidden",
          form_field: `<input type="hidden" id="page_id" value="${this.$route.params.page_id}">`,
        }
      );
      this.editaItem.propsData.fields = e.fields;
    },
    globalJustify(value, place) {
      let props = this.editaItem.propsData.alignment;
      props[this.typeView][place] = value;
      if (this.global && this.typeView == "desktop") {
        props[this.typeView][place] = value;
        props[this.typeView][place] = value;
      }
    },    
    iconSelect(item) {
      this.editaItem.propsData.icon_selected = item;
    },
    saveOpt(opt) {
      if (!this.editaItem.propsData.form && !this.editaItem.propsData.form.id) {
        this.$grToast.toast("Por favor, selecione um formulário", {
          title: "Editor",
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
        this.editaItem.propsData.thank_you_page = {
          id: null,
          title: "Selecione a página de agradecimento",
        };
        return;
      }
    },
    nextIcon(type) {
      this.$emit("attIconView", type);
    },  
    debounce: _.debounce(function (opt) {
      this.saveOpt(opt);
    }, 500),
  },
  created() {
    if (this.typeView == "desktop") {
      this.global = true;
    }
  },
};
</script>

<style lang="scss">
// Está fazendo interferência com a classe content usada na side right
// .content {
//   padding: 10px 30px 35px 30px !important;
// }
.button-collapse-alpha {
  width: 100%;
  border-radius: 8px;
  background-color: var(--greenn) 25;
  border: none;
  margin-bottom: 4px;
  height: 40px;
}
.und_form {
  position: relative;
  .und {
    position: absolute;
    right: 8px;
    top: 43px;
    font-size: 20px;
    font-weight: 600;
    color: #ccc;
    cursor: pointer;
  }
  .und2 {
    top: 7px !important;
  }
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: var(--greenn);
}

input[type="range"]::-webkit-slider-thumb {
  background: var(--greenn) !important;
}

button {
  border: none;
}

button:disabled,
.disabled {
  background-color: transparent !important;
}

.more-options {
  padding: 10px;
  background-color: transparent;
  width: 100%;
}

#range-1::-webkit-slider-thumb,
#range-1::-moz-range-thumb {
  background-color: var(--greenn) !important;
}

*:disabled {
  color: #dadada !important;
}

.custom-control-input:focus ~ .custom-control-label::before {
  -webkit-box-shadow: 0 0 0 0.2rem var(--greenn) 50 !important;
  box-shadow: 0 0 0 0.2rem var(--greenn) 50 !important;
}

.btn-secondary:focus,
.btn-secondary.focus {
  color: rgb(0, 0, 0);
  background-color: transparent !important;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0) !important;
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0) !important;
}

.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  background-color: #545b6200 !important;
}

input:disabled {
  background-color: transparent !important;
}

.alinhamento-2 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.alinhamento {
  display: flex;
  align-items: center;
  justify-content: center;
}

.item-1,
.item-2,
.item-3 {
  input {
    font-size: 0.8rem !important;
    line-height: 1.25rem !important;
    outline: 2px solid transparent !important;
    outline-offset: 2px !important;
    text-align: center !important;
    width: 2rem !important;
    padding: 0 !important;
    margin: 0 !important;
    height: 1rem !important;
    border: none !important;
  }
}

.container-spacing {
  display: flex;
  justify-content: center;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.labelOff {
  color: rgb(185, 185, 185) !important;
}
input {
  width: 100%;
}
</style>
