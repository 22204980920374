let propsColumn = {
  custom: {
    c_class: '',
    c_id: '',
  },
  ////// TAMANHO
  width: 100,
  width_division: 100,
  width_equalizar_und: "Não",
  height: {
    desktop: { height: "100", height_und: "px" },
    tablet: { height: "100", height_und: "px" },
    mobile: { height: "100", height_und: "px" }
  },
  alignment: 'center',
  ///// BACKGRODUND
  backgd_und: "Nenhum",
  backgd: "background:#00000000",
  backgd_solid: "#FFFFFF00",
  backgd_gradient_type: "linear-gradient",
  backgd_gradient_deg: "0",
  backgd_gradient_c1: "#FFFFFF",
  backgd_gradient_c2: "#FFFFFF",
  backgd_image:
    "https://gdigital.s3.amazonaws.com/gdigital/1/background.webp",
  backgd_image_repeat_und: "Nenhuma",
  backgd_image_repeat_type: "no-repeat",
  backgd_image_position_und: "Centralizado",
  backgd_image_position_type: "center center",
  backgd_anexacao_und: "Deslizar",
  backgd_anexacao: "scroll",
  backgd_escaca_und: "Preencher",
  backgd_escaca_type: "cover",
  ///// SOBREPOSIÇÂO DE BACKGRODUND
  backgd_overlap_und: "Nenhuma",
  backgd_overlap: "",
  backgd_overlap_cor: "#00000088",
  backgd_overlap_type: "linear-gradient",
  backgd_overlap_deg: "0",
  backgd_overlap_c1: "#00000088",
  backgd_overlap_c2: "#ffffff88",
  ////// BORDER
  border_default: true,
  border_und: "Nenhuma",
  border_type: "",
  border: "border:none",
  border_color: "#000000",
  border_px_top: 2,
  border_px_right: 2,
  border_px_bottom: 2,
  border_px_left: 2,
  border_radius_top: 0,
  border_radius_right: 0,
  border_radius_bottom: 0,
  border_radius_left: 0,
  border_shadow_color: "#000000",
  border_shadow_x: 0,
  border_shadow_y: 0,
  border_shadow_blur: 0,
  border_shadow_spread: 0,
  ////// ESPAÇAMENTO
  margin: "margin:0px",
  padding: "padding:0px",
  spacing: {
    desktop: {
      margin_top: 0,
      margin_right: 0,
      margin_bottom: 0,
      margin_left: 0,
      padding_top: 0,
      padding_right: 0,
      padding_bottom: 0,
      padding_left: 0,
    },
    tablet: {
      margin_top: 0,
      margin_right: 0,
      margin_bottom: 0,
      margin_left: 0,
      padding_top: 0,
      padding_right: 0,
      padding_bottom: 0,
      padding_left: 0,
    },
    mobile: {
      margin_top: 0,
      margin_right: 0,
      margin_bottom: 0,
      margin_left: 0,
      padding_top: 0,
      padding_right: 0,
      padding_bottom: 0,
      padding_left: 0,
    },
  },
  ////// ANIMAÇÕES
  attribute_und: "Nenhuma",
  attribute_animation: "",
  duration: 1,
  delay: 0,
  ////// VISIBILIDADE
  visibility: {
    tablet: true,
    phone: true,
    monitor: true,
  },
};
export default propsColumn;
