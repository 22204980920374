<template>
  <div id="SideRightTabs">
    <b-card no-body>
      <b-tabs pills justified small style="width: 100%">
        <b-tab title="Conteúdo" active>
          <!-- Layout do card de depoimento -->
          <div class="separador"></div>
          <div class="content">
            <label class="titulo">Layout</label>
            <div style="display: flex; width: 100%; gap: 10px">
              <div style="display: flex; width: 100%; transition: all 0.3s">
                <label class="subtitulo">Foto</label>
                <b-form-checkbox style="margin-top: -5px; margin-left: auto; margin-right: 0"
                  v-model="editaItem.propsData.styles.hasPicture" size="lg" switch>
                </b-form-checkbox>
              </div>
              <div style="display: flex; width: 100%; transition: all 0.3s">
                <label class="subtitulo">Estrelas</label>
                <b-form-checkbox style="margin-top: -5px; margin-left: auto; margin-right: 0;"
                  v-model="editaItem.propsData.styles.hasStar" size="lg" switch id="input-max-width">
                </b-form-checkbox>
              </div>
            </div>
            <div style="display: flex; width: 100%; gap: 10px">
              <!-- <div style="display: flex; width: 100%; transition: all 0.3s; margin-top: 5px;">
                <label class="subtitulo">Carrossel</label>
                <b-form-checkbox style="margin-top: -5px; margin-left: auto; margin-right: 0"
                  v-model="editaItem.propsData.styles.isCarousel" size="lg" switch>
                </b-form-checkbox>
              </div> -->
              <div style="display: flex; width: 100%; transition: all 0.3s">
                <div class="select-align">
                  <button :class="{ 'selected-align': editaItem.propsData.styles.align == 'left' }"
                    @click="editaItem.propsData.styles.align = 'left'"><img
                      src="@/assets/editor/align-left.svg" /></button>
                  <button :class="{ 'selected-align': editaItem.propsData.styles.align == 'center' }"
                    @click="editaItem.propsData.styles.align = 'center'"><img
                      src="@/assets/editor/align-center.svg" /></button>
                  <button :class="{ 'selected-align': editaItem.propsData.styles.align == 'right' }" style="z-index: 2"
                    @click="editaItem.propsData.styles.align = 'right'"><img
                      src="@/assets/editor/align-right.svg" /></button>
                </div>
              </div>
            </div>

            <div class="card-type" :class="{
              'card-selected': editaItem.propsData.styles.layout == 1,
              'card-center': editaItem.propsData.styles.align == 'center', 'card-right': editaItem.propsData.styles.align == 'right'
            }" @click="editaItem.propsData.styles.layout = 1">
              <span v-if="editaItem.propsData.styles.hasPicture" class="material-icons card-icon">account_circle</span>
              <p class="card-title">
                Nome do usuário
              </p>
              <div v-if="editaItem.propsData.styles.hasStar">
                <span class="material-icons card-stars" v-for="n in 5" :key="n">
                  star
                </span>
              </div>
              <p class="card-content">
                Exemplo de depoimento que pode ser adicionado à página para dar mais credibilidade ao produto ofertado. É
                recomendado que as avaliações sejam verídicas.
              </p>
            </div>

            <div class="card-type card-2"
              :class="{ 'card-selected': editaItem.propsData.styles.layout == 2, 'card-inverse': editaItem.propsData.styles.align == 'right', 'card-right': editaItem.propsData.styles.align == 'right', 'card-center': editaItem.propsData.styles.align == 'center', }"
              @click="editaItem.propsData.styles.layout = 2">
              <div v-if="editaItem.propsData.styles.hasPicture">
                <div>
                  <span class="material-icons card-icon">account_circle</span>
                </div>
                <div v-if="editaItem.propsData.styles.hasStar">
                  <span class="material-icons card-stars" v-for="n in 4" :key="n">
                    star
                  </span>
                </div>
              </div>
              <div style="margin-top: -55px; margin-right: -10px;">
                <span class="material-icons"
                  :style="editaItem.propsData.styles.align == 'right' ? 'color: #838383;' : 'transform: rotate(180deg); color: #838383;'">
                  format_quote
                </span>
              </div>
              <div>
                <p class="card-content">
                  Exemplo de depoimento que pode ser adicionado à página para dar mais credibilidade ao produto ofertado.
                  É recomendado que as avaliações sejam verídicas.
                </p>
                <div v-if="editaItem.propsData.styles.hasStar && !editaItem.propsData.styles.hasPicture">
                  <span class="material-icons card-stars" v-for="n in 4" :key="n">
                    star
                  </span>
                </div>
                <p class="card-name">
                  - Nome do usuário
                </p>
              </div>
            </div>
          </div>

          <!-- Depoimentos -->
          <div class="separador"></div>
          <div class="content">
            <div class="container-links">
              <div class="half">
                <label class="titulo">Depoimentos</label>
                <div class="contain-buttons" style="margin-top: -15px">
                  <button class="btn button-add-produto" @click="addImg"></button>
                </div>
              </div>
            </div>
            <div v-for="(slide, idx) in editaItem.propsData.slides" :key="idx">
              <div class="p-2" style="position: relative; display: flex; justify-content: space-between;">
                <div class="separador" style="position:absolute; top: 17px; left: -3px;"></div>
                <label class="subtitulo component-group-title">Depoimento {{ idx + 1 }}</label>
                <div class="image-control2">
                  <img :class="{ 'disabled-arrow': idx == 0 }" class="first-arrow" src="@/assets/editor/chevron-down.svg"
                    @click="idx == 0 ? '' : moveUp(idx)" title="Mover para cima" />
                  <button :disabled="editaItem.propsData.slides.length == 1" class="button-remove-produto"
                    @click="removeImgIndex(idx)"></button>
                  <img :class="{ 'disabled-arrow': idx + 1 == editaItem.propsData.slides.length }" class="last-arrow"
                    src="@/assets/editor/chevron-down.svg"
                    @click="idx + 1 == editaItem.propsData.slides.length ? '' : moveDown(idx)" title="Mover para baixo" />
                </div>
              </div>
              <div class="select-image" v-if="editaItem.propsData.styles.hasPicture">
                <div @click="openModal('Upload-Editor', idx)">
                  <img :src="slide.imagem ? slide.imagem : 'https://gdigital.s3.amazonaws.com/gdigital/8/imagem-vazia%20%28200%C2%A0%C3%97%C2%A0200%C2%A0px%29.webp'" alt="Imagem preview" style="border-radius: 1000px;">
                </div>
                <div>
                  <input type="text" v-model="slide.imagem">
                  <span @click="openModal('Upload-Editor', idx)">{{ slide.imagem ? 'Alterar imagem' : 'Selecionar imagem'}}</span>
                </div>
              </div>
              <div v-if="editaItem.propsData.styles.hasPicture" class="space"></div>
              <input type="text" v-model="slide.name" placeholder="Nome do usuário">
              <div class="space"></div>
              <div class="rating" v-if="editaItem.propsData.styles.hasStar">
                <span class="material-icons" v-for="n in 5" :key="n" style="position: relative; font-size: 24px; cursor: pointer;"
                  @click.self="slide.rating = n">
                  {{ slide.rating >= n ? 'star' : (slide.rating > n - 1) ? 'star_half' : 'star_border' }}
                  <div style="width: 50%; height: 100%; position: absolute; top: 0; left: 0;"
                    @click.self="slide.rating = n - 0.5"></div>
                </span>
              </div>
              <div class="space" v-if="editaItem.propsData.styles.hasStar"></div>
              <textarea placeholder="Depoimento" v-model="slide.text" name="Depoimento" id="" cols="30"
                rows="5"></textarea>
              <div class="space"></div>
            </div>
          </div>
          <div class="separador"></div>
        </b-tab>

        <b-tab title="Estilo">
          <div class="separador"></div>

          <!-- Fundo -->
          <div class="content">
            <!-- Tipo de fundo -->
            <label class="titulo">Fundo</label>
            <multiselect 
              v-model="editaItem.propsData.styles.card.background.type" 
              :options="['Cor sólida', 'Gradiente']"
              :searchable="false" 
              :show-labels="false" 
              placeholder="Fonte" 
              :hide-selected="true" 
              :allow-empty="false">
            </multiselect>
            <div class="space"></div>

            <!-- Fundo Gradiente -->
            <div class="half" v-if="editaItem.propsData.styles.card.background.type == 'Gradiente'">
              <div>
                <label class="subtitulo"> Cor 1 </label>
                <LvColorpicker id="LvColorpicker" v-model="editaItem.propsData.styles.card.background.grad1"
                  label="Cor do texto" :colors="palette"></LvColorpicker>
              </div>
              <div>
                <label class="subtitulo"> Cor 2 </label>
                <LvColorpicker id="LvColorpicker" v-model="editaItem.propsData.styles.card.background.grad2"
                  label="Cor do texto" :colors="palette"></LvColorpicker>
              </div>
            </div>

            <div v-if="editaItem.propsData.styles.card.background.type == 'Gradiente'">
              <div class="space"></div>
              <div class="label-range">
                <label class="subtitulo">Angulo do gradiente</label>
                <input type="number" v-model="editaItem.propsData.styles.card.background.gradAngle" />
              </div>
              <b-form-input v-model="editaItem.propsData.styles.card.background.gradAngle" type="range" min="0" max="360"
                step="1"></b-form-input>
            </div>

            <!-- Fundo sólido -->
            <div v-else>
              <label class="subtitulo"> Cor do fundo</label>
              <LvColorpicker id="LvColorpicker" v-model="editaItem.propsData.styles.card.background.color"
                label="Cor do texto" :colors="palette"></LvColorpicker>
              <div class="space"></div>
              <label class="subtitulo"> Cor do fundo ao passar o mouse</label>
              <LvColorpicker id="LvColorpicker" v-model="editaItem.propsData.styles.card.background.hover_color"
                label="Cor do texto" :colors="palette"></LvColorpicker>
            </div>
            <div class="space"></div>
            <label class="sub">Posicionamento</label>
            <div style="display: flex; width: 100%; gap: 10px">
              <div style="display: flex; width: 50%; transition: all 0.3s">
                <div class="select-align">
                  <button :class="{ 'selected-align': editaItem.propsData.alignment[typeView].justify_vertical == 'start' }"
                    @click="globalJustify('start', 'justify_vertical')"><img
                      src="@/assets/editor/align-top.svg" /></button>
                  <button :class="{ 'selected-align': editaItem.propsData.alignment[typeView].justify_vertical == 'center' }"
                    @click="globalJustify('center', 'justify_vertical')"><img
                      src="@/assets/editor/align-center-vertical.svg" /></button>
                  <button :class="{ 'selected-align': editaItem.propsData.alignment[typeView].justify_vertical == 'end' }"
                    @click="globalJustify('end', 'justify_vertical')"><img
                      src="@/assets/editor/align-bottom.svg" /></button>
                </div>
              </div>
            </div>
          </div>
          <div class="separador"></div>

          <!-- Mídia -->
          <div v-if="editaItem.propsData.styles.hasPicture || editaItem.propsData.styles.hasStar" class="content">
            <label class="titulo">Mídias</label>
            <div v-if="editaItem.propsData.styles.hasPicture">
              <div class="label-range">
                <div class="label-icon">
                  <label class="subtitulo">Tamanho da foto (px)</label>
                  <div>
                    <img src="@/assets/editor/globe.svg" alt="monitor" style="filter: invert(50%) !important"
                      @click="nextIcon('desktop'), (global = !global)" v-if="global" />
                    <img src="@/assets/editor/monitor.svg" alt="monitor" style="filter: invert(50%) !important"
                      @click="nextIcon('tablet')" v-if="typeView === 'desktop' && !global" />
                    <img src="@/assets/editor/tablet.svg" alt="tablet" style="filter: invert(50%) !important"
                      @click="nextIcon('mobile')" v-if="typeView === 'tablet' && !global" />
                    <img src="@/assets/editor/phone.svg" alt="phone" style="filter: invert(50%) !important"
                      @click="nextIcon('desktop'), (global = !global)" v-if="typeView === 'mobile' && !global" />
                  </div>
                </div>
                <input
                  type="number"
                  v-model="editaItem.propsData.styles.picture.size[typeView]"
                />
              </div>
              <b-form-input v-model="editaItem.propsData.styles.picture.size[typeView]" type="range" min="20" max="350"
                step="10"></b-form-input>
            </div>
            <div v-if="editaItem.propsData.styles.hasStar">
              <div>
                <div class="label-range">
                  <div class="label-icon">
                    <label class="subtitulo">Tamanho das estrelas</label>
                    <div>
                      <img src="@/assets/editor/globe.svg" alt="monitor" style="filter: invert(50%) !important"
                        @click="nextIcon('desktop'), (global = !global)" v-if="global" />
                      <img src="@/assets/editor/monitor.svg" alt="monitor" style="filter: invert(50%) !important"
                        @click="nextIcon('tablet')" v-if="typeView === 'desktop' && !global" />
                      <img src="@/assets/editor/tablet.svg" alt="tablet" style="filter: invert(50%) !important"
                        @click="nextIcon('mobile')" v-if="typeView === 'tablet' && !global" />
                      <img src="@/assets/editor/phone.svg" alt="phone" style="filter: invert(50%) !important"
                        @click="nextIcon('desktop'), (global = !global)" v-if="typeView === 'mobile' && !global" />
                    </div>
                  </div>
                  <input
                    type="number"
                    v-model="editaItem.propsData.styles.stars.size[typeView]"
                  />
                </div>
                <b-form-input v-model="editaItem.propsData.styles.stars.size[typeView]" type="range" min="4" max="60"
                  step="2"></b-form-input>
              </div>

              <label class="subtitulo"> Cor das estrelas</label>
              <LvColorpicker id="LvColorpicker" v-model="editaItem.propsData.styles.stars.color"
                label="Cor do texto" :colors="palette"></LvColorpicker>
            </div>
          </div>
          <div class="separador"></div>

          <!-- Textos -->
          <div class="content">
            <label class="titulo">Textos</label>

            <!-- Cores -->
            <div class="half">
              <div>
                <label class="subtitulo"> Cor do texto </label>
                <LvColorpicker id="LvColorpicker" v-model="editaItem.propsData.styles.text.color" label="Cor do texto"
                  :colors="palette"></LvColorpicker>
              </div>
              <div>
                <label class="subtitulo"> Cor do nome </label>
                <LvColorpicker id="LvColorpicker" v-model="editaItem.propsData.styles.name.color" label="Cor do texto"
                  :colors="palette"></LvColorpicker>
              </div>
            </div>

            <!-- Tamanho do texto-->
            <div class="space"></div>
            <div>
              <div class="label-icon">
                <label class="subtitulo" style="margin: 5px 0 0 0 !important;">Tamanho do texto</label>
                <div>
                  <img src="@/assets/editor/globe.svg" alt="monitor" style="filter: invert(50%) !important; margin: 0"
                    @click="nextIcon('desktop'), (global = !global)" v-if="global" />
                  <img src="@/assets/editor/monitor.svg" alt="monitor" style="filter: invert(50%) !important; margin: 0"
                    @click="nextIcon('tablet')" v-if="typeView === 'desktop' && !global" />
                  <img src="@/assets/editor/tablet.svg" alt="tablet" style="filter: invert(50%) !important; margin: 0"
                    @click="nextIcon('mobile')" v-if="typeView === 'tablet' && !global" />
                  <img src="@/assets/editor/phone.svg" alt="phone" style="filter: invert(50%) !important; margin: 0"
                    @click="nextIcon('desktop'), (global = !global)" v-if="typeView === 'mobile' && !global" />
                </div>
              </div>
              <div class="mt-2" style="
                    display: flex;
                    gap: 10px;
                    width: 100%;
                    gap: 10px;
                    margin-bottom: -1rem;
                  ">
                <b-button-group>
                  <b-button 
                    :disabled="editaItem.propsData.styles.text.size[typeView] == 0
                    " size="sm" style="
                        height: min-content !important;
                        padding: 10.5px 7px !important;
                    "
                    @click="editaItem.propsData.styles.text.size[typeView]--, globalInput(editaItem.propsData.styles.text.size[typeView], 'styles', 'size')"><img
                    src="@/assets/editor/minus3.svg" alt="align" 
                  /></b-button>
                  <b-form-input 
                    @focus="openDropdownSlider('editaItem.propsData.styles.text.size[typeView]')"
                    min="1"
                    v-model="editaItem.propsData.styles.text.size[typeView]" style="
                        width: 44px;
                        text-align: center !important;
                        border: none !important;
                        padding: 0 !important;
                  "></b-form-input>
                  <b-button
                    @click="editaItem.propsData.styles.text.size[typeView]++, globalInput(editaItem.propsData.styles.text.size[typeView], 'styles', 'size')"
                    size="sm" style="
                        height: min-content !important;
                        padding: 10.5px 7px !important;
                  "><img src="@/assets/editor/plus.svg" alt="align" /></b-button>
                </b-button-group>

                <div>
                  <multiselect v-model="editaItem.propsData.styles.text.font_measure[typeView]"
                    :options="['px', 'rem', '%', 'em']" :searchable="false" :show-labels="false" placeholder=" "
                    :hide-selected="true" :allow-empty="false"
                    @input="globalInput(editaItem.propsData.styles.text.font_measure[typeView], 'styles', 'font_measure')">
                  </multiselect>
                </div>
              </div>
            </div>

            <!-- Tamanho do nome -->
            <div class="space"></div>
            <div class="space"></div>
            <div>
              <div class="label-icon">
                <label class="subtitulo" style="margin: 5px 0 0 0 !important;">Tamanho do nome</label>
                <div>
                  <img src="@/assets/editor/globe.svg" alt="monitor" style="filter: invert(50%) !important; margin: 0"
                    @click="nextIcon('desktop'), (global = !global)" v-if="global" />
                  <img src="@/assets/editor/monitor.svg" alt="monitor" style="filter: invert(50%) !important; margin: 0"
                    @click="nextIcon('tablet')" v-if="typeView === 'desktop' && !global" />
                  <img src="@/assets/editor/tablet.svg" alt="tablet" style="filter: invert(50%) !important; margin: 0"
                    @click="nextIcon('mobile')" v-if="typeView === 'tablet' && !global" />
                  <img src="@/assets/editor/phone.svg" alt="phone" style="filter: invert(50%) !important; margin: 0"
                    @click="nextIcon('desktop'), (global = !global)" v-if="typeView === 'mobile' && !global" />
                </div>
              </div>
              <div class="mt-2" style="
                    display: flex;
                    gap: 10px;
                    width: 100%;
                    gap: 10px;
                    margin-bottom: -1rem;
                  ">
                <b-button-group>
                  <b-button :disabled="editaItem.propsData.styles.name.size[typeView] <= 1
                    " size="sm" style="
                        height: min-content !important;
                        padding: 10.5px 7px !important;
                    "
                    @click="editaItem.propsData.styles.name.size[typeView]--, globalInput(editaItem.propsData.styles.name.size[typeView], 'styles', 'size')"><img
                    src="@/assets/editor/minus3.svg" alt="align" 
                  />
                  </b-button>
                  <b-form-input 
                    @focus="openDropdownSlider('editaItem.propsData.styles.name.size[typeView]')"
                    min="1"
                    v-model="editaItem.propsData.styles.name.size[typeView]" style="
                        width: 44px;
                        text-align: center !important;
                        border: none !important;
                        padding: 0 !important;
                  ">
                  </b-form-input>
                  <b-button
                    @click="editaItem.propsData.styles.name.size[typeView]++, globalInput(editaItem.propsData.styles.name.size[typeView], 'styles', 'size')"
                    size="sm" style="
                        height: min-content !important;
                        padding: 10.5px 7px !important;
                  ">
                    <img src="@/assets/editor/plus.svg" alt="align" />
                  </b-button>
                </b-button-group>

                <div>
                  <multiselect v-model="editaItem.propsData.styles.name.font_measure[typeView]"
                    :options="['px', 'rem', '%', 'em']" :searchable="false" :show-labels="false" placeholder=" "
                    :hide-selected="true" :allow-empty="false"
                    @input="globalInput(editaItem.propsData.styles.name.font_measure[typeView], 'styles', 'font_measure')">
                  </multiselect>
                </div>
              </div>
            </div>

            <!-- Fonte -->
            <div class="space"></div>
            <div class="space"></div>
            <div class="max-multi">
              <label class="subtitulo">Fonte</label>
              <multiselect 
                v-model="editaItem.propsData.styles.text.font" 
                :options="fontOptions"
                @input="$emit('callCharge',$event)" 
                :searchable="true"
                :show-labels="false"
                placeholder="Fonte" 
                :hide-selected="true" 
                :allow-empty="false">
              </multiselect>
              <div class="space"></div>
            </div>

            <!-- Espessura do texto -->
            <div>
              <div class="label-range">
                <label class="subtitulo">Espessura da fonte (texto)</label>
                <input type="number" v-model="editaItem.propsData.styles.text.font_weight" />
              </div>
              <b-form-input v-model="editaItem.propsData.styles.text.font_weight" type="range" min="100" max="900"
                step="100"></b-form-input>
            </div>

            <!-- Espessura do nome -->
            <div>
              <div class="label-range">
                <label class="subtitulo">Espessura da fonte (nome)</label>
                <input type="number" v-model="editaItem.propsData.styles.name.font_weight" />
              </div>
              <b-form-input v-model="editaItem.propsData.styles.name.font_weight" type="range" min="100" max="900"
                step="100"></b-form-input>
            </div>

          </div>
          <div class="separador"></div>

          <!-- Tempo -->
          <div class="content" v-if="editaItem.propsData.styles.isCarousel">
            <label class="titulo">Temporarizador</label>
            <label class="subtitulo">Selecione o tempo em segundos para os depoimentos mudarem</label>
            <div class="space"></div>
            <div class="focus-control">
              <button :disabled="editaItem.propsData.styles.timer <= 1" style="
                  border-radius: 10px 0 0 10px;
                  border-right: none !important;
                " @click="
                  editaItem.propsData.styles.timer--
                  ">
                -
              </button>
              <input type="number" style="
                  height: 40px !important;
                  border-radius: 0px !important;
                  text-align: center;
                " min="1" @input="validTimer()" v-model="editaItem.propsData.styles.timer" />
              <button style="
                  border-radius: 0 10px 10px 0;
                  border-left: none !important;
                " @click="
                  editaItem.propsData.styles.timer++
                  ">
                +
              </button>
            </div>
          </div>
          <div class="separador" v-if="editaItem.propsData.styles.isCarousel"></div>

          <!-- Bordas -->
          <div class="content">
            <label class="titulo">Bordas</label>
            <div class="label-range">
              <label class="subtitulo">Arredondar bordas</label>
              <input type="number" v-model="editaItem.propsData.styles.card.border.radius" />
            </div>
            <b-form-input v-model="editaItem.propsData.styles.card.border.radius" type="range" min="0" max="75"
              step="1"></b-form-input>
            <div style="display: flex; width: 100%; transition: all 0.3s">
              <label :class="{ labelOff: editaItem.propsData.styles.card.border.hasBorder == false }"
                class="subtitulo">Bordas
                visíveis</label>
              <b-form-checkbox style="margin-top: -5px; margin-left: auto; margin-right: 0"
                v-model="editaItem.propsData.styles.card.border.hasBorder" name="is_main" size="lg" switch>
              </b-form-checkbox>
            </div>
            <div class="space"></div>
            <label class="subtitulo" :class="{ labelOff: editaItem.propsData.styles.card.border.hasBorder == false }"> Cor
              da
              borda </label>
            <LvColorpicker :class="{ colorDisabled: editaItem.propsData.styles.card.border.hasBorder == false }"
              :disabled="editaItem.propsData.styles.card.border.hasBorder == false" id="LvColorpicker"
              v-model="editaItem.propsData.styles.card.border.color" label="Cor do texto" :colors="palette"></LvColorpicker>
            <div class="space"></div>
            <div class="label-range">
              <label class="subtitulo"
                :class="{ labelOff: editaItem.propsData.styles.card.border.hasBorder == false }">Espessura</label>
              <input :disabled="editaItem.propsData.styles.card.border.hasBorder == false" type="number"
                v-model="editaItem.propsData.styles.card.border.width" />
            </div>
            <b-form-input :disabled="editaItem.propsData.styles.card.border.hasBorder == false"
              v-model="editaItem.propsData.styles.card.border.width" type="range" min="0" max="5"
              step="0.1"></b-form-input>
          </div>
          <div class="separador"></div>

          <!-- Sombras -->
          <div class="content">
            <div style="display: flex; width: 100%; transition: all 0.3s">
              <label :class="{ labelOff: editaItem.propsData.styles.card.shadow.hasShadow == false }"
                class="titulo">Sombra</label>
              <b-form-checkbox style="margin-top: -5px; margin-left: auto; margin-right: 0"
                v-model="editaItem.propsData.styles.card.shadow.hasShadow" name="is_main" size="lg" switch>
              </b-form-checkbox>
            </div>
            <div class="space"></div>
            <label class="subtitulo" :class="{ labelOff: editaItem.propsData.styles.card.shadow.hasShadow == false }"> Cor
              da
              Sombra </label>
            <LvColorpicker :class="{ colorDisabled: editaItem.propsData.styles.card.shadow.hasShadow == false }"
              :disabled="editaItem.propsData.styles.card.shadow.hasShadow == false" id="LvColorpicker"
              v-model="editaItem.propsData.styles.card.shadow.color" label="Cor do texto" :colors="palette"></LvColorpicker>
            <div class="space"></div>
            <div class="label-range">
              <label class="subtitulo"
                :class="{ labelOff: editaItem.propsData.styles.card.shadow.hasShadow == false }">Borrão</label>
              <input :disabled="editaItem.propsData.styles.card.shadow.hasShadow == false" type="number"
                v-model="editaItem.propsData.styles.card.shadow.blur" />
            </div>
            <b-form-input :disabled="editaItem.propsData.styles.card.shadow.hasShadow == false"
              v-model="editaItem.propsData.styles.card.shadow.blur" type="range" min="0" max="10"
              step="0.5"></b-form-input>
            <div class="label-range">
              <label class="subtitulo"
                :class="{ labelOff: editaItem.propsData.styles.card.shadow.hasShadow == false }">Posição
                Horizontal</label>
              <input :disabled="editaItem.propsData.styles.card.shadow.hasShadow == false" type="number"
                v-model="editaItem.propsData.styles.card.shadow.x" />
            </div>
            <b-form-input :disabled="editaItem.propsData.styles.card.shadow.hasShadow == false"
              v-model="editaItem.propsData.styles.card.shadow.x" type="range" min="-50" max="50" step="1"></b-form-input>
            <div class="label-range">
              <label class="subtitulo"
                :class="{ labelOff: editaItem.propsData.styles.card.shadow.hasShadow == false }">Posição
                Vertical</label>
              <input :disabled="editaItem.propsData.styles.card.shadow.hasShadow == false" type="number"
                v-model="editaItem.propsData.styles.card.shadow.y" />
            </div>
            <b-form-input :disabled="editaItem.propsData.styles.card.shadow.hasShadow == false"
              v-model="editaItem.propsData.styles.card.shadow.y" type="range" min="-50" max="50" step="1"></b-form-input>
          </div>
          <div class="separador"></div>
        </b-tab>

        <b-tab title="Avançado">
          <advancedBasic
            :spacing="editaItem.propsData.spacing" 
            :visibility="editaItem.propsData.visibility" 
            :animation="editaItem.propsColumn" 
            :attributes="editaItem.propsData.custom" 
            :typeView="typeView"
            :global="global"
            @nextIcon="nextIcon"
            @update:spacing="editaItem.propsData.spacing = $event"
            @update:visibility="editaItem.propsData.visibility = $event"
            @update:animation="editaItem.propsColumn = $event"
            @update:attributes="editaItem.propsData.custom = $event"
          ></advancedBasic>
        </b-tab>
      </b-tabs>
    </b-card>
    <Upload @setImg="setImg" />
    <dropdownSlider
      ref="dropdownSlider"   
      @updateValue="updateValue"
    />    
  </div>
</template>

<script>
import dropdownSliderMixin from "@/mixins/dropdownSliderMixin.js";
import advancedBasic from "../EditComponents/advancedBasic.vue";
import Upload from "../../Upload.vue";
import LvColorpicker from "lightvue/color-picker";
import Multiselect from "vue-multiselect";

export default {
  props: ["editaItem", "typeView", "fontOptions", "palette"],
  mixins: [dropdownSliderMixin],
  components: {
    advancedBasic,
    Upload,
    LvColorpicker: LvColorpicker,
    Multiselect,
  },
  data() {
    return {
      global: false,
      block_rotate_logo: 0,
      img_carousel_index: null,
      proportion: "Personalizado",
    };
  },
  methods: {
    moveUp(idx) {
      var moving = this.editaItem.propsData.slides[idx]
      this.editaItem.propsData.slides.splice(idx, 1);
      this.editaItem.propsData.slides.splice(idx - 1, 0, moving);
    },
    moveDown(idx) {
      var moving = this.editaItem.propsData.slides[idx]
      this.editaItem.propsData.slides.splice(idx, 1);
      this.editaItem.propsData.slides.splice(idx + 1, 0, moving);
    },
    validTimer() {
      if (this.editaItem.propsData.styles.carousel.timer < 1) {
        this.editaItem.propsData.styles.carousel.timer = 1
      }
    },    
    nextIcon(type) {
      this.$emit("attIconView", type);
    },      
    globalJustify(value, place) {
      let props = this.editaItem.propsData.alignment
      props[this.typeView][place] = value
      if (this.global && this.typeView == 'desktop') {
        props[this.typeView][place] = value
        props[this.typeView][place] = value
      }
    },
    addImg() {
      this.editaItem.propsData.slides.push({
        imagem: "https://gdigital.s3.amazonaws.com/gdigital/1/user-undefined.webp",
        name: "",
        rating: 5,
        text: "",
      });
    },    
    removeImgIndex(index) {
      this.editaItem.propsData.slides.splice(index, 1);
    },
    openModal(data, index) {
      this.img_carousel_index = index;
      this.$bvModal.show(data);
    },
    setImg(img) {
      this.editaItem.propsData.slides[this.img_carousel_index].imagem = img;
    },    
  },
  created() {
    if (this.typeView == "desktop") {
      this.global = true;
    }
  },
};
</script>

<style lang="scss" scoped>
.rating {
  display: flex;
  justify-content: center;
  border: 1px solid #ededf0 !important;
  height: 45px;
  border-radius: 10px;
  align-items: center;
  transition: all 0.5s;

  &:hover {
    border-color: var(--greenn) !important;
  }
}

.focus-control {
  display: flex;

  button {
    height: 40px;
    width: 40px;
    background-color: #00000000;
    border: 1px solid #eee !important;
    font-size: 20px;
    transition: all 0.3s;
  }

  input {
    width: 40px !important;
    background-color: #00000000 !important;
    border: 1px solid #eee !important;
    font-size: 20px !important;
    padding: 15px 0px !important;
  }
}

.card-type {
  border-radius: 7px;
  background-color: #f1f1f1;
  border: 1px dotted #c0c0c0;
  width: 100%;
  padding: 20px;
  margin-top: 1rem;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    background-color: #e7e7e7;
  }

  .card-icon {
    font-size: 48px;
    transition: all 0.3s;
    color: #838383;
  }

  .card-stars {
    font-size: 12px;
    transition: all 0.3s;
    color: #838383;
  }

  .card-title {
    transition: all 0.3s;
    color: #838383;
    font-size: 12px;
    margin: 0 !important;
  }

  .card-content {
    transition: all 0.3s;
    color: #838383;
    font-size: 8px;
  }

  .card-name {
    transition: all 0.3s;
    color: #838383;
    font-size: 8px;
    text-align: end;
  }
}

.card-center {
  justify-content: center;
  text-align: center;
}

.card-right {
  justify-content: end;
  text-align: right;
}

.card-inverse {
  flex-direction: row-reverse;
}

.card-2 {
  display: flex;
  gap: 15px;
  align-items: center;
}

.card-3 {
  display: flex;
  gap: 15px;
  align-items: center;

  .card-title {
    line-height: 1;
    margin: 0;
  }
}
</style>
