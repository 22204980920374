let propsCarrousel = {
  alignment: {
    desktop: { justify: "center", },
    tablet: { justify: "center", },
    mobile: { justify: "center", },
  },
  custom: {
    c_class: '',
    c_id: '',
  },
  slides: [
    {
      imagem:
        "https://gdigital.s3.amazonaws.com/gdigital/1/imagem-vazia3%20%281%29.webp",
      isVideo: false,
      content: ''
    },
    {
      imagem:
        "https://gdigital.s3.amazonaws.com/gdigital/1/imagem-vazia3%20%281%29.webp",
      isVideo: false,
      content: ''
    },
    {
      imagem:
        "https://gdigital.s3.amazonaws.com/gdigital/1/imagem-vazia3%20%281%29.webp",
      isVideo: false,
      content: ''
    },
  ],
  styles: {
    color_btns: "#FFFFFF",
  },
  size: {
    desktop: { width: 200, height: 200 },
    tablet: { width: 200, height: 200 },
    mobile: { width: 200, height: 200 },
  },
  spacing: {
    compleat_padding_bottom: 56.25,
    desktop: {
      margin_top: 0,
      margin_right: 0,
      margin_bottom: 0,
      margin_left: 0,
      padding_top: 0,
      padding_right: 0,
      padding_bottom: 0,
      padding_left: 0,
    },
    tablet: {
      margin_top: 0,
      margin_right: 0,
      margin_bottom: 0,
      margin_left: 0,
      padding_top: 0,
      padding_right: 0,
      padding_bottom: 0,
      padding_left: 0,
    },
    mobile: {
      margin_top: 0,
      margin_right: 0,
      margin_bottom: 0,
      margin_left: 0,
      padding_top: 0,
      padding_right: 0,
      padding_bottom: 0,
      padding_left: 0,
    },
  },
  visibility: {
    tablet: true,
    phone: true,
    monitor: true,
    compleat: true,
  },
};
export default propsCarrousel;
