let propsCountdown = {
  custom: {
    c_class: "",
    c_id: "",
  },
  count: {
    date: "",
  },
  alignment: {
    desktop: { justify: "center", justify_vertical: "center" },
    tablet: { justify: "center", justify_vertical: "center" },
    mobile: { justify: "center", justify_vertical: "center" },
  },
  styles: {
    number_color: "#000000",
    label_color: "#000000",
    bar_color: "#ffffff",
    type: 'Círculo',
    font_label: 'Montserrat',
    font_number: 'Montserrat'
  },
  count_size: {
    desktop: {
      count_size: 50,
      gap: 500,
    },
    tablet: {
      count_size: 45,
      gap: 400,
    },
    mobile: {
      count_size: 30,
      gap: 300,
    },
  },
  spacing: {
    desktop: {
      margin_top: 0,
      margin_right: 0,
      margin_bottom: 0,
      margin_left: 0,
      padding_top: 0,
      padding_right: 0,
      padding_bottom: 0,
      padding_left: 0,
    },
    tablet: {
      margin_top: 0,
      margin_right: 0,
      margin_bottom: 0,
      margin_left: 0,
      padding_top: 0,
      padding_right: 0,
      padding_bottom: 0,
      padding_left: 0,
    },
    mobile: {
      margin_top: 0,
      margin_right: 0,
      margin_bottom: 0,
      margin_left: 0,
      padding_top: 0,
      padding_right: 0,
      padding_bottom: 0,
      padding_left: 0,
    },
  },
  visibility: {
    tablet: true,
    phone: true,
    monitor: true,
  },
};

export default propsCountdown;
