import { render, staticRenderFns } from "./ConfigPage.vue?vue&type=template&id=666c7efa&scoped=true"
import script from "./ConfigPage.vue?vue&type=script&lang=js"
export * from "./ConfigPage.vue?vue&type=script&lang=js"
import style0 from "./ConfigPage.vue?vue&type=style&index=0&id=666c7efa&prod&lang=css"
import style1 from "./ConfigPage.vue?vue&type=style&index=1&id=666c7efa&prod&scoped=true&lang=scss"
import style2 from "./ConfigPage.vue?vue&type=style&index=2&id=666c7efa&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "666c7efa",
  null
  
)

export default component.exports