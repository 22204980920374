let propsHtml = {
  scriptRender: true,
  content: {
    htmlContent: "Insira seu código HTML",
  },
  spacing: {
    desktop: {
      margin_top: 0,
      margin_right: 0,
      margin_bottom: 0,
      margin_left: 0,
      padding_top: 0,
      padding_right: 0,
      padding_bottom: 0,
      padding_left: 0,
    },
    tablet: {
      margin_top: 0,
      margin_right: 0,
      margin_bottom: 0,
      margin_left: 0,
      padding_top: 0,
      padding_right: 0,
      padding_bottom: 0,
      padding_left: 0,
    },
    mobile: {
      margin_top: 0,
      margin_right: 0,
      margin_bottom: 0,
      margin_left: 0,
      padding_top: 0,
      padding_right: 0,
      padding_bottom: 0,
      padding_left: 0,
    },
  },
  visibility: {
    tablet: true,
    phone: true,
    monitor: true,
  },
};

export default propsHtml;
