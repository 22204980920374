let propsImagem = {
  custom: {
    c_class: '',
    c_id: '',
  },
  image: {
    src: "https://gdigital.s3.amazonaws.com/gdigital/1/SELECIONE%20UMA%20IMAGEM.webp",
    alt: "alt_image",
  },
  redirectImage:{
    link:"",
    isBlank:false,
  },
  alignment: {
    desktop: { justify: "center", justify_vertical: "center", fit: { "value": "cover", "text": "Cobrir" }, proportion: "Respeitar coluna", percWidth: 100, setHeight: 66.66 },
    tablet: { justify: "center", justify_vertical: "center", fit: { "value": "cover", "text": "Cobrir" }, proportion: "Respeitar coluna", percWidth: 100, setHeight: 66.66 },
    mobile: { justify: "center", justify_vertical: "center", fit: { "value": "cover", "text": "Cobrir" }, proportion: "Respeitar coluna", percWidth: 100, setHeight: 66.66 },
  },
  shadow: {
    hasShadow: false,
    shadowColor: "#000000",
    shadowX: 5,
    shadowY: 5,
    shadowBlur: 5,
  },
  border: {
    hasBorder: false,
    border_style: "━━━━━━━━━━━━━━━━━━━━━",
    border_thickness: "2",
    border_color: "#000000",                                                    
    border_top: 0,                                                        
    border_right: 0,
    border_bottom: 0,
    border_left: 0,
    border_radius_top: 0,
    border_radius_right: 0,
    border_radius_bottom: 0,
    border_radius_left: 0,
  },
  size: {
    desktop: { width: 450, height: 300, und_width: 'px', und_height: 'px' },
    tablet: { width: 450, height: 300, und_width: 'px', und_height: 'px' },
    mobile: { width: 450, height: 300, und_width: 'px', und_height: 'px' },
  },
  spacing: {
    desktop: {
      margin_top: 0,
      margin_right: 0,
      margin_bottom: 0,
      margin_left: 0,
    },
    tablet: {
      margin_top: 0,
      margin_right: 0,
      margin_bottom: 0,
      margin_left: 0,
    },
    mobile: {
      margin_top: 0,
      margin_right: 0,
      margin_bottom: 0,
      margin_left: 0,
    },
  },
  visibility: {
    tablet: true,
    phone: true,
    monitor: true,
  },
};

export default propsImagem;
