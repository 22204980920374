<template>
  <div id="SideRightTabs">
    <b-card no-body>
      <b-tabs pills justified small style="width: 100%">
        <b-tab
          title="Conteúdo"
          active
        >
          <div class="separador"></div>
          <div class="content">
            <label class="titulo">Geral</label>
            <label class="sub">Tipo de vídeo</label>
            <multiselect
              :options="['YouTube', 'Incorporar']"
              v-model="editaItem.propsData.video.type"
              :searchable="false"
              :show-labels="false"
              placeholder="Tipo de video"
              :allow-empty="false"
            ></multiselect>
            <div class="space"></div>
            <div v-if="editaItem.propsData.video.type == 'YouTube' || showConfig">
              <label class="sub">URL</label>
              <input
                placeholder="https://seulink"
                class="w-100 mb-2"
                type="text"
                name="name"
                id="name"
                v-model="editaItem.propsData.video.url"
                @input="convertURL"
              />
              <div class="space"></div>
              <label class="sub">Thumbnail (opcional)                   
                <img
                  width="17px"
                  height="17px"
                  src="@/assets/editor/help-circle.svg"
                  alt="icon"
                  v-b-tooltip.hover
                  title="Caso não exista uma thumbnail selecionada, será mostrada a thumbnail padrão. Se o vídeo for não listado, a thumbnail personalizada é recomendada."
                /></label>
              <div class="select-image">
                <div @click="openModal('Upload-Editor')">
                  <img :src="editaItem.propsData.video.thumb ? editaItem.propsData.video.thumb : 'https://gdigital.s3.amazonaws.com/gdigital/8/imagem-vazia%20%28200%C2%A0%C3%97%C2%A0200%C2%A0px%29.webp'"  alt="Imagem preview">
                </div>
                <div>
                  <input type="text" v-model="editaItem.propsData.video.thumb">
                  <div class="with-trash">
                    <span @click="openModal('Upload-Editor')">{{ editaItem.propsData.video.thumb ? 'Alterar thumbnail' : 'Selecionar thumbnail'}}</span>
                    <img title="Remover thumbnail" @click="editaItem.propsData.video.thumb = ''" class="trash" src="@/assets/editor/trash-2.svg"/>
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              <label class="sub">Código para incorporar</label>
              <textarea
                placeholder="<iframe src=''https://seulink.com'''> ... </iframe>"
                class="w-100 mb-2"
                type="text"
                name="code"
                id="code"
                v-model="editaItem.propsData.video.code"
                rows="10"
                style="min-height: 100px !important; max-height: fit-content !important;"
              ></textarea>
            </div>
          </div>
          <div class="separador"></div>
          <div class="content">
            <!-- Label typeView -->
            <div class="label-icon">
              <label class="titulo">Dimensões</label>
              <div>
                <img
                  src="@/assets/editor/globe.svg"
                  alt="monitor"
                  style="filter: invert(50%) !important"
                  @click="nextIcon('desktop'), (global = !global)"
                  v-if="global"
                />
                <img
                  src="@/assets/editor/monitor.svg"
                  alt="monitor"
                  style="filter: invert(50%) !important"
                  @click="nextIcon('tablet')"
                  v-if="typeView === 'desktop' && !global"
                />
                <img
                  src="@/assets/editor/tablet.svg"
                  alt="tablet"
                  style="filter: invert(50%) !important"
                  @click="nextIcon('mobile')"
                  v-if="typeView === 'tablet' && !global"
                />
                <img
                  src="@/assets/editor/phone.svg"
                  alt="phone"
                  style="filter: invert(50%) !important"
                  @click="nextIcon('desktop'), (global = !global)"
                  v-if="typeView === 'mobile' && !global"
                />
              </div>
            </div>
            <div style="display: flex; width: 100%; transition: all 0.3s">
              <label class="subtitulo">Largura da coluna</label>
              <b-form-checkbox
                style="margin-top: -5px; margin-left: auto; margin-right: 0"
                v-model="editaItem.propsData.size[typeView].compleate"
                size="lg"
                switch
              >
              </b-form-checkbox>
            </div>
            <b-collapse v-model="editaItem.propsData.size[typeView].compleate">
              <div class="space"></div>
              <label class="subtitulo">Proporções</label>
              <multiselect
                :options="[
                  'Paisagem (16:9)',
                  'Vertical (9:16)',
                  'Personalizado',
                  'Quadrado (1:1)',
                  'Retrato (4:5)',
                  'Panorama (2:1)',
                ]"
                v-model="proportion"
                :searchable="false"
                :show-labels="false"
                placeholder=" "
                @input="dimensions()"
              ></multiselect>
            </b-collapse>
            <div class="space"></div>
            <!-- Altura e largura -->
            <div class="half" v-if="editaItem.propsData.video.type == 'YouTube' || showConfig">
              <div>
                <label class="subtitulo" :class="{ labelOff: editaItem.propsData.size[typeView].compleate }">Altura</label>
                <input
                  :disabled="editaItem.propsData.size[typeView].compleate"
                  type="number"
                  max="1000"
                  min="50"
                  step="10"
                  v-model="editaItem.propsData.size[typeView].height"             
                  @focus="openDropdownSlider('editaItem.propsData.size[typeView].height')"
                />
              </div>
              <div>
                <label class="subtitulo" :class="{ labelOff: editaItem.propsData.size[typeView].compleate }">Largura</label>
                <input
                  :disabled="editaItem.propsData.size[typeView].compleate"
                  type="number"
                  v-model="editaItem.propsData.size[typeView].width"                 
                  @focus="openDropdownSlider('editaItem.propsData.size[typeView].width')"
                />
              </div>
            </div>
            <div class="space"></div>

            <div>
              <label class="subtitulo">Posicionamento</label>
              <div style="display: flex; width: 100%; gap: 10px">
                <div style="display: flex; width: 50%; transition: all 0.3s">
                  <div class="select-align">
                    <button :class="{ 'selected-align': editaItem.propsData.alignment[typeView].justify == 'flex-start' }"
                    @click="globalJustify('flex-start', 'justify')"><img
                        src="@/assets/editor/align-left-new.svg" /></button>
                    <button :class="{ 'selected-align': editaItem.propsData.alignment[typeView].justify == 'center' }"
                    @click="globalJustify('center', 'justify')"><img
                        src="@/assets/editor/align-center-horizontal.svg" /></button>
                    <button :class="{ 'selected-align': editaItem.propsData.alignment[typeView].justify == 'flex-end' }"
                    @click="globalJustify('flex-end', 'justify')"><img
                        src="@/assets/editor/align-right-new.svg" /></button>
                  </div>
                </div>

                <div style="display: flex; width: 50%; transition: all 0.3s">
                  <div class="select-align">
                    <button :class="{ 'selected-align': editaItem.propsData.alignment[typeView].justify_vertical == 'start' }"
                      @click="globalJustify('start', 'justify_vertical')"><img
                        src="@/assets/editor/align-top.svg" /></button>
                    <button :class="{ 'selected-align': editaItem.propsData.alignment[typeView].justify_vertical == 'center' }"
                      @click="globalJustify('center', 'justify_vertical')"><img
                        src="@/assets/editor/align-center-vertical.svg" /></button>
                    <button :class="{ 'selected-align': editaItem.propsData.alignment[typeView].justify_vertical == 'end' }"
                      @click="globalJustify('end', 'justify_vertical')"><img
                        src="@/assets/editor/align-bottom.svg" /></button>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div class="space"></div>
              <label for="form-altura" class="sub">Altura mínima da coluna</label>
              <b-form-group id="form-altura" class="und_form">
                <div class="und_form">
                  <b-form-input
                    id="form-altura"
                    v-model="editaItem.propsColumn.height[typeView].height"
                    @focus="openDropdownSlider('editaItem.propsColumn.height[typeView].height')"
                    type="number"
                    min="0"
                  ></b-form-input>
                  <span
                    @click="next_und_height"
                    class="und text"
                    v-b-tooltip.hover
                    title="Altura em pixel"
                    >{{editaItem.propsColumn.height[typeView].height_und}}</span
                  >
                </div>
              </b-form-group>
            </div>
          </div>
          <div class="separador"></div>
        </b-tab>

        <b-tab title="Avançado">
          <advancedBasic
            :spacing="editaItem.propsData.spacing" 
            :visibility="editaItem.propsData.visibility" 
            :animation="editaItem.propsColumn" 
            :attributes="editaItem.propsData.custom" 
            :typeView="typeView"
            :global="global"
            @toggleGlobal="global = !global"
            @nextIcon="nextIcon"
            @update:spacing="editaItem.propsData.spacing = $event"
            @update:visibility="editaItem.propsData.visibility = $event"
            @update:animation="editaItem.propsColumn = $event"
            @update:attributes="editaItem.propsData.custom = $event"
          ></advancedBasic>
        </b-tab>
      </b-tabs>
    </b-card>
    <Upload @setImg="setImg" />
    <dropdownSlider
      ref="dropdownSlider"   
      @updateValue="updateValue"
    /> 
  </div>
</template>

<script>
import dropdownSliderMixin from "@/mixins/dropdownSliderMixin.js";
import advancedBasic from "../EditComponents/advancedBasic.vue";
import Upload from "../../Upload.vue";
import LvColorpicker from "lightvue/color-picker";
import Multiselect from "vue-multiselect";

export default {
  mixins: [dropdownSliderMixin],
  components: {
    advancedBasic,
    Upload,
    Multiselect,
    LvColorpicker,
  },
  props: ["editaItem", "typeView"],
  computed: {
    theThumb() {
      return this.editaItem.propsData.video.thumb
    },
  },
  watch: {
    theThumb(newVal) {
      this.updateThumb(this.editaItem.propsData.video.type);
    },
  },
  data() {
    return {
      global: false,
      showConfig: false,
      proportion: 'Paisagem (16:9)'
    };
  },
  methods: {
    dimensions() {
      if (this.proportion.includes("Personalizado")) {
        this.editaItem.propsData.size[this.typeView].proportion = 0;
        this.editaItem.propsData.size.desktop.compleate = false
        this.editaItem.propsData.size.tablet.compleate = false
        this.editaItem.propsData.size.mobile.compleate = false
      } else {
        this.editaItem.propsData.size.desktop.compleate = true
        this.editaItem.propsData.size.tablet.compleate = true
        this.editaItem.propsData.size.mobile.compleate = true
        if (this.proportion.includes("Quadrado")) {
          this.editaItem.propsData.size[this.typeView].proportion = '1';
        } else if (this.proportion.includes("Panorama")) {
          this.editaItem.propsData.size[this.typeView].proportion = '2';
        } else if (this.proportion.includes("Paisagem")) {
          this.editaItem.propsData.size[this.typeView].proportion = '16/9';
        } else if (this.proportion.includes("Vertical")) {
          this.editaItem.propsData.size[this.typeView].proportion = '9/16';
        } else if (this.proportion.includes("Retrato")) {
          this.editaItem.propsData.size[this.typeView].proportion = '4/5';
        }
      }
    },
    updateThumb(value) {
      this.showConfig = true
      this.editaItem.propsData.video.type = 'YouTube'
      setTimeout(() => {
        this.editaItem.propsData.video.type = value
        this.showConfig = false
      }, 100);
    },
    setImg(img) {
      this.editaItem.propsData.video.thumb = img;
    },
    openModal(data) {
      this.$bvModal.show(data);
    },
    next_und_height() {
      const props = this.editaItem.propsColumn.height;
      props[this.typeView].height_und = props[this.typeView].height_und == "px" ? "vh" : props[this.typeView].height_und == "vh" ? "vw" : "px";
      if (this.global && this.typeView == 'desktop') {
        props.tablet.height_und = props.desktop.height_und
        props.mobile.height_und = props.desktop.height_und
      }
    }, 
    nextIcon(type) {
      this.$emit("attIconView", type);
    },
    globalProp(value, prop) {
      if (this.global && this.typeView == 'desktop') {
        var props = this.editaItem.propsData.size;
        var px = value.target.value;
        if (prop == "altura") {
          props.tablet.height = px;
          props.mobile.height = px;
        } else {
          props.tablet.width = px;
          props.mobile.width = px;
        }
      }
    },
    convertURL() {
      var url = this.editaItem.propsData.video.url;
      const regex = /https:\/\/(www\.)?(youtube\.com|youtu\.be)\/(watch\?v=|embed\/|v\/|)([a-zA-Z0-9_-]+)/;
      const match = url.match(regex);
      var base_url = "https://www.youtube.com/embed/";
      if (match) {
        this.editaItem.propsData.video.url_converted = base_url + match[4];
      } else {
        this.editaItem.propsData.video.url_converted = "invalid";
      }
    },
    globalJustify(value, place) {
      let props = this.editaItem.propsData.alignment
      props[this.typeView][place] = value
      if (this.global && this.typeView == 'desktop') {
        props[this.typeView][place] = value
        props[this.typeView][place] = value
      }
    },
  },
  created() {
    if (this.typeView == "desktop") {
      this.global = true;
    }

    if (!this.editaItem.propsData.size.desktop.hasOwnProperty('proportion')) {
      this.editaItem.propsData.size.desktop.proportion = '9/16'
    }

    switch (this.editaItem.propsData.size.desktop.proportion) {
      case '1':
        this.proportion = 'Quadrado (1:1)';
        break;
      case '2':
        this.proportion = 'Panorama (2:1)';
        break;
      case '16/9':
        this.proportion = 'Paisagem (16:9)';
        break;
      case '9/16':
        this.proportion = 'Vertical (9:16)';
        break;
      case '4/5':
        this.proportion = 'Retrato (4:5)';
        break;
      default:
        this.proportion = 'Personalizado';
    }
  },
};
</script>
