<template>
	<div id="SideRightTabs">
		<b-tabs pills fill small style="width: 100%">
			<!-- Geral -->
			<b-tab title="Geral" active>
				<div class="b-tab-content">
					<!-- Conteúdo -->
					<div class="separador" />
					<div class="content">
						<label class="titulo">Conteúdo</label>
						<label class="subtitulo">Texto</label>
						<textarea v-focus v-model="editaItem.propsData.redirection.text" rows="2" class="w-100"></textarea>
						<label class="subtitulo">Número</label>
            <input type="number"  v-model="editaItem.propsData.redirection.number">
					</div>
			
				</div>
			</b-tab>

			<!-- Estilo -->
			<b-tab title="Estilo">
				<div class="b-tab-content">
					<!-- Corpo -->
					<div class="separador"></div>
					<div class="content">
						<label class="titulo">Ícone</label>
						<label class="sub">Cor padrão</label>

						<!-- Seleção do tipo de fundo -->
						<multiselect v-model="editaItem.propsData.icon.back_selected" :options="['Cor sólida', 'Gradiente']"
							:searchable="false" :show-labels="false" placeholder="Tipo do fundo" 
							@input="iconCreate(), backgroundCreate()" :hide-selected="true" :allow-empty="false"></multiselect>
              <div class="space"></div>
						<!-- Cor sólida -->
						<div v-show="editaItem.propsData.icon.back_selected == 'Cor sólida'">
              <label class="subtitulo">Cor de fundo</label>
								<LvColorpicker id="LvColorpicker" v-model="editaItem.propsData.icon.backColor" :colors="palette" >
								</LvColorpicker>
							<div v-if="editaItem.propsData.icon.color">
								<div class="space"></div>

                <label class="subtitulo">Cor do Ícone</label>
							<LvColorpicker id="LvColorpicker" 
								v-model="editaItem.propsData.icon.color" :colors="palette"></LvColorpicker>
                <div class="space"></div>

								<label class="subtitulo">Cor ao passar o mouse no ícone</label>
								<LvColorpicker id="LvColorpicker" v-model="editaItem.propsData.icon.hoverColor" :colors="palette"
                @input="hoverCreate()">
								</LvColorpicker>
                <div class="space"></div>

                <label class="subtitulo">Cor ao passar o mouse no fundo</label>
								<LvColorpicker id="LvColorpicker" v-model="editaItem.propsData.icon.hoverBackColor" :colors="palette"
                @input="hoverBackCreate()">
								</LvColorpicker>
							</div>
						</div>
            
						<!-- Gradiente -->
            <div
              v-show="editaItem.propsData.icon.back_selected == 'Gradiente'"
            >
              <!-- Pickers -->
              <div class="pickers pb-2">
                <div class="picker">
                  <LvColorpicker
                    @input="backgroundGradiente()"
                    id="LvColorpicker"
                    v-model="editaItem.propsData.icon.grad_color1"
                    :colors="palette"
                  ></LvColorpicker>
                </div>
                <div class="picker">
                  <LvColorpicker
                    @input="backgroundGradiente()"
                    id="LvColorpicker"
                    v-model="editaItem.propsData.icon.grad_color2"
                    :colors="palette"
                  ></LvColorpicker>
                </div>
              </div>
              <div class="space"></div>

              <!-- Direção -->
              <div class="label-range">
                <label class="subtitulo">Direção do gradiente</label>
                <input
                  type="number"
                  @input="backgroundGradiente()"
                  v-model="editaItem.propsData.icon.grad_angle"
                />
              </div>
              <b-form-input
                @input="backgroundGradiente()"
                v-model="editaItem.propsData.icon.grad_angle"
                type="range"
                min="0"
                max="360"
                step="1"
              ></b-form-input>
            </div>

					</div>
				</div> 
			</b-tab>
      <b-tab title="Avançado">
        <div class="separador"></div>
        <advancedBasic
          :visibility="editaItem.propsData.visibility"      
          :attributes="editaItem.propsData.custom" 
          :typeView="typeView"
          :global="global"
          :hideSpacing="true"
          :hideAnimation="true"
          @toggleGlobal="global = !global"
          @nextIcon="nextIcon"  
          @update:visibility="editaItem.propsData.visibility = $event"
          @update:animation="editaItem.propsColumn = $event"     
        ></advancedBasic>
      </b-tab>
		</b-tabs>

	</div>
</template>

<script>
import advancedBasic from "../EditComponents/advancedBasic.vue";
import LvColorpicker from "lightvue/color-picker";
import Multiselect from "vue-multiselect";

export default {
	props: ["editaItem", "typeView", "palette"],
	components: {
    advancedBasic,
		LvColorpicker: LvColorpicker,
		Multiselect,
	},
	data() {
		return {
			lastColor: "",
			lastBackColor:"",
			lastHoverColor: "",
			lastHoverBackColor: "",
			content: true,
			gradientType: "linear-gradient",
		};
	},
	methods: {
		backgroundCreate() {
			if (this.editaItem.propsData.icon.back_selected === "Cor sólida") {
				if (this.lastBackColor) {
					this.editaItem.propsData.icon.backColor = this.lastBackColor;
				} else {
					this.editaItem.propsData.icon.backColor = '#25D366'; 
				}
				if (this.lastColor) {
					this.editaItem.propsData.icon.color = this.lastColor;
				}
			} else if (this.editaItem.propsData.icon.back_selected === "Gradiente") {
				this.lastBackColor = this.editaItem.propsData.icon.backColor;
				this.lastColor = this.editaItem.propsData.icon.color;
			}
		},
		iconCreate() {
			if (this.editaItem.propsData.icon.back_selected === "Cor sólida") {
				if (this.lastColor) {
					this.editaItem.propsData.icon.color = this.lastColor; 
				}
			} else {
				this.lastColor = this.editaItem.propsData.icon.color;
			}
		},
		hoverCreate() {
			if (this.editaItem.propsData.icon.back_selected === "Cor sólida" && this.editaItem.propsData.icon.color) {
				this.editaItem.propsData.icon.hoverColor = this.editaItem.propsData.icon.hoverColor || '';
				if (this.lastHoverColor) {
					this.editaItem.propsData.icon.hoverColor = this.lastHoverColor;
				}
			} else {
				this.lastHoverColor = this.editaItem.propsData.icon.hoverColor;
			}
		},
		hoverBackCreate(){
			if(this.editaItem.propsData.icon.back_selected === "Cor sólida" && this.editaItem.propsData.icon.color){
				this.editaItem.propsData.icon.hoverBackColor = this.editaItem.propsData.icon.hoverBackColor || '';
				if(this.lastHoverBackColor){
					this.editaItem.propsData.icon.hoverBackColor = this.lastHoverBackColor;
				}
			} else {
				this.lastHoverBackColor = this.editaItem.propsData.icon.hoverBackColor
			}
		},
		backgroundGradiente(){
			if (this.editaItem.propsData.icon.back_selected == "Gradiente") {
				this.editaItem.propsData.icon.back_type = "image";
				this.editaItem.propsData.icon.backColor = `${this.gradientType}(${this.editaItem.propsData.icon.grad_angle}deg,
				${this.editaItem.propsData.icon.grad_color2}, ${this.editaItem.propsData.icon.grad_color1})`;
			}
		}, 
	}
}
</script>
