<template>
  <div>
    <div class="content">
      <div class="label-icon">
        <label class="titulo">{{ label }}</label>        
        <img
          src="@/assets/editor/globe.svg"
          alt="monitor"
          style="filter: invert(50%) !important"
          @click="nextIcon('desktop'), toggleGlobal()"
          v-if="global"
        />
        <img
          src="@/assets/editor/monitor.svg"
          alt="monitor"
          style="filter: invert(50%) !important"
          @click="nextIcon('tablet')"
          v-if="typeView === 'desktop' && !global"
        />
        <img
          src="@/assets/editor/tablet.svg"
          alt="tablet"
          style="filter: invert(50%) !important"
          @click="nextIcon('mobile')"
          v-if="typeView === 'tablet' && !global"
        />
        <img
          src="@/assets/editor/phone.svg"
          alt="phone"
          style="filter: invert(50%) !important"
          @click="nextIcon('desktop'), toggleGlobal()"
          v-if="typeView === 'mobile' && !global"
        />       
      </div>
      <!-- Inputs -->
      <div class="container-spacing" style="padding-bottom: 15px">
        <div class="border-dash" v-if="!hideMargin">
          <span class="txt-mg">MARGEM EXTERNA</span>
          <img
            src="@/assets/editor/link-click.svg"
            alt="Link"
            class="links-advanced"
            :class="linkMargin ? '' : 'link-disabled'"
            @click="linkMargin = !linkMargin"
          />
          <div class="alinhamento">
            <!-- MARGIN LEFT -->
            <div class="item-1">
              <div class="content-value">
                <input
                  type="number"
                  v-model="spacing[typeView].margin_left"
                  @focus="openDropdownSlider('spacing[typeView].margin_left', 'linkMargin')"
                />          
              </div>                
            </div>
            <div class="item-2">
              <div class="alinhamento-2">
                <!-- MARGIN TOP -->
                <div class="content-value">
                  <input
                    type="number"
                    v-model="spacing[typeView].margin_top"                     
                    @focus="openDropdownSlider('spacing[typeView].margin_top', 'linkMargin')"
                  />         
                </div>  
                <!-- let path = 'spacing[typeView].margin_top' -->
                <!-- editaItem[path] -->
                <div>
                  <div class="contain-borders">
                    <span class="txt-mg">MARGEM INTERNA</span>
                    <img
                      src="@/assets/editor/link-click.svg"
                      alt="Link"
                      class="links-advanced"
                      :class="linkPadding ? '' : 'link-disabled'"
                      @click="linkPadding = !linkPadding"
                    />
                    <div class="alinhamento">
                      <!-- PADDING LEFT -->
                      <div class="item-1">                     
                        <div class="content-value">
                          <input
                            type="number"
                            v-model="spacing[typeView].padding_left"                     
                            @focus="openDropdownSlider('spacing[typeView].padding_left', 'linkPadding')"
                          />         
                        </div>           
                      </div>
                      <div class="item-2">
                        <div class="alinhamento-2">
                          <!-- PADDING TOP -->
                          <div class="content-value">
                            <input
                              type="number"
                              v-model="spacing[typeView].padding_top"                     
                              @focus="openDropdownSlider('spacing[typeView].padding_top', 'linkPadding')"
                            />         
                          </div> 
                          <div>
                            <div class="border-final"></div>
                          </div>
                          <!-- PADDING BOTTOM -->                     
                            <div class="content-value">                    
                              <input
                                type="number"
                                v-model="spacing[typeView].padding_bottom"                     
                                @focus="openDropdownSlider('spacing[typeView].padding_bottom', 'linkPadding')"
                              />                    
                            </div>                                                 
                        </div>
                      </div>
                      <!-- PADDING RIGHT -->
                      <div class="item-3"> 
                        <div class="content-value">                       
                          <input
                            type="number"
                            v-model="spacing[typeView].padding_right"                     
                            @focus="openDropdownSlider('spacing[typeView].padding_right', 'linkPadding')"
                          />                                   
                        </div>                            
                      </div>
                    </div>
                  </div>
                </div>
                <!-- MARGIN BOTTOM -->              
                <div class="content-value" >
                  <input
                    type="number"
                    v-model="spacing[typeView].margin_bottom"                     
                    @focus="openDropdownSlider('spacing[typeView].margin_bottom', 'linkMargin')"
                  />        
                </div>  
              </div>
            </div>
            <!-- MARGIN RIGHT -->
            <div class="item-3"> 
              <div class="content-value">           
                <input
                  type="number"
                  v-model="spacing[typeView].margin_right"                     
                  @focus="openDropdownSlider('spacing[typeView].margin_right', 'linkMargin')"
                />         
              </div>
            </div>            
          </div> 
        </div>       
        <div class="contain-borders" v-else>
          <span class="txt-mg">MARGEM INTERNA</span>
          <img
            src="@/assets/editor/link-click.svg"
            alt="Link"
            class="links-advanced"
            :class="linkPadding ? '' : 'link-disabled'"
            @click="linkPadding = !linkPadding"
          />
          <div class="alinhamento">
            <!-- PADDING LEFT -->
            <div class="content-value">            
              <input
                type="number"
                v-model="spacing[typeView].padding_left"                     
                @focus="openDropdownSlider('spacing[typeView].padding_left', 'linkPadding')"
              />             
            </div>
            <div class="item-2">
              <div class="alinhamento-2">
                <!-- PADDING TOP -->
                <div class="content-value">        
                  <input
                    type="number"
                    v-model="spacing[typeView].padding_top"                     
                    @focus="openDropdownSlider('spacing[typeView].padding_top', 'linkPadding')"
                  />     
                </div>
                <div>
                  <div class="border-final"></div>
                </div>
                <!-- PADDING BOTTOM --> 
                <div class="content-value">          
                  <input
                    type="number"
                    v-model="spacing[typeView].padding_bottom"                     
                    @focus="openDropdownSlider('spacing[typeView].padding_bottom', 'linkPadding')"
                  />       
                </div>                     
              </div>
            </div>
            <!-- PADDING RIGHT -->
            <div class="item-3">                
              <div class="content-value">
                <input
                  type="number"
                  v-model="spacing[typeView].padding_right"                     
                  @focus="openDropdownSlider('spacing[typeView].padding_right', 'linkPadding')"
                />      
              </div>   
            </div>
          </div>
        </div> 
      </div>      
    </div>  
    <dropdownSlider
      ref="dropdownSlider"   
      @updateValue="updateValue"
    />          
  </div>
</template>

<script>
import dropdownSlider from './dropdownSlider.vue';
import dropdownSliderMixin from "@/mixins/dropdownSliderMixin.js";
export default {
  mixins: [dropdownSliderMixin],
  components: {
    dropdownSlider
  },
  props: {    
    spacing: {
      type: Object,
    },   
    label: {
      type: String,
      default: 'Espaçamento geral'
    },
    global: {
      type: Boolean,
      default: ''
    },
    typeView: {
      type: String,
      default: ''
    },
    hideMargin: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {  
      linkPadding: false,
      linkMargin: false,  
      activePosition: "", 
      newValue: 0,
      varLinks: {
        linkPadding: [
          'spacing[typeView].padding_top',
          'spacing[typeView].padding_right',
          'spacing[typeView].padding_bottom',
          'spacing[typeView].padding_left',
        ],
        linkMargin: [
          'spacing[typeView].margin_top',
          'spacing[typeView].margin_right',
          'spacing[typeView].margin_bottom',
          'spacing[typeView].margin_left',
        ]
      }
    };
  },
  methods: {   
    nextIcon(type) {
      this.$emit("nextIcon", type);
    },
    toggleGlobal() {
      this.$emit("toggleGlobal");
    },     
  }
};
</script>

<style lang="scss" scoped>
.dropdown-button{
  text-align: center;
  background-color: transparent;
  width: 32px;
  height: 16px;
  font-size: 13px;
  font-family: "Montserrat";
}
.links-advanced {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 18px;
  height: 18px;
  cursor: pointer;
}
.links {
  cursor: pointer;
}
.link-disabled {
  filter: invert(50%);
}
.container-mg {
  border: 0.5px solid #ededf0;
  display: flex;
  align-items: center;
  padding: 15px 0px !important;
  height: 45px !important;
  border-radius: 10px !important;
  gap: 35px;
  margin-bottom: 12px;
  .borderx {
    border-right: 0.5px solid #ededf0;
    padding-right: 15px;
  }
  .form-group {
    margin: 0 !important;
  }
  input,
  input:hover {
    border: 0.5px solid #ededf000 !important;
    margin: 0 !important;
    padding: 0 !important;
  }
  &:hover {
    border: 0.5px solid var(--greenn);
    .borderx {
      border-right: 0.5px solid var(--greenn);
    }
  } 
}
</style>
